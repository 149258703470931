import { Observable, fromEvent, map } from 'rxjs';

export class MessagePortError extends Error {
  override name = 'MessagePortError';

  constructor(
    /** Original event error data */
    public readonly error: unknown,
  ) {
    super('Error when receiving message');
  }
}

/**
 * Creates an `Observable` that emits when message events are received
 * from the given `port`.
 *
 * @param port Port to received messages events on.
 *
 * @returns
 * `Observable` that emits with the event messages received on the port.
 * If a `messageerror` is received, the `Observable` will error.
 */
function fromPortMessageEvent<T = MessageEvent<unknown>>(
  port: MessagePort,
): Observable<T> {
  return new Observable<T>((observer) => {
    const sub = fromEvent<T>(port, 'message').subscribe(observer);
    sub.add(
      fromEvent<MessageEvent>(port, 'messageerror').subscribe((event) => {
        sub.unsubscribe();
        observer.error(new MessagePortError(event.data));
      }),
    );

    port.start();

    return () => {
      sub.unsubscribe();
    };
  });
}

/**
 * Creates an `Observable` that emits when messages are received from
 * the given `port`.
 *
 * @param port Port to received messages on.
 *
 * @returns
 * `Observable` that emits with the messages received on the port.
 * If a `messageerror` is received, the `Observable` will error.
 */
export function fromPortMessage<T>(port: MessagePort): Observable<T> {
  return fromPortMessageEvent<MessageEvent<T>>(port).pipe(
    map((event) => event.data),
  );
}

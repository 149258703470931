import { CustomerId } from '@fmnts/api/shared';

function joinNames(...parts: string[]) {
  return parts.filter((n) => n).join(' ');
}

export type FundraiserId = IFundraiserMinimal['id'];

export interface IFundraiserMinimal {
  id: string;
  email: string | null;
  firstName: string;
  fundraiserCode: string;
  image: string | null;
  language: string | null;
  locale: string | null;
  lastName: string;
  fundraiserExternalId: string | null;
}

export interface IFundraiser {
  id: string;
  city: string | null;
  createdAt: Date;
  customerId: CustomerId;
  email: string | null;
  firstName: string;
  fundraiserCode: string;
  fundraiserExternalId: string | null;
  image: string | null;
  image_upload: boolean | null;
  isActive: boolean;
  isCoach: boolean;
  isTeamLeader: boolean;
  language: string | null;
  locale: string | null;
  lastName: string;
  modifiedAt: Date;
  phoneNumber: string | null;
  street: string | null;
  username: string;
  zipCode: string | null;
}

export class FundraiserMinimal implements IFundraiserMinimal {
  public id: string;
  public firstName: string;
  public lastName: string;
  public fundraiserCode: string;

  public email: string | null;
  public fundraiserExternalId: string | null;
  public image: string | null;
  public language: string | null;
  public locale: string | null;

  constructor(dto: Readonly<IFundraiserMinimal>) {
    this.id = dto.id;
    this.email = dto.email;
    this.firstName = dto.firstName;
    this.fundraiserCode = dto.fundraiserCode;
    this.fundraiserExternalId = dto.fundraiserExternalId;
    this.image = dto.image;
    this.language = dto.language;
    this.locale = dto.locale;
    this.lastName = dto.lastName;
  }

  get fullName(): string {
    return joinNames(this.firstName, this.lastName);
  }
}
export class Fundraiser implements IFundraiser {
  public id: string;
  public city: string | null;
  public createdAt: Date;
  public customerId: CustomerId;
  public email: string | null;
  public firstName: string;
  public fundraiserCode: string;
  public fundraiserExternalId: string | null;
  public image: string | null;
  public image_upload: boolean | null;
  public isActive: boolean;
  public isCoach: boolean;
  public isTeamLeader: boolean;
  public language: string | null;
  public locale: string | null;
  public lastName: string;
  public modifiedAt: Date;
  public phoneNumber: string | null;
  public street: string | null;
  public username: string;
  public zipCode: string | null;

  get fullName(): string {
    return joinNames(this.firstName, this.lastName);
  }

  constructor(dto: Readonly<IFundraiser>) {
    this.id = dto.id;
    this.city = dto.city;
    this.createdAt = new Date(dto.createdAt);
    this.customerId = dto.customerId;
    this.email = dto.email;
    this.firstName = dto.firstName;
    this.fundraiserCode = dto.fundraiserCode;
    this.fundraiserExternalId = dto.fundraiserExternalId;
    this.image = dto.image;
    this.image_upload = dto.image_upload;
    this.isActive = dto.isActive;
    this.isCoach = dto.isCoach;
    this.isTeamLeader = dto.isTeamLeader;
    this.language = dto.language;
    this.locale = dto.locale;
    this.lastName = dto.lastName;
    this.modifiedAt = new Date(dto.modifiedAt);
    this.phoneNumber = dto.phoneNumber;
    this.street = dto.street;
    this.username = dto.username;
    this.zipCode = dto.zipCode;
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, CanLoadFn } from '@angular/router';
import {
  AppFeatureFlags,
  AppFeatures,
} from '@app/core/config/feature-flag.service';
import {
  FeatureFlagGuardConfig,
  featureFlagCanActivate,
  featureFlagCanLoad,
} from '@fmnts/common/feature-flags';

/**
 * Can Activate guard that checks for app feature flags.
 *
 * @param config Guard configuration
 * @returns
 * Guard function.
 */
export function appFeatureFlagCanActivate(
  config: FeatureFlagGuardConfig<AppFeatures>,
): CanActivateFn {
  return () =>
    featureFlagCanActivate(
      {
        fallbackUrl: '/not-found',
        ...config,
      },
      inject(AppFeatureFlags),
    );
}

/**
 * Can Load guard that checks for app feature flags.
 *
 * @param config Guard configuration
 * @returns
 * Guard function.
 */
export function appFeatureFlagCanLoad(
  config: FeatureFlagGuardConfig<AppFeatures>,
): CanLoadFn {
  return () =>
    featureFlagCanLoad(
      {
        fallbackUrl: '/not-found',
        ...config,
      },
      inject(AppFeatureFlags),
    );
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPageAdapter, PagedApiResponse, type Page } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiQuizFundraiserStatusAdapter } from '../adapter/quiz-fundraiser-status.adapter';
import {
  ApiQuizAdapter,
  ApiQuizMinimalAdapter,
  IQuizDto,
  QuizToApiAdapter,
} from '../adapter/quiz.adapter';
import {
  IApiQuiz,
  IApiQuizAssignment,
  IApiQuizMinimal,
} from '../api-model/api-model';
import { Quiz, QuizFundraiserStatus, QuizMinimal } from '../model/quiz';

interface PageQuery {
  page?: number;
  page_size?: number;
}

interface SortingQuery {
  ordering?: string;
}

export interface GetQuizListQuery extends PageQuery, SortingQuery {}

@Injectable({
  providedIn: 'root',
})
export class TrainingcenterQuizApi {
  public static readonly rootUrl = '/v2/trainingcenter/quizzes';

  private readonly _fromApiQuizMinimalAdapter = new ApiQuizMinimalAdapter();
  private readonly _fromApiQuizAdapter = new ApiQuizAdapter();
  private readonly _fromApiQuizPageAdapter = new ApiPageAdapter(
    this._fromApiQuizMinimalAdapter,
  );
  private readonly _fromApiQuizFundraiserStatusAdapter =
    new ApiQuizFundraiserStatusAdapter();

  private readonly _toApiAdapter = new QuizToApiAdapter();

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  /**
   * Fetch a quiz using `quiz.id`
   *
   * @param id The id of the quiz
   */
  public get(id: string): Observable<Quiz> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterQuizApi.rootUrl,
      id,
    ]);

    return this.http
      .get<IApiQuiz>(url)
      .pipe(map((response) => this._fromApiQuizAdapter.adapt(response)));
  }

  /**
   * Fetches a list of quizzes
   *
   * @param urlOrFilter options for filtering
   */
  public list(
    urlOrFilter: string | GetQuizListQuery = {},
  ): Observable<Page<QuizMinimal>> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterQuizApi.rootUrl,
    ]);

    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiQuizMinimal>>(url, { params })
      .pipe(map((response) => this._fromApiQuizPageAdapter.adapt(response)));
  }

  /**
   * Creates a new quiz with the given quiz object
   *
   * @param quiz Complete or partial Quiz Object
   */
  public create(quiz: IQuizDto): Observable<Quiz> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterQuizApi.rootUrl,
    ]);
    const data = this._toApiAdapter.adaptForCreate(quiz);

    return this.http
      .post<IApiQuiz>(url, data)
      .pipe(map((response) => this._fromApiQuizAdapter.adapt(response)));
  }

  /**
   * Updates the quiz with the given `quiz.id` and the data it holds.
   *
   * @param quiz The quiz with the id and the new data
   */
  public edit(quiz: Partial<IQuizDto>): Observable<Quiz> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterQuizApi.rootUrl,
      quiz.id,
    ]);
    const data = this._toApiAdapter.adaptForUpdate(quiz);

    return this.http
      .patch<IApiQuiz>(url, data)
      .pipe(map((response) => this._fromApiQuizAdapter.adapt(response)));
  }

  /**
   * Deletes a quiz using the `quiz.id`.
   *
   * @param quiz The quiz which should be deleted
   */
  public delete(quiz: Quiz): Observable<any> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterQuizApi.rootUrl,
      quiz.id,
    ]);

    return this.http.delete(url);
  }

  public getAssignments(
    quizId: Quiz['id'],
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<{ quizId: Quiz['id']; fundraisers: QuizFundraiserStatus[] }> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterQuizApi.rootUrl,
      quizId,
      'assignments',
    ]);

    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http.get<IApiQuizAssignment>(url, { params }).pipe(
      map(({ fundraisers }) => ({
        quizId,
        fundraisers: fundraisers.map((f) =>
          this._fromApiQuizFundraiserStatusAdapter.adapt(f),
        ),
      })),
    );
  }
}

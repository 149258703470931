import { createPathGetter } from '@fmnts/core/object';
import { MonoTypeOperatorFunction, distinctUntilChanged } from 'rxjs';

type Path = string | (string | number)[];

/**
 * @param keys
 * Array of keys to observe for changes.
 * Does also support nested keys like
 * `'groupname.control'` or `['groupname', 'control']`
 *
 * @returns
 * Observable that emits when any of the specified keys changed a value
 */
export function distinctUntilFormKeysChanged<T extends Record<string, unknown>>(
  keys: Path[],
): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged<T>(formKeyChanged(keys));
}

function formKeyChanged(keys: Path[]) {
  return (prev: Record<string, unknown>, current: Record<string, unknown>) => {
    const a = createPathGetter(current, '.');
    const b = createPathGetter(prev, '.');

    return keys.every((k) => a(k) === b(k));
  };
}

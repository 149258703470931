import { ENTER, SPACE } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { SelectableChipComponent } from './base-chip.component';

/**
 * Displays a chip option.
 * A chip option is selectable and can be disabled.
 *
 * @example
 * <fmnts-chip-option>
 *  Chip content
 * </fmnts-chip-option>
 */
@Component({
  selector: 'fmnts-chip-option',
  templateUrl: 'chip-option.component.html',
  styleUrls: ['./chip.component.scss', './chip-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipOptionComponent extends SelectableChipComponent {
  @HostBinding('class.fmnts-chip-option')
  protected readonly _componentClass = 'fmnts-chip-option';

  @HostListener('keydown', ['$event'])
  protected _handleKeydown(event: KeyboardEvent): void {
    if (
      (event.keyCode === ENTER || event.keyCode === SPACE) &&
      !this.disabled
    ) {
      event.preventDefault();
      this._toggle(true);
    }
  }
}

export interface ITrainingcenterAssignment {
  score: number;
  status: string;
  title: string;
  uniqueName: string;
}

export interface ITrainingcenterTestMinimal {
  title: string;
}
export interface ITrainingcenterTest extends ITrainingcenterTestMinimal {
  uniqueName: string;
}

export interface ITrainingcenterMember {
  id: string;
  assignments: ITrainingcenterAssignment[];
  averageScore: number;
  completedAssignments: number;
  image: string;
  name: string;
}

export interface ITrainingcenterGroupMinimal {
  membersCount: number;
  name: string;
  tests: ITrainingcenterTestMinimal[];
}

export interface ITrainingcenterGroup extends ITrainingcenterGroupMinimal {
  tests: ITrainingcenterTest[];
  members: ITrainingcenterMember[];
}

export class TrainingcenterAssignment implements ITrainingcenterAssignment {
  score: number;
  status: string;
  title: string;
  uniqueName: string;

  constructor(dto: ITrainingcenterAssignment) {
    this.score = dto.score;
    this.status = dto.status;
    this.title = dto.title;
    this.uniqueName = dto.uniqueName;
  }
}

export class TrainingcenterTestMinimal implements ITrainingcenterTestMinimal {
  title: string;

  constructor(dto: Readonly<ITrainingcenterTestMinimal>) {
    this.title = dto.title;
  }
}

export class TrainingcenterTest
  extends TrainingcenterTestMinimal
  implements ITrainingcenterTest
{
  uniqueName: string;

  constructor(dto: Readonly<ITrainingcenterTest>) {
    super(dto);
    this.uniqueName = dto.uniqueName;
  }
}

export class TrainingcenterMember implements ITrainingcenterMember {
  id: string;
  assignments: TrainingcenterAssignment[];
  averageScore: number;
  completedAssignments: number;
  image: string;
  name: string;

  get fullName(): string {
    return this.name;
  }

  constructor(dto: Readonly<ITrainingcenterMember>) {
    this.id = dto.id;
    this.averageScore = dto.averageScore;
    this.completedAssignments = dto.completedAssignments;
    this.image = dto.image;
    this.name = dto.name;

    this.assignments = dto.assignments.map(
      (a) => new TrainingcenterAssignment(a),
    );
  }
}

export class TrainingcenterGroupMinimal implements ITrainingcenterGroupMinimal {
  membersCount: number;
  name: string;
  tests: TrainingcenterTestMinimal[];

  constructor(dto: Readonly<ITrainingcenterGroupMinimal>) {
    this.membersCount = dto.membersCount;
    this.name = dto.name;
    this.tests = dto.tests.map((t) => new TrainingcenterTestMinimal(t));
  }
}

export class TrainingcenterGroup implements ITrainingcenterGroup {
  membersCount: number;
  name: string;

  members: TrainingcenterMember[];
  tests: TrainingcenterTest[];

  constructor(dto: Readonly<ITrainingcenterGroup>) {
    this.name = dto.name;
    this.membersCount = dto.membersCount;

    this.members = dto.members.map((m) => new TrainingcenterMember(m));
    this.tests = dto.tests.map((t) => new TrainingcenterTest(t));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPageAdapter, PagedApiResponse, type Page } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiCampaignAdapter } from '../adapter/campaign.adapter';
import { IApiCampaign } from '../api-model/api-model';
import type { Campaign } from '../model/campaign';

@Injectable({
  providedIn: 'root',
})
export class CampaignApi {
  private static readonly rootUrl = '/v1/campaigns';

  private readonly _fromApiAdapter = new ApiCampaignAdapter();
  private readonly _fromApiPageAdapter = new ApiPageAdapter(
    this._fromApiAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(id: string, forceRefresh = false): Observable<Campaign> {
    const url = this.configService.buildCockpitApiUrl([
      CampaignApi.rootUrl,
      id,
    ]);

    const headers = this.apiHelper.makeDefaultHeaders({
      force: forceRefresh,
    });

    return this.http
      .get<IApiCampaign>(url, { headers })
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<Campaign>> {
    const url = this.configService.buildCockpitApiUrl([CampaignApi.rootUrl]);

    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiCampaign>>(url, { params })
      .pipe(map((response) => this._fromApiPageAdapter.adapt(response)));
  }
}

import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeSpacing, cssVarFactory } from '@fmnts/components/core';

export const columnLayoutCssVars = cssVarFactory<'gap' | 'columns'>('cols');

/**
 * Displays a column layout.
 */
@Directive({
  selector: `fmnts-cols, [fmnts-cols]`,
})
export class ColumnLayoutDirective {
  protected readonly componentClass = 'fmnts-cols';

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([this.componentClass]);
  }

  /**
   * Spacing between the columns.
   */
  @Input()
  get gap(): ThemeSpacing | null {
    return this._gap;
  }
  set gap(value: ThemeSpacing | null) {
    this._gap = value;
  }
  private _gap: ThemeSpacing | null = null;

  /**
   * Defines the number of columns.
   * If not provided, the default is taken.
   */
  @Input()
  get columns(): number | null {
    return this._columns;
  }
  set columns(value: NumberInput | null) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._columns = coerceNumberProperty(value, null);
  }
  private _columns: number | null = null;

  /**
   * Default classes for each column.
   * Requires to use the `fmnts-col` directive on the column.
   */
  @Input() columnClass: string | null = null;

  @HostBinding('style')
  protected get hostStyles(): Record<string, string> {
    return columnLayoutCssVars({
      gap: this._gap && ThemeSpacing[this._gap],
      columns: this.columns,
    });
  }
}

/**
 * Displays a column in a column layout.
 */
@Directive({
  selector: `fmnts-col, [fmnts-col]`,
})
export class ColumnLayoutColumnDirective {
  protected readonly componentClass = 'fmnts-col';

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([this.componentClass, this.container.columnClass]);
  }

  constructor(protected container: ColumnLayoutDirective) {}
}

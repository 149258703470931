/**
 * Helper to check if a given `date` is an invalid date.
 */
function _isInvalidDate(date: Date): boolean {
  return isNaN(date.valueOf());
}

/**
 * Checks if the given `date` is the invalid date.
 *
 * @param u Value to check
 * @returns
 * `true` if `u` is the invalid date.
 */
export function isInvalidDate(u: unknown): u is never {
  return isDate(u) && _isInvalidDate(u);
}

/**
 * Type guard to check if a given value is of type `Date`.
 * Could also be the invalid date.
 *
 * @param u Value to check
 * @returns
 */
export function isDate(u: unknown): u is Date {
  return u instanceof Date;
}

/**
 * Type guard to check if a given value is a valid date.
 *
 * @param u Value to check
 * @returns
 */
export function isValidDate(u: unknown): u is Date {
  return isDate(u) && !_isInvalidDate(u);
}

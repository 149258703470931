type KeyType = string | number | symbol;

type HasPropertyPredicate<K extends KeyType> = <
  T extends Partial<Record<K, unknown>>,
>(
  obj: T,
) => boolean;

/**
 *
 * @param properties Name of the properties to check for
 *
 * @returns
 * A function that when called if an object will return `true` if
 * the passed object contains ***any*** of the given `properties`.
 *
 * @example
 * const fn = hasAnyPropertyPredicate(['prop']);
 * fn({prop: 'value'}) // === true;
 * fn({other: 'value'}) // === false;
 */
export function hasAnyPropertyPredicate<K extends KeyType>(
  properties: K[],
): HasPropertyPredicate<K>;

export function hasAnyPropertyPredicate(
  properties: KeyType[],
): HasPropertyPredicate<KeyType> {
  return (obj) => properties.some((p) => p in obj);
}

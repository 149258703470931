import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  HostBinding,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { hasAnyPropertyPredicate } from '@fmnts/core/object';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { Subscription, filter, merge } from 'rxjs';
import { fmntsExpansionAnimations } from './expansion-animation';
import {
  ExpansionTogglePosition,
  FmntsExpansionPanelState,
} from './expansion-model';
import { ExpansionPanelComponent } from './expansion-panel.component';

/**
 * Displays the header component for expansion panels
 */
@Component({
  selector: 'fmnts-expansion-panel-header',
  templateUrl: './expansion-panel-header.component.html',
  styleUrls: ['./expansion-panel-header.component.scss'],
  animations: [fmntsExpansionAnimations.indicatorRotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionPanelHeaderComponent implements OnDestroy {
  @HostBinding('class.fmnts-expansion-panel-header')
  protected readonly componentClass = 'fmnts-expansion-panel-header';

  public readonly toggleIcon = faAngleDown;

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      this._isExpanded && `${this.componentClass}--expanded`,
      this.disabled && `${this.componentClass}--disabled`,
      this._getTogglePosition() === 'before' &&
        `${this.componentClass}--reverse`,
    ]);
  }

  @HostBinding('attr.aria-disabled')
  public get disabled(): boolean {
    return this.panel.disabled;
  }

  private _parentChangeSubscription = Subscription.EMPTY;

  constructor(
    @Host() public panel: ExpansionPanelComponent,
    private _cd: ChangeDetectorRef,
  ) {
    // Since the toggle state depends on an @Input on the panel, we
    // need to subscribe and trigger change detection manually.
    this._parentChangeSubscription = merge(
      panel.opened,
      panel.closed,
      panel._inputChanges.pipe(
        filter(hasAnyPropertyPredicate(['disabled', 'togglePosition'])),
      ),
    ).subscribe(() => this._cd.markForCheck());
  }

  ngOnDestroy(): void {
    this._parentChangeSubscription.unsubscribe();
  }

  toggle(): void {
    if (!this.disabled) {
      this.panel.toggle();
    }
  }

  /** Gets whether the panel is expanded. */
  @HostBinding('attr.aria-expanded')
  protected get _isExpanded(): boolean {
    return this.panel.expanded;
  }

  /** Gets the expanded state string of the panel. */
  _getExpandedState(): FmntsExpansionPanelState {
    return this.panel._getExpandedState();
  }

  _getTogglePosition(): ExpansionTogglePosition {
    return this.panel.togglePosition;
  }
}

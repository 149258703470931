import type { Adapter } from '@fmnts/core';
import { IApiStatisticsPage } from '../api-model/api-model';
import { StatisticsPage } from '../model/statistics-page';
import { ApiStatisticsItemAdapter } from './statistics-item.adapter';

export class ApiStatisticsPageAdapter<T, TSource>
  implements Adapter<StatisticsPage<T>>
{
  private readonly statisticsItemAdapter = new ApiStatisticsItemAdapter();

  constructor(private readonly itemAdapter: Adapter<T, TSource>) {}

  adapt(value: IApiStatisticsPage<TSource>): StatisticsPage<T> {
    return new StatisticsPage({
      count: value.count,
      currentPage: value.current_page,
      next: value.next,
      pageSize: value.page_size,
      previous: value.previous,
      results: value.results.map((item) => this.itemAdapter.adapt(item)),
      totals: value._total
        ? {
            items: value._total.statistics.map((item) =>
              this.statisticsItemAdapter.adapt(item),
            ),
          }
        : undefined,
    });
  }
}

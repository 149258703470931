import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiPageAdapter,
  FileUpload,
  PagedApiResponse,
  type Page,
} from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import {
  ApiFundraiserAdapter,
  FundraiserToApiAdapter,
  IFundraiserDto,
  IFundraiserUpdateDto,
} from '../adapter/fundraiser.adapter';
import { IApiFundraiser } from '../api-model/api-model';
import type { Fundraiser } from '../model/fundraiser';

@Injectable({
  providedIn: 'root',
})
export class FundraiserApi {
  private static readonly rootUrl = '/v1/fundraisers';

  private readonly _toApiAdapter = new FundraiserToApiAdapter();
  private readonly _fromApiAdapter = new ApiFundraiserAdapter();
  private readonly _fromApiPageAdapter = new ApiPageAdapter(
    this._fromApiAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(id: string, forceRefresh = false): Observable<Fundraiser> {
    const url = this.configService.buildCockpitApiUrl([
      FundraiserApi.rootUrl,
      id,
    ]);
    const headers = this.apiHelper.makeDefaultHeaders({ force: forceRefresh });

    return this.http
      .get<IApiFundraiser>(url, { headers })
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<Fundraiser>> {
    const url = this.configService.buildCockpitApiUrl([FundraiserApi.rootUrl]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiFundraiser>>(url, { params })
      .pipe(map((response) => this._fromApiPageAdapter.adapt(response)));
  }

  /**
   * Creates a new fundraiser on the backend.
   *
   * @param fundraiser Fundraiser object with data
   * @param image Fundraiser image
   */
  public create(
    fundraiser: IFundraiserDto,
    image: FileUpload,
  ): Observable<Fundraiser> {
    const url = this.configService.buildCockpitApiUrl([FundraiserApi.rootUrl]);
    const dto = this._toApiAdapter.adaptForCreate(fundraiser);
    const data = this._toApiAdapter.adaptWithImage(dto, image);

    return this.http
      .post<IApiFundraiser>(url, data)
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }

  /**
   * new Fundraiser
   * Updates the given fundraiser with the passed data on the backend
   *
   * @param fundraiser Fundraiser object with data
   * @param image Fundraiser image. When `null` is passed, image will be deleted.
   */
  public update(
    fundraiser: IFundraiserUpdateDto,
    image: FileUpload,
  ): Observable<Fundraiser> {
    const url = this.configService.buildCockpitApiUrl([
      FundraiserApi.rootUrl,
      fundraiser.id,
    ]);
    const dto = this._toApiAdapter.adaptForUpdate(fundraiser);
    const data = this._toApiAdapter.adaptWithImage(dto, image);

    return this.http
      .patch<IApiFundraiser>(url, data)
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }
}

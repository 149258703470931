import type { Adapter } from '@fmnts/core';
import * as Predicate from 'effect/Predicate';
import { CssInlineStyles } from '../model/css-inline-styles';

export class CssInlineStylesAdapter implements Adapter<CssInlineStyles> {
  adapt(item: Record<string, unknown>): CssInlineStyles {
    if (!item || typeof item !== 'object') {
      return {};
    }

    const inlineStyles: CssInlineStyles = {};

    // background color
    if (
      Predicate.hasProperty(item, 'background_color') &&
      Predicate.isString(item.background_color)
    ) {
      inlineStyles['background-color'] = item.background_color;
    }

    return inlineStyles;
  }
}

<div #formControl class="form-control" (click)="toggleInput()">
  <span class="icon"> <fa-icon [icon]="iconCalendar"></fa-icon> </span>

  <span class="value">{{ value | i18n_dateRange }}</span>

  <span class="toggle">
    <fa-icon [icon]="iconOpen" [rotate]="showInput ? 180 : undefined"></fa-icon>
  </span>
</div>

<div #dropdown class="date-range-picker-selector" [class.hidden]="!showInput">
  <div
    class="deselect"
    *ngIf="allowDeselect && fromDate && toDate"
    (click)="input = null; toggleInput(false)"
  >
    {{ 'clear_filter' | translate }}
  </div>

  <div class="date-range-picker-row">
    <div class="ranges">
      <div
        *ngFor="let range of ranges"
        class="range"
        (click)="range.select(); toggleInput(false)"
      >
        {{ range.label | translate }}
      </div>
    </div>

    <app-calendar-picker
      [selectedFromDate]="fromDate"
      [selectedToDate]="toDate"
      [range]="true"
      (selectedRangeChanged)="input = $event; toggleInput(false)"
    ></app-calendar-picker>
  </div>
</div>

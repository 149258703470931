import { UnknownApiError } from './unknown-api-error';

/**
 * Error on the formunauts API occured, but doesn't use the new error
 * response format.
 *
 * @deprecated
 * Let the backend know that this endpoint does not use the new error
 * response format yet.
 * Remove once all API endpoints embrace the new error response format.
 */
export class LegacyFmntsApiError extends UnknownApiError {
  override name = 'LegacyFmntsApiError';
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarIconComponent } from './avatar-icon.component';
import { AvatarTextInitialsPipe } from './avatar-text-initials.pipe';
import {
  AvatarDescriptionComponent,
  AvatarTitleComponent,
} from './avatar-text.component';
import { AvatarComponent } from './avatar.component';

@NgModule({
  declarations: [
    AvatarComponent,
    AvatarIconComponent,
    AvatarDescriptionComponent,
    AvatarTitleComponent,
    AvatarTextInitialsPipe,
  ],
  exports: [
    AvatarComponent,
    AvatarIconComponent,
    AvatarDescriptionComponent,
    AvatarTitleComponent,
    AvatarTextInitialsPipe,
  ],
  imports: [CommonModule],
})
export class FmntsAvatarModule {}

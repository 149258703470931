import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import {
  CssCorner,
  CssCornerList,
  CssPhysicalInset,
  CssPhysicalInsetList,
  cssScale,
  cssTranslate,
} from '@fmnts/components';
import { isDefined } from '@fmnts/core';
import { Rect, Vector2d } from '@fmnts/core/math';
import {
  Observable,
  ReplaySubject,
  Subject,
  filter,
  map,
  mergeMap,
  pairwise,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { ImageEditorComponent } from './image-editor.component';

type PointerLikeEvent = MouseEvent | PointerEvent | TouchEvent;

function getPositionFromEvent(event: PointerLikeEvent) {
  const { clientX, clientY } =
    'changedTouches' in event ? event.changedTouches[0] : event;
  return new Vector2d(clientX, clientY);
}

@Component({
  selector: 'fmnts-image-editor-crop-tool',
  templateUrl: './image-editor-crop-tool.component.html',
  styleUrls: [
    './image-editor-tool.scss',
    './image-editor-crop-tool.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ImageEditorCropToolComponent {
  private readonly destroyed = new ReplaySubject<boolean>(1);

  private readonly _cropRect$ = this._editor._modifications.pipe(
    map((o) => o.crop),
  );

  public canvasSize: Vector2d = Vector2d.zero;

  /** @internal */
  public readonly _edges = CssPhysicalInsetList;
  /** @internal */
  public readonly _corners = CssCornerList;
  /** @internal */
  private readonly _startDrag = new Subject<{
    pointer: Vector2d;
    target: CssCorner | CssPhysicalInset | 'box';
  }>();
  private readonly _stopDrag = new Subject<void>();
  private readonly _resizeDrag = new Subject<PointerLikeEvent>();

  private readonly _resize = this._resizeDrag.pipe(map(getPositionFromEvent));

  constructor(public readonly _editor: ImageEditorComponent) {
    // Resize crop tool when moving
    this._startDrag
      .pipe(
        mergeMap(({ target, pointer }) =>
          this._resize.pipe(
            startWith(pointer),
            pairwise(),
            map(
              ([prev, current]) =>
                [current.subtract(prev), target] as [
                  Vector2d,
                  CssCorner | CssPhysicalInset | 'box',
                ],
            ),
            takeUntil(this._stopDrag),
          ),
        ),
        withLatestFrom(this._cropRect$),
        takeUntil(this.destroyed),
      )
      .subscribe(([[difference, draggedElement], crop]) => {
        if (crop) {
          if (draggedElement === 'box') {
            this._moveCrop(crop, difference);
          } else {
            this._resizeCrop(crop, draggedElement, difference);
          }
        }
      });
  }

  public get cropWidth(): Observable<string> {
    return this._cropRect$.pipe(
      filter(isDefined),
      map((rect) => `${rect.size.x}px`),
      startWith('1px'),
    );
  }

  public get cropHeight(): Observable<string> {
    return this._cropRect$.pipe(
      filter(isDefined),
      map((rect) => `${rect.size.y}px`),
      startWith('1px'),
    );
  }

  public transformCrop(): Observable<string> {
    return this._cropRect$.pipe(
      filter(isDefined),
      map((rect) => {
        const stuff = cssTranslate(
          [...getCropTranslate(CssCorner.TopLeft, rect).toArray(), 0],
          'px',
        );
        return stuff;
      }),
    );
  }

  /**
   * Used to resize the crop
   *
   * @param crop
   * @param cornerOrEdge
   * @param difference
   */
  private _resizeCrop(
    crop: Rect,
    cornerOrEdge: CssCorner | CssPhysicalInset,
    difference: Vector2d,
  ) {
    const viewportRect = new Rect(
      Vector2d.zero,
      this._editor._viewportSize.value,
    );
    let newCrop = resizeCropRect(crop, cornerOrEdge, difference);
    // Make sure that size doesn't get negative (and leave some extra space)
    newCrop = new Rect(
      newCrop.topLeft,
      Vector2d.max(newCrop.size, Vector2d.one.scale(50)),
    );
    // Make sure that the new rect is inside the boundaries
    newCrop = newCrop.constrainBy(viewportRect);

    this._editor.updateImageTransform({
      crop: newCrop,
    });
  }

  /**
   * Moves the crop rect
   * @param crop
   * @param difference
   */
  private _moveCrop(crop: Rect, difference: Vector2d) {
    this._editor.updateImageTransform({
      crop: moveCropRect(
        crop,
        difference,
        new Rect(Vector2d.zero, this._editor._viewportSize.value),
      ),
    });
  }

  public _startCropResize(
    cornerOrEdge: CssCorner | CssPhysicalInset | 'box',
    event: PointerLikeEvent,
  ): void {
    this._startDrag.next({
      target: cornerOrEdge,
      pointer: getPositionFromEvent(event),
    });
  }

  /**
   * @internal
   */
  @HostListener('window:mouseup')
  @HostListener('window:touchup')
  public _stopCropResize(): void {
    return this._stopDrag.next();
  }

  /**
   * @internal
   */
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  public _mouseMove(ev: PointerLikeEvent): void {
    this._resizeDrag.next(ev);
  }

  /**
   * @param edge Edge for which to calculate the CSS transform
   * @returns
   * Observable that emits with the CSS transform
   *
   * @internal
   */
  public transformForEdge$(edge: CssPhysicalInset): Observable<string> {
    return this._cropRect$.pipe(
      filter(isDefined),
      map((cropRect) => {
        const scale = cssScale(getCropScale(edge, cropRect));
        const corner =
          edge === 'bottom'
            ? CssCorner.BottomLeft
            : edge === 'right'
              ? CssCorner.TopRight
              : CssCorner.TopLeft;

        const translate = cssTranslate(
          [...getCropTranslate(corner, cropRect).toArray(), 0],
          'px',
        );

        return [translate, scale].join(' ');
      }),
    );
  }

  /**
   * @param corner Corner for which to calculate the CSS transform
   * @returns
   * Observable that emits with the CSS transform
   *
   * @internal
   */
  public transformForCorner$(corner: CssCorner): Observable<string> {
    return this._cropRect$.pipe(
      filter(isDefined),
      map((cropRect) =>
        cssTranslate(
          [...getCropTranslate(corner, cropRect).toArray(), 0],
          'px',
        ),
      ),
    );
  }
}

function getCropTranslate(corner: CssCorner, crop: Rect): Vector2d {
  switch (corner) {
    case 'top-left':
      return crop.topLeft;
    case 'top-right':
      return crop.topRight;
    case 'bottom-right':
      return crop.bottomRight;
    case 'bottom-left':
      return crop.bottomLeft;
  }

  return Vector2d.zero;
}

function getCropScale(
  edge: CssPhysicalInset,
  { size }: Rect,
): [number, number] {
  switch (edge) {
    case 'left':
    case 'right':
      return [1, size.y];
    case 'top':
    case 'bottom':
      return [size.x, 1];
  }

  return [1, 1];
}

function resizeCropRect(
  crop: Rect,
  cornerOrEdge: CssCorner | CssPhysicalInset,
  difference: Vector2d,
): Rect {
  switch (cornerOrEdge) {
    // Edges
    case 'left':
      return crop.offsetBy(Vector2d.right.scale(difference.x));
    case 'top':
      return crop.offsetBy(Vector2d.up.scale(difference.y));
    case 'bottom':
      return crop.resizeBy(Vector2d.up.scale(difference.y));
    case 'right':
      return crop.resizeBy(Vector2d.right.scale(difference.x));

    // Corners
    case 'top-left':
      return crop.offsetBy(difference);
    case 'bottom-right':
      return crop.resizeBy(difference);
    case 'top-right':
      return new Rect(
        crop.topLeft.add(new Vector2d(0, difference.y)),
        crop.size.add(new Vector2d(difference.x, -difference.y)),
      );
    case 'bottom-left':
      return new Rect(
        crop.topLeft.add(new Vector2d(difference.x, 0)),
        crop.size.add(new Vector2d(-difference.x, difference.y)),
      );
  }

  return crop;
}

/**
 * @param crop
 * @param difference
 * @param imageSize
 * Used to find boundaries for moving the crop rectangle
 * @returns
 */
function moveCropRect(crop: Rect, difference: Vector2d, imageSize: Rect): Rect {
  return crop.translate(difference).constrainBy(imageSize);
}

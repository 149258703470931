import { Directive } from '@angular/core';
import { AbstractControlDirective, NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

/** An interface which allows a control to work inside of a `FmntsFormField`. */
@Directive()
export abstract class FmntsFormFieldControl<T> {
  /** The value of the control. */
  abstract value: T | null;

  /**
   * Stream that emits whenever the state of the control changes such that the parent `FmntsFormField`
   * needs to run change detection.
   */
  abstract readonly stateChanges: Observable<void>;

  /** The element ID for this control. */
  abstract readonly id: string;

  /** The placeholder for this control. */
  readonly placeholder: string | null = null;

  /** Gets the AbstractControlDirective for this control. */
  abstract readonly ngControl: NgControl | AbstractControlDirective | null;

  /** Whether the control is focused. */
  abstract readonly focused: boolean;

  /** Whether the control is empty. */
  abstract readonly empty: boolean;

  /** Whether the control is required. */
  abstract readonly required: boolean;

  /** Whether the control is disabled. */
  abstract readonly disabled: boolean;

  /** Whether the control is readonly. */
  abstract readonly readonly: boolean;

  /** Whether the control is in an error state. */
  abstract readonly errorState: boolean;

  /**
   * An optional name for the control type that can be used to distinguish `fmnts-form-field` elements
   * based on their control type. The form field will add a class,
   * `fmnts-form-field-type-{{controlType}}` to its root element.
   */
  readonly controlType?: string;

  /**
   * Value of `aria-describedby` that should be merged with the described-by ids
   * which are set by the form-field.
   */
  readonly userAriaDescribedBy?: string;

  /** Sets the list of element IDs that currently describe this control. */
  abstract setDescribedByIds(ids: string[]): void;

  /** Handles a click on the control's container. */
  abstract onContainerClick(event: MouseEvent): void;
}

import type { Adapter } from '@fmnts/core';
import { IApiStatisticsItem } from '../api-model/api-model';
import { StatisticsItem } from '../model/statistics-item';

export class ApiStatisticsItemAdapter implements Adapter<StatisticsItem> {
  public adapt(apiDto: IApiStatisticsItem): StatisticsItem {
    return new StatisticsItem({
      slug: apiDto.slug,
      order: apiDto.order,
      value: apiDto.value,
      type: apiDto.type,
      title: apiDto.title,
    });
  }
}

import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ViewOptionMergeStrategy,
  mergeOptionsForView,
  mergeViews,
} from '../merge-view-options';
import { ViewOptionStore } from '../view-option.model';
import { ViewOptionActions } from './view-options.actions';

export const VIEW_OPTIONS_FEATURE_KEY = 'viewOptions';

export interface ViewOptionsFeatureState {
  views: ViewOptionStore;
}

export interface ViewOptionsPartialState {
  readonly [VIEW_OPTIONS_FEATURE_KEY]: ViewOptionsFeatureState;
}

export const initialViewOptionsFeatureState: ViewOptionsFeatureState = {
  views: {},
};

export const viewOptionsFeature = createFeature({
  name: VIEW_OPTIONS_FEATURE_KEY,
  reducer: createReducer(
    initialViewOptionsFeatureState,
    on(
      ViewOptionActions.set,
      (state, { type, ...newState }): ViewOptionsFeatureState => newState,
    ),
    on(
      ViewOptionActions.patchDisplayOptions,
      (state, { viewId, displayOptions }): ViewOptionsFeatureState => ({
        ...state,
        views: mergeViews(state, {
          [viewId]: mergeOptionsForView(
            state,
            viewId,
            { display: displayOptions },
            ViewOptionMergeStrategy.Merge,
          ),
        }),
      }),
    ),
    on(
      ViewOptionActions.patchFilters,
      (state, { viewId, filters }): ViewOptionsFeatureState => ({
        ...state,
        views: mergeViews(state, {
          [viewId]: mergeOptionsForView(
            state,
            viewId,
            { filters },
            ViewOptionMergeStrategy.Merge,
          ),
        }),
      }),
    ),
    on(
      ViewOptionActions.setDisplayOptions,
      (state, { viewId, displayOptions }): ViewOptionsFeatureState => ({
        ...state,
        views: mergeViews(state, {
          [viewId]: mergeOptionsForView(
            state,
            viewId,
            { display: displayOptions },
            ViewOptionMergeStrategy.Set,
          ),
        }),
      }),
    ),
    on(
      ViewOptionActions.setFilters,
      (state, { viewId, filters }): ViewOptionsFeatureState => ({
        ...state,
        views: mergeViews(state, {
          [viewId]: mergeOptionsForView(
            state,
            viewId,
            { filters },
            ViewOptionMergeStrategy.Set,
          ),
        }),
      }),
    ),
  ),
});

export const { reducer: viewOptionsFeatureReducer } = viewOptionsFeature;

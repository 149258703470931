import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  EmbeddedViewRef,
  Injectable,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import { first } from 'rxjs';
import { ToastRef } from './toast-component-ref';
import { ToastConfig } from './toast-config';
import { ToastGroupComponent } from './toast-group.component';
import { TextOnlyToast, TextOnlyToastData } from './toast.component';

/**
 * Service to dispatching multiple toasts in a toast group.
 */
@Injectable()
export class Toaster implements OnDestroy {
  /**
   * Reference to all toasts in the view.
   */
  private _toastRefsAtThisLevel = new Set<ToastRef<unknown, unknown>>();

  /**
   * Toast group that displays the toasts.
   */
  private get toastGroup() {
    if (!this._toastGroup) {
      this._toastGroup = this._createToastGroup();
    }
    return this._toastGroup;
  }

  private _toastGroup: ToastGroupComponent | undefined;
  private _overlayRef: OverlayRef;

  /**
   * The type to use to create a new toast group component.
   */
  private readonly _toastGroupComponentType: ComponentType<ToastGroupComponent> =
    ToastGroupComponent;

  constructor(
    _overlay: Overlay,
    @Optional() @SkipSelf() private _parentToaster: Toaster,
  ) {
    this._overlayRef = _overlay.create({
      panelClass: 'fmnts-toast-group-container',
      width: '100%',
      height: '100%',
    });
  }

  ngOnDestroy(): void {
    // Only dismiss the toast at the current level on destroy.
    for (const toastRef of this._toastRefsAtThisLevel) {
      toastRef.dismiss();
    }

    this._overlayRef.dispose();
  }

  /**
   * Creates and dispatches a toast with a custom component for the content.
   *
   * @param component Component to be instantiated.
   * @param config Extra configuration for the toast.
   */
  openFromComponent<T, D = any, TResult = unknown>(
    component: ComponentType<T>,
    config?: ToastConfig<D>,
  ): ToastRef<T, TResult> {
    const ref = this.toastGroup.openFromComponent<T, D, TResult>(
      component,
      config,
    );
    this._trackToastRef(ref);
    return ref;
  }

  /**
   * Creates and dispatches a toast with a custom template for the content.
   *
   * @param template Template to be instantiated.
   * @param config Extra configuration for the toast.
   */
  openFromTemplate<D = any, TResult = unknown>(
    template: TemplateRef<any>,
    config?: ToastConfig<D>,
  ): ToastRef<EmbeddedViewRef<any>, TResult> {
    const ref = this.toastGroup.openFromTemplate<D, TResult>(template, config);
    this._trackToastRef(ref);
    return ref;
  }

  /**
   * Opens a toast from static data.
   * @param data Data to be passed to toast
   * @param config Additional configuration options for the toast.
   */
  open<TResult = unknown>(
    data: TextOnlyToastData,
    config?: ToastConfig<TextOnlyToastData>,
  ): ToastRef<TextOnlyToast, TResult> {
    const ref = this.toastGroup.open<TextOnlyToastData, TResult>(data, config);
    this._trackToastRef(ref);
    return ref;
  }

  /**
   * Helper to keep track of toast refs created with this toaster.
   *
   * @param toastRef
   */
  private _trackToastRef(toastRef: ToastRef<unknown, any>) {
    this._toastRefsAtThisLevel.add(toastRef);

    toastRef
      .afterDismissed()
      .pipe(first())
      .subscribe(() => {
        this._toastRefsAtThisLevel.delete(toastRef);
      });
  }

  /**
   * Creates the toast group component on demand.
   *
   * @returns
   * Instance of a toast group component.
   */
  private _createToastGroup() {
    const toastGroupPortal = new ComponentPortal(this._toastGroupComponentType);
    const toastGroupRef = this._overlayRef.attach(toastGroupPortal);
    return toastGroupRef.instance;
  }
}

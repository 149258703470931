import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipOptionComponent } from './chip-option.component';
import { ChipComponent } from './chip.component';

@NgModule({
  declarations: [ChipComponent, ChipOptionComponent],
  exports: [ChipComponent, ChipOptionComponent],
  imports: [CommonModule],
})
export class FmntsChipModule {}

/* eslint-disable @angular-eslint/no-host-metadata-property */
import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  InjectionToken,
  ViewChild,
  ViewEncapsulation,
  inject,
  signal,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { htmlIdMaker } from '@fmnts/components/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import {
  IconDefinition,
  faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { AbstractRadioButton } from './base-radio-button.directive';
import { FmntsRadioButton } from './radio.model';

export interface RadioCardDefaultOptions {
  iconChecked: IconDefinition;
}

export const FMNTS_RADIO_CARD_DEFAULT_OPTIONS =
  new InjectionToken<RadioCardDefaultOptions>(
    '@fmnts.components.radio.card.default-options',
    {
      factory: () => ({
        iconChecked: faCheckCircle,
      }),
    },
  );

const radioIds = htmlIdMaker('fmnts-radio-card');

@Component({
  selector: 'fmnts-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss'],
  standalone: true,
  imports: [FmntsIconsModule],
  providers: [
    {
      provide: FmntsRadioButton,
      useExisting: RadioCardComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    // Needs to be removed since it causes some a11y issues.
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[attr.aria-describedby]': 'null',
  },
})
export class RadioCardComponent
  extends AbstractRadioButton<unknown>
  implements AfterViewInit
{
  private readonly _focusMonitor = inject(FocusMonitor);
  protected readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  protected readonly options = inject(FMNTS_RADIO_CARD_DEFAULT_OPTIONS);

  override _id = signal(radioIds.next().value);

  protected readonly _iconChecked = this.options.iconChecked;

  @HostBinding('class.fmnts-radio-card') protected componentClass =
    'fmnts-radio-card';

  /** The native `<input type=radio>` element */
  @ViewChild('input') _inputElement!: ElementRef<HTMLInputElement>;

  @HostBinding('class') get hostClasses(): string {
    return classnames([
      this.disabled && `${this.componentClass}--disabled`,
      this.checked && `${this.componentClass}--checked`,
    ]);
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this._focusMonitor
      .monitor(this._elementRef, true)
      .subscribe((focusOrigin) => {
        if (!focusOrigin) {
          this._emitRadioGroupTouched();
        }
      });
    this._destroyRef.onDestroy(() => {
      this._focusMonitor.stopMonitoring(this._elementRef);
    });
  }

  protected override setTabIndex(value: number): void {
    // We have to set the tabindex directly on the DOM node, because it depends on
    // the selected state which is prone to "changed after checked errors".
    this._inputElement?.nativeElement?.setAttribute('tabindex', value + '');
  }

  @HostListener('focus')
  protected override focus(options?: FocusOptions, origin?: FocusOrigin): void {
    if (origin) {
      this._focusMonitor.focusVia(this._inputElement, origin, options);
    } else {
      this._inputElement.nativeElement.focus(options);
    }
  }

  /** Triggered when the radio button receives an interaction from the user. */
  protected _onInputInteraction(event: Event): void {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();

    this._select();
  }

  /** Triggered when the user clicks on the touch target. */
  @HostListener('click', ['$event'])
  protected _onClick(event: Event): void {
    this._onInputInteraction(event);

    if (!this.disabled) {
      this.focus();
    }
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfigService } from '@fmnts/api/util';
import { Observable } from 'rxjs';
import { AuthClientConfig } from './auth-client-config.service';
import { ApiTokenResponse } from './auth-response.types';

/**
 * Client for handling OAuth related API calls.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthClient {
  constructor(
    private apiConfig: ApiConfigService,
    private authConfig: AuthClientConfig,
    private http: HttpClient,
  ) {}

  /**
   * Acquires a token via the given `username` and `password`
   * and emits with the authentication information, then completes.
   *
   * @param username
   * @param password
   */
  public authenticate(
    username: string,
    password: string,
  ): Observable<ApiTokenResponse> {
    const url = this.authConfig.buildAuthUrl(['/token/']);
    const data = new FormData();
    data.append('username', username);
    data.append('password', password);
    data.append('grant_type', 'password');
    data.append('client_id', this.authConfig.clientId);

    return this.http.post<ApiTokenResponse>(url, data);
  }

  /**
   * Refreshes the oauth token by using the `refreshToken` acquired by
   * `authenticate`.
   *
   * @param refreshToken
   * Token to refresh the oauth token.
   */
  public refresh(refreshToken: string): Observable<ApiTokenResponse> {
    const url = this.authConfig.buildAuthUrl(['/token/']);
    const data = new FormData();
    data.append('refresh_token', refreshToken);
    data.append('grant_type', 'refresh_token');
    data.append('client_id', this.authConfig.clientId);

    return this.http.post<ApiTokenResponse>(url, data);
  }

  /**
   * Revokes the given auth `token`.
   *
   * @param accessToken Access token that should be revoked.
   *
   * @returns
   * An `Observable` that emits when the token was revoked.
   */
  public revoke(accessToken: string): Observable<void> {
    const url = this.authConfig.buildAuthUrl(['/revoke_token/']);

    const data = new FormData();
    data.append('token', accessToken);
    data.append('grant_type', 'revoke_token');
    data.append('client_id', this.authConfig.clientId);

    return this.http.post<void>(url, data);
  }

  /**
   * Creates an `Observable` that upon subscription will access
   * the backend auth page via an iframe. It will emit a boolean
   * value indicating if the request was successful, then complete.
   *
   * Note: `true` won't mean that the authentication was successful,
   * but rather that the request didn't result in an error.
   *
   * @param access_token Access token
   */
  public authenticateWithAdmin(access_token: string): Observable<unknown> {
    const url = this.apiConfig.buildApiUrl(['/admin_oauth_login']);
    const params = new HttpParams().append('token', access_token);

    return this.http.get(url, { params, responseType: 'text' });
  }

  /**
   * @returns
   * An `Observable` that emits with the result of the unauthentication request
   * against admin, then completes.
   */
  public unauthenticateWithAdmin(): Observable<unknown> {
    const url = this.apiConfig.buildApiUrl(['/admin/logout/']);
    return this.http.post(url, null);
  }
}

import { inject, Injectable } from '@angular/core';
import { none, Option } from '@fmnts/core';
import { selectAuth } from '@fmnts/shared/auth/data-access';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

interface DiagnosticsMeta {
  username: Option<string>;
}

@Injectable({
  providedIn: 'root',
})
export class AppDiagnosticsMetaService {
  private readonly _store = inject(Store);

  private readonly _auth$ = this._store.select(selectAuth);

  /**
   * Collects diagnostics meta information.
   *
   * @returns
   */
  getMeta(): DiagnosticsMeta {
    let username: DiagnosticsMeta['username'] = none;

    this._auth$.pipe(first()).subscribe((auth) => {
      username = auth?.username ?? none;
    });

    return {
      username,
    };
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ZendeskApiClientConfigService } from '../zendesk-api-config.service';
import {
  IApiTicketingRequestCreateRequest,
  IApiTicketingRequestCreateResponse,
} from './zendesk-ticketing.api-model';

export type TicketingRequestCreateDto =
  IApiTicketingRequestCreateRequest['request'];
export type TicketingRequest = IApiTicketingRequestCreateResponse['request'];

@Injectable({
  providedIn: 'root',
})
export class ZendeskTicketingRequestApi {
  private readonly http = inject(HttpClient);
  private readonly apiConfig = inject(ZendeskApiClientConfigService);

  private static readonly endpointPrefix = '/v2/requests.json';

  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public create(
    dto: TicketingRequestCreateDto,
  ): Observable<IApiTicketingRequestCreateResponse['request']> {
    const url = this.endpointUrl([]);
    const apiDto = this._mapToApiTicketingRequestCreateRequest(dto);

    return this.http
      .post<IApiTicketingRequestCreateResponse>(url, apiDto, {
        headers: this.httpHeaders,
      })
      .pipe(map((response) => response.request));
  }

  private _mapToApiTicketingRequestCreateRequest(
    request: TicketingRequestCreateDto,
  ): IApiTicketingRequestCreateRequest {
    return {
      request,
    };
  }

  /**
   * @param parts Parts of the url that should be joined
   *
   * @returns
   * Complete URL for the given parts
   */
  private endpointUrl(parts: (string | number)[]): string {
    return this.apiConfig.endpointUrl([
      ZendeskTicketingRequestApi.endpointPrefix,
      ...parts,
    ]);
  }
}

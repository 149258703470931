import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { cssScale } from '@fmnts/components';
import { isDefined, isNotNullish } from '@fmnts/core';
import { blobAsDataUri } from '@fmnts/core/file';
import { Rect, Vector2d } from '@fmnts/core/math';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ReplaySubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ImageControl, ImageEditorBase } from './image-input-base';
import {
  IMAGE_SELECTION_MODEL_PROVIDER,
  ImageSelectionModel,
} from './image-selection-model';

type ImagePreviewEditor = ImageControl;

type Tools = 'crop' | null;

/**
 * A 90 degree rotation in radians.
 */
const ROTATION_IN_RAD = Math.PI * 0.5;

class EditImage {
  /**
   * Natural size of the image. Available after image was loaded.
   */
  public size: Vector2d = new Vector2d(0, 0);

  public get ratio(): number {
    return this.size ? this.size.x / this.size.y : 0;
  }

  constructor(
    /** Content as data URI */
    public readonly dataUri: string,
    /** Byte size */
    public readonly length: number,
    public readonly name?: string,
  ) {}

  public updateSize(size: Vector2d): EditImage {
    this.size = size;
    return this;
  }
}

interface ImageTransform {
  rotation: number;
  scale: number;
  translate: Vector2d;
  crop?: Rect;
}

@Component({
  selector: 'fmnts-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [IMAGE_SELECTION_MODEL_PROVIDER],
})
export class ImageEditorComponent
  extends ImageEditorBase<ImagePreviewEditor>
  implements OnInit, AfterViewChecked
{
  @HostBinding('class.fmnts-image-editor')
  protected readonly componentClass = 'fmnts-image-editor';

  /** Currently selected image editing utilitiy */
  public currentUtil: Tools = null;

  public isEditing = false;

  public isImageLoaded = false;

  /**
   * Minimum allowed width.
   */
  @Input()
  get minWidth(): number {
    return this._minSize.x;
  }
  set minWidth(value: number) {
    this._minSize = new Vector2d(value, this._minSize.y);
  }

  /**
   * Minimum allowed height.
   */
  @Input()
  get minHeight(): number {
    return this._minSize.y;
  }
  set minHeight(value: number) {
    this._minSize = new Vector2d(this._minSize.x, value);
  }

  /**
   * Maximum allowed width.
   */
  @Input()
  get maxWidth(): number {
    return this._maxSize.x;
  }
  set maxWidth(value: number) {
    this._maxSize = new Vector2d(value, this._maxSize.y);
  }

  /**
   * Maximum allowed height.
   */
  @Input()
  get maxHeight(): number {
    return this._maxSize.y;
  }
  set maxHeight(value: number) {
    this._maxSize = new Vector2d(this._maxSize.x, value);
  }

  @ViewChild('originalImage', { static: true })
  private originalImageRef!: ElementRef<HTMLImageElement>;

  @ViewChild('canvas', { static: true })
  private canvasRef!: ElementRef<HTMLCanvasElement>;

  /** Rendering context */
  private _ctx: CanvasRenderingContext2D | null = null;

  public get ctx(): CanvasRenderingContext2D {
    if (!this._ctx) {
      throw new Error('Could not initialize CanvasRenderingContext2D');
    }
    return this._ctx;
  }

  public set ctx(canvas: CanvasRenderingContext2D | null) {
    this._ctx = canvas;
  }

  /**
   * Emits with the image transformations
   *
   * @internal
   */
  public readonly _modifications = new BehaviorSubject<ImageTransform>({
    rotation: 0,
    scale: 0,
    translate: Vector2d.zero,
  });

  /** @internal */
  public readonly imageTransform$ = this._modifications.pipe(
    map(cssImageTransform),
  );

  /**
   * Image that is currently being edited.
   *
   * @internal
   */
  private readonly _image = new BehaviorSubject<EditImage | null>(null);
  public readonly image$ = this._image.pipe(filter(isNotNullish));

  /**
   * Emits with the size of the editor canvas
   * CanvasSize refers to the actual canvas element
   * This is used to create dimensions for the final
   * result. During editing this canvas is not displayed.
   */
  private readonly _canvasSize = new BehaviorSubject(Vector2d.zero);
  public readonly canvasSize$ = this._canvasSize.asObservable();
  /** @internal */
  public readonly _canvasWidth$ = this._canvasSize.pipe(map(({ x }) => x));
  /** @internal */
  public readonly _canvasHeight$ = this._canvasSize.pipe(map(({ y }) => y));

  /**
   * Emits with the size of viewport
   * This is used for editing
   */
  public readonly _viewportSize = new BehaviorSubject(Vector2d.zero);
  public readonly viewportSize$ = this._viewportSize.asObservable();
  /** @internal */
  public readonly _viewportWidth$ = this._viewportSize.pipe(map(({ x }) => x));
  /** @internal */
  public readonly _viewportHeight$ = this._viewportSize.pipe(map(({ y }) => y));

  /**
   * Emits with container size
   */
  private readonly _containerSize = new BehaviorSubject(Vector2d.zero);
  public readonly containerSize$ = this._containerSize.pipe(
    distinctUntilChanged(
      (previous, current) =>
        previous.x === current.x && previous.y === current.y,
    ),
  );
  /** Minimum size */
  private _minSize = Vector2d.zero;

  /** Maximum size */
  private _maxSize = new Vector2d(
    Number.POSITIVE_INFINITY,
    Number.POSITIVE_INFINITY,
  );

  private readonly destroyed = new ReplaySubject<boolean>(1);

  @HostBinding('class.no-animate')
  private _isLoading = false;

  @HostBinding('class.fmnts-image-editor--hidden')
  public get isClosed(): boolean {
    return !super.opened;
  }

  @Output()
  public imageLoaded = new EventEmitter<void>();

  /**
   * calculate container size when parent is resized
   */
  @HostListener('window:resize')
  private updateContainerSize() {
    const { clientWidth, clientHeight } = this.el.nativeElement;
    this._containerSize.next(new Vector2d(clientWidth, clientHeight));
  }

  constructor(
    _model: ImageSelectionModel,
    private el: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
  ) {
    super(_model);

    // Might need change detection when canvas is resized
    this._canvasSize.pipe(skip(1), takeUntil(this.destroyed)).subscribe(() => {
      this.cd.markForCheck();
    });
  }

  ngAfterViewChecked(): void {
    this.updateContainerSize();

    // After image loaded detect changes to get containerSize
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    // Prepare canvas context
    this.ctx = this.canvasRef.nativeElement.getContext('2d');
    this.ctx.imageSmoothingQuality = 'high';

    this.updateContainerSize();

    // Read file
    this._model.selectionChanged
      .pipe(
        switchMap((change) =>
          isDefined(change.selection.modified)
            ? readFile(change.selection.modified)
            : isDefined(change.selection.original)
              ? readFile(change.selection.original)
              : EMPTY,
        ),
        takeUntil(this.destroyed),
      )
      .subscribe((image) => {
        this._isLoading = true;
        this.updateContainerSize();
        this._image.next(new EditImage(image.content, image.size));
      });

    // Subscribe to changes in container size and image,
    // in order to render the viewport and the image accordingly
    combineLatest([this.containerSize$, this.image$]).subscribe(
      ([container, image]) => {
        const scale = this._getImageDefaultScale(image, container);

        this.updateImageTransform({ scale });
        this._updateViewPort();
      },
    );

    this._renderResult(
      this.originalImageRef.nativeElement,
      this._modifications.value,
    );
  }

  /**
   * Resets all operations
   */
  private reset(overrides: Partial<ImageTransform> = {}) {
    this._modifications.next({
      rotation: 0,
      scale: 0,
      translate: Vector2d.zero,
      crop: undefined,
      ...overrides,
    });
  }

  public updateImageTransform(overrides: Partial<ImageTransform>): void {
    const value = this._modifications.value;
    this._modifications.next({
      ...value,
      ...overrides,
    });
  }

  /**
   * Rotates an image
   * @param radians Angle by which to rotate
   */
  private rotate(radians: number) {
    const { value: image } = this._image;
    if (!image) {
      return;
    }

    const value = this._modifications.value;
    const rotation = (value.rotation ?? 0) + radians;

    // Apply rotation to image by switching height and width
    const { x: width, y: height } = image.size;
    this._image.next(image.updateSize(new Vector2d(height, width)));

    this.updateImageTransform({ rotation });
    void this.apply();
  }

  /**
   * Rotates the image counter clockwise
   */
  public rotateCounterClockwise(): void {
    this.rotate(-ROTATION_IN_RAD);
  }

  /**
   * Rotates the image clockwise
   */
  public rotateClockwise(): void {
    this.rotate(ROTATION_IN_RAD);
  }

  public override close(): void {
    super.close();
    this.reset({});
  }

  /**
   * Starts editing mode
   *
   * if current editing tools is already selected, it will stop
   *
   * @param tool
   * if a editing tool is selected, the viewport gets scaled down, in order
   * to render additional editing tools.
   */
  public beginEditing(tool?: Tools): void {
    const { value: image } = this._image;
    if (!image) {
      return;
    }

    if (tool === this.currentUtil) {
      this.currentUtil = null;
      this.stopEditingTool();
      return;
    } else {
      this.isEditing = true;
      this.currentUtil = tool ?? null;
    }

    if (tool === 'crop') {
      const viewportSize = this._viewportSize.value;

      const crop = Rect.fromCenterAndSize(
        viewportSize.scale(0.5),
        viewportSize.scale(0.5),
      );

      // scale viewport and image to 90%
      this._updateViewPort(0.9);

      const scale = this._getImageDefaultScale(image, viewportSize.scale(0.9));

      this.updateImageTransform({ crop, scale });
    }
  }

  /**
   * Stops current editing tool
   */
  public stopEditingTool(): void {
    const { value: image } = this._image;
    if (!image) {
      return;
    }

    this.currentUtil = null;
    this._updateViewPort();

    const scale = this._getImageDefaultScale(image, this._containerSize.value);
    this.reset({ scale });

    // Render final image and propagate changes
    this._renderResult(
      this.originalImageRef.nativeElement,
      this._modifications.value,
    );
  }

  /**
   * Undo last change to the image
   */
  public undo(): void {
    this._model.removeSelection(this);
  }

  /**
   * Applies changes to the image and changes to preview mode
   * Will also scale the image down, using `maxWidth` and `maxheight`
   *
   * @param type
   * A string indicating the image format. The default type is `image/png`;
   * that type is also used if the given type isn't supported.
   *
   * @param quality
   * A Number between 0 and 1 indicating the image quality to be used
   * when creating images using file formats that support lossy
   * compression (such as `image/jpeg` or `image/webp`).
   * A user agent will use its default quality value if this option
   * is not specified, or if the number is outside the allowed range.
   */
  public async save(type?: string, quality?: number): Promise<void> {
    await this.apply(type, quality, true);
    this.stopEditingTool();
    this.isEditing = false;
  }

  /**
   * Applies changes to the image
   * Saves all modifications and updates the model
   *
   * @param type
   * A string indicating the image format. The default type is `image/png`;
   * that type is also used if the given type isn't supported.
   *
   * @param quality
   * A Number between 0 and 1 indicating the image quality to be used
   * when creating images using file formats that support lossy
   * compression (such as `image/jpeg` or `image/webp`).
   * A user agent will use its default quality value if this option
   * is not specified, or if the number is outside the allowed range.
   *
   * @param scaled
   * A boolean value indicating whether image should be scaled.
   * If `true` it will apply `this.maxWidth` and `this.maxHeight`
   * to the final rendering
   */
  public async apply(
    type?: string,
    quality?: number,
    scaled?: boolean,
  ): Promise<void> {
    // Render final image and propagate changes
    this._renderResult(
      this.originalImageRef.nativeElement,
      this._modifications.value,
      scaled,
    );

    const modified = await canvasToBlob(
      this.canvasRef.nativeElement,
      type,
      quality,
    ).catch((error) => console.error(error));

    if (modified) {
      this._model.updateSelection(
        {
          size: modified.size,
          modified,
        },
        this,
      );

      this.stopEditingTool();
    }
  }

  /**
   * render transformations and draw the image to canvas
   *
   * @param originalImage
   * The HTMLImageElement that will be drawn onto the canvas
   *
   * @param transform
   * An object containing all transformations that should be applied
   * to the image.
   *
   * @param scaled
   * A boolean value indicating whether image should be scaled.
   */
  private _renderResult(
    originalImage: HTMLImageElement,
    transform: ImageTransform,
    scaled?: boolean,
  ) {
    // get size to apply to canvas for rendering
    const maxSize = this._getMaxResolution(scaled);
    let size = this._scaleToMaxResolution(
      new Vector2d(originalImage.width, originalImage.height),
      maxSize,
    );
    this._canvasSize.next(size);

    const { crop, scale, rotation } = transform;

    // If image direction changed, also switch canvas
    if (rotation) {
      if (
        rotationInDegrees(rotation) === 90 ||
        rotationInDegrees(rotation) === 270
      ) {
        this._canvasSize.next(new Vector2d(size.y, size.x));
      }
    }

    // If there was a 'cropping' active, we need to change width and height
    // to the size of the crop rectangle
    if (crop) {
      size = this._scaleToMaxResolution(crop.size, maxSize);
      this._canvasSize.next(size);
    }

    // detect changes, in order to render canvas properly
    this.cd.detectChanges();

    const width = size.x;
    const height = size.y;

    // Begin
    this.ctx.save();

    // Step 1: Reset
    this.ctx.clearRect(0, 0, width, height);

    // Step 2: Perform transformations
    if (transform.rotation !== 0) {
      // get the rotation in degrees in the interval [0,360[
      switch (rotationInDegrees(transform.rotation)) {
        case 90:
          this.ctx.translate(height, 0);
          break;
        case 180:
          this.ctx.translate(width, height);
          break;
        case 270:
          this.ctx.translate(0, width);
          break;
      }
      this.ctx.rotate(transform.rotation);
    }

    // Step 3: Render cropped image
    if (crop) {
      this.ctx.drawImage(
        originalImage,
        crop.topLeft.x / scale,
        crop.topLeft.y / scale,
        crop.size.x / scale,
        crop.size.y / scale,
        0,
        0,
        crop.size.x,
        crop.size.y,
      );
    } else {
      this.ctx.drawImage(originalImage, 0, 0, width, height);
    }

    // End
    this.ctx.restore();
  }

  /**
   * Prepares image and canvas for proper rendering
   * @param element
   */
  public onImageLoaded(element: HTMLImageElement): void {
    const { value: image } = this._image;
    if (!image) {
      return;
    }

    const containerSize = this._containerSize.value;
    this._image.next(
      image.updateSize(
        new Vector2d(element.naturalWidth, element.naturalHeight),
      ),
    );

    // Scale image to fit into container view
    const scale = this._getImageDefaultScale(image, containerSize);
    this.reset({
      scale,
    });

    this._isLoading = false;

    if (!this.isImageLoaded) {
      this.isImageLoaded = true;
      this.imageLoaded.emit();
    }
    this._updateViewPort();
  }

  /**
   * @returns
   * `true` if `tool` is disabled,
   * `false` if it is available
   */
  public isToolDisabled(tool?: string): boolean {
    if (!this.currentUtil || this.currentUtil === tool) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Scales a vector to fit into a container vector,
   * while preserving the ratio of the smaller vector.
   *
   * @param originalSize
   * @param containerSize
   *
   * @returns
   * size vector with maximum value applied
   */
  private _scaleToMaxResolution(
    originalSize: Vector2d,
    containerSize: Vector2d,
  ): Vector2d {
    let { x: width, y: height } = originalSize;
    const { x: maxWidth, y: maxHeight } = containerSize;

    if (width > height) {
      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    return new Vector2d(width, height);
  }

  /**
   * @param scaled whether image should be scaled
   *
   * @returns
   * the max size for which the image should be rendered.
   */
  private _getMaxResolution(scaled?: boolean): Vector2d {
    // in case it should be scaled down, use max width/height from input
    if (scaled) {
      return new Vector2d(this.maxWidth, this.maxHeight);
    }

    // otherwise use maximum size of container
    const maxContainer = Math.max(
      this._containerSize.value.x,
      this._containerSize.value.y,
    );
    return new Vector2d(maxContainer, maxContainer);
  }

  /**
   * Updates the viewport to fit the scaled image size
   *
   * @param scale {number} can be used to scale down the container
   */
  private _updateViewPort(scale: number = 1) {
    const { value: image } = this._image;
    if (!image) {
      return;
    }

    // calculate width/height depending on max value of editor
    const scaled = this._scaleToMaxResolution(
      image.size,
      this._containerSize.value.scale(scale),
    );
    this._viewportSize.next(scaled);
  }

  private _getImageDefaultScale(image: EditImage, containerSize: Vector2d) {
    const { ratio: imageRatio } = image;
    // Use the minimum of both, container and image because we don't
    // want to stretch images smaller than the container
    const viewportSize = new Vector2d(
      Math.min(containerSize.x, image.size.x),
      Math.min(containerSize.y, image.size.y),
    );
    const viewportRatio = viewportSize.x / viewportSize.y;

    // This helps us comparing the shape of the image compared to the
    // viewport. If the value is > 1, the image is more landscap-ish
    // than the viewport and if < 1 it's more portrait-ish.
    const imageToViewportRatio = imageRatio / viewportRatio;

    // Use width for landscape viewports and images even more landscape-ish
    // Or for portrait viewports with
    return imageToViewportRatio > 1
      ? viewportSize.x / image.size.x
      : viewportSize.y / image.size.y;
  }
}

function cssImageTransform({ rotation, scale }: ImageTransform): string {
  return [`rotate(${rotation ?? 0}rad)`, cssScale([scale])].join(' ');
}

function readFile(file: Blob): Observable<{ content: string; size: number }> {
  return blobAsDataUri(file).pipe(
    map((content) => ({
      content,
      size: file.size,
    })),
  );
}

function canvasToBlob(
  canvas: HTMLCanvasElement,
  type?: string,
  quality?: any,
): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject('Could not save canvas as Blob')),
      type,
      quality,
    );
  });
}

/**
 * Calculate the rotation in degrees in the interval [0,360[
 *
 * @param rotation radian value of rotation
 *
 * @returns
 * rotation in degrees
 */
function rotationInDegrees(rotation: number) {
  return ((rotation * 180) / Math.PI + 360) % 360;
}

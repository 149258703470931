import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays the main card content.
 */
@Component({
  selector: 'fmnts-card-content',
  template: '<ng-content></ng-content>',
  styleUrls: ['./card-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardContentComponent {
  @HostBinding('class.fmnts-card-content')
  private readonly componentClass = 'fmnts-card-content';
}

import {
  APP_INITIALIZER,
  ErrorHandler as AngularErrorHandler,
  EnvironmentProviders,
  Provider,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProviderFeature, providerFeature } from '@fmnts/common';
import { TraceService } from '@sentry/angular-ivy';
import { LazyArg, constVoid } from 'effect/Function';
import {
  SENTRY_CLIENT_OPTIONS_RESOLVER,
  SentryClientOptionsResolver,
  SentryRef,
  sentryRefInitializer,
} from './sentry-ref.service';
import {
  SentryErrorExtractor,
  SentryErrorHandler,
  SentryErrorHandlerConfig,
  SentryErrorHandlerExtractor,
  SentryErrorHandlerSettings,
} from './sentry.error-handler';

enum SentryFeatureKind {
  ErrorHandler,
  Tracing,
}

type SentryFeature<TKind extends SentryFeatureKind> = ProviderFeature<TKind>;
const { make } = providerFeature<SentryFeatureKind>();

/**
 * Provides common sentry features.
 */
export function provideSentryDataAccess(
  opts: {
    /** Resolves the options for sentry. */
    resolveOptions: SentryClientOptionsResolver;
  },
  ...features: SentryFeature<SentryFeatureKind>[]
): EnvironmentProviders {
  const providers = features.flatMap((f) => f.providers);
  return makeEnvironmentProviders([
    SentryRef,
    {
      provide: SENTRY_CLIENT_OPTIONS_RESOLVER,
      useFactory: () => opts.resolveOptions,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: sentryRefInitializer,
    },
    ...providers,
  ]);
}

/**
 * Provides an angular error handler using sentry.
 */
export function withErrorHandler(opts: {
  /** Configuration for the error handler. */
  config?: LazyArg<SentryErrorHandlerConfig>;
  /** Custom extractor for improving error handling. */
  extractor?: LazyArg<SentryErrorExtractor>;
}): SentryFeature<SentryFeatureKind.ErrorHandler> {
  const providers: Provider[] = [
    {
      provide: AngularErrorHandler,
      useClass: SentryErrorHandler,
    },
    SentryErrorHandlerSettings,
  ];

  if (opts.config) {
    providers.push({
      provide: SentryErrorHandlerConfig,
      useFactory: opts.config,
    });
  }

  if (opts.extractor) {
    providers.push({
      provide: SentryErrorHandlerExtractor,
      useFactory: opts.extractor,
    });
  }

  return make(SentryFeatureKind.ErrorHandler, providers);
}

/**
 * Set up tracing for sentry.
 */
export function withTracing(): SentryFeature<SentryFeatureKind.Tracing> {
  // see https://docs.sentry.io/platforms/javascript/guides/angular/#register-sentry-providers
  return make(SentryFeatureKind.Tracing, [
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (_ts = inject(TraceService)) => constVoid,
    },
  ]);
}

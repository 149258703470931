import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FmntsIconsModule } from '@fmnts/components/icons';

import { ExpansionPanelComponent } from './expansion-panel.component';
import { ExpansionPanelHeaderComponent } from './expansion-panel-header.component';

@NgModule({
  declarations: [ExpansionPanelComponent, ExpansionPanelHeaderComponent],
  exports: [ExpansionPanelComponent, ExpansionPanelHeaderComponent],
  imports: [CommonModule, FmntsIconsModule],
})
export class FmntsExpansionModule {}

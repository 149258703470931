import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsCiModule } from '@fmnts/components/corporate-identity';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { LoadingIndicatorComponent } from './loading-indicator.component';
import { ProgressBarComponent } from './progress-bar.component';

@NgModule({
  declarations: [LoadingIndicatorComponent, ProgressBarComponent],
  exports: [LoadingIndicatorComponent, ProgressBarComponent],
  imports: [CommonModule, FmntsCiModule, FmntsIconsModule],
})
export class FmntsProgressIndicatorModule {}

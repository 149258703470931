/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ANIMATION_MODULE_TYPE,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AbstractFormFieldComponent } from './abstract-form-field.component';
import { FmntsFormErrorDirective } from './form-error.directive';
import { FmntsHintDirective } from './hint.directive';

type FormFieldDirection = 'row' | 'column';

/**
 * Represents the default options for the form field that can be configured
 * using the `FMNTS_FORM_FIELD_DEFAULT_OPTIONS` injection token.
 */
export interface FmntsFormFieldDefaultOptions {
  /** Default form field appearance style. */
  direction?: FormFieldDirection;
  /** Default color of the form field. */
  color?: ThemeColor;
  /** Whether the required marker should be hidden by default. */
  hideRequiredMarker?: boolean;
}

/**
 * Injection token that can be used to inject an instances of `FmntsFormField`. It serves
 * as alternative token to the actual `FmntsFormField` class which would cause unnecessary
 * retention of the `FmntsFormField` class and its component metadata.
 */
export const FMNTS_FORM_FIELD = new InjectionToken<FmntsFormFieldComponent>(
  '@fmnts.components.form-field.component',
);

/**
 * Injection token that can be used to configure the
 * default options for all form field within an app.
 */
export const FMNTS_FORM_FIELD_DEFAULT_OPTIONS =
  new InjectionToken<FmntsFormFieldDefaultOptions>(
    '@fmnts.components.form-field.default-options',
  );

let nextUniqueId = 0;

/**
 * ***NOTE***:
 * **Do not** use component in production.
 * Only experimental at the moment.
 *
 * Displays a form field.
 *
 * Use this component to provide a consistent form UI and UX.
 */
@Component({
  selector: 'fmnts-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: FMNTS_FORM_FIELD, useExisting: FmntsFormFieldComponent },
  ],
  host: {
    '[class.fmnts-form-field--invalid]': 'control.errorState',
    '[class.fmnts-form-field--disabled]': 'control.disabled',
    '[class.fmnts-form-field--readonly]': 'control.readonly',
    '[class.fmnts-form-field--no-animations]':
      '_animationMode === "NoopAnimations"',
    // syncs angular forms classes
    '[class.ng-untouched]': 'shouldForward("untouched")',
    '[class.ng-touched]': 'shouldForward("touched")',
    '[class.ng-pristine]': 'shouldForward("pristine")',
    '[class.ng-dirty]': 'shouldForward("dirty")',
    '[class.ng-valid]': 'shouldForward("valid")',
    '[class.ng-invalid]': 'shouldForward("invalid")',
    '[class.ng-pending]': 'shouldForward("pending")',
  },
})
export class FmntsFormFieldComponent
  extends AbstractFormFieldComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  @HostBinding('class.fmnts-form-field') protected readonly componentClass =
    'fmnts-form-field';

  @ContentChildren(FmntsFormErrorDirective, { descendants: true })
  _errorChildren!: QueryList<FmntsFormErrorDirective>;
  @ContentChildren(FmntsHintDirective, { descendants: true })
  _hintChildren!: QueryList<FmntsHintDirective>;

  /**
   * Direction in which the field is oriented.
   */
  @Input() direction: FormFieldDirection = 'column';

  // TODO: does this make sense here?
  /**
   * Color to use for this
   */
  @Input() color?: ThemeColor;

  /** Whether the required marker should be hidden. */
  @Input()
  get hideRequiredMarker(): boolean {
    return this._hideRequiredMarker;
  }
  set hideRequiredMarker(value: BooleanInput) {
    this._hideRequiredMarker = coerceBooleanProperty(value);
  }
  private _hideRequiredMarker = false;

  // Unique id for the internal form field label.
  readonly _labelId = `fmnts-form-field-label-${nextUniqueId++}`;

  // Unique id for the hint label.
  readonly _hintLabelId = `fmnts-hint-${nextUniqueId++}`;

  /** State of the fmnts-hint and fmnts-error animations. */
  _subscriptAnimationState = '';

  private _destroyed = new ReplaySubject<boolean>();
  private _isFocused: boolean | null = null;

  @HostBinding('class')
  protected get hostClasses(): string {
    const prefix = this.componentClass;
    return classnames([
      `${prefix}--${this.direction}`,
      this.control.focused && `${prefix}--focused`,
      this.color && `${prefix}--${this.color}`,
    ]);
  }

  constructor(
    public _elementRef: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(FMNTS_FORM_FIELD_DEFAULT_OPTIONS)
    private _defaults?: FmntsFormFieldDefaultOptions,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) public _animationMode?: string,
  ) {
    super();
    if (_defaults) {
      if (_defaults.direction) {
        this.direction = _defaults.direction;
      }
      this._hideRequiredMarker = Boolean(_defaults?.hideRequiredMarker);
      if (_defaults.color) {
        this.color = _defaults.color;
      }
    }
  }

  ngAfterViewInit(): void {
    // Initial focus state sync. This happens rarely, but we want to account for
    // it in case the form field control has "focused" set to true on init.
    this._updateFocusState();
    // Enable animations now. This ensures we don't animate on initial render.
    this._subscriptAnimationState = 'enter';
    // Because the above changes a value used in the template after it was checked, we need
    // to trigger CD or the change might not be reflected if there is no other CD scheduled.
    this._changeDetectorRef.detectChanges();
  }

  ngAfterContentInit(): void {
    this._initializeControl();
  }

  ngOnDestroy(): void {
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  @HostListener('click', ['$event'])
  protected _onClick(ev: MouseEvent): void {
    this.control.onContainerClick(ev);
  }

  private _updateFocusState() {
    // Handle the focus by checking if the abstract form field control focused state changes.
    if (this.control.focused && !this._isFocused) {
      this._isFocused = true;
    } else if (
      !this.control.focused &&
      (this._isFocused || this._isFocused === null)
    ) {
      this._isFocused = false;
    }
  }

  /** Initializes the registered form field control. */
  private _initializeControl() {
    const control = this.control;

    if (control.controlType) {
      this._elementRef.nativeElement.classList.add(
        `fmnts-form-field-type-${control.controlType}`,
      );
    }

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.subscribe(() => {
      this._updateFocusState();
      this._changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => this._changeDetectorRef.markForCheck());
    }
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FeatureFlagSet } from './feature-flag-set';

/**
 * Interface for for an feature flag provider
 */
interface IFeatureFlags<T extends string> {
  /** Observable that emits when the set of flags changed. */
  readonly flagsChange: Observable<void>;

  /**
   * @param flag Identifier for the flag
   * @returns
   * `true` if the flag is enabled.
   */
  on(flag: T): boolean;

  /**
   * @param flag Identifier for the flag
   * @returns
   * `true` if the flag is not enabled.
   */
  off(flag: T): boolean;

  /**
   * Sets the given list of feature flags as enabled.
   * Every other feature flag will be disabled
   *
   * @param enabledFlags The set of feature flags that are enabled
   */
  setEnabled(enabledFlags: readonly T[]): void;
}

/**
 * Abstract implementation of feature flags provider.
 */
@Injectable()
export abstract class AbstractFeatureFlags<T extends string>
  implements IFeatureFlags<T>, OnDestroy
{
  private _flags = new FeatureFlagSet<T>([]);
  private _flagsChangeSubject = new Subject<void>();

  public readonly flagsChange = this._flagsChangeSubject.asObservable();

  ngOnDestroy(): void {
    this._flagsChangeSubject.complete();
  }

  /**
   * @param flag Flag that should be checked if it is enabled
   * @returns `true` if the flag is enabled.
   */
  public on(flag: T): boolean {
    return this._flags.on(flag);
  }

  /**
   * @param flag Flag that should be checked if it is disabled
   * @returns `true` if the flag is disabled.
   */
  public off(flag: T): boolean {
    return this._flags.off(flag);
  }

  /**
   * @param flags Set of feature flags
   * @returns
   * `true` if every feature flag is on.
   */
  public every(...flags: readonly T[]): boolean {
    return flags.every((f) => this.on(f));
  }

  /**
   * @param flags Set of feature flags
   * @returns
   * `true` if some of the feature flag are on.
   */
  public some(...flags: readonly T[]): boolean {
    return flags.some((f) => this.on(f));
  }

  /**
   * Sets the list of all flags that should be enabled to the given
   * `enabledFlags`.
   *
   * @param enabledFlags All flags that should be enabled.
   */
  public setEnabled(enabledFlags: readonly T[]): void {
    this._flags = new FeatureFlagSet(enabledFlags);
    this._flagsChangeSubject.next();
  }
}

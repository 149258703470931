import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfigService } from '@fmnts/api/util';
import { Observable } from 'rxjs';
import { FilterResult, FilterType } from '../model/filter';

@Injectable({
  providedIn: 'root',
})
export class FilterApi {
  private static readonly rootUrl = '/v1/filters';

  constructor(
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(types: `${FilterType}`[]): Observable<FilterResult[]> {
    const url = this.configService.buildCockpitApiUrl([FilterApi.rootUrl]);
    const params = {
      types: types.join(','),
    };

    return this.http.get<FilterResult[]>(url, { params });
  }
}

<fmnts-layout layout="page">
  <fmnts-modal-title layoutSlot="header">
    {{ 'send_a_support_request' | translate }}
  </fmnts-modal-title>

  <fmnts-modal-content layoutSlot="content">
    <form [formGroup]="supportRequestForm">
      <fmnts-form-field>
        <fmnts-label> {{ 'support_request_type' | translate }}</fmnts-label>

        <fmnts-radio-group
          formControlName="type"
          fmnts-grid
          gap="space.075"
          templateColumns="1fr 1fr"
        >
          @for (type of supportRequestTypes; track $index) {
            <fmnts-radio-card [value]="type">{{
              type | mapString: _i18n.translations.requestType | translate
            }}</fmnts-radio-card>
          }
        </fmnts-radio-group>

        @if (showError('type')) {
          <fmnts-form-error>{{
            'field_is_required' | translate
          }}</fmnts-form-error>
        }
      </fmnts-form-field>

      <fmnts-form-field>
        <fmnts-text-input>
          <fmnts-label>
            {{ 'email' | translate }}
          </fmnts-label>

          <input fmnts-input type="email" formControlName="email"
        /></fmnts-text-input>

        @if (showError('email')) {
          <fmnts-form-error>{{
            'field_is_required' | translate
          }}</fmnts-form-error>
        }
      </fmnts-form-field>

      <fmnts-form-field>
        <fmnts-text-input>
          <fmnts-label>
            {{ 'description' | translate }}
          </fmnts-label>

          <textarea
            fmnts-input
            type="text"
            formControlName="description"
            rows="5"
          ></textarea>
        </fmnts-text-input>

        @if (showError('description')) {
          <fmnts-form-error>{{
            'field_is_required' | translate
          }}</fmnts-form-error>
        }
      </fmnts-form-field>
    </form>
  </fmnts-modal-content>

  <fmnts-modal-actions layoutSlot="footer">
    <button type="button" fmnts-button fill="solid" (click)="ref.close()">
      {{ 'cancel' | translate }}
    </button>

    <button
      type="button"
      fmnts-button
      fill="solid"
      color="primary"
      [disabled]="supportRequestForm.invalid"
      (click)="submit()"
    >
      {{ 'submit' | translate }}
    </button>
  </fmnts-modal-actions>
</fmnts-layout>

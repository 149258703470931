import {
  ISerializer,
  Nullish,
  ReverseAdapter,
  isNonEmptyString,
  isNotNullish,
} from '@fmnts/core';

export class ArrayQueryParamAdapter
  implements ReverseAdapter<string | null, (string | number)[] | Nullish>
{
  private readonly separator = ',';

  adapt(value: (string | number)[] | Nullish): string | null {
    return Array.isArray(value) && value.length > 0
      ? value.join(this.separator)
      : null;
  }

  reverse(value: string | Nullish): string[] | null {
    return isNonEmptyString(value) ? value.split(this.separator) : null;
  }
}

export function arrayQueryParamSerializer<T>(
  elementSerializer: ISerializer<T, string | Nullish>,
): ISerializer<T[] | undefined, string | undefined>;
export function arrayQueryParamSerializer<T, S extends T>(
  elementSerializer: ISerializer<T, string | Nullish>,
  predicate: (item: T) => item is S,
): ISerializer<S[] | undefined, string | undefined>;
export function arrayQueryParamSerializer<T, S extends T>(
  elementSerializer: ISerializer<T, string | Nullish>,
  predicate: (item: T) => item is S = (item: T): item is S => true,
): ISerializer<S[] | undefined, string | undefined> {
  const arrayFilterTransform = new ArrayQueryParamAdapter();

  return {
    serialize: (data) => {
      if (!Array.isArray(data)) {
        return undefined;
      }
      const param = data
        .map((d) => elementSerializer.serialize(d))
        .filter(isNotNullish);
      return arrayFilterTransform.adapt(param) ?? undefined;
    },

    deserialize: (value) => {
      const asSerialized = arrayFilterTransform.reverse(value);

      return Array.isArray(asSerialized)
        ? asSerialized
            .map((s) => elementSerializer.deserialize(s))
            .filter(predicate)
        : undefined;
    },
  };
}

import { coerceArray } from '@angular/cdk/coercion';
import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Permission } from '@fmnts/api/auth';
import { PermissionService, selectAuth } from '@fmnts/shared/auth/data-access';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  map,
} from 'rxjs';

/**
 * Structural directive to check if the current user has a set
 * of certain permission. If so, the provided template is rendered.
 */
@Directive({
  selector: '[appUserHasPermission]',
  standalone: true,
})
export class UserHasPermissionDirective implements OnInit, OnDestroy {
  /**
   * The permission(s) to evaluate as the condition for showing a template.
   */
  @Input()
  get appUserHasPermission(): Permission[] {
    return this._permissions$.value;
  }
  set appUserHasPermission(value: Permission | Permission[]) {
    this._permissions$.next(coerceArray(value));
  }
  private _permissions$ = new BehaviorSubject<Permission[]>([]);

  private readonly auth$ = this.store.select(selectAuth);
  private _subscription = new Subscription();

  constructor(
    private _viewContainer: ViewContainerRef,
    private _hasPermissionTemplateRef: TemplateRef<any>,
    private permissionService: PermissionService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    // Checks for any permission
    this._subscription.add(
      combineLatest([this.auth$, this._permissions$])
        .pipe(
          map(([authInfo, permissions]) =>
            authInfo
              ? this.permissionService.hasSome(authInfo, permissions)
              : false,
          ),
          distinctUntilChanged(),
        )
        .subscribe((hasPermission) => {
          this._updateView(hasPermission);
        }),
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this._permissions$.complete();
  }

  private _updateView(hasPermission: boolean) {
    if (hasPermission) {
      this._viewContainer.createEmbeddedView(this._hasPermissionTemplateRef);
    } else {
      this._viewContainer.clear();
    }
  }
}

import { inject } from '@angular/core';
import { signalStore, withState } from '@ngrx/signals';

export abstract class RaiseNowClientConfig {
  abstract readonly testMode: boolean;
}

interface RaiseNowSettingsState {
  /** Whether test mode should be used or not. */
  testMode: boolean;
}

export const RaiseNowSettings = signalStore(
  withState<RaiseNowSettingsState>(() =>
    _fromConfig(inject(RaiseNowClientConfig)),
  ),
);

function _fromConfig(cfg: RaiseNowClientConfig): RaiseNowSettingsState {
  return {
    testMode: cfg.testMode,
  };
}

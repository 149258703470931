import { Params } from '@angular/router';
import { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilObjectChanged } from './distinct-until-object-changed.operator';

/**
 * @param value The value to check
 *
 * @returns
 * `true`, if `value` is `undefined`, `null` or `""`
 */
function isFalsy(value: any) {
  return value === undefined || value === null || value === '';
}

function paramValueEquals(a: any, b: any) {
  if (a === b) {
    return true;
  } else if (isFalsy(a) && isFalsy(b)) {
    return true;
  }

  return false;
}

/**
 * Operator that filters emission where the emitted `Params` did not change
 */
export function distinctUntilParamsChanged(): MonoTypeOperatorFunction<Params> {
  return distinctUntilObjectChanged(false, (prev, cur) =>
    paramValueEquals(prev, cur),
  );
}

import { FocusOrigin } from '@angular/cdk/a11y';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Attribute,
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  WritableSignal,
  booleanAttribute,
  computed,
  inject,
  numberAttribute,
  signal,
} from '@angular/core';
import { ThemeColor } from '@fmnts/components';
import { Option, none } from '@fmnts/core';
import {
  FMNTS_RADIO_GROUP,
  FmntsRadioButton,
  FmntsRadioChange,
} from './radio.model';

/**
 * Base implementation for radio button-like inputs and
 * DI token used in the fmnts-radio-group.
 */
@Directive({})
export abstract class AbstractRadioButton<T>
  implements OnInit, AfterViewInit, DoCheck, FmntsRadioButton<T>
{
  /** Parent radio group. */
  protected readonly radioGroup = inject(FMNTS_RADIO_GROUP);
  protected readonly _cd = inject(ChangeDetectorRef);
  protected readonly _destroyRef = inject(DestroyRef);
  private readonly _radioDispatcher = inject(UniqueSelectionDispatcher);

  /** The unique ID for the radio button. */
  @HostBinding('attr.id')
  @Input()
  get id(): string {
    return this._id();
  }
  set id(value: string) {
    this._id.set(value);
  }

  protected readonly name = computed(() => this.radioGroup._name());

  /** Used to set the 'aria-label' attribute on the underlying input element. */
  @Input('aria-label') ariaLabel?: string;

  /** The 'aria-labelledby' attribute takes precedence as the element's text alternative. */
  @Input('aria-labelledby') ariaLabelledby?: string;

  /** The 'aria-describedby' attribute is read after the element's label and field type. */
  @Input('aria-describedby') ariaDescribedby?: string;

  /** Tabindex of the radio button. */
  @Input({
    transform: (value: unknown) =>
      // eslint-disable-next-line @angular-eslint/no-input-rename
      value === null ? 0 : numberAttribute(value),
  })
  tabIndex = 0;

  /** Whether this radio button is checked. */
  @Input({ transform: booleanAttribute })
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (this._checked === value) {
      return;
    }

    this._checked = value;
    if (value && this.radioGroup.value !== this.value) {
      this.radioGroup.selected = this;
    } else if (!value && this.radioGroup.value === this.value) {
      // When unchecking the selected radio button, update the selected radio
      // property on the group.
      this.radioGroup.selected = null;
    }

    if (value) {
      // Notify all radio buttons with the same name to un-check.
      this._radioDispatcher.notify(this.id, this.name());
    }
    this._cd.markForCheck();
  }
  /** Whether this radio is checked. */
  private _checked = false;

  /** The value of this radio button. */
  @Input()
  get value(): Option<T> {
    return this._value;
  }
  set value(value: Option<T>) {
    if (this._value === value) {
      return;
    }

    this._value = value;
    if (!this.checked) {
      // Update checked when the value changed to match the radio group's value
      this.checked = this.radioGroup.value === value;
    }
    if (this.checked) {
      this.radioGroup.selected = this;
    }
  }
  /** Value assigned to this radio. */
  protected _value: Option<T> = none as Option<T>;

  /** Whether the radio button is disabled. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled();
  }
  set disabled(value: boolean) {
    this._selfDisabled.set(value);
  }
  /** Whether this radio is disabled. */
  protected readonly _selfDisabled = signal(false);
  protected readonly _disabled = computed(
    () => this._selfDisabled() || this.radioGroup._disabled(),
  );

  /** Whether the radio button is required. */
  @Input({ transform: booleanAttribute })
  get required(): boolean {
    return this._required();
  }
  set required(value: boolean) {
    this._selfRequired.set(value);
  }
  protected readonly _selfRequired = signal(false);
  /** Whether this radio is required. */
  protected readonly _required = computed(
    () => this._selfRequired() || this.radioGroup._required(),
  );

  /** Theme color of the radio button. */
  @Input()
  get color(): ThemeColor {
    return this._color ?? this.radioGroup.color;
  }
  set color(newValue: ThemeColor) {
    this._color = newValue;
  }
  private _color?: ThemeColor;

  /**
   * Event emitted when the group value changes.
   * Change events are only emitted when the value changes due to user interaction with
   * a radio button (the same behavior as `<input type-"radio">`).
   */
  @Output() readonly radioChange = new EventEmitter<FmntsRadioChange<T>>();

  /** ID of the native input element inside `<mat-radio-button>` */
  protected inputId = computed(() => `${this._id()}-input`);

  /** Previous value of the input's tabindex. */
  private _previousTabIndex: number | undefined;

  constructor(@Attribute('tabindex') tabIndex?: string) {
    if (tabIndex) {
      this.tabIndex = numberAttribute(tabIndex, 0);
    }
  }

  ngOnInit(): void {
    // If the radio is inside a radio group, determine if it should be checked
    this.checked = this.radioGroup.value === this._value;
    if (this.checked) {
      this.radioGroup.selected = this;
    }

    this._setupRadioDispatchListener();
  }

  ngDoCheck(): void {
    this._updateTabIndex();
  }

  ngAfterViewInit(): void {
    this._updateTabIndex();
  }

  /** Updates state on changes dispatched by selection dispatcher. */
  private _setupRadioDispatchListener() {
    const unregisterSelectionListener = this._radioDispatcher.listen(
      (id, name) => {
        if (id !== this.id && name === this.name()) {
          this.checked = false;
        }
      },
    );
    this._destroyRef.onDestroy(unregisterSelectionListener);
  }

  /** Dispatch change event with current value. */
  private _emitChangeEvent(): void {
    this.radioChange.emit(new FmntsRadioChange(this, this._value));
  }

  /**
   * Sets this radio button to checked.
   */
  protected _select(): void {
    if (this.checked || this.disabled) {
      return;
    }

    const groupValueChanged =
      this.radioGroup && this.value !== this.radioGroup.value;
    this.checked = true;
    this._emitChangeEvent();

    if (this.radioGroup) {
      this._emitRadioGroupChange(this.value);
      if (groupValueChanged) {
        this.radioGroup._emitChangeEvent();
      }
    }
  }

  protected _emitRadioGroupTouched(): void {
    this.radioGroup._onTouched();
  }

  protected _emitRadioGroupChange(value: Option<T>): void {
    this.radioGroup._onChange(value);
  }

  /** Gets the tabindex for the underlying input element. */
  private _updateTabIndex() {
    const { radioGroup } = this;
    let value: number;

    // Implement a roving tabindex if the button is inside a group. For most cases this isn't
    // necessary, because the browser handles the tab order for inputs inside a group automatically,
    // but we need an explicitly higher tabindex for the selected button in order for things like
    // the focus trap to pick it up correctly.
    if (!radioGroup.selected || this.disabled) {
      value = this.tabIndex;
    } else {
      value = radioGroup.selected === this ? this.tabIndex : -1;
    }

    if (value !== this._previousTabIndex) {
      this.setTabIndex(value);
      this._previousTabIndex = value;
    }
  }

  /** The unique ID for the radio button. */
  abstract _id: WritableSignal<string>;
  protected abstract setTabIndex(value: number): void;
  protected abstract focus(options?: FocusOptions, origin?: FocusOrigin): void;
}

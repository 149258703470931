import {
  Directive,
  EventEmitter,
  inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Maybe, Option, SortDirection as CoreSortDir } from '@fmnts/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

export type SortDirection = Option<CoreSortDir>;

/** Position of the arrow that displays when sorted. */
export type SortHeaderArrowPosition = 'before' | 'after';

/** Interface for a directive that holds sorting state consumed by `sortHeader`. */
export interface Sortable {
  /** The id of the column being sorted. */
  id: string;

  /** Starting sort direction. */
  start: Maybe<CoreSortDir>;

  /** Whether to disable clearing the sorting state. */
  disableClear: Maybe<boolean>;
}

/** The current sort state. */
export interface Sort {
  /** The id of the column being sorted. */
  active: Option<string>;

  /** The sort direction. */
  direction: SortDirection;
}

/** Default options for `sort`.  */
export interface SortDefaultOptions {
  /** Whether to disable clearing the sorting state. */
  disableClear?: boolean;
  /** Position of the arrow that displays when sorted. */
  arrowPosition?: SortHeaderArrowPosition;
}

/** Injection token to be used to override the default options for `sort`. */
export const SORT_DEFAULT_OPTIONS = new InjectionToken<SortDefaultOptions>(
  'SORT_DEFAULT_OPTIONS',
);

/** Container for Sortables to manage the sort state and provide default sort parameters. */
@Directive({
  selector: '[fmntsSort]',
  exportAs: 'fmntsSort',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'fmnts-sort',
  },
  standalone: true,
})
export class FmntsSortDirective implements OnChanges, OnDestroy, OnInit {
  private readonly _defaultOptions = inject(SORT_DEFAULT_OPTIONS, {
    optional: true,
  });

  private readonly _initializedStream = new ReplaySubject<void>(1);

  /** Collection of all registered sortables that this directive manages. */
  readonly sortables = new Map<string, Sortable>();

  /** Used to notify any child components listening to state changes. */
  readonly _stateChanges = new Subject<void>();

  /** The id of the most recently sorted Sortable. */
  @Input() active: Option<string> = null;

  /**
   * The direction to set when an Sortable is initially sorted.
   * May be overridden by the Sortable's sort start.
   */
  @Input() start: CoreSortDir = 'asc';

  /** The sort direction of the currently active Sortable. */
  @Input('sortDirection')
  get direction(): SortDirection {
    return this._direction;
  }
  set direction(direction: SortDirection) {
    if (direction && direction !== 'asc' && direction !== 'desc') {
      throw new Error(
        `Invalid sort direction. Allowed values are 'asc' or 'desc'.`,
      );
    }
    this._direction = direction;
  }
  private _direction: SortDirection = 'asc';

  /**
   * Whether to disable the user from clearing the sort by finishing the sort direction cycle.
   * May be overridden by the Sortable's disable clear input.
   */
  @Input() sortDisableClear: boolean | undefined;

  /** Whether the sortable is disabled. */
  @Input() sortDisabled = false;

  /** Event emitted when the user changes either the active sort or sort direction. */
  @Output() readonly sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();

  /** Emits when the paginator is initialized. */
  initialized: Observable<void> = this._initializedStream;

  /**
   * Register function to be used by the contained Sortables. Adds the Sortable to the
   * collection of Sortables.
   */
  register(sortable: Sortable): void {
    if (!sortable.id) {
      throw new Error(
        `Sortable is missing an ID.${sortable.disableClear}, ${sortable.start}, ${sortable.id}`,
      );
    }
    if (this.sortables.has(sortable.id)) {
      throw new Error(`Duplicate sortable ID: ${sortable.id}`);
    }

    this.sortables.set(sortable.id, sortable);
  }

  /**
   * Unregister function to be used by the contained Sortables. Removes the Sortable from the
   * collection of contained Sortables.
   */
  deregister(sortable: Sortable): void {
    this.sortables.delete(sortable.id);
  }

  /** Sets the active sort id and determines the new sort direction. */
  sort(sortable: Sortable): void {
    if (this.active !== sortable.id) {
      this.active = sortable.id;
      this.direction = sortable.start ? sortable.start : this.start;
    } else {
      this.direction = this.getNextSortDirection(sortable);
    }

    this.sortChange.emit({ active: this.active, direction: this.direction });
  }

  /** Returns the next sort direction of the active sortable, checking for potential overrides. */
  private getNextSortDirection(sortable: Sortable): SortDirection {
    // Get the sort direction cycle with the potential sortable overrides.
    const disableClear =
      sortable.disableClear ??
      this.sortDisableClear ??
      this._defaultOptions?.disableClear ??
      false;

    const sortDirectionCycle = getSortDirectionCycle(
      sortable.start ?? this.start,
      disableClear,
    );

    // Get and return the next direction in the cycle
    const nextDirectionIndex = sortDirectionCycle.indexOf(this.direction) + 1;
    return sortDirectionCycle[nextDirectionIndex % sortDirectionCycle.length];
  }

  ngOnInit(): void {
    this._initializedStream.next();
  }

  ngOnChanges(): void {
    this._stateChanges.next();
  }

  ngOnDestroy(): void {
    this._stateChanges.complete();
    this._initializedStream.complete();
  }
}

/**
 * @returns
 * the sort direction cycle to use given the provided parameters of order and clear.
 */
function getSortDirectionCycle(
  start: SortDirection,
  disableClear: boolean,
): SortDirection[] {
  const sortOrder: SortDirection[] = ['asc', 'desc'];
  if (start === 'desc') {
    sortOrder.reverse();
  }

  if (!disableClear) {
    sortOrder.push(null);
  }

  return sortOrder;
}

import { Directionality } from '@angular/cdk/bidi';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import {
  CdkPortalOutlet,
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  StaticProvider,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { DrawerConfig, FMNTS_DRAWER_DATA } from './drawer-config';
import { DrawerContainerComponent } from './drawer-container.component';
import { DrawerRef } from './drawer-ref';

/**
 * Injection token that can be used to specify default drawer options.
 */
export const FMNTS_DRAWER_DEFAULT_OPTIONS = new InjectionToken<DrawerConfig>(
  'fmnts.components.drawer.default-options',
);

interface DrawerTemplateRefContext<TData, TResult> {
  $implicit: TData;
  /**
   * Reference to the drawer. When using a `TemplateRef`
   * the `instance` is `null`.
   */
  drawerRef: DrawerRef<never, TResult>;
}

/**
 * The Drawer service can be used to open a drawer.
 */
@Injectable()
export class Drawer implements OnDestroy {
  /**
   * Reference to the currently opened drawer.
   */
  get _openedDrawerRef(): DrawerRef | null {
    return this._parentDrawer?._openedDrawerRef ?? this._drawerRefAtThisLevel;
  }
  set _openedDrawerRef(value: DrawerRef | null) {
    if (this._parentDrawer) {
      this._parentDrawer._openedDrawerRef = value;
    } else {
      this._drawerRefAtThisLevel = value;
    }
  }
  private _drawerRefAtThisLevel: DrawerRef | null = null;

  constructor(
    /** Service for creating overlays */
    private readonly _overlay: Overlay,
    private readonly _injector: Injector,
    /** Potential parent drawer service */
    @Optional() @SkipSelf() private readonly _parentDrawer: Drawer,
    /**
     * Default options that may be provided using the injection
     * token `FMNTS_DRAWER_DEFAULT_OPTIONS`.
     */
    @Optional()
    @Inject(FMNTS_DRAWER_DEFAULT_OPTIONS)
    private readonly _defaultOptions?: DrawerConfig,
  ) {
    this._defaultOptions = this._defaultOptions ?? new DrawerConfig<null>();
  }

  ngOnDestroy(): void {
    // Only close drawers at this level on destroy
    // since the parent service may still be active.
    this._drawerRefAtThisLevel?.dismiss();
  }

  /**
   * Opens a drawer containing the given component.
   *
   * @param component Type of the component to load into the drawer.
   * @param config Extra configuration options.
   *
   * @returns Reference to the newly-opened drawer.
   */
  open<TComponent, TData = unknown, TResult = unknown>(
    component: ComponentType<TComponent>,
    config?: DrawerConfig<TData>,
  ): Observable<DrawerRef<TComponent, TResult>>;

  /**
   * Opens a drawer containing the given template.
   *
   * @param template TemplateRef to instantiate as the drawer content.
   * @param config Extra configuration options.
   *
   * @returns Reference to the newly-opened drawer.
   */
  open<TContext, TData = unknown, TResult = unknown>(
    template: TemplateRef<TContext>,
    config?: DrawerConfig<TData>,
  ): Observable<DrawerRef<never, TResult>>;

  open<TInstance, TData = unknown, TResult = unknown>(
    componentOrTemplateRef:
      | ComponentType<TInstance>
      | TemplateRef<DrawerTemplateRefContext<TData, TResult>>,
    config?: DrawerConfig<TData>,
  ): Observable<DrawerRef<TInstance, TResult | unknown>> {
    const _config = _applyConfigDefaults(
      this._defaultOptions || new DrawerConfig(),
      config,
    );

    // If a PortalOutlet was passed we need, handle the exit for Portals
    if (_config.portal) {
      return this.openPortal(componentOrTemplateRef, _config);
    }

    const ref = this.setupRef(componentOrTemplateRef, _config);

    // When the drawer is dismissed, clear the reference to it.
    ref.afterDismissed().subscribe(() => {
      // Clear the drawer ref if it hasn't already been replaced by a newer one.
      if (this._openedDrawerRef === ref) {
        this._openedDrawerRef = null;
      }
    });

    if (this._openedDrawerRef) {
      // If a drawer is already in view, dismiss it and enter the
      // new drawer after exit animation is complete.
      this._openedDrawerRef
        .afterDismissed()
        .subscribe(() => ref.containerInstance.enter());
      this._openedDrawerRef.dismiss();
    } else {
      // If no drawer is in view, enter the new drawer.
      ref.containerInstance.enter();
    }

    this._openedDrawerRef = ref;

    return of(ref);
  }

  /**
   * Opens a Portal on the PortalOutlet.
   *
   * If the PortalOutlet already has a Portal attached, it will
   * first dismiss the Portal, and then create the new one.
   */
  private openPortal<TInstance, TData = unknown, TResult = unknown>(
    componentOrTemplateRef:
      | ComponentType<TInstance>
      | TemplateRef<DrawerTemplateRefContext<TData, TResult>>,
    _config: DrawerConfig,
  ): Observable<DrawerRef<TInstance, TResult | unknown>> {
    // If a drawer is already in view, dismiss it and enter the
    // new drawer after exit animation is complete.
    if (this._openedDrawerRef && _config.portal?.hasAttached()) {
      const obs$ = this._openedDrawerRef.afterDismissed().pipe(
        map(() => {
          const ref = this.setupRef(componentOrTemplateRef, _config);

          this._openedDrawerRef = ref;

          ref.containerInstance.enter();
          return ref;
        }),
      );
      this._openedDrawerRef.dismiss();
      return obs$;
    } else {
      const ref = this.setupRef(componentOrTemplateRef, _config);
      this._openedDrawerRef = ref;

      ref.containerInstance.enter();

      return of(ref);
    }
  }

  /**
   * Creates the Reference to the Drawer
   *
   * @param componentOrTemplateRef
   * @param config
   * @returns
   */
  private setupRef<TInstance, TData = unknown, TResult = unknown>(
    componentOrTemplateRef:
      | ComponentType<TInstance>
      | TemplateRef<DrawerTemplateRefContext<TData, TResult>>,
    config: DrawerConfig,
  ): DrawerRef<TInstance, TResult | unknown> {
    const overlayRef = this._createOverlay(config);
    const container = this._attachContainer(overlayRef, config);
    const ref = this._attachDrawerContent(
      componentOrTemplateRef,
      container,
      overlayRef,
      config,
    );

    return ref;
  }

  /**
   * Dismisses the currently-visible drawer.
   *
   * @param result Data to pass to the drawer instance.
   */
  dismiss<R = unknown>(result?: R): void {
    this._openedDrawerRef?.dismiss(result);
  }

  /**
   * Creates a new overlay from the given `config`.
   *
   * @param config The drawer config.
   * @returns The overlay reference
   */
  private _createOverlay(config: DrawerConfig): OverlayRef {
    const overlayConfig = this._createOverlayConfig(config);

    return this._overlay.create(overlayConfig);
  }

  /**
   * Creates an overlay config from a drawer config.
   *
   * @param config The drawer configuration.
   * @returns The overlay configuration.
   */
  private _createOverlayConfig(config: DrawerConfig): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      direction: config.direction,
      hasBackdrop: config.hasBackdrop,
      disposeOnNavigation: config.closeOnNavigation,
      scrollStrategy:
        config.scrollStrategy ?? this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position().global(),
    });

    if (config.backdropClass) {
      overlayConfig.backdropClass = config.backdropClass;
    }

    return overlayConfig;
  }

  /**
   * Attaches the drawer container component to the overlay.
   *
   * @param overlayRef
   * Reference to the overlay to which the container should be attached.
   *
   * @param config The drawer configuragion.
   *
   * @returns
   * Instance of the created and attached drawer container component.
   */
  private _attachContainer(
    overlayRef: OverlayRef,
    config: DrawerConfig,
  ): DrawerContainerComponent {
    const userInjector = config?.viewContainerRef?.injector;
    const injector = Injector.create({
      parent: userInjector ?? this._injector,
      providers: [{ provide: DrawerConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(
      DrawerContainerComponent,
      config.viewContainerRef,
      injector,
    );

    /**
     * When host provided a Portal, use that instead
     * of creating an overlay
     */
    if (config.portal) {
      const portalRef = config.portal as CdkPortalOutlet;
      const containerRef = portalRef.attach(containerPortal);

      return containerRef.instance;
    }

    const containerRef = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  /**
   * Attaches the user-provided content (`Component` or `TemplateRef`) to
   * the already-created drawer container.
   *
   * @param componentOrTemplateRef
   * The type of component being loaded into the drawer, or a TemplateRef to instantiate as the content.
   *
   * @param drawerContainer Reference to the drawer container.
   * @param overlayRef Reference to the overlay in which the drawer resides.
   * @param config Drawer configuration.
   *
   * @returns
   * A newly-created `DrawerRef` that should be returned to the user.
   */
  private _attachDrawerContent<TInstance, TData = unknown, TResult = unknown>(
    componentOrTemplateRef:
      | ComponentType<TInstance>
      | TemplateRef<DrawerTemplateRefContext<TData, TResult>>,
    drawerContainer: DrawerContainerComponent,
    overlayRef: OverlayRef,
    config: DrawerConfig<TData>,
  ): DrawerRef<TInstance, TResult | unknown> {
    // Create a reference to the drawer we're creating in order to give
    // the user a handle to modify and close it.
    const ref = new DrawerRef<TInstance, TResult | unknown>(
      drawerContainer,
      overlayRef,
      config.portal,
    );

    if (componentOrTemplateRef instanceof TemplateRef) {
      drawerContainer.attachTemplatePortal(
        new TemplatePortal<DrawerTemplateRefContext<unknown, TResult>>(
          componentOrTemplateRef,
          null as unknown as ViewContainerRef,
          {
            $implicit: config.data,
            drawerRef: ref as DrawerRef<never, TResult>,
          },
        ),
      );
    } else {
      const injector = this._createInjector(config, ref, drawerContainer);
      const contentRef = drawerContainer.attachComponentPortal(
        new ComponentPortal(
          componentOrTemplateRef,
          config.viewContainerRef,
          injector,
        ),
      );
      ref.instance = contentRef.instance;
    }

    return ref;
  }

  /**
   * Creates an injector to be used inside of a drawer component.
   *
   * @param config Config that was used to create the drawer.
   * @param drawerRef Reference to the drawer.
   * @param drawerContainer Reference to the container component.
   *
   * @returns
   * DI Injector for the drawer content component.
   */
  private _createInjector<T>(
    config: DrawerConfig,
    drawerRef: DrawerRef<T>,
    drawerContainer: DrawerContainerComponent,
  ): Injector {
    const userInjector = config?.viewContainerRef?.injector;

    // The drawer container should be provided, as the drawer container and the drawer's
    // content are created out of the same `ViewContainerRef` and as such, are siblings
    // for injector purposes. To allow the hierarchy that is expected, the drawer
    // container is explicitly provided in the injector.
    const providers: StaticProvider[] = [
      { provide: DrawerRef, useValue: drawerRef },
      { provide: FMNTS_DRAWER_DATA, useValue: config.data },
      { provide: DrawerContainerComponent, useValue: drawerContainer },
    ];

    // Provide the passed directionality, if not already provided.
    if (
      config.direction &&
      !userInjector?.get<Directionality | null>(Directionality, null)
    ) {
      providers.push({
        provide: Directionality,
        useValue: { value: config.direction, change: of() },
      });
    }

    return Injector.create({
      parent: userInjector ?? this._injector,
      providers,
    });
  }
}

/**
 * Applies default options to the drawer config.
 *
 * @param defaults Object containing the default values to which to fall back.
 * @param config The configuration to which the defaults will be applied.
 *
 * @returns The new configuration object with defaults applied.
 */
function _applyConfigDefaults(
  defaults: DrawerConfig,
  config?: DrawerConfig,
): DrawerConfig<unknown> {
  return { ...defaults, ...config };
}

<ng-template #labelTemplate>
  <ng-content select="fmnts-label"></ng-content>
</ng-template>

<label class="fmnts-text-input__wrapper">
  <fmnts-flex direction="row" class="fmnts-text-input__prefix">
    <ng-content select="[fmntsFieldPrefix]"></ng-content>
  </fmnts-flex>

  <div class="fmnts-text-input__infix">
    <ng-template [ngTemplateOutlet]="labelTemplate"></ng-template>

    <ng-content></ng-content>
  </div>

  <fmnts-flex direction="row" class="fmnts-text-input__suffix">
    <ng-content select="[fmntsFieldSuffix]"></ng-content>
  </fmnts-flex>
</label>

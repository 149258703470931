import { collectFromEntityDictionary } from '@fmnts/common/store';
import { isDefined, isNotNullish, matchOption } from '@fmnts/core';
import { createSelector } from '@ngrx/store';
import { customerAdapter, customerFeature } from './customer.reducer';

// Selectors from feature and adapter
export const { selectCustomersState, selectAssignedCustomerId } =
  customerFeature;

export const {
  selectIds: selectCustomerIds,
  selectEntities: selectCustomerEntities,
  selectAll: selectAllCustomers,
  selectTotal: selectCustomerTotal,
} = customerAdapter.getSelectors(selectCustomersState);

export const selectAssignedCustomer = createSelector(
  selectAssignedCustomerId,
  selectCustomerEntities,
  (assignedCustomerId, customers) =>
    matchOption({
      onSome: collectFromEntityDictionary(customers),
    })(assignedCustomerId),
);

export const selectCurrencyCode = createSelector(
  selectAssignedCustomer,
  (customer) => customer?.currencyCode ?? '',
);

export const selectFeatures = createSelector(
  selectAssignedCustomer,
  (customer) => customer?.features ?? [],
);

export const selectAssignedCustomerInitialized = createSelector(
  selectAssignedCustomer,
  isDefined,
);

export const selectHasAssignedCustomer = createSelector(
  selectAssignedCustomer,
  isNotNullish,
);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiFileResponse } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import type { TimeSpan } from '@fmnts/core/chronos';
import { map, type Observable } from 'rxjs';
import { ApiFundraiserStatisticsAdapter } from '../adapter/fundraiser-statistics.adapter';
import { ApiStatisticsPageAdapter } from '../adapter/statistics-page.adapter';
import {
  IApiFundraiserStatistics,
  IApiStatisticsPage,
} from '../api-model/api-model';
import { FundraiserStatistics } from '../model/fundraiser-statistics';
import type { StatisticsPage } from '../model/statistics-page';

@Injectable({
  providedIn: 'root',
})
export class PetitionFundraiserStatisticsApi {
  private static readonly rootUrl = '/v1/petition-fundraiser-stats';

  private readonly apiToFundraiserStatsAdapter =
    new ApiFundraiserStatisticsAdapter();
  private readonly apiToFundraiserStatsPageAdapter =
    new ApiStatisticsPageAdapter(this.apiToFundraiserStatsAdapter);

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(
    id: string,
    dateRange: TimeSpan,
  ): Observable<FundraiserStatistics> {
    const url = this.configService.buildCockpitApiUrl([
      PetitionFundraiserStatisticsApi.rootUrl,
      id,
    ]);

    const params = {
      date_from: dateRange[0].format('YYYY-MM-DD'),
      date_to: dateRange[1].format('YYYY-MM-DD'),
    };

    return this.http
      .get<IApiFundraiserStatistics>(url, { params })
      .pipe(
        map((response) => this.apiToFundraiserStatsAdapter.adapt(response)),
      );
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<StatisticsPage<FundraiserStatistics>> {
    const url = this.configService.buildCockpitApiUrl([
      PetitionFundraiserStatisticsApi.rootUrl,
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<IApiStatisticsPage<IApiFundraiserStatistics>>(url, { params })
      .pipe(
        map((response) => this.apiToFundraiserStatsPageAdapter.adapt(response)),
      );
  }

  /**
   *
   * @param format Chosen download format (e.g. "csv", "xslx")
   * @param filters Filters used for download
   */
  public downloadStatisticsFile(
    format: string,
    filters: string | Record<string, any>,
  ): Observable<ApiFileResponse> {
    const url = this.configService.buildCockpitApiUrl([
      PetitionFundraiserStatisticsApi.rootUrl,
      format,
    ]);
    const params = this.apiHelper.makeParams(filters);

    return this.http.get<ApiFileResponse>(url, { params });
  }
}

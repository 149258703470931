import { HttpErrorResponse } from '@angular/common/http';

/**
 * There was either an error with the client or network.
 */
export class HttpClientOrNetworkError extends Error {
  override name = 'HttpClientOrNetworkError';

  /** Original error event. */
  readonly errorEvent: ErrorEvent;

  constructor({
    errorEvent,
  }: {
    httpError: HttpErrorResponse;
    errorEvent: ErrorEvent;
  }) {
    super(errorEvent.message);
    this.errorEvent = errorEvent;
  }
}

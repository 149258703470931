import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';

/**
 * Status bar component.
 */
@Component({
  selector: 'fmnts-status-bar',
  templateUrl: 'status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBarComponent {
  @HostBinding('class.fmnts-status-bar')
  protected readonly componentClass = 'fmnts-status-bar';

  /**
   * Color scheme for status bar.
   */
  @Input()
  color?: ThemeColor;

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([this.color && `${this.componentClass}--${this.color}`]);
  }
}

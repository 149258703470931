import {
  BooleanInput,
  NumberInput,
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { isNumber } from '@fmnts/core';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

type ValueType = number | null;

function ensureValueType(v: unknown): ValueType {
  return isNumber(v) ? v : null;
}

/**
 * Component that shows a rating or allows to give a rating.
 *
 * @example
 * <fmnts-icon-rating
 *  [max]="5"
 * >
 * </fmnts-icon-rating>
 */
@Component({
  selector: 'fmnts-icon-rating',
  templateUrl: './icon-rating.component.html',
  styleUrls: ['./icon-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconRatingComponent),
      multi: true,
    },
  ],
})
export class IconRatingComponent implements ControlValueAccessor {
  @HostBinding('class') componentClass = 'fmnts-icon-rating';

  /**
   * Positive integer.
   * Maximum number of icons to be shown.
   */
  @Input() get max(): number {
    return this._maxItem.length;
  }
  set max(newValue: NumberInput) {
    const max = Math.max(0, coerceNumberProperty(newValue));
    this._maxItem = [];
    for (let i = 0; i < max; i++) {
      this._maxItem.push(i + 1);
    }
  }
  public _maxItem: number[] = [];

  /**
   * Icon to render
   */
  @Input() icon!: IconProp;
  /**
   * Size for the rating icons
   */
  @Input() iconSize: SizeProp = '1x';
  /**
   * If `true`, the control is readonly
   */
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(val: BooleanInput) {
    this._readonly = coerceBooleanProperty(val);
  }
  private _readonly = false;

  /**
   * Rating. Can be floating number.
   * Use this property, if you don't want to use
   * the component via a form control.
   */
  @Input() set rating(newValue: ValueType) {
    this.value = newValue;
  }
  get rating(): ValueType {
    return this._value;
  }

  /**
   * Value that the current rating represents
   */
  public get value(): ValueType {
    return this._value;
  }
  public set value(val: ValueType) {
    this._value = val;
    this._filledStars = val ?? 0;
  }
  private _value: ValueType = null;

  public _filledStars = 0;
  public _disabled = false;

  private _onChanged: ChangeCallback<ValueType> = () => {};
  private _onTouched: TouchedCallback = () => {};

  public get isInteractive(): boolean {
    return !this._disabled && !this.readonly;
  }

  public toggleRating(s: number): void {
    if (this.isInteractive) {
      this.value = s;
      this._onChanged(s);
      this._onTouched();
    }
  }

  public hoverRating(s: number): void {
    if (this.isInteractive) {
      this._filledStars = s;
    }
  }

  public unhoverRating(): void {
    this._filledStars = this.value ?? 0;
  }

  writeValue(val: unknown): void {
    this.value = ensureValueType(val);
  }
  registerOnChange(fn: ChangeCallback): void {
    this._onChanged = fn;
  }
  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
}

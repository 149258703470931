<div class="fmnts-select-label">
  <ng-content select="fmnts-label,label[fmnts-select-label]"></ng-content>
</div>

<div
  class="fmnts-select-input"
  (click)="toggleInput()"
  data-testid="multi-select-input"
>
  @if (isEmpty || preferPlaceholderOverValue) {
    <div
      class="fmnts-select-input-placeholder"
      data-testid="multi-select-input-placeholder"
    >
      {{ placeholder || '&ZeroWidthSpace;' }}
    </div>
  } @else {
    <div
      class="fmnts-select-input-value"
      data-testid="multi-select-input-value"
    >
      {{ formattedValues }}
    </div>
  }
  <span
    class="fmnts-select-dropdown-toggle"
    [@fmntsIconRotateZ]="panelOpen ? 180 : 0"
  >
    <fa-icon [icon]="dropdownArrow"></fa-icon>
  </span>
</div>

<div
  #dropdown
  class="fmnts-select-dropdown fmnts-select-dropdown--selected-on-top"
  [style.display]="panelOpen ? undefined : 'none'"
  data-testid="multi-select-dropdown"
>
  @if (showSearchField) {
    <div
      class="fmnts-select-type-ahead"
      data-testid="dropdown-multi-select-type-ahead"
    >
      <input
        #typeAhead
        type="text"
        [formControl]="typeAheadControl"
        placeholder="{{ typeAheadText }}…"
      />
    </div>
  }

  <div class="fmnts-select-options" data-testid="dropdown-multi-select-options">
    @if (isEmpty) {
      <div class="fmnts-select-option-divider"></div>
    }

    <!-- Project option for deselecting -->
    @if (allowDeselect && !isEmpty) {
      <ng-content select="fmnts-select-option[deselect]"></ng-content>
    }

    <!-- Project options with a value -->
    <ng-content></ng-content>

    <!-- Project option to show no-results info -->
    @if (!hasTypeAheadResult) {
      <ng-content select="fmnts-select-option[noAvailableOptions]"></ng-content>
    }
  </div>
</div>

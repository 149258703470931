import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import { I18nModule } from '@fmnts/i18n';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Toast } from './toast';
import { ToastContainerComponent } from './toast-container';
import {
  FmntsToastActionDirective,
  FmntsToastActionsDirective,
} from './toast-content';
import { ToastContentComponent } from './toast-content.component';
import { ToastGroupComponent } from './toast-group.component';
import { ToastTextComponent } from './toast-text.component';
import { ToastTitleComponent } from './toast-title.component';
import { ToastComponent } from './toast.component';
import { Toaster } from './toaster';

@NgModule({
  imports: [
    OverlayModule,
    PortalModule,
    FmntsLayoutModule,
    I18nModule,
    CommonModule,
    FmntsIconsModule,
    FmntsButtonModule,
    FontAwesomeModule,
  ],
  exports: [
    ToastComponent,
    ToastContainerComponent,
    FmntsToastActionDirective,
    FmntsToastActionsDirective,
    ToastGroupComponent,
    ToastTitleComponent,
    ToastTextComponent,
    ToastContentComponent,
  ],
  declarations: [
    ToastComponent,
    ToastContainerComponent,
    FmntsToastActionDirective,
    FmntsToastActionsDirective,
    ToastGroupComponent,
    ToastTitleComponent,
    ToastTextComponent,
    ToastContentComponent,
  ],
  providers: [Toast, Toaster],
})
export class FmntsToastModule {}

import { Injectable, inject } from '@angular/core';
import { AuthClient } from '@fmnts/api/auth';
import { Auth } from '@formunauts/shared/domain';
import { Observable, catchError, map, mergeMap, of } from 'rxjs';
import { AuthRepository } from './auth-repository.model';
import { Credentials } from './shared-auth-data-access.model';

@Injectable()
export class AuthApiRepository extends AuthRepository {
  private readonly authApi = inject(AuthClient);

  authenticate({
    username,
    password,
  }: Credentials): Observable<Auth.AuthSession> {
    return this.authApi.authenticate(username, password).pipe(
      mergeMap((response) =>
        this.authApi.authenticateWithAdmin(response.access_token).pipe(
          catchError(() => of(false)),
          map(() => response),
        ),
      ),
    );
  }

  revoke(accessToken: string): Observable<void> {
    return this.authApi.revoke(accessToken).pipe(
      mergeMap((response) =>
        this.authApi.unauthenticateWithAdmin().pipe(
          catchError(() => of(false)),
          map(() => response),
        ),
      ),
    );
  }

  refresh(refreshToken: string): Observable<Auth.AuthSession> {
    return this.authApi.refresh(refreshToken);
  }
}

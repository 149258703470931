import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import type { Subscription } from 'rxjs';
import { LocaleService } from './locale.service';

@Pipe({
  name: 'i18n_currency',
  pure: false,
})
export class I18nCurrencyPipe
  extends CurrencyPipe
  implements PipeTransform, OnDestroy
{
  /**
   * The locale is used to format the number
   */
  private locale?: string;

  private subscription: Subscription;

  constructor(
    private localeService: LocaleService,
    private cd: ChangeDetectorRef,
  ) {
    super(localeService.fallbackLocale);
    this.subscription = this.localeService.locale$.subscribe((locale) => {
      this.locale = locale;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Overrides Angulars Currency Pipe and uses global Locale for formatting
   *
   * @param value The number to format.
   * @param currencyCode The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217)
    * currency code, used to display the currency symbol
   * @param display The format for the currency symbol. See `CurrencyPipe` for more details.
   * @param digitsInfo Decimal representation options, specified by a string in the following format:
   * `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`. See `CurrencyPipe` for more details.

   *
   * @returns The formatted currency value.
   *
   * @see [CurrencyPipe](https://angular.io/api/common/CurrencyPipe)
   */

  override transform(
    value: number | string,
    currencyCode: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string,
    digitsInfo?: string,
  ): string;
  override transform(
    value: null,
    currencyCode: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string,
    digitsInfo?: string,
  ): null;
  override transform(
    value: number | string | null,
    currencyCode: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string,
    digitsInfo?: string,
  ): string | null {
    return super.transform(
      value,
      currencyCode,
      display,
      digitsInfo,
      this.locale,
    );
  }
}

import type { Adapter } from '@fmnts/core';
import { IApiAchievement } from '../api-model/api-model';
import { Achievement } from '../model/achievement';

export class ApiAchievementAdapter implements Adapter<Achievement> {
  public adapt(apiDto: IApiAchievement): Achievement {
    return {
      id: apiDto.id,
      name: apiDto.name,
      category: apiDto.category,
      image: apiDto.image,
    };
  }
}

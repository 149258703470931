import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RotateDeviceComponent } from '@app/shared/ui';
import { FmntsCommonModule } from '@fmnts/common';
import { FmntsAvatarModule } from '@fmnts/components/avatar';
import { FmntsBadgeModule } from '@fmnts/components/badge';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsCardModule } from '@fmnts/components/card';
import { FmntsChipModule } from '@fmnts/components/chip';
import { FmntsComplimentModule } from '@fmnts/components/compliment';
import { FmntsTextDirective } from '@fmnts/components/content';
import { FmntsCiModule } from '@fmnts/components/corporate-identity';
import { FmntsDrawerModule } from '@fmnts/components/drawer';
import { FmntsEmptyStateModule } from '@fmnts/components/empty-state';
import { FmntsExpansionModule } from '@fmnts/components/expansion';
import { FmntsFormFieldModule } from '@fmnts/components/form-field';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsImageModule } from '@fmnts/components/image';
import { FmntsInputModule } from '@fmnts/components/input';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import { FmntsListModule } from '@fmnts/components/list';
import { FmntsModalModule } from '@fmnts/components/modal';
import { FmntsPaginationModule } from '@fmnts/components/pagination';
import { FmntsProgressIndicatorModule } from '@fmnts/components/progress-indicator';
import { FmntsRatingModule } from '@fmnts/components/rating';
import { FmntsScrollModule } from '@fmnts/components/scroll';
import { FmntsSelectModule } from '@fmnts/components/select';
import { FmntsSheetModule } from '@fmnts/components/sheet';
import { FmntsSidebarModule } from '@fmnts/components/sidebar';
import { FmntsSortModule } from '@fmnts/components/sort';
import { FmntsStatusModule } from '@fmnts/components/status';
import { FmntsTableModule } from '@fmnts/components/table';
import { FmntsTabsModule } from '@fmnts/components/tabs';
import { FmntsToastModule } from '@fmnts/components/toast';
import { FmntsToggleModule } from '@fmnts/components/toggle';
import { FmntsToolbarModule } from '@fmnts/components/toolbar';
import { I18nModule } from '@fmnts/i18n';
import {
  UserHasPermissionDirective,
  UserHasPermissionPipe,
} from '@fmnts/shared/auth/infra';
import {
  CustomerHasEveryFeaturePipe,
  CustomerHasFeatureDirective,
} from '@fmnts/shared/customer/infra';
import { BundleVersionComponent } from '@formunauts/shared/bundle/ui';
import { LetDirective, PushPipe } from '@ngrx/component';
import { MomentModule } from 'ngx-moment';
import { MapsModule } from './maps/maps.module';
import { MessagingModule } from './messaging/messaging.module';

const SHARED_MODULES = [
  // Angular modules
  CommonModule,
  NgOptimizedImage,
  FormsModule,
  ReactiveFormsModule,
  // Third party modules
  MomentModule,
  LetDirective,
  PushPipe,
  // @fmnts/components modules
  FmntsAvatarModule,
  FmntsBadgeModule,
  FmntsButtonModule,
  FmntsCardModule,
  FmntsChipModule,
  FmntsCiModule,
  FmntsComplimentModule,
  FmntsDrawerModule,
  FmntsEmptyStateModule,
  FmntsExpansionModule,
  FmntsFormFieldModule,
  FmntsIconsModule,
  FmntsImageModule,
  FmntsInputModule,
  FmntsLayoutModule,
  FmntsListModule,
  FmntsModalModule,
  FmntsPaginationModule,
  FmntsProgressIndicatorModule,
  FmntsRatingModule,
  FmntsScrollModule,
  FmntsSelectModule,
  FmntsSheetModule,
  FmntsSidebarModule,
  FmntsSortModule,
  FmntsStatusModule,
  FmntsTableModule,
  FmntsTabsModule,
  FmntsTextDirective,
  FmntsToastModule,
  FmntsToggleModule,
  FmntsToolbarModule,
  // Other @fmnts/* modules
  CustomerHasEveryFeaturePipe,
  CustomerHasFeatureDirective,
  FmntsCommonModule,
  I18nModule,
  RotateDeviceComponent,
  UserHasPermissionDirective,
  UserHasPermissionPipe,
  BundleVersionComponent,
  // Others
  MapsModule,
  MessagingModule,
];

/**
 * Module that exports all commonly used modules.
 */
@NgModule({
  imports: SHARED_MODULES,
  exports: SHARED_MODULES,
})
export class SharedModule {}

import type { Adapter } from '@fmnts/core';
import { IApiWorkshift } from '../api-model/api-model';
import { Workshift } from '../model/workshift';

export class ApiWorkshiftAdapter implements Adapter<Workshift> {
  adapt(apiDto: IApiWorkshift): Workshift {
    return new Workshift({
      id: `${apiDto.id}`,
      customerId: apiDto.customer,
      endTime: apiDto.end_time,
      label: apiDto.label,
      startTime: apiDto.start_time,
      weight: apiDto.weight,
    });
  }
}

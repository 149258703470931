import { Injectable } from '@angular/core';
import { selectHasAssignedCustomer } from '@fmnts/shared/customer/data-access';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';

/**
 * Guard that activates once a customer is assigned and available.
 */
@Injectable({ providedIn: 'root' })
export class AwaitCustomerGuard {
  private readonly hasAssignedCustomer$ = this.store.select(
    selectHasAssignedCustomer,
  );

  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.hasAssignedCustomer$.pipe(
      filter((hasAssignedCustomer) => hasAssignedCustomer),
    );
  }
}

import { apiAdaptOrNull } from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import { IApiDonation } from '../api-model/api-model';
import { Donation } from '../model/donation';

export class ApiDonationAdapter implements Adapter<Donation> {
  public adapt(apiDto: IApiDonation): Donation {
    return {
      id: apiDto.id,
      annualAmount: apiDto.annual_amount,
      currency: apiDto.currency,
      paymentInterval: apiDto.payment_interval,
      paymentMethod: apiDto.payment_method,
      points: apiAdaptOrNull.valueOrNull(apiDto.points),
      sponsorshipTitle: apiAdaptOrNull.valueOrNull(apiDto.sponsorship_title),
    };
  }
}

import { IDBStoreConfigs } from '@fmnts/common/indexed-db';
import { DBSchema } from 'idb';
import { AnalyticsEvent } from './data/types/event';

interface IDBAnalyticsEvent extends AnalyticsEvent {
  id?: number;
}

/**
 * Interface representing all stores in this database.
 */
export interface AnalyticsEventsSchema extends DBSchema {
  events: {
    key: 'id';
    value: IDBAnalyticsEvent;
  };
}

export const analyticsDbStoreConfigs: IDBStoreConfigs<AnalyticsEventsSchema> = {
  /**
   * Store configuration for Events
   *
   * This store will automatically store `AnalyticsEvents` using a
   * Key Generator.
   */
  events: {
    key: 'id',
    autoIncrement: true,
    migrations: [],
  },
};

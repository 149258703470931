import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { ProviderFeature, providerFeature } from '@fmnts/common';
import { Bundle, BundleInfo } from './bundle.model';
import {
  CHECK_UPDATE_STREAM_TOKEN,
  intervalUpdateCheck,
} from './update.service';

export enum BundleFeatureKind {
  IntervalUpdateCheck,
}

export type BundleFeature<TKind extends BundleFeatureKind> =
  ProviderFeature<TKind>;

const { make: makeBundleFeature } = providerFeature<BundleFeatureKind>();

export function provideBundleDataAccess(
  opts: BundleInfo,
  ...features: BundleFeature<BundleFeatureKind>[]
): EnvironmentProviders {
  const providers = features.flatMap((f) => f.providers);
  return makeEnvironmentProviders([
    {
      provide: Bundle,
      useFactory: () => new Bundle(opts),
    },
    ...providers,
  ]);
}

/**
 * Adds an additional check for app updates.
 */
export function withUpdateCheck(opts: {
  /**
   * Interval in ms in which an update check should be performed.
   * If `none`, the interval check is disabled.
   */
  interval: number;
}): BundleFeature<BundleFeatureKind.IntervalUpdateCheck> {
  return makeBundleFeature(BundleFeatureKind.IntervalUpdateCheck, [
    {
      provide: CHECK_UPDATE_STREAM_TOKEN,
      useFactory: () => intervalUpdateCheck(opts.interval),
    },
  ]);
}

<ng-template #labelTemplate>
  <label [id]="_labelId" [attr.for]="control.id">
    <ng-content select="fmnts-label"></ng-content>
  </label>
</ng-template>

<ng-template [ngTemplateOutlet]="labelTemplate"></ng-template>

<ng-content></ng-content>

<ng-content select="fmnts-error"></ng-content>
<ng-content select="fmnts-hint"></ng-content>

import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanToString, isNil, isString } from '@fmnts/core';

type Key = string | number | symbol;

interface DefaultHeaderOptions {
  force?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiRequestHelper {
  public makeDefaultHeaders(options: DefaultHeaderOptions): HttpHeaders {
    let result = new HttpHeaders();

    if (options.force) {
      result = result.set('x-refresh', `${true}`);
    }

    return result;
  }

  public makeParams<T>(urlOrFilter: string | T): HttpParams;
  public makeParams(urlOrFilter: string | Record<Key, CanToString>): HttpParams;

  public makeParams(
    urlOrFilter: string | Record<Key, CanToString>,
  ): HttpParams {
    const paramObj = isString(urlOrFilter)
      ? this._paramsFromUrl(urlOrFilter)
      : (urlOrFilter ?? {});

    return Object.keys(paramObj)
      .sort()
      .reduce((p, key) => {
        const value = paramObj[key];
        return isNil(value) ? p : p.set(key, value.toString());
      }, new HttpParams());
  }

  private _paramsFromUrl(url: string): Record<string, CanToString> {
    const newFilters: Record<string, CanToString> = {};
    // The fake for a base that is passed here allows us to handle
    // URLs that are only a path, like `/some/path?with=params`
    // since we're only interested in the params here, it doesn't
    // really matter
    new URL(url, 'https://fake.base').searchParams.forEach(
      (value, key) => (newFilters[key] = value),
    );

    return newFilters;
  }
}

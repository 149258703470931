import { Maybe, maybe, maybeHasValue } from '@fmnts/core';

export enum AsyncDataState {
  Init = 'init',
  Loading = 'loading',
  Loaded = 'loaded',
}

interface AsyncErrorState<TError> {
  /** Error information. */
  error: TError;
}

type AsyncState<TError> = `${AsyncDataState}` | AsyncErrorState<TError>;

export interface LoadedAsyncData<TData> {
  /** Async data. */
  data: TData;
  /**
   * ISO Date time when the data was refreshed.
   */
  refresh: string;
}

interface BaseData<TState extends AsyncState<unknown>> {
  /** State of the async data. */
  state: TState;
}

/**
 * Async data encapsulates data that might not be synchrounos
 * available. In addition to the data it also provides info
 * about the state of the data.
 */
export type AsyncData<TData, TError> =
  // No data available
  | BaseData<`${AsyncDataState.Init}`>
  // Data is available
  | (BaseData<`${AsyncDataState.Loaded}`> & LoadedAsyncData<TData>)
  // Data is maybe available
  | (BaseData<`${AsyncDataState.Loading}` | AsyncErrorState<TError>> &
      Partial<LoadedAsyncData<TData>>);

/**
 * Type guard to check if async data is available.
 *
 * @param data
 * @returns
 * `true` if data is available
 */
export function isAsyncDataAvailable<TData>(
  data: AsyncData<TData, unknown>,
): data is AsyncData<TData, unknown> & LoadedAsyncData<TData> {
  return maybeHasValue((data as LoadedAsyncData<TData>).data);
}

/**
 * Type guard to check if state is `AsyncErrorState`
 *
 * @param state
 * @returns
 * `true` if async loading failed
 */
export function isAsyncErrorState<TError>(
  state: AsyncState<TError>,
): state is AsyncErrorState<TError> {
  return maybeHasValue((state as AsyncErrorState<TError>).error);
}

/**
 * Selector that returns either the async data or `undefined`
 *
 * @param maybeData
 * @returns
 * - The loaded data when async loading completed
 * - `undefined` when no data has emitted
 */
export function maybeLoadedAsyncData<TData>(
  maybeData: AsyncData<TData, unknown>,
): Maybe<LoadedAsyncData<TData>> {
  if (!isAsyncDataAvailable(maybeData)) {
    return maybe;
  }

  return { data: maybeData.data, refresh: maybeData.refresh };
}

/**
 * Selector that returns either an error message or `undefined`
 *
 * @param maybeError
 * @returns
 * - Translated Error Message when an Error occured during async loading
 * - `undefined` when no Error occured
 */
export function maybeLoadedAsyncError<TError>(
  maybeError: AsyncData<unknown, TError>,
): Maybe<TError> {
  const { state } = maybeError;
  return (isAsyncErrorState(state) ? state.error : maybe) as Maybe<TError>;
}

export interface AsyncDataAdapter<T, TError> {
  getInitialState(): AsyncData<T, TError>;
  getInitialState<S extends object>(state: S): AsyncData<T, TError> & S;
  setLoading<S extends AsyncData<T, TError>>(state: S): S;
  setData<S extends AsyncData<T, TError>>(data: T, state: S): S;
  setError<S extends AsyncData<T, TError>>(error: TError, state: S): S;
}

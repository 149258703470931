import { DialogConfig } from '@angular/cdk/dialog';
import { CdkPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';

/** Options for where to set focus to automatically on dialog open */
export type AutoFocusTarget = 'container' | 'first-tabbable';

/**
 * Injection token that can be used to access the data that was passed in to a drawer.
 */
export const FMNTS_DRAWER_DATA = new InjectionToken<unknown>(
  '@fmnts.components.drawer.data',
);

/**
 * Configuration used when opening a drawer.
 */
export class DrawerConfig<TData = any> extends DialogConfig<TData> {
  /** Data being injected into the child component. */
  override data?: TData | null = null;

  /** Whether the drawer has a backdrop. */
  override hasBackdrop?: boolean = true;

  /** Whether the user can use escape or clicking outside to close the drawer. */
  override disableClose?: boolean = false;

  /** Aria label to assign to the drawer element. */
  override ariaLabel?: string | null = null;

  /**
   * Whether the drawer should close when the user goes backwards/forwards in history.
   * Note that this usually doesn't include clicking on links (unless the user is using
   * the `HashLocationStrategy`).
   */
  override closeOnNavigation?: boolean = true;

  // Note that this is set to 'container' by default, because while the a11y recommendations
  // are to focus the first focusable element, doing so prevents screen readers from reading out the
  // rest of the drawer content.
  /**
   * Where the drawer should focus on open.
   */
  override autoFocus?: AutoFocusTarget | string = 'container';

  /**
   * Whether the drawer should restore focus to the
   * previously-focused element, after it's closed.
   */
  override restoreFocus?: boolean = true;

  /**
   * Passing a PortalOutlet when opening a Drawer, can be used to embed the drawer to
   * the page instead of creating an overlay.
   * 
   * @description This is intended to work with `fmnts-layout` when using `Page` or `Sidebar`, 
   * as the drawer can be injected to the `aside` element of the layout
   * 
   * @example
   * ```
   * <fmnts-layout layout="page">
      <aside layoutSlot="panel-start">
        <ng-template #drawerPortal cdkPortalOutlet></ng-template>
      </aside>
      <main layoutSlot="content">
        ...
      </main>
    </fmnts-layout>
   * ```
   */
  portal?: CdkPortalOutlet | PortalOutlet;

  /**
   * The position of the drawer, can be either on the start or end of the screen.
   */
  position?: 'start' | 'end' = 'start';
}

import { Observable, debounceTime, map, startWith } from 'rxjs';
import { interaction$ } from './interaction';

/**
 * @param treshold Threshold in [ms] before a value emits
 *
 * @returns
 * An observable that emits with the original treshold value,
 * how long the user has been inactive.
 */
export function inactive$(treshold: number): Observable<number> {
  return interaction$().pipe(
    startWith(''),
    map(() => treshold),
    debounceTime(treshold),
  );
}

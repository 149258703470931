import { type Observable } from 'rxjs';
import {
  AddTrashedFormDataDto,
  TrashedFormDataEntity,
  TrashedFormDataEntityId,
} from './trashed-form-data.entity';

/**
 * Token for the form data trash repository.
 */
export abstract class FormDataTrashRepository {
  /**
   * Persists the `entity`.
   *
   * @param dto Entity to persist.
   */
  abstract put<TContent>(
    dto: AddTrashedFormDataDto<TContent>,
  ): Observable<TrashedFormDataEntity<TContent>>;

  /**
   * @returns
   * An Observable that emits the entities.
   */
  abstract getAll(): Observable<TrashedFormDataEntity<unknown>[]>;

  /**
   * Removes the entities with the given IDs.
   *
   * @param ids IDs of entities to be deleted.
   */
  abstract removeByIds(ids: TrashedFormDataEntityId[]): Observable<number>;

  /**
   * Expires entities.
   *
   * @returns
   * An Observable that emits with the number of expired entities^.
   */
  abstract expireEntries(): Observable<number>;
}

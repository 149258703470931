import { BundleVersion } from '@formunauts/shared/bundle/domain';

export interface BundleInfo {
  /** Name of the bundle. */
  readonly name: string;
  /** Bundle version info */
  readonly version: BundleVersion;
}

/**
 * Used to access information about the bundle.
 */
export class Bundle {
  /** Name of the bundle. */
  readonly name: string;
  /** Bundle version info */
  readonly version: BundleVersion;

  constructor(info: BundleInfo) {
    this.name = info.name;
    this.version = info.version;
  }
}

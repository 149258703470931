import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

/**
 * Service to apply styling based on the detected
 * - platform
 * - browser
 */
@Injectable({
  providedIn: 'root',
})
export class PlatformStyleService {
  private readonly _prefixBrowser = 'browser';
  private readonly _prefixPlatform = 'os';

  constructor(public platform: Platform) {}

  /**
   * Adds CSS class to `target` indicating the detected browser.
   *
   * @param target Target to apply CSS class on. Usually <html> or <body>.
   *
   * @see https://material.angular.io/cdk/platform/overview
   */
  public addBrowserClass(target: HTMLElement): void {
    const browser = this._getBrowserClassName();
    if (browser) {
      target.classList.add(`${this._prefixBrowser}-${browser}`);
    }
  }

  /**
   * Adds CSS class to `target` indicating the detected platform.
   *
   * @param target Target to apply CSS class on. Usually <html> or <body>.
   *
   * @see https://material.angular.io/cdk/platform/overview
   */
  public addPlatformClass(target: HTMLElement): void {
    const platform = this._getPlatformClassName();
    if (platform) {
      target.classList.add(`${this._prefixPlatform}-${platform}`);
    }
  }

  private _getBrowserClassName() {
    if (this.platform.EDGE) {
      return 'edge';
    } else if (this.platform.FIREFOX) {
      return 'firefox';
    } else if (this.platform.SAFARI) {
      return 'safari';
    }

    return undefined;
  }

  private _getPlatformClassName() {
    if (this.platform.IOS) {
      return 'ios';
    } else if (this.platform.ANDROID) {
      return 'android';
    }

    return undefined;
  }
}

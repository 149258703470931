import { Pipe, PipeTransform } from '@angular/core';
import { DurationFormatter, type Duration } from '@fmnts/core/chronos';

/**
 * Pipe for formatting durations.
 * For more information on formatting see {@link DurationFormatter}.
 *
 * @example
 * ```html
 * <span>
 *  {{ {seconds: 135} | i18n_duration }}
 * </span>
 * ```
 * prints '00:02:15'
 *
 * @example
 * ```html
 * <span>
 *  {{ { seconds: 135 } | i18n_duration: 'mm:ss' }}
 * </span>
 * ```
 * prints '02:15'
 */
@Pipe({
  name: 'i18n_duration',
  // there is no locale specific formatting yet, so for now
  // we can use it as a pure pipe
  pure: true,
})
export class I18nDurationPipe implements PipeTransform {
  /**
   * @param value The duration value
   * @param format String specifying the duration formatting
   */
  transform(value: Duration, format = 'hh:mm:ss'): string {
    const formatter = new DurationFormatter({
      format,
    });

    return formatter.format(value);
  }
}

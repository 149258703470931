import { inject, InjectionToken } from '@angular/core';
import { NAVIGATOR } from '@fmnts/common/browser';

/**
 * DI token for default position options.
 */
export const GEOLOCATION_POSITION_DEFAULT_OPTIONS =
  new InjectionToken<PositionOptions>(
    '@fmnts.common.geolocation.position.default_options',
    { factory: () => ({}) },
  );

/**
 * DI token for geolocation web api.
 */
export const GEOLOCATION = new InjectionToken<Geolocation>(
  '@fmnts.common.geolocation.api',
  {
    factory: () => inject(NAVIGATOR).geolocation,
  },
);

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiPageAdapter, PagedApiResponse, type Page } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiActivityAdapter } from '../adapter/activity.adapter';
import { DonationFeedbackToApiAdapter } from '../adapter/donation-feedback.adapter';
import { IApiActivity } from '../api-model/api-model';
import type { Activity } from '../model/activity';
import type { DonationFeedback } from '../model/donation-feedback';

interface ApiFilter {
  cursor?: string;
  target?: string;
  /**
   * comma separated list of campaign ids
   */
  campaigns?: string;
  /**
   * comma separated list of campaign types
   */
  campaign_types?: string;
  /**
   * Date in format `yyyy-mm-dd`
   */
  date_from?: string;
  /**
   * Date in format `yyyy-mm-dd`
   */
  date_to?: string;
  /**
   * Filters for activities with custom answer approved (`"true"`),
   * rejected (`"false"`) or open (`"null"`).
   * Only valid with `target="donationfeedback"`
   */
  custom_answer_approved?: string;
  /**
   * Comma separated list of fundraiser ids
   */
  fundraisers?: string;
  /**
   * comma separated list of ratings (1-5).
   * Only valid with `target="donationfeedback"`
   */
  ratings?: string;
  /**
   * Comma separated list of team ids
   */
  teams?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ActivityStreamApi {
  private static readonly rootUrl = '/v1/activity-stream/';

  private readonly _fromApiAdapter = new ApiActivityAdapter();
  private readonly _fromApiPageAdater = new ApiPageAdapter(
    this._fromApiAdapter,
  );

  private readonly _feedbackToApiAdapter = new DonationFeedbackToApiAdapter();

  private readonly configService = inject(ApiConfigService);
  private readonly apiHelper = inject(ApiRequestHelper);
  private readonly http = inject(HttpClient);

  /**
   * Fetches list of activities.
   *
   * @param urlOrFilter
   */
  public list(
    urlOrFilter: string | ApiFilter = {},
  ): Observable<Page<Activity>> {
    const url = this.configService.buildCockpitApiUrl([
      ActivityStreamApi.rootUrl,
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiActivity>>(url, { params })
      .pipe(map((response) => this._fromApiPageAdater.adapt(response)));
  }

  /**
   * Updates the visibility on a donor feedback comment
   */
  public updateDonationFeedback(
    obj: DonationFeedback,
  ): Observable<Record<string, any>> {
    const { id } = obj;

    const url = this.configService.buildCockpitApiUrl([
      ActivityStreamApi.rootUrl,
      'donationfeedback',
      id,
    ]);

    const dto = this._feedbackToApiAdapter.adapt(obj);

    return this.http.patch(url, dto);
  }
}

import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  getCurrentPosition$,
  watchPosition$,
} from './geolocation-rxjs-interop';
import {
  GEOLOCATION,
  GEOLOCATION_POSITION_DEFAULT_OPTIONS,
} from './geolocation.tokens';

/**
 * Service to access the client position.
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceGeolocationService {
  private readonly geolocation = inject(GEOLOCATION);
  private readonly positionOptions = inject(
    GEOLOCATION_POSITION_DEFAULT_OPTIONS,
  );

  /**
   * Emits with the current position of the device.
   *
   * @param options Options for getting the position.
   */
  currentPosition$(options?: PositionOptions): Observable<GeolocationPosition> {
    return getCurrentPosition$(this.geolocation, {
      ...this.positionOptions,
      ...options,
    });
  }

  /**
   * Emits when the position of the device changes.
   * Does not complete on its own.
   *
   * @param options Options for watching the position.
   */
  watchPosition$(options?: PositionOptions): Observable<GeolocationPosition> {
    return watchPosition$(this.geolocation, {
      ...this.positionOptions,
      ...options,
    });
  }
}

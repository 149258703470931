import { merge, fromEvent, Observable } from 'rxjs';

/**
 * Events that we want to observe
 */
const events = [
  'mousemove',
  'mousedown',
  'keypress',
  'scroll',
  'touchstart',
  'touchmove',
];

/**
 * @returns
 * An observable that emits, when an user interaction happens
 */
export function interaction$(): Observable<Event> {
  // We use `passive` here, to improve scroll performance
  const evtOptions = { passive: true };

  return merge(...events.map((e) => fromEvent(document, e, evtOptions)));
}

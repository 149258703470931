import { Params } from '@angular/router';
import {
  ISerializer,
  Maybe,
  Option,
  isDefined,
  isNonEmptyString,
  isNumber,
  isString,
  matchMaybe,
  matchOption,
  maybe,
  none,
} from '@fmnts/core';
import { includedIn } from '@fmnts/core/collection';

export type QueryParamsSerializer<T> = ISerializer<T, Params>;

/**
 * Serializes query param `<param>=<value>` and uses the given `serializer` to
 * serialize the `<value>`.
 *
 * @param param Name of the parameter
 * @param serializer Value serializer
 *
 * @returns
 * Serializer.
 */
export function queryParamKeySerializer<K extends string, T>(
  param: K,
  serializer: ISerializer<T, unknown>,
): QueryParamsSerializer<T | undefined> {
  return {
    serialize(data: T): Params {
      const value = serializer.serialize(data);

      return {
        [param]: value,
      };
    },
    deserialize(params: Params): T | undefined {
      const value: unknown = params[param];
      if (isDefined(value)) {
        return serializer.deserialize(value);
      }
      return undefined;
    },
  };
}

export class CombinedQueryParamsAdapter<T> implements QueryParamsSerializer<T> {
  protected readonly keys = Object.keys(this.adapters) as (keyof T)[];

  constructor(
    public readonly adapters: {
      [P in keyof T]: QueryParamsSerializer<T[P]>;
    },
  ) {}

  serialize(adapted: T): Params {
    return this.keys.reduce(
      (acc, cur) => ({
        ...acc,
        ...this.adapters[cur].serialize(adapted[cur]),
      }),
      {} as Params,
    );
  }

  deserialize(value: Params): T {
    return this.keys.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: this.adapters[cur].deserialize(value),
      }),
      {} as T,
    );
  }
}

export const stringQueryParamSerializer: ISerializer<
  string | undefined,
  string | undefined
> = {
  serialize: (data) => (isNonEmptyString(data) ? data : undefined),
  deserialize: (serialized) =>
    isNonEmptyString(serialized) ? serialized : undefined,
};

export const numberQueryParamSerializer: ISerializer<
  number | undefined,
  string | undefined
> = {
  serialize(data) {
    return isNumber(data) && !isNaN(data) ? `${data}` : undefined;
  },
  deserialize(s: string | undefined) {
    if (!isNonEmptyString(s)) {
      return undefined;
    }
    const result = Number.parseInt(s, 10);
    return isNaN(result) ? undefined : result;
  },
};

/**
 * Serializer that maps boolean values to the literals `'true'` or `'false'`.
 */
export const literalBoolQueryParamSerializer = maybeQueryParamSerializer(
  boolQueryParamSerializer('true', 'false'),
);

export const defaultQueryParamSerializer: ISerializer<
  (number | string) | undefined,
  string | undefined
> = {
  serialize(data) {
    return (
      (isNumber(data)
        ? numberQueryParamSerializer.serialize(data)
        : isString(data)
          ? stringQueryParamSerializer.serialize(data)
          : undefined) ?? undefined
    );
  },
  deserialize(s: string): (number | string) | undefined {
    const asNumber = numberQueryParamSerializer.deserialize(s);
    if (asNumber) {
      return asNumber;
    }

    return stringQueryParamSerializer.deserialize(s) ?? undefined;
  },
};

/**
 * Creates a serializer that takes a boolean value and maps
 * truthy values to `trueValue` and everything else to `falseValue`.
 *
 * @param trueValue Serialized value for `true`.
 * @param falseValue Serialized value for `false`.
 */
export function boolQueryParamSerializer<TTrue, TFalse>(
  trueValue: TTrue,
  falseValue: TFalse,
): ISerializer<boolean, TTrue | TFalse> {
  return {
    serialize: (data) => (data ? trueValue : falseValue),
    deserialize: (serialized) => (serialized === trueValue ? true : false),
  };
}

/**
 * Creates a serializer that allows only one of the given `values` and mapping
 * everything else to `undefined`.
 *
 * @param values Allowed values
 */
export function enumQueryParamSerializer<T extends string, S extends T>(
  values: S[],
): ISerializer<T | undefined, S | undefined> {
  const isAllowedValue = includedIn<string | undefined, S>(values);

  return {
    serialize: (data) => (isAllowedValue(data) ? data : undefined),
    deserialize: (serialized) =>
      isAllowedValue(serialized) ? serialized : undefined,
  };
}

/**
 * Creates a serializer that handles `none` values by mapping
 * them to the provided value of `noneSerialized` and passing
 * other values to the provided `serializer`.
 */
export function optionQueryParamSerializer<
  T,
  TTarget,
  TNoneSerialized extends string | number,
>(
  serializer: ISerializer<T, TTarget>,
  noneSerialized: TNoneSerialized,
): ISerializer<Option<T>, TTarget | TNoneSerialized>;
/**
 * Creates a serializer that handles `none` values by mapping
 * them to `'null'` and passing other values to the provided
 * `serializer`.
 */
export function optionQueryParamSerializer<T, TTarget>(
  serializer: ISerializer<T, TTarget | 'null'>,
): ISerializer<Option<T>, TTarget | 'null'>;
export function optionQueryParamSerializer<T>(
  serializer: ISerializer<T, unknown>,
  noneSerialized = 'null',
): ISerializer<Option<T>, unknown> {
  return {
    serialize: matchOption<T, unknown, string>({
      onSome: (value) => serializer.serialize(value),
      onNone: () => noneSerialized,
    }),
    deserialize: (serialized) =>
      (serialized === noneSerialized
        ? none
        : serializer.deserialize(serialized)) as Option<T>,
  };
}

/**
 * Creates a serializer that handles `maybe` values by mapping
 * them to the provided value of `maybeSerialized` and passing
 * other values to the provided `serializer`.
 */
export function maybeQueryParamSerializer<
  T,
  TTarget,
  TMaybeSerialized extends string | number,
>(
  serializer: ISerializer<T, TTarget>,
  maybeSerialized: TMaybeSerialized,
): ISerializer<Maybe<T>, TTarget | TMaybeSerialized>;
/**
 * Creates a serializer that handles `maybe` values by mapping
 * them to `undefined` and passing other values to the provided
 * `serializer`.
 */
export function maybeQueryParamSerializer<T, TTarget>(
  serializer: ISerializer<T, TTarget | undefined>,
): ISerializer<Maybe<T>, TTarget | undefined>;
export function maybeQueryParamSerializer<T>(
  serializer: ISerializer<T, unknown>,
  maybeSerialized = undefined,
): ISerializer<Maybe<T>, unknown> {
  return {
    serialize: matchMaybe<T, unknown, undefined>({
      onValue: (value) => serializer.serialize(value),
      onMaybe: () => maybeSerialized,
    }),
    deserialize: (serialized) =>
      (serialized === maybeSerialized
        ? maybe
        : serializer.deserialize(serialized)) as Maybe<T>,
  };
}

import {
  animate,
  AnimationTriggerMetadata,
  group,
  sequence,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { timingByThemeTokens } from '@fmnts/components/core';

/**
 * Animation states for drawer
 */
export enum DrawerState {
  /**
   * You can associate styles with this name to be used when the
   * element is detached from the application. For example,
   * when an `*ngIf` evaluates to false, the state of the associated
   * element is void.
   *
   * @see {@link state}
   */
  Void = 'void',

  OpenedStart = 'openedStart',

  OpenedEnd = 'openedEnd',
}

/**
 * Animations used by the Drawer container.
 */
export const drawerAnimations: {
  readonly drawerState: AnimationTriggerMetadata;
} = {
  /** Animation that shows and hides a drawer. */
  drawerState: trigger('state', [
    state(
      [DrawerState.Void].join(','),
      style({ transform: 'translateX(-100%)', visibility: 'hidden' }),
    ),

    // open start
    transition(
      `${DrawerState.Void} => ${DrawerState.OpenedStart}`,
      sequence([
        style({ transform: 'translateX(-100%)' }),
        animate(
          timingByThemeTokens({
            duration: 'duration.medium-4',
            curve: 'ease.emphasized-decelerate',
          }),
          style({ transform: 'translateX(0%)', visibility: 'visible' }),
        ),
      ]),
    ),
    // close start
    transition(
      `${DrawerState.OpenedStart} => ${DrawerState.Void}`,

      group([
        animate(
          timingByThemeTokens({
            duration: 'duration.short-4',
            curve: 'ease.emphasized-accelerate',
          }),
          style({ transform: 'translateX(-100%)' }),
        ),
      ]),
    ),

    // open end
    transition(
      `${DrawerState.Void} => ${DrawerState.OpenedEnd}`,
      sequence([
        style({ transform: 'translateX(100%)' }),
        animate(
          timingByThemeTokens({
            duration: 'duration.medium-4',
            curve: 'ease.emphasized-decelerate',
          }),
          style({ transform: 'translateX(0%)', visibility: 'visible' }),
        ),
      ]),
    ),
    // close end
    transition(
      `${DrawerState.OpenedEnd} => ${DrawerState.Void}`,
      group([
        animate(
          timingByThemeTokens({
            duration: 'duration.short-4',
            curve: 'ease.emphasized-accelerate',
          }),
          style({ transform: 'translateX(100%)' }),
        ),
      ]),
    ),
  ]),
};

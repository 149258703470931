import { inject, Injectable } from '@angular/core';
import { Repository } from '@fmnts/common/indexed-db';
import {
  IndexedDbLogDriverConfig,
  IndexedDbLogStore,
  LogEvent,
  LogLevel,
} from '@fmnts/common/log';
import { isDefined, Option } from '@fmnts/core';
import { concatMap, map, Observable, of } from 'rxjs';
import {
  AppDiagnosticsLogsDbSchema,
  DiagnosticsLogIdbModel,
} from './app-diagnostics-logs.db';

/** Log event entity. */
export interface LogEventEntity extends LogEvent {
  /** Log message. */
  message: string;
  /** Severity of the log message. */
  level: LogLevel;
  /** Category that the log message belongs to. */
  category: string;
  /** Scope that the log message belongs to. */
  scope: string;
  /** Timestamp of the log event. */
  timestamp: Date;
  /** Name of the subsystem that was used to create the event. */
  subsystem: string;
}

interface Range {
  lower: number;
  upper: number;
}

@Injectable()
export class AppLogsIdbRepository extends IndexedDbLogStore {
  private readonly _db =
    inject<Repository<AppDiagnosticsLogsDbSchema>>(Repository);

  private readonly _config = inject(IndexedDbLogDriverConfig);
  private readonly _mapper = inject(DiagnosticsLogIdbRepositoryMapper);

  override addMany(logs: LogEvent[]): Observable<LogEventEntity[]> {
    const entities = logs.map((log) => this._dtoToEntity(log));
    const models = entities.map((entity) => this._mapper.mapTo(entity));

    return this._db.add('diagnostic_logs', models).pipe(map(() => entities));
  }

  getAll(): Observable<LogEventEntity[]> {
    const allEntries$ = this._db.getAll('diagnostic_logs');

    return allEntries$.pipe(
      map((entries) => entries.map(this._mapper.mapFrom)),
    );
  }

  expireEntries(): Observable<number> {
    const allEntries$ = this._db.getAll('diagnostic_logs');

    return allEntries$.pipe(
      map((entries) =>
        entries
          .slice(0, Math.max(0, entries.length - this._config.maxEntries))
          .map((e) => e.id)
          .filter(isDefined),
      ),
      concatMap((idsToDelete) => {
        const range = this._getIdRange(idsToDelete);
        return range === null
          ? of(0)
          : this.removeByRange(range).pipe(map(() => idsToDelete.length));
      }),
    );
  }

  private removeByRange({ lower, upper }: Range): Observable<void> {
    return this._db.remove('diagnostic_logs', IDBKeyRange.bound(lower, upper));
  }

  private _getIdRange(ids: number[]): Option<Range> {
    if (ids.length === 0) {
      return null;
    }

    const [lower] = ids;
    const upper = ids[ids.length - 1];

    return { lower, upper };
  }

  private _dtoToEntity(dto: LogEvent): LogEventEntity {
    return {
      category: dto.category,
      level: dto.level,
      message: dto.message,
      scope: dto.scope,
      subsystem: dto.subsystem,
      timestamp: dto.timestamp,
    };
  }
}

/**
 * Mapper between Entity and Model.
 */
@Injectable({ providedIn: 'root' })
class DiagnosticsLogIdbRepositoryMapper {
  mapFrom = (model: DiagnosticsLogIdbModel): LogEventEntity => ({
    category: model.category,
    level: model.level,
    message: model.message,
    scope: model.scope,
    subsystem: model.subsystem,
    timestamp: model.timestamp,
  });

  mapTo = (entity: LogEventEntity): DiagnosticsLogIdbModel => ({
    category: entity.category,
    level: entity.level,
    message: entity.message,
    scope: entity.scope,
    subsystem: entity.subsystem,
    timestamp: entity.timestamp,
  });
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfigService } from '@fmnts/api/util';
import { map, Observable } from 'rxjs';
import * as Api from './api-model';

/**
 * Api client for handling user API calls.
 */
@Injectable({
  providedIn: 'root',
})
export class UserApi {
  private readonly apiConfig = inject(ApiConfigService);
  private readonly http = inject(HttpClient);

  /**
   * Fetches user information.
   *
   * @returns
   * An `Observable` that emits with the user information, then completes.
   */
  public fetchAuthInfo(): Observable<Api.UserProfile> {
    const url = this.apiConfig.buildCockpitApiUrl(['/v1/me/']);

    return this.http.get<Api.UserProfile>(url);
  }

  /**
   * Updates the current users password.
   *
   * @param dto DTO describing the change
   *
   * @returns
   * An `Observable` that emits when the change was OK.
   */
  public updatePassword(dto: Api.PasswordChange): Observable<void> {
    const url = this.apiConfig.buildCockpitApiUrl(['/v2/me/password/']);

    return this.http.post(url, dto).pipe(map(() => {}));
  }
}

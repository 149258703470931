import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pure pipe equivalent for `[].join()`.
 *
 * @example
 * {{ ['a', 'b', 'c'] | join: '/' }}
 * // --> 'a/b/c
 */
@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  /**
   * Adds all the elements of an input array into a string,
   * separated by the specified separator string.
   *
   * @param input Input array
   *
   * @param separator
   * A string used to separate one element of the array from the next in the resulting string.
   *
   * @returns
   * Joined array items
   */
  transform(input: unknown, separator: string = ''): any {
    if (!Array.isArray(input)) {
      return input;
    }

    return input.join(separator);
  }
}

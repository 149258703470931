import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import {
  FmntsCellDefDirective,
  FmntsCellDirective,
  FmntsColumnDefDirective,
  FmntsFooterCellDefDirective,
  FmntsFooterCellDirective,
  FmntsHeaderCellDefDirective,
  FmntsHeaderCellDirective,
} from './cell.directive';
import {
  FmntsCellOutletDirective,
  FmntsFooterRowComponent,
  FmntsFooterRowDefDirective,
  FmntsHeaderRowComponent,
  FmntsHeaderRowDefDirective,
  FmntsNoDataRowDirective,
  FmntsRowComponent,
  FmntsRowDefDirective,
} from './row.directive';
import {
  DataRowOutletDirective,
  FmntsRecycleRowsDirective,
  FmntsTableComponent,
  FooterRowOutletDirective,
  HeaderRowOutletDirective,
  NoDataRowOutletDirective,
} from './table.component';

const EXPORTED_DECLARATIONS = [
  FmntsTableComponent,
  FmntsRecycleRowsDirective,
  FmntsRowDefDirective,
  FmntsCellDefDirective,
  FmntsCellOutletDirective,
  FmntsHeaderCellDefDirective,
  FmntsFooterCellDefDirective,
  FmntsColumnDefDirective,
  FmntsCellDirective,
  FmntsRowComponent,
  FmntsHeaderCellDirective,
  FmntsFooterCellDirective,
  FmntsHeaderRowComponent,
  FmntsHeaderRowDefDirective,
  FmntsFooterRowComponent,
  FmntsFooterRowDefDirective,
  DataRowOutletDirective,
  HeaderRowOutletDirective,
  FooterRowOutletDirective,
  FmntsNoDataRowDirective,
  NoDataRowOutletDirective,
];

@NgModule({
  exports: EXPORTED_DECLARATIONS,
  imports: [ScrollingModule, ...EXPORTED_DECLARATIONS],
})
export class FmntsTableModule {}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector } from '@ngrx/store';
import { ViewId } from '../view-option.model';
import { viewOptionsFeature } from './view-options.reducer';

export const {
  selectViewOptionsState, // feature selector
  selectViews,
} = viewOptionsFeature;

export const selectOptionsForView = (viewId: ViewId) =>
  createSelector(selectViews, (views) => views[viewId]);

export const selectViewIds = createSelector(selectViews, (views) =>
  Object.keys(views ?? {}),
);

export const selectDisplayOptionsForView = (viewId: ViewId) =>
  createSelector(
    selectOptionsForView(viewId),
    (viewOptions) => viewOptions?.display,
  );

export const selectFiltersForView = (viewId: ViewId) =>
  createSelector(
    selectOptionsForView(viewId),
    (viewOptions) => viewOptions?.filters,
  );

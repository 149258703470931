import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ToastRef } from './toast-component-ref';
import { TOAST_DATA, TOAST_DURATION, ToastConfig } from './toast-config';
import { ToastContainerComponent } from './toast-container';
import {
  TextOnlyToast,
  TextOnlyToastData,
  ToastComponent,
} from './toast.component';

/** @docs-private */
export function FMNTS_TOAST_DEFAULT_OPTIONS_FACTORY(): ToastConfig {
  return new ToastConfig();
}

/** Injection token that can be used to specify default toast. */
export const FMNTS_TOAST_DEFAULT_OPTIONS = new InjectionToken<ToastConfig>(
  'fmnts-toast-default-options',
  {
    providedIn: 'root',
    factory: FMNTS_TOAST_DEFAULT_OPTIONS_FACTORY,
  },
);

/**
 * Service to dispatch a single toast message.
 *
 * Prefer using `Toaster` as this will group toast messages.
 */
@Injectable()
export class Toast implements OnDestroy {
  public toastRefs?: ToastRef<ToastComponent>[];
  /**
   * Reference to the current toast in the view.
   * If there is a parent toast service, all operations should delegate to that parent
   * via `_openedToastRef`.
   */
  private _toastRefAtThisLevel: ToastRef<any> | null = null;

  /** The component that should be rendered as the toast's simple component. */
  toastComponent = ToastComponent;

  /** The container component that attaches the provided template or component. */
  toastContainerComponent = ToastContainerComponent;

  /** The CSS class to apply for handset mode. */
  handsetCssClass = 'fmnts-toast-handset';

  /** Reference to the currently opened toast at *any* level. */
  get _openedToastRef(): ToastRef<any, any> | null {
    const parent = this._parentToast;
    return parent ? parent._openedToastRef : this._toastRefAtThisLevel;
  }

  set _openedToastRef(value: ToastRef<any, any> | null) {
    if (this._parentToast) {
      this._parentToast._openedToastRef = value;
    } else {
      this._toastRefAtThisLevel = value;
    }
  }

  constructor(
    private _overlay: Overlay,
    private _live: LiveAnnouncer,
    private _injector: Injector,
    private _breakpointObserver: BreakpointObserver,
    @Optional() @SkipSelf() private _parentToast: Toast,
    @Inject(FMNTS_TOAST_DEFAULT_OPTIONS) private _defaultConfig: ToastConfig,
  ) {}

  /**
   * Creates and dispatches a toast with a custom component for the content, removing any
   * currently opened toast.
   *
   * @param component Component to be instantiated.
   * @param config Extra configuration for the toast.
   */
  openFromComponent<T, D = any, TResult = unknown>(
    component: ComponentType<T>,
    config?: ToastConfig<D>,
  ): ToastRef<T, TResult> {
    return this._attach(component, config) as ToastRef<T, TResult>;
  }

  /**
   * Creates and dispatches a toast with a custom template for the content, removing any
   * currently opened toasts.
   *
   * @param template Template to be instantiated.
   * @param config Extra configuration for the toast.
   */
  openFromTemplate<D = any, TResult = unknown>(
    template: TemplateRef<any>,
    config?: ToastConfig<D>,
  ): ToastRef<EmbeddedViewRef<any>, TResult> {
    return this._attach(template, config) as ToastRef<
      EmbeddedViewRef<any>,
      TResult
    >;
  }

  /**
   * Opens a toast with a message and an optional actions.
   * @param data Data to be passed to toast
   * @param config Additional configuration options for the toast.
   */
  open<TResult = unknown>(
    data: TextOnlyToastData,
    config?: ToastConfig,
  ): ToastRef<TextOnlyToast, TResult> {
    const _config: ToastConfig<TextOnlyToastData> = {
      ...this._defaultConfig,
      ...config,
    };

    // Since the user doesn't have access to the component, we can
    // override the data to pass in our own message and action.
    _config.data = { ...data };

    // Since the toast has `role="alert"`, we don't
    // want to announce the same message twice.
    if (_config.announcementMessage === _config.data.message) {
      _config.announcementMessage = undefined;
    }

    return this.openFromComponent(this.toastComponent, _config);
  }

  /**
   * Dismisses the currently-visible toast.
   */
  dismiss(): void {
    if (this._openedToastRef) {
      this._openedToastRef.dismiss();
    }
  }

  ngOnDestroy(): void {
    // Only dismiss the toast at the current level on destroy.
    this._toastRefAtThisLevel?.dismiss();
  }

  /**
   * Attaches the toast container component to the overlay.
   */
  private _attachToastContainer(
    overlayRef: OverlayRef,
    config: ToastConfig,
  ): ToastContainerComponent {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = Injector.create({
      parent: userInjector || this._injector,
      providers: [{ provide: ToastConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(
      this.toastContainerComponent,
      config.viewContainerRef,
      injector,
    );
    const containerRef: ComponentRef<ToastContainerComponent> =
      overlayRef.attach(containerPortal);
    containerRef.instance.toastConfig = config;
    return containerRef.instance;
  }

  /**
   * Places a new component or a template as the content of the toast container.
   */
  private _attach<T, D = any, TResult = unknown>(
    content: ComponentType<T> | TemplateRef<T>,
    userConfig?: ToastConfig<D>,
  ): ToastRef<T | EmbeddedViewRef<any>, TResult> {
    const config = {
      ...new ToastConfig(),
      ...this._defaultConfig,
      ...userConfig,
    };
    const overlayRef = this._createOverlay(config);
    const container = this._attachToastContainer(overlayRef, config);
    const toastRef = new ToastRef<T | EmbeddedViewRef<any>, TResult>(
      container,
      overlayRef,
    );

    if (content instanceof TemplateRef) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const portal = new TemplatePortal(content, null!, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        $implicit: config.data,
        toastRef,
      } as any);

      toastRef.instance = container.attachTemplatePortal(portal);
    } else {
      const injector = this._createInjector(config, toastRef);
      const portal = new ComponentPortal(content, undefined, injector);
      const contentRef = container.attachComponentPortal<T>(portal);

      // We can't pass this via the injector, because the injector is created earlier.
      toastRef.instance = contentRef.instance;
    }

    // Subscribe to the breakpoint observer and attach the toast-handset class as
    // appropriate. This class is applied to the overlay element because the overlay must expand to
    // fill the width of the screen for full width toasts.
    this._breakpointObserver
      .observe(Breakpoints.HandsetPortrait)
      .pipe(takeUntil(overlayRef.detachments()))
      .subscribe((state) => {
        overlayRef.overlayElement.classList.toggle(
          this.handsetCssClass,
          state.matches,
        );
      });

    if (config.announcementMessage) {
      // Wait until the toast contents have been announced then deliver this message.
      container._onAnnounce.subscribe(() => {
        if (config.announcementMessage) {
          void this._live.announce(
            config.announcementMessage,
            config.politeness,
          );
        }
      });
    }

    this._animateToast(toastRef, config);
    this._openedToastRef = toastRef;
    return toastRef;
  }

  /** Animates the old toast out and the new one in. */
  private _animateToast(toastRef: ToastRef<any, any>, config: ToastConfig) {
    // When the toast is dismissed, clear the reference to it.
    toastRef.afterDismissed().subscribe(() => {
      // Clear the toast ref if it hasn't already been replaced by a newer toast.
      if (this._openedToastRef === toastRef) {
        this._openedToastRef = null;
      }

      if (config.announcementMessage) {
        this._live.clear();
      }
    });

    if (this._openedToastRef) {
      // If a toast is already in view, dismiss it and enter the
      // new toast after exit animation is complete.
      this._openedToastRef.afterDismissed().subscribe(() => {
        toastRef.containerInstance.enter();
      });
      this._openedToastRef.dismiss();
    } else {
      // If no toast is in view, enter the new toast.
      toastRef.containerInstance.enter();
    }

    // If a dismiss timeout is provided, set up dismiss based on after the toast is opened.
    if (config.duration && config.duration > 0) {
      toastRef.afterOpened().subscribe(() => {
        if (config.duration) {
          toastRef._dismissAfter(config.duration);
        }
      });
    }
  }

  /**
   * Creates a new overlay and places it in the correct location.
   * @param config The user-specified toast config.
   */
  private _createOverlay(config: ToastConfig): OverlayRef {
    const overlayConfig = new OverlayConfig();
    overlayConfig.direction = config.direction;

    const positionStrategy = this._overlay.position().global();

    // Set horizontal position.
    const isRtl = config.direction === 'rtl';
    const isLeft =
      config.horizontalPosition === 'left' ||
      (config.horizontalPosition === 'start' && !isRtl) ||
      (config.horizontalPosition === 'end' && isRtl);
    const isRight = !isLeft && config.horizontalPosition !== 'center';
    if (isLeft) {
      positionStrategy.left('0');
    } else if (isRight) {
      positionStrategy.right('0');
    } else {
      positionStrategy.centerHorizontally();
    }
    // Set vertical position.
    if (config.verticalPosition === 'top') {
      positionStrategy.top('0');
    } else {
      positionStrategy.bottom('0');
    }

    overlayConfig.positionStrategy = positionStrategy;
    return this._overlay.create(overlayConfig);
  }

  /**
   * Creates an injector to be used inside of a toast component.
   * @param config Config that was used to create the toast.
   * @param toastRef Reference to the toast.
   */
  private _createInjector<T>(
    config: ToastConfig,
    toastRef: ToastRef<T, any>,
  ): Injector {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;

    return Injector.create({
      parent: userInjector || this._injector,
      providers: [
        { provide: ToastRef, useValue: toastRef },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { provide: TOAST_DATA, useValue: config.data },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        { provide: TOAST_DURATION, useValue: config.duration },
      ],
    });
  }
}

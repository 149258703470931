import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatusBarComponent } from './status-bar.component';
import { StatusIndicatorComponent } from './status-indicator.component';

@NgModule({
  declarations: [StatusBarComponent, StatusIndicatorComponent],
  exports: [StatusBarComponent, StatusIndicatorComponent],
  imports: [CommonModule],
})
export class FmntsStatusModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import { I18nModule } from '@fmnts/i18n';
import { ToastStackComponent } from './components/toast-stack/toast-stack.component';
import { ToastComponent } from './components/toast/toast.component';
import { PushToastDirective } from './directives/push-toast/push-toast.directive';

@NgModule({
  declarations: [PushToastDirective, ToastComponent, ToastStackComponent],
  exports: [PushToastDirective, ToastComponent, ToastStackComponent],
  imports: [
    CommonModule,
    FmntsButtonModule,
    FmntsIconsModule,
    FmntsLayoutModule,
    I18nModule,
  ],
})
export class MessagingModule {}

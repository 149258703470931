/**
 * Tries a deep equal matching of the 2 given values
 *
 * @param a First value
 * @param b Second value
 *
 * @returns
 * `true`, if the values `a` and `b` are equal.
 */
export function deepEqual(a: unknown, b: unknown): boolean {
  if (Array.isArray(a) && Array.isArray(b)) {
    return deepEqualArray(a, b);
  }

  if (
    a !== null &&
    b !== null &&
    typeof a === 'object' &&
    typeof b === 'object'
  ) {
    return deepEqualObj(a, b);
  }

  return a === b;
}

/**
 * @returns
 * `true` if the objects `a` and `b` appear to be equal.
 */
export function deepEqualObj(
  a: Record<string, any>,
  b: Record<string, any>,
): boolean {
  return deepEqualArray(Object.values(a), Object.values(b));
}

/**
 *
 * @param a First array
 * @param b Second array
 *
 * @returns
 * `true`, if the values in the array are equal and in
 * the same order.
 */
export function deepEqualArray(a: any[], b: any[]): boolean {
  return (
    a.length === b.length &&
    a.every((value, index) => deepEqual(value, b[index]))
  );
}

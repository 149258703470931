import {
  apiAdaptOrNull,
  apiArrayAdapter,
  formDataAdapter,
} from '@fmnts/api/shared';
import { Adapter } from '@fmnts/core';
import {
  IApiQuiz,
  IApiQuizAnswer,
  IApiQuizAttempt,
  IApiQuizCreateDto,
  IApiQuizMinimal,
  IApiQuizUpdateDto,
} from '../api-model/api-model';
import {
  QuestionType,
  Quiz,
  QuizAnswer,
  QuizAttempt,
  QuizMinimal,
} from '../model/quiz';

export class ApiQuizAdapter implements Adapter<Quiz> {
  adapt(apiDto: IApiQuiz): Quiz {
    return new Quiz({
      id: `${apiDto.id}`,
      isActive: apiDto.is_active,
      isEditable: apiDto.is_editable,
      formVersion: apiDto.form_version,
      title: apiDto.title,
      description: apiDto.description,
      image: apiAdaptOrNull.valueOrNull(apiDto.image),
      thumbnailUrl: apiAdaptOrNull.valueOrNull(apiDto.thumbnail_url),
      steps: apiDto.steps,
      assetIds: apiArrayAdapter.itemsOrNull(apiDto.assets),
      scoreNeeded: apiDto.score_needed,
      attemptsAllowed: apiDto.attempts_allowed,
      expectedDuration: apiDto.expected_duration,
      activeFrom: apiAdaptOrNull.valueOrNull(apiDto.active_from),
      activeTo: apiAdaptOrNull.valueOrNull(apiDto.active_to),
      created: apiDto.createtime,
      modified: apiDto.modifytime,
      showTrainingResults: apiDto.show_training_results,
      revision: apiDto.revision,
    });
  }
}

export interface IQuizDto {
  id?: Quiz['id'];
  isActive: boolean;
  formVersion: number;
  title: string;
  description: string;
  steps: Record<string, unknown>[];
  assetIds: number[];
  scoreNeeded: number;
  attemptsAllowed: number;
  expectedDuration: number;
  activeFrom: string | null;
  activeTo: string | null;
  image: File | null;
  showTrainingResults: boolean;
  revision: string;
}

export class QuizToApiAdapter {
  adaptForCreate(dto: IQuizDto): FormData {
    const apiDto = this._toApiDto(dto);
    return this.adaptToFormData(apiDto);
  }

  adaptForUpdate(dto: Partial<IQuizDto>): FormData {
    const apiDto = this._toApiDto(dto);
    return this.adaptToFormData(apiDto);
  }

  /** Adapt to DTO for create operation. */
  private _toApiDto(dto: IQuizDto): IApiQuizCreateDto;
  /** Adapt to DTO for update operation. */
  private _toApiDto(dto: Partial<IQuizDto>): IApiQuizUpdateDto;
  private _toApiDto(dto: Partial<IQuizDto>): IApiQuizUpdateDto {
    return {
      is_active: dto.isActive,
      form_version: dto.formVersion,
      title: dto.title,
      description: dto.description,
      steps: dto.steps,
      assets: dto.assetIds,
      score_needed: dto.scoreNeeded,
      attempts_allowed: dto.attemptsAllowed,
      expected_duration: dto.expectedDuration,
      image: dto.image === null ? null : (dto.image ?? undefined),
      active_from:
        dto.activeFrom === undefined
          ? undefined
          : dto.activeFrom === null
            ? null
            : dto.activeFrom,
      active_to:
        dto.activeTo === undefined
          ? undefined
          : dto.activeTo === null
            ? null
            : dto.activeTo,
      show_training_results: dto.showTrainingResults,
      revision: dto.revision,
    };
  }

  adaptModelToDto(quiz: Quiz): IQuizDto {
    return {
      id: quiz.id,
      isActive: quiz.isActive,
      formVersion: quiz.formVersion,
      title: quiz.title,
      description: quiz.description,
      steps: quiz.steps,
      assetIds: quiz.assetIds,
      scoreNeeded: quiz.scoreNeeded,
      attemptsAllowed: quiz.attemptsAllowed,
      expectedDuration: quiz.expectedDuration,
      activeFrom: quiz.activeFrom ? quiz.activeFrom : null,
      activeTo: quiz.activeTo ? quiz.activeTo : null,
      image: quiz.image ? undefined : null,
      showTrainingResults: quiz.showTrainingResults,
      revision: quiz.revision,
    };
  }

  /**
   * Adapts the given quiz data to be compatible with the API endpoint
   *
   * @param quiz The quiz object
   *
   * @returns
   * Parsed data that can be passed to the API endpoint
   */
  private adaptToFormData(
    dto: IApiQuizCreateDto | IApiQuizUpdateDto,
  ): FormData {
    const { steps, ...otherValues } = dto;
    const data = formDataAdapter.adaptRecord(otherValues);

    if (steps) {
      formDataAdapter.appendJson(data, 'steps', steps);
    }

    return data;
  }
}

export class ApiQuizMinimalAdapter implements Adapter<QuizMinimal> {
  adapt(apiDto: IApiQuizMinimal): QuizMinimal {
    return {
      id: `${apiDto.id}`,
      title: apiDto.title,
      scoreNeeded: apiDto.score_needed,
      thumbnailUrl: apiAdaptOrNull.valueOrNull(apiDto.thumbnail_url),
      expectedDuration: apiDto.expected_duration,
    };
  }
}

export class ApiQuizAttemptAdapter implements Adapter<QuizAttempt> {
  private readonly quizAnswerAdapter = new ApiQuizAnswerAdapter();

  public adapt(apiDto: IApiQuizAttempt): QuizAttempt {
    return new QuizAttempt({
      id: `${apiDto.id}`,
      startedAt: apiDto.started_at,
      finishedAt: apiDto.finished_at,
      quizId: `${apiDto.quiz}`,
      fundraiserId: `${apiDto.fundraiser}`,
      score: apiDto.score,
      passed: apiDto.passed,
      answers: apiArrayAdapter.adaptItemsOrEmpty(
        apiDto.answers,
        this.quizAnswerAdapter,
      ),
      formVersion: apiDto.form_version,
      revision: apiDto.quiz_revision,
      isExpired: apiDto.is_expired,
    });
  }
}

export class ApiQuizAnswerAdapter implements Adapter<QuizAnswer> {
  public adapt(apiDto: IApiQuizAnswer): QuizAnswer {
    return new QuizAnswer({
      id: apiDto.id,
      answer: apiDto.answer,
      correctAnswers: apiDto.correctAnswers,
      correct: apiDto.correct,
      type: apiDto.type as QuestionType,
    });
  }
}

import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Nullish, isNil } from '@fmnts/core';
import type { Subscription } from 'rxjs';
import { TranslationService } from './translation.service';
import type { TranslationId } from './translation.types';

/**
 * Pipe to use with a value that is either a translation id or some nullish value.
 *
 * @example
 * {{ 'value' | translateWithFallback: '-'}}
 * // -> same as 'value' | translate
 *
 * @example
 * {{ null | translateWithFallback: ('none' | translate)}}
 * // -> same as 'none' | translate
 */
@Pipe({
  name: 'translateWithFallback',
  pure: false,
})
export class TranslateWithFallbackPipe implements PipeTransform, OnDestroy {
  private _sub: Subscription;

  constructor(
    private translationService: TranslationService,
    private cd: ChangeDetectorRef,
  ) {
    // Update components with OnPush change detection strategy
    this._sub = this.translationService.currentLanguage$.subscribe(() => {
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  transform(
    value: TranslationId | Nullish,
    fallback: string,
    ...substitutions: unknown[]
  ): string {
    return isNil(value)
      ? fallback
      : this.translationService.getTranslation(value, ...substitutions);
  }
}

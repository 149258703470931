<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'display'">
    <fmnts-rocket
      [animateThrusts]="this.animateThrusts"
      [color]="this.color"
    ></fmnts-rocket>
    <fmnts-logo-type [color]="color" [tagline]="tagline"></fmnts-logo-type>
  </ng-container>

  <ng-container *ngSwitchCase="'regular'">
    <fmnts-rocket
      [animateThrusts]="animateThrusts"
      [color]="color"
    ></fmnts-rocket>
    <fmnts-logo-type
      stretchTagline
      [color]="color"
      [tagline]="tagline"
    ></fmnts-logo-type>
  </ng-container>

  <fmnts-rocket
    *ngSwitchCase="'icon'"
    [animateThrusts]="animateThrusts"
    [color]="color"
  ></fmnts-rocket>
</ng-container>

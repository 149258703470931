import { LocationMinimal } from '@fmnts/api/shared';
import { FundraiserMinimal, IFundraiserMinimal } from './fundraiser';
import { IWorkshift, Workshift } from './workshift';

export interface ILocationReporting {
  campaignName: string;
  /**
   * Timestamp when the user checked in
   * at the location
   */
  checkIn: string;
  customerName: string;
  organizationName: string;
  location: Readonly<LocationMinimal>;
  fundraiser: Readonly<IFundraiserMinimal>;
  workshift: Readonly<IWorkshift>;
}

/**
 * A location report
 */
export class LocationReporting implements ILocationReporting {
  campaignName: string;
  checkIn: string;
  customerName: string;
  organizationName: string;
  location: Readonly<LocationMinimal>;
  fundraiser: Readonly<FundraiserMinimal>;
  workshift: Readonly<Workshift>;

  constructor(dto: Readonly<ILocationReporting>) {
    this.campaignName = dto.campaignName;
    this.checkIn = dto.checkIn;
    this.customerName = dto.customerName;
    this.organizationName = dto.organizationName;
    this.location = dto.location;
    this.fundraiser = new FundraiserMinimal(dto.fundraiser);
    this.workshift = new Workshift(dto.workshift);
  }
}

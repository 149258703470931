import { MonoTypeOperatorFunction, distinctUntilChanged } from 'rxjs';

interface Change<T> {
  /**
   * Previous object
   */
  previous: Record<string, T>;
  /**
   * current object
   */
  current: Record<string, T>;
  /**
   * key, for which the predicate is called
   */
  key: string;
}

type PredicateFn<T> = (left: T, right: T, change: Change<T>) => boolean;

const eqeqeq: PredicateFn<unknown> = (left, right) => left === right;

/**
 * @param compareLength
 * If `true`, the check is more strict regarding missing keys.
 * If `false`, objects like `{}` and `{prop: undefined}` will
 * be treated the same.
 * Defaults to `true`.
 *
 * @param predicate
 * Predicate function for comparing object properties
 * Defaults to a `a === b` check.
 *
 * @returns
 * An Operator that filters emission where the emitted
 * object did not change.
 */
export function distinctUntilObjectChanged<T = unknown>(
  compareLength: boolean = true,
  predicate: PredicateFn<T> = eqeqeq,
): MonoTypeOperatorFunction<Record<string, T>> {
  return distinctUntilChanged((previous, current) => {
    const a = Object.keys(previous);
    const b = Object.keys(current);

    // Compare length of keys, if requested
    if (compareLength && a.length !== b.length) {
      return false;
    }

    const longer = a.length > b.length ? a : b;
    return longer.every((key) =>
      predicate(previous[key], current[key], { key, previous, current }),
    );
  });
}

<fmnts-flex direction="column" justify="center" align="center" gap="space.200">
  <div class="rotate-device__icon">
    <fa-layers>
      <fa-icon [icon]="_iconMobile" transform="left-14 up-3"></fa-icon>
      <fa-icon [icon]="_iconArrow" transform="left-2 shrink-8"></fa-icon>
      <fa-icon
        [icon]="_iconMobile"
        [rotate]="90"
        transform="right-12"
      ></fa-icon>
    </fa-layers>
  </div>
  <div class="rotate-device__text fmnts-text-xl">
    {{ 'rotate_device' | translate }}
  </div>
</fmnts-flex>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { RocketComponent } from '@fmnts/components/corporate-identity';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

type LoadingIndicatorVariant = 'fontawesome' | 'rocket';

/**
 * Displays a loading indicator
 */
@Component({
  selector: 'fmnts-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
  @HostBinding('class')
  protected readonly componentClass = 'fmnts-loading-indicator';

  protected readonly iconSpinner = faSpinner;

  /**
   * Size of the indicator
   */
  @Input() size: RocketComponent['size'] = 'md';
  /**
   * Color variant to use
   */
  @Input() color: RocketComponent['color'] = 'primary';
  /**
   * Loading indicator variant to use
   */
  @Input() variant: LoadingIndicatorVariant = 'fontawesome';

  public spinnerSize(): SizeProp {
    switch (this.size) {
      case 'xs':
        return '1x';
      case 'sm':
        return '2x';
      case 'lg':
        return '4x';
      default:
        return '3x';
    }
  }
}

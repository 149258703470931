import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@fmnts/common/browser';

/** DI token for local storage. */
export const LOCAL_STORAGE = new InjectionToken<Storage>(
  '@fmnts.common.web-storage-api.local-storage',
  {
    factory: () => inject(WINDOW).localStorage,
  },
);

/** DI token for session storage. */
export const SESSION_STORAGE = new InjectionToken<Storage>(
  '@fmnts.common.web-storage-api.session-storage',
  {
    factory: () => inject(WINDOW).sessionStorage,
  },
);

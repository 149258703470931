import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { Subject, Subscription } from 'rxjs';
import { ImageControl, ImageEditorPanel } from './image-input-base';
import {
  ImageSelectionModel,
  imageContentFromFile,
} from './image-selection-model';

type ImageInputControl = ImageControl;

/**
 * Image Input
 *
 * The Image Input Component serves as a base component, for loading images,
 * from file system or device cameras.
 *
 * As a standalone component it can be used as a regular `input[type=file]`
 * element within a form.
 *
 * Combined with the Image Editor Component, it is possible to modify images.
 *
 */
@Component({
  selector: 'fmnts-image-input',
  templateUrl: './image-input.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageInputComponent),
      multi: true,
    },
  ],
})
export class ImageInputComponent
  implements ImageInputControl, OnDestroy, OnInit, ControlValueAccessor
{
  private _closedSubscription = Subscription.EMPTY;
  private _uploaded = false;

  @HostBinding('class.fmnts-image-input')
  protected readonly componentClass = 'fmnts-image-input';

  /**
   * Accepted MIME types
   */
  @Input() accepts: string[] = ['image/*'];

  /** Whether the input is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this.stateChanges.next(undefined);
    }
  }
  private _disabled = false;

  /**
   * Value with the current image file
   */
  public get value(): Blob | null {
    return this._value;
  }
  public set value(val: Blob | null) {
    this._value = val;
  }
  private _value: Blob | null = null;

  /** Whether an image was uploaded. */
  get uploaded(): boolean {
    return this._uploaded;
  }
  set uploaded(value: boolean) {
    this._uploaded = value;
  }

  /** The editor canvas that this input is associated with. */
  @Input()
  get editor(): ImageEditorPanel<ImageInputControl> | undefined {
    return this._editor;
  }
  set editor(editor: ImageEditorPanel<ImageInputControl> | undefined) {
    if (!editor) {
      return;
    }

    this._model = editor.registerInput(this);
    this._editor = editor;
    this._closedSubscription.unsubscribe();
    this._closedSubscription = editor.closedStream.subscribe(() => {
      this._uploaded = true;
    });
  }
  private _editor?: ImageEditorPanel<ImageInputControl>;

  /** @internal */
  @ViewChild('fileInput', { static: true })
  fileInput!: ElementRef<HTMLInputElement>;

  /** Emits when the input's state has changed. */
  readonly stateChanges = new Subject<void>();

  public _model?: ImageSelectionModel;

  private _onChange: ChangeCallback<Blob | null> = () => {};
  private _onTouched: TouchedCallback = () => {};

  ngOnInit(): void {
    // if there is no editor associated with this input instance
    // initialize a new model
    if (!this._model) {
      this._model = new ImageSelectionModel();
    }

    this._model.selectionChanged.subscribe(({ selection, source }) => {
      this.value = selection.modified ?? selection.original ?? null;

      // Propagate changes to `FormControl`
      if (source !== 'model') {
        this._onChange(this.value);
      }
    });
  }

  ngOnDestroy(): void {
    this._closedSubscription.unsubscribe();
    this.stateChanges.complete();
  }

  /**
   * opens the system file reader/camera
   */
  public openFileSelectDialog(): void {
    if (!this.disabled) {
      this.fileInput.nativeElement.click();
    }
  }

  /**
   * Saves Image File to Model and opens the editor
   * @param inputElement
   * @internal
   */
  public updateFile(inputElement: HTMLInputElement): void {
    const file = inputElement.files?.item(0);
    if (!file) {
      return;
    }

    // check if file type is in accepted MIME Type list
    if (this.accepts.every((value) => file.type.match(value) === null)) {
      return;
    }

    this.stateChanges.next();
    this._uploaded = true;

    const image = imageContentFromFile(file);
    this._model?.updateSelection(image, this);
    this._onTouched();
    this._onChange(image.original);

    this.stateChanges.next();
    this._uploaded = true;
    this.editor?.open();
  }
  /** @internal */
  writeValue(img: unknown): void {
    // TODO: handle other values like e.g. `null`
    if (!(img instanceof File)) {
      return;
    }

    this.stateChanges.next();
    this._uploaded = true;

    const image = imageContentFromFile(img);
    this._model?.updateSelection(image, 'model');
  }

  /** @internal */
  registerOnChange(fn: ChangeCallback): void {
    this._onChange = fn;
  }
  /** @internal */
  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }
  /** @internal */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

import {
  Directive,
  EmbeddedViewRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { isNotNull } from '@fmnts/core';
import {
  BehaviorSubject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  startWith,
} from 'rxjs';
import { AbstractFeatureFlags } from './feature-flags';

/**
 * Structural directive that conditionally renders a template when the given
 * feature flag is active.
 */
@Directive()
export abstract class AbstractHasFeatureFlagDirective<T extends string>
  implements OnInit, OnDestroy
{
  /** Feature flag service. */
  protected abstract featureFlags: AbstractFeatureFlags<T>;

  /** The feature flag to check. */
  protected _flag = new BehaviorSubject<T | null>(null);
  /**
   * Evaluated value if the given flag is on or off or
   * `undefined` if not yet evaluated.
   */
  private _hasFlag?: boolean;

  protected _thenViewRef: EmbeddedViewRef<unknown> | null = null;
  protected _elseViewRef: EmbeddedViewRef<unknown> | null = null;
  protected _elseTemplateRef: TemplateRef<unknown> | null = null;

  private _subscription = new Subscription();

  constructor(
    /** Template that is shown when the feature flag is active */
    private _hasFlagTemplateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this._subscription.add(
      combineLatest([
        this._flag.pipe(filter(isNotNull), distinctUntilChanged()),
        this.featureFlags.flagsChange.pipe(startWith(null)),
      ])
        .pipe(
          map(([flag]) => this.featureFlags.on(flag)),
          distinctUntilChanged(),
        )
        .subscribe((hasFlag) => {
          this._hasFlag = hasFlag;
          this._updateView();
        }),
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected _updateView(): void {
    const hasFlag = this._hasFlag;
    if (hasFlag === undefined) {
      return;
    }

    if (hasFlag === true) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._hasFlagTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(
            this._hasFlagTemplateRef,
          );
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(
            this._elseTemplateRef,
          );
        }
      }
    }
  }
}

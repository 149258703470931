<ng-content select="fmnts-expansion-panel-header"></ng-content>

<div class="fmnts-expansion-panel-wrapper">
  <div
    #body
    class="fmnts-expansion-panel-content"
    role="region"
    [@bodyExpansion]="_getExpandedState()"
  >
    <div class="fmnts-expansion-panel-body" data-testid="expansion-panel-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>

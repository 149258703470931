import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorVariant } from './ci-colors';

/**
 * Component that displays the formunauts typography and claim.
 */
@Component({
  selector: 'fmnts-logo-type',
  templateUrl: './logo-type.component.html',
  styleUrls: ['./logo-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoTypeComponent {
  /** Color theme of the logo that should be used. */
  @Input() color: ColorVariant = 'primary';

  /** Whether the tagline should be shown or not. */
  @Input() get tagline(): boolean {
    return this._tagline;
  }
  set tagline(newValue: BooleanInput) {
    this._tagline = coerceBooleanProperty(newValue);
  }
  private _tagline = false;

  /**
   * When set to `true`, the tagline fills the available space.
   */
  @Input() get stretchTagline(): boolean {
    return this._stretchTagline;
  }
  set stretchTagline(newValue: BooleanInput) {
    this._stretchTagline = coerceBooleanProperty(newValue);
  }
  private _stretchTagline = false;
}

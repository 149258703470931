import { NgModule } from '@angular/core';
import { RadioButtonComponent } from './radio-button.component';
import { RadioCardComponent } from './radio-card.component';
import { RadioGridItemComponent } from './radio-grid-item.component';
import { FmntsRadioGroup } from './radio-group.directive';

const directives = [
  FmntsRadioGroup,
  RadioButtonComponent,
  RadioCardComponent,
  RadioGridItemComponent,
];

@NgModule({
  imports: directives,
  exports: directives,
})
export class FmntsRadioModule {}

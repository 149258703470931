import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ApiPageAdapter,
  Page,
  PagedApiRequest,
  PagedApiResponse,
} from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiQuizAttemptAdapter } from '../adapter/quiz.adapter';
import {
  IApiQuizAttempt,
  IApiQuizAttemptCreateDto,
} from '../api-model/api-model';
import { FundraiserId } from '../model/fundraiser';
import { QuizAttempt, QuizId } from '../model/quiz';

export interface AttemptPageFilters {
  quiz?: QuizId;
  fundraiser?: FundraiserId;
}

export interface GetTrainingAttemptsFilters
  extends AttemptPageFilters,
    PagedApiRequest {}

@Injectable({
  providedIn: 'root',
})
export class TrainingcenterAttemptApi {
  public static readonly rootUrl = '/v2/trainingcenter/attempts';

  private readonly _fromApiQuizAttemptAdapter = new ApiQuizAttemptAdapter();
  private readonly _fromApiQuizAttemptPageAdapter = new ApiPageAdapter(
    this._fromApiQuizAttemptAdapter,
  );

  private readonly apiHelper = inject(ApiRequestHelper);
  private readonly configService = inject(ApiConfigService);
  private readonly http = inject(HttpClient);

  /**
   * Creates a Quiz Attempt
   *
   * @description After submitting a quiz, the result and answers
   * (as well as some meta-information like starting and end timestamps),
   * will be stored by uploading a `quizAttempt`.
   *
   * @param quizAttempt The attempt that should be stored
   */
  public createAttempt(dto: IApiQuizAttemptCreateDto): Observable<QuizAttempt> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterAttemptApi.rootUrl,
    ]);

    return this.http
      .post<IApiQuizAttempt>(url, dto)
      .pipe(map((response) => this._fromApiQuizAttemptAdapter.adapt(response)));
  }

  /**
   * Fetches a list of attempts
   *
   * @description The list of attempts can be used to show
   * data regarding quizzes like scores and whether it was passed.
   *
   * @returns a paginated list of `QuizAttempt`s
   */
  public listAttempts(
    filter?: GetTrainingAttemptsFilters,
  ): Observable<Page<QuizAttempt>> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterAttemptApi.rootUrl,
    ]);
    const params = this.apiHelper.makeParams(filter);

    return this.http
      .get<PagedApiResponse<IApiQuizAttempt>>(url, { params })
      .pipe(
        map((response) => this._fromApiQuizAttemptPageAdapter.adapt(response)),
      );
  }

  /**
   * Fetch an attempt using `QuizAttempt.id`
   *
   * @param id the id of the attempt
   */
  public getAttempt(id: string): Observable<QuizAttempt> {
    const url = this.configService.buildCockpitApiUrl([
      TrainingcenterAttemptApi.rootUrl,
      id,
    ]);

    return this.http
      .get<IApiQuizAttempt>(url)
      .pipe(map((response) => this._fromApiQuizAttemptAdapter.adapt(response)));
  }
}

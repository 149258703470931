import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';

/**
 * Variant of `BehaviorSubject` that can be used
 * for locking.
 */
export class LockSetSubject<T> extends BehaviorSubject<T[]> {
  /**
   * Observable that emits `true`,
   * if there are items in the set and `false` otherwise.
   */
  public get locked$(): Observable<boolean> {
    return this.asObservable().pipe(
      map(({ length }) => length > 0),
      distinctUntilChanged(),
    );
  }

  /**
   * Adds `source` to the set
   *
   * @param source
   */
  public add(source: T): void {
    const s = new Set(this.value);
    if (this.value.length !== s.add(source).size) {
      this.next([...s.values()]);
    }
  }

  /**
   * Removes `source` from the set
   *
   * @param source
   */
  public remove(source: T): void {
    const s = new Set(this.value);
    if (s.delete(source)) {
      this.next([...s.values()]);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import {
  BundleVersion,
  bundleShortVersionString,
} from '@formunauts/shared/bundle/domain';

/**
 * Pipe that formats a passed version
 */
@Pipe({
  name: 'fsBundleVersion',
  standalone: true,
})
export class VersionPipe implements PipeTransform {
  transform(version: BundleVersion): string {
    return bundleShortVersionString(version);
  }
}

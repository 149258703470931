import {
  catchError,
  from,
  map,
  Observable,
  ObservableInput,
  OperatorFunction,
} from 'rxjs';
import { TAction } from '../store.model';
import {
  HttpUploadActionEvents,
  HttpUploadRequestFailureActionProps,
  HttpUploadResponseActionProps,
  RequestActionCreator,
} from './http-upload.model ';

type RequestEffectResult<ResponsePayload, ErrorActionProps> = TAction<
  HttpUploadResponseActionProps<ResponsePayload> | ErrorActionProps
>;

type EffectHandler<T, ResponsePayload, ErrorActionProps> = (
  payload: T,
) => Observable<RequestEffectResult<ResponsePayload, ErrorActionProps>>;

interface HttpRequestHandlerConfig<T, ResponsePayload, ErrorPayload> {
  /** Runs the request */
  run: (value: T) => ObservableInput<ResponsePayload>;
  /** Used to handle errors */
  onError: (error: any, value: T) => ObservableInput<ErrorPayload>;
}

/**
 * Helper function to create an effect handler to dispatch a success
 * or failure action.
 *
 * @param action Http request action group
 * @param projection Projection function.
 * @returns
 * A map function that can be used in effects. Use it in combination
 * with a mapping operator.
 *
 * @example
 * const ApiActions = createHttpUploadActions(...);
 *
 * export class EntityApiEffects {
 *  postEntity$ = createEffect(() => {
 *    return this.actions$.pipe(
 *      ofType(ApiActions.post.request),
 *      exhaustMap(
 *        createHttpUploadRequestEffectHandler(
 *          ApiActions.post,
 *          {
 *            run: ({ payload }) => this.entityApi.post(payload),
 *            onError: (error, payload) => of(error)
 *          }
 *        )
 *      )
 *    );
 *  });
 * ...
 * }
 */
export function createHttpUploadRequestEffectHandler<
  T,
  RequestActionProps,
  ResponsePayload,
  ErrorActionProps extends HttpUploadRequestFailureActionProps<unknown>,
>(
  action: HttpUploadActionEvents<
    RequestActionProps,
    ResponsePayload,
    ErrorActionProps
  >,
  config: HttpRequestHandlerConfig<T, ResponsePayload, ErrorActionProps>,
): EffectHandler<T, ResponsePayload, ErrorActionProps> {
  return (payload) =>
    from(config.run(payload)).pipe(
      mapSuccess(action.success),
      catchFailure(action.failure, (e) => config.onError(e, payload)),
    );
}

function mapSuccess<ResponsePayload>(
  action: RequestActionCreator<HttpUploadResponseActionProps<ResponsePayload>>,
): OperatorFunction<
  ResponsePayload,
  TAction<HttpUploadResponseActionProps<ResponsePayload>>
> {
  return map((response) => action({ response }));
}

function catchFailure<
  T,
  ErrorActionProps extends HttpUploadRequestFailureActionProps<unknown>,
>(
  action: RequestActionCreator<ErrorActionProps>,
  onError: (error: any) => ObservableInput<ErrorActionProps>,
): OperatorFunction<T, T | TAction<ErrorActionProps>> {
  return catchError<T, Observable<TAction<ErrorActionProps>>>((e) =>
    from(onError(e)).pipe(map((props) => action(props))),
  );
}

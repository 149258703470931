import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUpload } from '@fmnts/api/shared';
import { ApiConfigService } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiAssetAdapter } from '../adapter/asset.adapter';
import { IApiAsset } from '../api-model/api-model';
import { Asset } from '../model/asset';

@Injectable({ providedIn: 'root' })
export class AssetService {
  private static readonly rootUrl = '/v2/assets';

  private _fromApiAssetAdapter = new ApiAssetAdapter();

  constructor(
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public create(asset: FileUpload): Observable<Asset>;
  public create(
    asset: FileUpload,
    options: { observe: 'response' },
  ): Observable<HttpResponse<IApiAsset>>;
  public create(
    asset: FileUpload,
    options?: { observe: 'response' },
  ): Observable<HttpResponse<IApiAsset>> | Observable<Asset> {
    const url = this.configService.buildCockpitApiUrl([AssetService.rootUrl]);
    const data = new FormData();

    data.append('file', asset.file);

    if (options) {
      return this.http.post<IApiAsset>(url, data, options);
    } else {
      return this.http
        .post<IApiAsset>(url, data)
        .pipe(map((response) => this._fromApiAssetAdapter.adapt(response)));
    }
  }

  public read(): Observable<Asset> {
    const url = this.configService.buildCockpitApiUrl([AssetService.rootUrl]);

    return this.http
      .get<IApiAsset>(url)
      .pipe(map((response) => this._fromApiAssetAdapter.adapt(response)));
  }

  public delete(): Observable<Record<string, any>> {
    const url = this.configService.buildCockpitApiUrl([AssetService.rootUrl]);

    return this.http.delete(url);
  }
}

import { createActionGroup, props } from '@ngrx/store';
import { ViewId } from '../view-option.model';
import { ViewOptionsFeatureState } from './view-options.reducer';

/**
 * Enum of the operations that can be used to update view options.
 */
export enum ViewOptionActionOperation {
  Patch = 'patch',
  Set = 'set',
}

export enum ViewOptionAction {
  PatchDisplayOptions = 'Patch Display Options',
  PatchFilters = 'Patch Filters',
  SetDisplayOptions = 'Set Display Options',
  SetFilters = 'Set Filters',
}

export const ViewOptionActions = createActionGroup({
  source: 'App View Option',
  events: {
    set: props<ViewOptionsFeatureState>(),
    [ViewOptionAction.PatchDisplayOptions]: props<{
      viewId: ViewId;
      displayOptions: Record<string, any>;
    }>(),
    [ViewOptionAction.PatchFilters]: props<{
      viewId: ViewId;
      filters: Record<string, any>;
    }>(),
    [ViewOptionAction.SetDisplayOptions]: props<{
      viewId: ViewId;
      displayOptions: Record<string, any>;
    }>(),
    [ViewOptionAction.SetFilters]: props<{
      viewId: ViewId;
      filters: Record<string, any>;
    }>(),
  },
});

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './+state/auth.effects';
import { authFeature } from './+state/auth.reducer';
import { UserApiEffects } from './+state/user-api.effects';
import { AuthApiRepository } from './auth-api-repository';
import { AuthRepository, UserRepository } from './auth-repository.model';
import { AuthService } from './auth.service';
import { AuthStore } from './auth.store';
import { UserApiRepository } from './user-api-repository';

@NgModule({
  imports: [
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([AuthEffects, UserApiEffects]),
  ],
  providers: [
    AuthService,
    AuthStore,
    { provide: AuthRepository, useClass: AuthApiRepository },
    { provide: UserRepository, useClass: UserApiRepository },
  ],
})
export class SharedAuthDataAccessModule {}

import type { Adapter } from '@fmnts/core';
import { IApiCompliment } from '../api-model/api-model';
import { Compliment } from '../model/compliment';

export class ApiComplimentAdapter implements Adapter<Compliment> {
  public adapt(apiDto: IApiCompliment): Compliment {
    return new Compliment({
      value: apiDto.value,
      label: apiDto.label,
      image: apiDto.image,
    });
  }
}

import { Injectable } from '@angular/core';
import { CustomerFeatures } from '@fmnts/api';
import { AbstractFeatureFlags } from '@fmnts/common/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class CustomerFeatureService extends AbstractFeatureFlags<CustomerFeatures> {
  /**
   * Set the given list of `customerFeatures` as enabled.
   *
   * @param customerFeatures
   * Full list of features supported by this customer
   */
  public setFeatures(customerFeatures: readonly CustomerFeatures[]): void {
    this.setEnabled(customerFeatures);
  }

  /**
   * @deprecated
   * use {@link CustomerFeatureService.on} instead.
   *
   * @param feature Feature to check
   *
   * @returns
   * `true` if the `feature` is enabled
   */
  public has(feature: CustomerFeatures): boolean {
    return this.on(feature);
  }
}

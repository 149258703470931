import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays a collection of modal actions.
 */
@Component({
  selector: 'fmnts-modal-actions,[fmnts-modal-actions]',
  template: '<ng-content></ng-content>',
  standalone: true,
  styleUrls: ['./modal-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalActionsComponent {
  @HostBinding('class.fmnts-modal-actions') private readonly componentClass =
    'fmnts-modal-actions';
}

<div class="fmnts-radio-grid-item__radio">
  <input
    #input
    class="fmnts-radio-grid-item__native-control"
    type="radio"
    [id]="inputId()"
    [checked]="checked"
    [disabled]="_disabled()"
    [attr.name]="name()"
    [attr.value]="value"
    [required]="_required()"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    (change)="_onInputInteraction($event)"
  />
</div>

<label class="fmnts-radio-grid-item__label" [for]="inputId()">
  <ng-content></ng-content>
</label>
<ng-content select="[fmntsRadioButtonSuffix]"></ng-content>

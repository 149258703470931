import { CustomerId, LocationMinimal } from '@fmnts/api/shared';

interface ICampaignType {
  id: string;
  label: string;
}

interface ICampaignGoalValueUnit {
  id: string;
  label: string;
}

interface ITeamType {
  id: string;
  label: string;
}

export interface ICampaign {
  id: string;
  customerId: CustomerId;
  image: string;
  isOnline: boolean;
  name: string;

  campaignTypes: readonly Readonly<ICampaignType>[];
  goalValueUnits: readonly Readonly<ICampaignGoalValueUnit>[];
  teamTypes: readonly Readonly<ITeamType>[];

  locations: readonly Readonly<LocationMinimal>[];
}

export class Campaign implements ICampaign {
  id: string;
  customerId: CustomerId;
  image: string;
  isOnline: boolean;
  name: string;

  campaignTypes: readonly ICampaignType[];
  goalValueUnits: readonly ICampaignGoalValueUnit[];
  teamTypes: readonly ITeamType[];

  locations: readonly LocationMinimal[];

  // TODO: When we're using it for more than just team statics, we have to
  // implement the values below
  // paymentMethods?: IPaymentMethod[];
  // salutations?: ISalutation[];
  // academicTitles?: IAcademicTitle[];
  // occupations?: IOccupation[];
  // sponsorships?: ISponsorship[];
  // correspondenceLanguages?: ICorrespondenceLanguage[];
  // directDebitIntervals?: IDirectDebitInterval[];

  constructor(dto: Readonly<ICampaign>) {
    this.id = dto.id;
    this.customerId = dto.customerId;
    this.image = dto.image;
    this.isOnline = dto.isOnline;
    this.name = dto.name;

    this.campaignTypes = dto.campaignTypes ?? [];
    this.goalValueUnits = dto.goalValueUnits ?? [];
    this.teamTypes = dto.teamTypes ?? [];

    this.locations = dto.locations ?? [];
  }
}

import {
  ApiLocationMinimalAdapter,
  CustomerId,
  LocationId,
  apiAdaptOrNull,
  apiArrayAdapter,
  apiDateAdapter,
  toApiDtoAdapter,
} from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import {
  IApiTeam,
  IApiTeamCreateDto,
  IApiTeamDetailed,
  IApiTeamUpdateDto,
} from '../api-model/api-model';
import { Team, TeamDetailed } from '../model/team';
import { ApiCampaignAdapter } from './campaign.adapter';
import {
  ApiFundraiserAdapter,
  ApiFundraiserMinimalAdapter,
} from './fundraiser.adapter';
import { ApiPetitionCampaignAdapter } from './petition-campaign.adapter';

export class ApiTeamAdapter implements Adapter<Team> {
  private readonly fundraiserAdapter = new ApiFundraiserMinimalAdapter();

  adapt(apiDto: IApiTeam): Team {
    return new Team({
      id: `${apiDto.id}`,
      image: apiDto.image_thumbnail_url,
      name: apiDto.name,
      startDate: apiDateAdapter.adapt(apiDto.start_date),
      endDate: apiDateAdapter.adapt(apiDto.end_date),
      coach: apiAdaptOrNull.adaptOrNull(apiDto.coach, this.fundraiserAdapter),
      leader: apiAdaptOrNull.adaptOrNull(apiDto.leader, this.fundraiserAdapter),
      tags: [...apiDto.tags],
    });
  }
}

export class ApiTeamDetailedAdapter implements Adapter<TeamDetailed> {
  private readonly fundraiserAdapter = new ApiFundraiserAdapter();
  private readonly campaignAdapter = new ApiCampaignAdapter();
  private readonly locationMinimalAdapter = new ApiLocationMinimalAdapter();
  private readonly petitionCampaignAdapter = new ApiPetitionCampaignAdapter();

  adapt(apiDto: IApiTeamDetailed): TeamDetailed {
    // Objects
    const coach = apiAdaptOrNull.adaptOrNull(
      apiDto.coach,
      this.fundraiserAdapter,
    );
    const leader = apiAdaptOrNull.adaptOrNull(
      apiDto.leader,
      this.fundraiserAdapter,
    );

    // Object collections
    const assistantCoaches = apiArrayAdapter.adaptItemsOrEmpty(
      apiDto.assistant_coaches,
      this.fundraiserAdapter,
    );
    const campaigns = apiArrayAdapter.adaptItemsOrEmpty(
      apiDto.campaigns,
      this.campaignAdapter,
    );
    const locations = apiArrayAdapter.adaptItemsOrEmpty(
      apiDto.locations,
      this.locationMinimalAdapter,
    );
    const petitionCampaigns = apiArrayAdapter.adaptItemsOrEmpty(
      apiDto.petition_campaigns,
      this.petitionCampaignAdapter,
    );

    return new TeamDetailed({
      id: `${apiDto.id}`,
      goalValue: apiDto.goal_value,
      goalValueUnit: apiDto.goal_value_unit,
      image: apiDto.image_thumbnail_url,
      name: apiDto.name,
      campaignType: apiDto.campaign_type,
      teamType: apiDto.team_type,
      prolongWorkshiftSetup: apiDto.prolong_workshift_setup,
      startDate: apiDateAdapter.adapt(apiDto.start_date),
      endDate: apiDateAdapter.adapt(apiDto.end_date),

      customerId: `${apiDto.customer.id}`,
      // Primitive collections
      tags: [...apiDto.tags],
      // Objects
      coach,
      leader,
      // Object collections
      assistantCoaches,
      campaigns,
      locations,
      petitionCampaigns,
    });
  }
}

export interface ITeamDto {
  id?: string;
  name: string | null;
  teamType: string | null;
  campaignType: string | null;
  prolongWorkshiftSetup: boolean;
  startDate: Date;
  endDate: Date;
  /** Integer as number or string */
  goalValue: number | string;
  goalValueUnit: string;
  // Collections
  tags: string[];
  // References
  customerId: CustomerId;
  leaderId: string | null;
  coachId: string | null;
  // Reference collections
  campaignIds: string[];
  petitionCampaignIds: string[];
  assistentCoachIds: string[];
  locationIds: LocationId[];
}

export class TeamToApiAdapter {
  adaptModelToDto(team: TeamDetailed): ITeamDto {
    return {
      id: team.id,
      name: team.name,
      teamType: team.teamType,
      campaignType: team.campaignType,
      prolongWorkshiftSetup: team.prolongWorkshiftSetup,
      startDate: team.startDate,
      endDate: team.endDate,
      goalValue: team.goalValue,
      goalValueUnit: team.goalValueUnit,
      // Collections
      tags: [...team.tags],
      // References
      customerId: team.customerId,
      leaderId: toApiDtoAdapter.adaptMaybeReference(team.leader),
      coachId: toApiDtoAdapter.adaptMaybeReference(team.coach),
      // Reference collections
      campaignIds: toApiDtoAdapter.adaptReferences(team.campaigns),
      petitionCampaignIds: toApiDtoAdapter.adaptReferences(
        team.petitionCampaigns,
      ),
      assistentCoachIds: toApiDtoAdapter.adaptReferences(team.assistantCoaches),
      locationIds: toApiDtoAdapter.adaptReferences(team.locations),
    };
  }

  adaptForCreate(dto: ITeamDto): IApiTeamCreateDto {
    return this._toApiDto(dto);
  }

  adaptForUpdate(dto: ITeamDto): IApiTeamUpdateDto {
    return this._toApiDto(dto);
  }

  private _toApiDto(dto: ITeamDto): IApiTeamCreateDto {
    return {
      name: dto.name,
      team_type: dto.teamType,
      campaign_type: dto.campaignType,
      prolong_workshift_setup: dto.prolongWorkshiftSetup,
      start_date: apiDateAdapter.adaptToApi(dto.startDate),
      end_date: apiDateAdapter.adaptToApi(dto.endDate),
      goal_value: dto.goalValue,
      goal_value_unit: dto.goalValueUnit,
      // Collections
      tags: dto.tags,
      // References
      customer: dto.customerId,
      coach: dto.coachId,
      leader: dto.leaderId,
      // Reference Collections
      campaigns: dto.campaignIds,
      petition_campaigns: dto.petitionCampaignIds,
      assistant_coaches: dto.assistentCoachIds,
      locations: dto.locationIds,
    };
  }
}

import {
  AnimationTriggerMetadata,
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { timingByThemeTokens } from '@fmnts/components/core';

/**
 * Animations used by the toast.
 */
export const ToastAnimations: {
  readonly toastState: AnimationTriggerMetadata;
} = {
  /** Animation that shows and hides a toast. */
  toastState: trigger('state', [
    state(
      'void, hidden',
      style({
        transform: 'scale(0.8)',
        opacity: 0,
      }),
    ),
    state(
      'visible',
      style({
        transform: 'scale(1)',
        opacity: 1,
      }),
    ),
    transition(
      '* => visible',
      animate(
        timingByThemeTokens({
          duration: 'duration.short-3',
          curve: 'ease.standard-decelerate',
        }),
      ),
    ),
    transition(
      '* => void, * => hidden',
      animate(
        timingByThemeTokens({
          duration: 'duration.short-2',
          curve: 'ease.standard-accelerate',
        }),
        style({
          opacity: 0,
        }),
      ),
    ),
  ]),
};

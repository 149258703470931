import { InjectionToken } from '@angular/core';

export interface InputValueAccessor<T = any> {
  value: T;
}

/**
 * This token is used to inject the object whose value should be set into `FmntsInput`. If none is
 * provided, the native `HTMLInputElement` is used.
 */
export const FMNTS_INPUT_VALUE_ACCESSOR =
  new InjectionToken<InputValueAccessor>(
    '@fmnts.components.input.value-accessor',
  );

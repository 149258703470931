import { Campaign, ICampaign } from './campaign';
import { Fundraiser, IFundraiser } from './fundraiser';
import {
  FundraiserStatistics,
  IFundraiserStatistics,
} from './fundraiser-statistics';
import { IPetitionCampaign, PetitionCampaign } from './petition-campaign';
import { IStatisticsItem, StatisticsItem } from './statistics-item';

export interface ITeamStatistics {
  id: string;
  campaigns: readonly Readonly<ICampaign>[] | null;
  petitionCampaigns: readonly Readonly<IPetitionCampaign>[] | null;
  endDate: Date;
  image: string;
  leader: Readonly<IFundraiser> | null;
  name: string;
  startDate: Date;
  tags: readonly string[];

  items: readonly IStatisticsItem[];
  members?: readonly IFundraiserStatistics[];
  membersShown?: boolean;
}

export class TeamStatistics implements ITeamStatistics {
  id: string;
  campaigns: readonly Readonly<Campaign>[] | null;
  petitionCampaigns: readonly Readonly<PetitionCampaign>[] | null;
  endDate: Date;
  image: string;
  leader: Readonly<Fundraiser> | null;
  name: string;
  startDate: Date;
  tags: readonly string[];

  items: readonly Readonly<StatisticsItem>[];
  members?: readonly Readonly<FundraiserStatistics>[];
  // used in the display of statistics
  membersShown = false;

  constructor(dto: Readonly<ITeamStatistics>) {
    this.id = dto.id;
    this.name = dto.name;
    this.image = dto.image;
    this.endDate = new Date(dto.endDate);
    this.startDate = new Date(dto.startDate);

    // Objects
    this.leader = dto.leader ? new Fundraiser(dto.leader) : null;

    // Collections
    this.tags = dto.tags;
    this.items = dto.items.map((item) => new StatisticsItem(item));
    this.campaigns = dto.campaigns
      ? dto.campaigns.map((c) => new Campaign(c))
      : null;

    this.petitionCampaigns = dto.petitionCampaigns
      ? dto.petitionCampaigns.map((c) => new PetitionCampaign(c))
      : null;
  }
}

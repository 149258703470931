import moment from 'moment';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

interface FormatIsoOptions {
  representation: 'complete' | 'date' | 'time';
}

/**
 * Parses a given string `value` in ISO 8601 format to a date.
 * Time is parsed as a local time if no offset is specified.
 *
 * @param value String in ISO 8601 format.
 *
 * @returns
 * Date parsed from string. If it is not valid, an invalid date is returned.
 */
export function parseISO(value: string): Date {
  // The time is parsed as a local time if no offset is specified
  const dateAsMoment =
    value.length === ISO_DATE_FORMAT.length
      ? moment.utc(value, moment.ISO_8601, true)
      : moment(value, moment.ISO_8601, true);

  return dateAsMoment.isValid() ? dateAsMoment.toDate() : new Date(NaN);
}

/**
 * Formats the given `date` to ISO 8601 format in **UTC**.
 *
 * @param date Date to format
 * @param opts Options for formatting
 *
 * @returns
 * Date formatted as ISO 8601 string.
 */
export function formatISO(
  date: Date,
  opts: Partial<FormatIsoOptions> = {},
): string {
  const { representation }: FormatIsoOptions = {
    representation: 'complete',
    ...opts,
  };

  const isoStr = moment(date).toISOString(false);

  switch (representation) {
    case 'complete':
      return isoStr;
    case 'date':
      return isoStr.substring(0, ISO_DATE_FORMAT.length);
    case 'time':
      return isoStr.substring(ISO_DATE_FORMAT.length + 1);
  }
}

<input
  #fileInput
  type="file"
  [style.display]="'none'"
  [accept]="accepts | join: ','"
  (change)="updateFile(fileInput)"
/>

<div class="fmnts-image-input__buttons">
  <!-- action buttons from template-->
  <ng-content select="[needsEmptyImage]" *ngIf="!this.uploaded"></ng-content>
  <ng-content select="[needsImage]" *ngIf="this.uploaded"></ng-content>
  <ng-content></ng-content>
</div>

import { Dialog } from '@angular/cdk/dialog';
import {
  Overlay,
  OverlayContainer,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import {
  ANIMATION_MODULE_TYPE,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
  Type,
} from '@angular/core';
import { Observable, Subject, defer, startWith } from 'rxjs';
import { FMNTS_MODAL_DATA, ModalConfig } from './modal-config';
import { ModalRef } from './modal-ref';
import {
  ModalContainerBaseComponent,
  ModalContainerComponent,
} from './modal.component';

/**
 * Injection token that can be used to specify default modal options.
 */
export const FMNTS_MODAL_DEFAULT_OPTIONS = new InjectionToken<ModalConfig>(
  '@fmnts.components.modal.default-options',
);

/** Injection token that determines the scroll handling while the modal is open. */
export const FMNTS_MODAL_SCROLL_STRATEGY = new InjectionToken<
  () => ScrollStrategy
>('@fmnts.components.modal.scroll-strategy');

export const FMNTS_MODAL_SCROLL_STRATEGY_PROVIDER = {
  provide: FMNTS_MODAL_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: FMNTS_MODAL_SCROLL_STRATEGY_PROVIDER_FACTORY,
};

export function FMNTS_MODAL_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay,
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

// Counter for unique modal ids.
let uniqueId = 0;

/**
 * Base class for modal services. The base modal service allows
 * for arbitrary modal refs and modal container components.
 */
@Injectable()
export class ModalBase<TComponent extends ModalContainerBaseComponent>
  implements OnDestroy
{
  private readonly _openModalsAtThisLevel: ModalRef<any>[] = [];
  private readonly _afterAllClosedAtThisLevel = new Subject<void>();
  private readonly _afterOpenedAtThisLevel = new Subject<ModalRef<any>>();
  protected _idPrefix = 'modal-';
  private _modal: Dialog;
  protected modalConfigClass = ModalConfig;
  /**
   * Keeps track of the currently-opened modal.
   */
  get openModals(): ModalRef<any>[] {
    return this._parentModal
      ? this._parentModal.openModals
      : this._openModalsAtThisLevel;
  }

  /** Stream that emits when a dialog has been opened. */
  get afterOpened(): Subject<ModalRef<any>> {
    return this._parentModal
      ? this._parentModal.afterOpened
      : this._afterOpenedAtThisLevel;
  }

  /**
   * Stream that emits when all open modal have finished closing.
   * Will emit on subscribe if there are no open modals to begin with.
   */
  readonly afterAllClosed: Observable<any> = defer(() =>
    this.openModals.length
      ? this._getAfterAllClosed()
      : this._getAfterAllClosed().pipe(startWith(undefined)),
  ) as Observable<any>;

  private _getAfterAllClosed(): Subject<void> {
    const parent = this._parentModal;
    return parent
      ? parent._getAfterAllClosed()
      : this._afterAllClosedAtThisLevel;
  }

  constructor(
    /** Service for creating overlays */
    private _overlay: Overlay,
    injector: Injector,
    @Optional()
    @Inject(FMNTS_MODAL_DEFAULT_OPTIONS)
    private _defaultOptions: ModalConfig | undefined,
    /** Potential parent modal service */
    @Optional()
    @SkipSelf()
    private _parentModal: ModalBase<TComponent>,
    _overlayContainer: OverlayContainer,
    @Inject(FMNTS_MODAL_SCROLL_STRATEGY)
    private _scrollStrategy: () => ScrollStrategy,
    private _modalRefConstructor: Type<ModalRef<any>>,
    private _modalContainerType: Type<ModalContainerComponent>,
    private _modalDataToken: InjectionToken<any>,

    @Optional()
    @Inject(ANIMATION_MODULE_TYPE)
    _animationMode?: 'NoopAnimations' | 'BrowserAnimations',
  ) {
    this._modal = injector.get(Dialog);
  }

  /**
   * Opens a modal containing the given component.
   * @param component Type of the component to load into the modal.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened modal.
   */
  open<TInstance, TData = any, TResult = any>(
    component: ComponentType<TInstance>,
    config?: ModalConfig<TData>,
  ): ModalRef<TInstance, TResult>;

  /**
   * Opens a modal containing the given template.
   * @param template TemplateRef to instantiate as the modal content.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened modal.
   */
  open<TContext, TData = any, TResult = any>(
    template: TemplateRef<TContext>,
    config?: ModalConfig<TData>,
  ): ModalRef<TContext, TResult>;

  open<TInstance, TData = any, TResult = any>(
    componentOrTemplateRef: ComponentType<TInstance> | TemplateRef<TInstance>,
    config?: ModalConfig<TData>,
  ): ModalRef<TInstance, TResult> {
    config = { ...(this._defaultOptions || new ModalConfig()), ...config };
    config.id = config.id || `${this._idPrefix}${uniqueId++}`;
    config.scrollStrategy = config.scrollStrategy || this._scrollStrategy();

    // Will be initialized after call to `open`
    let modalRef!: ModalRef<TInstance, TResult>;
    const cdkRef = this._modal.open<TResult, TData, TInstance>(
      componentOrTemplateRef,
      {
        ...config,
        positionStrategy: this._overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
        // Disable closing since we need to sync it up to the animation ourselves.
        disableClose: true,
        // Disable closing on destroy, because this service cleans up its open modals as well.
        // We want to do the cleanup here, rather than the CDK service, because the CDK destroys
        // the modals immediately whereas we want it to wait for the animations to finish.
        closeOnDestroy: false,
        // Disable closing on detachments so that we can sync up the animation.
        // The Material dialog ref handles this manually.
        closeOnOverlayDetachments: false,
        container: {
          type: this._modalContainerType,
          providers: () => [
            // Provide our config as the CDK config as well since it has the same interface as the
            // CDK one, but it contains the actual values passed in by the user for things like
            // `disableClose` which we disable for the CDK dialog since we handle it ourselves.
            { provide: this.modalConfigClass, useValue: config },
            { provide: ModalConfig, useValue: config },
          ],
        },
        templateContext: () => ({ modalRef }),
        providers: (ref, cdkConfig, modalContainer) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          modalRef = new this._modalRefConstructor(ref, config, modalContainer);
          modalRef.updatePosition(config?.position);
          return [
            { provide: this._modalContainerType, useValue: modalContainer },
            { provide: this._modalDataToken, useValue: cdkConfig.data },
            { provide: this._modalRefConstructor, useValue: modalRef },
          ];
        },
      },
    );

    modalRef.componentInstance = cdkRef.componentInstance;

    this.openModals.push(modalRef);
    this.afterOpened.next(modalRef);

    modalRef.afterClosed().subscribe(() => {
      const index = this.openModals.indexOf(modalRef);

      if (index > -1) {
        this.openModals.splice(index, 1);

        if (!this.openModals.length) {
          this._getAfterAllClosed().next();
        }
      }
    });

    return modalRef;
  }

  closeAll(): void {
    this._closeModals(this.openModals);
  }

  /**
   * Finds an open modal by its id.
   * @param id ID to use when looking up the modal.
   */
  getModalById(id: string): ModalRef<any> | undefined {
    return this.openModals.find((modal) => modal.id === id);
  }

  ngOnDestroy(): void {
    // Only close modals at this level on destroy
    // since the parent service may still be active.
    this._closeModals(this._openModalsAtThisLevel);
    this._afterAllClosedAtThisLevel.complete();
    this._afterOpenedAtThisLevel.complete();
  }

  private _closeModals(modals: ModalRef<any>[]) {
    let i = modals.length;

    while (i--) {
      modals[i].close();
    }
  }
}

/**
 * The Modal service can be used to open a modal.
 */
@Injectable()
export class Modal extends ModalBase<ModalContainerComponent> {
  constructor(
    overlay: Overlay,
    injector: Injector,
    @Optional()
    @Inject(FMNTS_MODAL_DEFAULT_OPTIONS)
    defaultOptions: ModalConfig,
    @Inject(FMNTS_MODAL_SCROLL_STRATEGY) scrollStrategy: () => ScrollStrategy,
    @Optional() @SkipSelf() parentModal: Modal,

    overlayContainer: OverlayContainer,
    @Optional()
    @Inject(ANIMATION_MODULE_TYPE)
    animationMode?: 'NoopAnimations' | 'BrowserAnimations',
  ) {
    super(
      overlay,
      injector,
      defaultOptions,
      parentModal,
      overlayContainer,
      scrollStrategy,
      ModalRef,
      ModalContainerComponent,
      FMNTS_MODAL_DATA,
      animationMode,
    );

    this._idPrefix = 'fmnts-modal-';
  }
}

import { Injectable } from '@angular/core';
import { JsonSerializer } from '@fmnts/core';
import { State } from './state';
import { StorageAccessor } from './storage-accessor';

const DEFAULT_STATE: State = {
  sessionValues: {},
  globalValues: {},
};

const STATE_STORAGE_KEY = 'formunauts-state';

@Injectable({ providedIn: 'root' })
export class Store extends StorageAccessor<State> {
  constructor() {
    super(localStorage, STATE_STORAGE_KEY, DEFAULT_STATE, new JsonSerializer());
  }

  //
  public clear(includeGlobalValues = false): void {
    if (includeGlobalValues) {
      this.reset();
    } else {
      this.reset({
        globalValues: this.value.globalValues,
      });
    }
  }
}

import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TitleService } from '@fmnts/common/browser';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
  /** Emits when initial navigation is completed. */
  private readonly initialNavigationEnd$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    first(),
  );

  constructor(
    private router: Router,
    private titleService: TitleService,
    @Inject(DOCUMENT) private _doc: Document,
  ) {
    // Remove loader once the first navigation ended, which means that
    // any page component is visible
    this.initialNavigationEnd$.subscribe(() => {
      this._removeInitialLoader();
    });
  }

  ngAfterViewInit() {
    this.titleService.setTitle();
  }

  private _removeInitialLoader() {
    this._doc.getElementById('initial-page-load-spinner')?.remove();
  }
}

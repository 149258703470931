import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { IconRatingComponent } from './icon-rating.component';

@NgModule({
  declarations: [IconRatingComponent],
  exports: [IconRatingComponent],
  imports: [CommonModule, FmntsIconsModule],
})
export class FmntsRatingModule {}

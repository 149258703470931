import { CdkScrollable } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays the modal content.
 */
@Component({
  selector: 'fmnts-modal-content, [fmnts-modal-content]',
  template: '<ng-content></ng-content>',
  standalone: true,
  hostDirectives: [CdkScrollable],
  styleUrls: ['./modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalContentComponent {
  @HostBinding('class.fmnts-modal-content')
  private readonly componentClass = 'fmnts-modal-content';
}

<div class="error-page">
  <fmnts-empty-state>
    <fmnts-empty-state-visual>
      <fa-icon [icon]="_iconUnauthorized" size="lg"></fa-icon>
    </fmnts-empty-state-visual>
    <fmnts-empty-state-title fmnts-text="title.lg">
      {{ 'unauthorized_title' | translate }}
    </fmnts-empty-state-title>
    <fmnts-empty-state-description>
      {{ 'unauthorized_description' | translate }}
    </fmnts-empty-state-description>
    <fmnts-button-group>
      <a fmnts-button color="primary" fill="solid" href="/">{{
        'unauthorized_link' | translate
      }}</a>
    </fmnts-button-group>
  </fmnts-empty-state>
</div>

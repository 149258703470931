import { Directive, HostBinding, Input } from '@angular/core';
import { classnames } from '@fmnts/common';

let nextUniqueId = 0;

/**
 * Hints to be shown within a `fmnts-form-field`.
 */
@Directive({
  selector: 'fmnts-hint',
})
export class FmntsHintDirective {
  @HostBinding('class.fmnts-form-hint')
  protected readonly componentClass = 'fmnts-form-hint';

  /** Whether to align the hint label at the start or end of the line. */
  @Input() alignInline: 'start' | 'end' = 'start';
  /** Whether to align the hint label at the start or end of the line. */
  @Input() alignBlock: 'start' | 'end' = 'start';

  /** Unique ID for the hint. Used for the aria-describedby on the form field control. */
  @HostBinding('id')
  @Input()
  id = `fmnts-hint-${nextUniqueId++}`;

  @HostBinding('class')
  protected hostClasses(): string {
    return classnames([
      this.alignInline === 'end' && 'fmnts-form-hint--align-inline-end',
      this.alignBlock === 'end' && 'fmnts-form-hint--align-block-end',
    ]);
  }
}

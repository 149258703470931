import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiConfigService } from '@fmnts/api/util';
import { Observable, map } from 'rxjs';
import {
  ApiCreateSupportRequestAdapter,
  ApiUpdateSupportRequestAdapter,
  SupportRequestToApiAdapter,
} from '../adapter/support-request.adapter';
import {
  IApiSupportRequestCreateResponse,
  IApiSupportRequestUpdateResponse,
} from '../api-model/api-model';
import {
  SupportRequest,
  SupportRequestCreateDto,
  SupportRequestUpdateDto,
} from '../model/support-request';

@Injectable({
  providedIn: 'root',
})
export class SupportRequestApi {
  public static readonly rootUrl = '/v2/support/';

  private readonly createApiToSupportRequestAdapter =
    new ApiCreateSupportRequestAdapter();
  private readonly toSupportRequestAdapter = new SupportRequestToApiAdapter();
  private readonly updateApiToSupportRequestAdapter =
    new ApiUpdateSupportRequestAdapter();

  private readonly http = inject(HttpClient);
  private readonly configService = inject(ApiConfigService);

  public create(
    createDto: SupportRequestCreateDto,
  ): Observable<SupportRequest> {
    const url = this.configService.buildCockpitApiUrl([
      SupportRequestApi.rootUrl,
    ]);
    const data = this.toSupportRequestAdapter.adaptForCreate(createDto);

    return this.http
      .post<IApiSupportRequestCreateResponse>(url, data)
      .pipe(
        map((response) =>
          this.createApiToSupportRequestAdapter.adapt({ createDto, response }),
        ),
      );
  }

  public update(
    entity: SupportRequest,
    dto: SupportRequestUpdateDto,
  ): Observable<SupportRequest> {
    const url = this.configService.buildCockpitApiUrl([
      SupportRequestApi.rootUrl,
      entity.id,
    ]);
    const data = this.toSupportRequestAdapter.adaptForUpdate(dto);

    return this.http
      .patch<IApiSupportRequestUpdateResponse>(url, data)
      .pipe(
        map((response) =>
          this.updateApiToSupportRequestAdapter.adapt({ entity, response }),
        ),
      );
  }
}

import { LogLevel } from './log.model';

const NUMERIC_LEVELS: Record<LogLevel, number> = {
  [LogLevel.Trace]: 0,
  [LogLevel.Debug]: 1,
  [LogLevel.Info]: 2,
  [LogLevel.Warn]: 3,
  [LogLevel.Error]: 4,
};

export function gteLogLevel(level: LogLevel, other: LogLevel): boolean {
  return _getNumericLogLevel(level) >= _getNumericLogLevel(other);
}
function _getNumericLogLevel(l: LogLevel): number {
  return NUMERIC_LEVELS[l];
}

<div class="fmnts-sort-header__container">
  <span class="fmnts-sort-header__content">
    <ng-content></ng-content>
  </span>

  <fa-icon
    *ngIf="_isSorted() || !_isDisabled()"
    [icon]="iconName"
    class="fmnts-sort-header__icon"
  ></fa-icon>
</div>

import { IPage as ICorePage, SortDirection } from '@fmnts/core';

type BasePage = Omit<ICorePage<unknown>, 'results'>;

interface IPage<T> extends BasePage {
  next: string;
  previous: string;
  results: readonly T[];
}

export class Page<T> implements IPage<T> {
  count: number;
  currentPage: number;
  pageSize: number;
  next: string;
  previous: string;
  results: readonly T[];

  sortKey: string;
  sortDirection: SortDirection;

  constructor(json: Readonly<IPage<T>>) {
    this.count = json.count;
    this.currentPage = json.currentPage;
    this.next = json.next;
    this.pageSize = json.pageSize;
    this.previous = json.previous;
    this.results = json.results ?? [];
  }
}

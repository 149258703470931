import { apiArrayAdapter } from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import {
  IApiTrainingcenterAssignment,
  IApiTrainingcenterMember,
  IApiTrainingcenterTest,
  IApiTrainingcenterTestMinimal,
  IApiTranincenterGroup,
  IApiTranincenterGroupMinimal,
} from '../api-model/api-model';
import {
  TrainingcenterAssignment,
  TrainingcenterGroup,
  TrainingcenterGroupMinimal,
  TrainingcenterMember,
  TrainingcenterTest,
  TrainingcenterTestMinimal,
} from '../model/trainingcenter';

export class ApiTrainingcenterAssignmentAdapter
  implements Adapter<TrainingcenterAssignment>
{
  adapt(dto: IApiTrainingcenterAssignment): TrainingcenterAssignment {
    return new TrainingcenterAssignment({
      score: dto.score,
      status: dto.status
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase(),
      title: dto.title,
      uniqueName: dto.unique_name,
    });
  }
}

export class ApiTraningcenterMemberAdapter
  implements Adapter<TrainingcenterMember>
{
  private readonly assignmentAdapter = new ApiTrainingcenterAssignmentAdapter();
  adapt(apiDto: IApiTrainingcenterMember): TrainingcenterMember {
    const hasDefaultImage =
      apiDto.image_thumbnail_url &&
      apiDto.image_thumbnail_url.match(/default-avatar\.png$/);

    return new TrainingcenterMember({
      id: `${apiDto.id}`,
      averageScore: apiDto.score_average,
      completedAssignments: apiDto.completed_count,
      name: apiDto.name,
      image: hasDefaultImage ? undefined : apiDto.image_thumbnail_url,

      assignments: apiArrayAdapter.adaptItemsOrEmpty(
        apiDto.assignments,
        this.assignmentAdapter,
      ),
    });
  }
}

export class ApiTrainingcenterTestMinimalAdapter
  implements Adapter<TrainingcenterTestMinimal>
{
  adapt(apiDto: IApiTrainingcenterTestMinimal): TrainingcenterTestMinimal {
    return new TrainingcenterTestMinimal({
      title: apiDto.title,
    });
  }
}

export class ApiTrainingcenterTestAdapter
  implements Adapter<TrainingcenterTest>
{
  adapt(t: IApiTrainingcenterTest): TrainingcenterTest {
    return new TrainingcenterTest({
      title: t.title,
      uniqueName: t.unique_name,
    });
  }
}

export class ApiTraningcenterGroupMinimalAdapter
  implements Adapter<TrainingcenterGroupMinimal>
{
  private readonly testAdapter = new ApiTrainingcenterTestMinimalAdapter();
  adapt(apiDto: IApiTranincenterGroupMinimal): TrainingcenterGroupMinimal {
    return new TrainingcenterGroupMinimal({
      membersCount: apiDto.members_count,
      name: apiDto.name,
      tests: apiArrayAdapter.adaptItemsOrEmpty(apiDto.tests, this.testAdapter),
    });
  }
}

export class ApiTraningcenterGroupAdapter
  implements Adapter<TrainingcenterGroup>
{
  private readonly memberAdapter = new ApiTraningcenterMemberAdapter();
  private readonly testAdapter = new ApiTrainingcenterTestAdapter();

  adapt(json: IApiTranincenterGroup): TrainingcenterGroup {
    return new TrainingcenterGroup({
      name: json.name,
      membersCount: json.members_count,
      members: apiArrayAdapter.adaptItemsOrEmpty(
        json.members,
        this.memberAdapter,
      ),
      tests: apiArrayAdapter.adaptItemsOrEmpty(json.tests, this.testAdapter),
    });
  }
}

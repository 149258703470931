import { EqualFn } from '@fmnts/core';
import { TimeSpan } from './chronos.type';
import { equalDate } from './compare-date';

/**
 * Higher order function for creating a function
 * that compares two timespans for their equality
 * with a given granularity.
 *
 * @param granularity Smallest unit of time to consider for equality.
 *
 * @returns
 * Value equality function that returns `true` if the two timespans
 * are equal for the given granularity.
 */
export function equalTimespan(
  granularity: moment.unitOfTime.StartOf,
): EqualFn<TimeSpan> {
  const _equals = equalDate(granularity);
  return (a, b) =>
    _equals(a[0].toDate(), b[0].toDate()) &&
    _equals(a[1].toDate(), b[1].toDate());
}

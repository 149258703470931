import { DialogConfig, DialogRole } from '@angular/cdk/dialog';
import { InjectionToken } from '@angular/core';
import { ThemeDurationToken } from '@fmnts/components/core';

/** Options for where to set focus to automatically on modal open */
export type AutoFocusTarget = 'modal' | 'first-tabbable' | 'first-heading';

/** Valid ARIA roles for a modal element. */
export type ModalRole = 'modal' | 'alertmodal';

/** Possible overrides for a modal's position. */
export interface ModalPosition {
  /** Override for the modal's top position. */
  top?: string;

  /** Override for the modal's bottom position. */
  bottom?: string;

  /** Override for the modal's left position. */
  left?: string;

  /** Override for the modal's right position. */
  right?: string;
}

/**
 * Injection token that can be used to access the data that was passed in to a modal.
 */
export const FMNTS_MODAL_DATA = new InjectionToken<unknown>(
  '@fmnts.components.modal.data',
);

/**
 * Configuration used when opening a modal.
 */
export class ModalConfig<TData = any> extends DialogConfig<TData> {
  /** The ARIA role of the modal element. */
  override role?: DialogRole = 'dialog';

  /** Whether the modal has a backdrop. */
  override hasBackdrop?: boolean = true;

  /** Whether the user can use escape or clicking outside to close the modal. */
  override disableClose?: boolean = false;

  /** Max-width of the modal. If a number is provided, assumes pixel units. Defaults to 80vw. */
  override maxWidth?: number | string = '80vw';

  /** Position overrides. */
  position?: ModalPosition;

  /** Data being injected into the child component. */
  override data?: TData | null = null;

  /** Whether this is a modal dialog. Used to set the `aria-modal` attribute. */
  override ariaModal?: boolean = true;

  /**
   * Where the modal should focus on open.
   */
  override autoFocus?: AutoFocusTarget | string | boolean = 'first-tabbable';

  /**
   * Whether the modal should restore focus to the
   * previously-focused element, after it's closed.
   */
  override restoreFocus?: boolean = true;

  /** Whether to wait for the opening animation to finish before trapping focus. */
  delayFocusTrap?: boolean = true;

  /**
   * Whether the modal should close when the user goes backwards/forwards in history.
   * Note that this usually doesn't include clicking on links (unless the user is using
   * the `HashLocationStrategy`).
   */
  override closeOnNavigation?: boolean = true;

  /**
   * Duration token to use for the enter animation.
   */
  enterAnimationDuration?: ThemeDurationToken;

  /**
   * Duration token to use for the exit animation.
   */
  exitAnimationDuration?: ThemeDurationToken;
}

import type { Adapter } from '@fmnts/core';
import { IApiCampaignStatistics } from '../api-model/api-model';
import { CampaignStatistics } from '../model/campaign-statistics';
import { ApiStatisticsItemAdapter } from './statistics-item.adapter';

export class ApiCampaignStatisticsAdapter
  implements Adapter<CampaignStatistics>
{
  private readonly statisticsItemAdapter = new ApiStatisticsItemAdapter();

  public adapt(apiDto: IApiCampaignStatistics): CampaignStatistics {
    const items = apiDto.statistics.map((item) =>
      this.statisticsItemAdapter.adapt(item),
    );

    return new CampaignStatistics({
      id: `${apiDto.id}`,
      image: apiDto.image_thumbnail_url,
      isOnline: apiDto.is_online,
      logo: apiDto.logo_upload,
      name: apiDto.name,
      items,
    });
  }
}

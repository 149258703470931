import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpParamsOptions,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiRequestHelper } from '@fmnts/api/util';
import { isGeoCoordinates, toLatLon } from '@fmnts/common/geolocation';
import { isUndefined } from '@fmnts/core';
import { String, pipe } from 'effect';
import { Observable, map } from 'rxjs';
import { LoqateApiClientConfigService } from '../loqate-api-config.service';
import {
  ErrorResponse,
  LoqateApiError,
  LoqateUnknownApiError,
  isErrorResponse,
} from '../shared/loqate-shared.model';
import {
  FindResponse,
  IApiLoqateInteractiveFindQuery,
  IApiLoqateInteractiveRetrieveQuery,
  InteractiveFindQuery,
  InteractiveRetrieveQuery,
  RetrieveResponse,
} from './loqate-address-capture.api-model';

/**
 * Adapts the retrieve response by trying to extract the
 * building number from the building name in case that no
 * building number is present.
 *
 * @param response Response from retrieve endpoint
 *
 * @returns
 * Transformed response
 *
 * @example
 * // With extracted number from name
 * withNumberFromName({...,
 *  BuildingNumber: '',
 *  BuildingName: 'Rodney Court 6-8'
 * }) // -> {BuildingNumber: '6-8', BuildingName: 'Rodney Court' }
 *
 * @example
 * // No transformation with number
 * withNumberFromName({...,
 *  BuildingNumber: '1',
 *  BuildingName: 'The Flat'
 * }) // -> {BuildingNumber: '1', BuildingName: 'The Flat' }
 */
function withNumberFromName(response: RetrieveResponse): RetrieveResponse {
  if (response.BuildingNumber !== '' || !response.BuildingName) {
    return response;
  }

  // Split into blocks by space and find a block with any numeric
  // Extract that block as BuildingNumber and rejoin remaining
  // blocks as BuildingName
  const nameParts = response.BuildingName.split(' ');
  const digitRegex = /\d/;
  const numberIndex = nameParts.findIndex((p) => digitRegex.test(p));
  if (numberIndex === -1) {
    return response;
  }

  const BuildingNumber = nameParts.splice(numberIndex, 1).join('');
  return {
    ...response,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BuildingNumber,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BuildingName: nameParts.join(' '),
  };
}

/**
 * Adapts the retrieve response by adding the Company Name
 * to the Building name, in cases where the Building name
 * is missing in the response.
 *
 * @param response Response from retrieve endpoint
 *
 * @returns
 * Transformed response
 *
 * @example
 * // With added Company name
 * withBuildingNameOrCompany({...,
 *  Company: 'Example Company Ltd'
 *  BuildingName: ''
 * }) // -> {BuildingName: 'Example Company Ltd' }
 *
 * @example
 * // No transformation with Building name
 * withBuildingNameOrCompany({...,
 *  Company: 'Example Company Ltd',
 *  BuildingName: 'The Flat'
 * }) // -> {BuildingName: 'The Flat' }
 */
function withBuildingNameOrCompany(
  response: RetrieveResponse,
): RetrieveResponse {
  if (
    response.Company &&
    String.isNonEmpty(response.Company) &&
    String.isEmpty(response.BuildingName)
  ) {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      ...response,
      BuildingName: response.Company,
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  return response;
}

/**
 * Service for interacting with the loqate address capture API.
 */
@Injectable({
  providedIn: 'root',
})
export class LoqateAddressCaptureApi {
  private readonly http = inject(HttpClient);
  private readonly apiConfig = inject(LoqateApiClientConfigService);
  private readonly apiHelper = inject(ApiRequestHelper);

  private static readonly endpointPrefix =
    'https://api.addressy.com/Capture/Interactive';

  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'ngsw-bypass': 'true',
  });

  /**
   * Find Request
   *
   * @param params URL parameters, used to search for an address
   * @returns Array of matches, containing addresses
   */
  public find(query: InteractiveFindQuery): Observable<FindResponse[]> {
    const url = this.endpointUrl(['Find', 'v1.10', 'json3.ws']);
    const params = this.parseParams(this._mapToApiInteractiveFindQuery(query));

    return this.http
      .post<{ Items: FindResponse[] }>(url, params, {
        headers: this.httpHeaders,
      })
      .pipe(
        map((response) => {
          const items = response.Items;

          if (isErrorResponse(items[0])) {
            throw new LoqateApiError(items[0]);
          }

          return items;
        }),
      );
  }

  /**
   * Retrieve Request
   * This returns the address details based on the id
   *
   * NOTE: uses custom transformations for the response,
   * @see {@link withNumberFromName}, {@link withBuildingNameOrCompany}
   *
   * @param params URL parameters, used to retrieve an address
   * @returns Object containing full address
   */
  public retrieve(
    query: InteractiveRetrieveQuery,
  ): Observable<RetrieveResponse> {
    const url = this.endpointUrl(['Retrieve', 'v1.20', 'json3.ws']);
    const params = this.parseParams(
      this._mapToApiInteractiveRetrieveQuery(query),
    );

    return this.http
      .post<{ Items: [RetrieveResponse] | [ErrorResponse] }>(url, params, {
        headers: this.httpHeaders,
      })
      .pipe(
        map((response) => {
          const item = response.Items[0];
          if (isUndefined(item)) {
            throw new LoqateUnknownApiError(item);
          }
          if (isErrorResponse(item)) {
            throw new LoqateApiError(item);
          }

          return pipe(item, withNumberFromName, withBuildingNameOrCompany);
        }),
      );
  }

  private _mapToApiInteractiveFindQuery(
    query: InteractiveFindQuery,
  ): IApiLoqateInteractiveFindQuery {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      Text: query.text,
      Container: query.container,
      Countries: query.countries?.map((c) => c.toUpperCase()).join(','),
      Origin: isGeoCoordinates(query.origin)
        ? toLatLon(query.origin).join(',')
        : query.origin,
      Limit: query.limit,
      Language: query.language,
      Bias: query.bias,
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private _mapToApiInteractiveRetrieveQuery(
    query: InteractiveRetrieveQuery,
  ): IApiLoqateInteractiveRetrieveQuery {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      Id: query.id,
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private parseParams(
    object:
      | HttpParamsOptions['fromObject']
      | IApiLoqateInteractiveFindQuery
      | IApiLoqateInteractiveRetrieveQuery,
  ): HttpParams {
    return this.apiHelper.makeParams({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Key: this.apiConfig.addressCapture.apiKey,
      ...object,
    });
  }

  /**
   * @param parts Parts of the url that should be joined
   *
   * @returns
   * Complete URL for the given parts
   */
  private endpointUrl(parts: (string | number)[]): string {
    const url = [LoqateAddressCaptureApi.endpointPrefix, ...parts].join('/');

    return url;
  }
}

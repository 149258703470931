import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { SheetContainerComponent } from './sheet-container.component';
import { Sheet } from './sheet.service';

@NgModule({
  imports: [OverlayModule, PortalModule],
  exports: [SheetContainerComponent],
  declarations: [SheetContainerComponent],
  providers: [Sheet],
})
export class FmntsSheetModule {}

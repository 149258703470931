/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  Inject,
  Input,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  FmntsCellDefDirective,
  FmntsColumnDefDirective,
} from './cell.directive';
import { IFmntsTableComponents } from './table.model';
import { FMNTS_TABLE } from './table.tokens';

/**
 * The row template that can be used by the fmnts-table.
 */
const FMNTS_ROW_TEMPLATE = `<ng-container fmntsCellOutlet></ng-container>`;

/**
 * Base class for the FmntsHeaderRowDef and FmntsRowDef that handles checking their columns inputs
 * for changes and notifying the table.
 */
@Directive()
export abstract class BaseRowDef implements OnChanges {
  /** The columns to be displayed on this row. */
  columns!: Iterable<string>;

  /** Differ used to check if any changes were made to the columns. */
  protected _columnsDiffer!: IterableDiffer<any>;

  constructor(
    /** @internal */
    public template: TemplateRef<any>,
    protected _differs: IterableDiffers,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Create a new columns differ if one does not yet exist. Initialize it based on initial value
    // of the columns property or an empty array if none is provided.
    if (!this._columnsDiffer) {
      const columns = (changes['columns']?.currentValue ??
        []) as Iterable<string>;
      this._columnsDiffer = this._differs.find(columns).create();
      this._columnsDiffer.diff(columns);
    }
  }

  /**
   * Returns the difference between the current columns and the columns from the last diff, or null
   * if there is no difference.
   */
  getColumnsDiff(): IterableChanges<any> | null {
    return this._columnsDiffer.diff(this.columns);
  }

  /** Gets this row def's relevant cell template from the provided column def. */
  extractCellTemplate(column: FmntsColumnDefDirective): TemplateRef<any> {
    if (this instanceof FmntsHeaderRowDefDirective) {
      return column.headerCell.template;
    }
    if (this instanceof FmntsFooterRowDefDirective) {
      return column.footerCell.template;
    } else {
      return column.cell.template;
    }
  }
}

// Boilerplate for applying mixins to FmntsHeaderRowDef.
class FmntsHeaderRowDefBase extends BaseRowDef {}

/**
 * Header row definition for the table.
 * Captures the header row's template and other header properties such as the columns to display.
 */
@Directive({
  selector: '[fmntsHeaderRowDef]',
  standalone: true,
})
export class FmntsHeaderRowDefDirective
  extends FmntsHeaderRowDefBase
  implements OnChanges
{
  @Input('fmntsHeaderRowDef') override columns!: Iterable<string>;

  constructor(
    template: TemplateRef<any>,
    _differs: IterableDiffers,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(FMNTS_TABLE) @Optional() public _table?: any,
  ) {
    super(template, _differs);
  }

  // Prerender fails to recognize that ngOnChanges in a part of this class through inheritance.
  // Explicitly define it so that the method is called as part of the Angular lifecycle.
  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}

// Boilerplate for applying mixins to FmntsFooterRowDefDirective.
class FmntsFooterRowDefBase extends BaseRowDef {}

/**
 * Footer row definition for the table.
 * Captures the footer row's template and other footer properties such as the columns to display.
 */
@Directive({
  selector: '[fmntsFooterRowDef]',
  standalone: true,
})
export class FmntsFooterRowDefDirective
  extends FmntsFooterRowDefBase
  implements OnChanges
{
  @Input('fmntsFooterRowDef') override columns!: Iterable<string>;

  constructor(
    template: TemplateRef<any>,
    _differs: IterableDiffers,
    @Inject(FMNTS_TABLE) @Optional() public _table?: IFmntsTableComponents,
  ) {
    super(template, _differs);
  }

  // Prerender fails to recognize that ngOnChanges in a part of this class through inheritance.
  // Explicitly define it so that the method is called as part of the Angular lifecycle.
  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}

/**
 * Data row definition for the table.
 * Captures the header row's template and other row properties such as the columns to display and
 * a when predicate that describes when this row should be used.
 */
@Directive({
  selector: '[fmntsRowDef]',
  standalone: true,
})
export class FmntsRowDefDirective<T> extends BaseRowDef {
  @Input('fmntsRowDefColumns') override columns!: Iterable<string>;

  /**
   * Function that should return true if this row template should be used for the provided index
   * and row data. If left undefined, this row will be considered the default row template to use
   * when no other when functions return true for the data.
   * For every row, there must be at least one when function that passes or an undefined to default.
   */
  @Input('fmntsRowDefWhen')
  when!: (index: number, rowData: T) => boolean;

  constructor(
    template: TemplateRef<any>,
    _differs: IterableDiffers,
    @Inject(FMNTS_TABLE) @Optional() public _table?: IFmntsTableComponents,
  ) {
    super(template, _differs);
  }
}

/**
 * Outlet for rendering cells inside of a row or header row.
 */
@Directive({
  selector: '[fmntsCellOutlet]',
  standalone: true,
})
export class FmntsCellOutletDirective implements OnDestroy {
  /**
   * Static property containing the latest constructed instance of this class.
   * Used by the table when each FmntsHeaderRow and FmntsRow component is created using
   * createEmbeddedView. After one of these components are created, this property will provide
   * a handle to provide that component's cells and context. After init, the FmntsCellOutlet will
   * construct the cells with the provided context.
   */
  static mostRecentCellOutlet: FmntsCellOutletDirective | null = null;

  /** The ordered list of cells to render within this outlet's view container */
  cells!: FmntsCellDefDirective[];

  /** The data context to be provided to each cell */
  context: any;

  constructor(public _viewContainer: ViewContainerRef) {
    FmntsCellOutletDirective.mostRecentCellOutlet = this;
  }

  ngOnDestroy(): void {
    // If this was the last outlet being rendered in the view, remove the reference
    // from the static property after it has been destroyed to avoid leaking memory.
    if (FmntsCellOutletDirective.mostRecentCellOutlet === this) {
      FmntsCellOutletDirective.mostRecentCellOutlet = null;
    }
  }
}

/** Header template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'fmnts-header-row, tr[fmnts-header-row]',
  template: FMNTS_ROW_TEMPLATE,
  host: {
    class: 'fmnts-header-row',
    '[class.fmnts-header-row--sticky]': 'sticky',
    role: 'row',
  },
  // See note on FmntsTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FmntsCellOutletDirective],
})
export class FmntsHeaderRowComponent {
  /**
   * Whether this row is sticky or not.
   */
  @Input()
  set sticky(value: BooleanInput) {
    this._sticky = coerceBooleanProperty(value);
  }
  get sticky(): boolean {
    return this._sticky;
  }
  private _sticky = false;
}

/** Footer template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'fmnts-footer-row, tr[fmnts-footer-row]',
  template: FMNTS_ROW_TEMPLATE,
  host: {
    class: 'fmnts-footer-row',
    '[class.fmnts-footer-row--sticky]': 'sticky',
    role: 'row',
  },
  // See note on FmntsTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FmntsCellOutletDirective],
})
export class FmntsFooterRowComponent {
  /**
   * Whether this row is sticky or not.
   */
  @Input()
  set sticky(value: BooleanInput) {
    this._sticky = coerceBooleanProperty(value);
  }
  get sticky(): boolean {
    return this._sticky;
  }
  private _sticky = false;
}

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: 'fmnts-row, tr[fmnts-row]',
  template: FMNTS_ROW_TEMPLATE,
  host: {
    class: 'fmnts-row',
    role: 'row',
  },
  // See note on FmntsTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FmntsCellOutletDirective],
})
export class FmntsRowComponent {}

/** Row that can be used to display a message when no data is shown in the table. */
@Directive({
  selector: 'ng-template[fmntsNoDataRow]',
  standalone: true,
})
export class FmntsNoDataRowDirective {
  _contentClassName = 'fmnts-no-data-row';
  constructor(public templateRef: TemplateRef<any>) {}
}

import type { Data } from '@angular/router';
import { hasProperty, isString } from '@fmnts/core';

interface PageAnimationData {
  /** Animation name of the page. */
  pageName: string;
}

/**
 * Route containing animation data.
 */
export interface AnimationRouteData {
  animation: PageAnimationData;
}

const isPageAnimationData = hasProperty('pageName', isString);
const hasPageAnimationData = hasProperty('animation', isPageAnimationData);

function isRouteAnimationData(d: Data): d is AnimationRouteData {
  return hasPageAnimationData(d);
}

/**
 * @param routeData Data defined on a route
 * @returns
 * The name of a page as defined in the route data.
 *
 * @example
 * // define page name in route data
 * const routes: Routes = [
 *  {
 *    path: '/',
 *    component: ListPageComponent,
 *    data: {
 *      animation: {
 *        pageName: 'ListPage',
 *      },
 *    },
 *  },
 *  {
 *    path: '/:id',
 *    component: DetailPageComponent,
 *    data: {
 *      animation: {
 *        pageName: 'DetailPage',
 *      },
 *    },
 *  },
 * ];
 *
 *
 * // in the template define the following
 * <div [@routeAnimations]="prepareRoute(outlet)">
 *  <router-outlet #outlet="outlet"></router-outlet>
 * </div>
 *
 * // use the helper to retrieve the name
 * prepareRoute(outlet: RouterOutlet) {
 *  return pageAnimationNameFromRoute(outlet.activatedRouteData);
 * }
 *
 * // and some animations
 * const animations = trigger('routeAnimations', slideTransitions(...))
 */
export function pageAnimationNameFromRoute(
  routeData: Data,
): string | undefined {
  if (!isRouteAnimationData(routeData)) {
    return undefined;
  }

  return routeData.animation.pageName;
}

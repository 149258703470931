/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ContentChild,
  Directive,
  ElementRef,
  Inject,
  Input,
  Optional,
  TemplateRef,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { Nullish, isNil } from '@fmnts/core';
import { CellSticky, IFmntsTableComponents } from './table.model';
import { FMNTS_TABLE } from './table.tokens';

/** Base interface for a cell definition. Captures a column's cell template definition. */
export interface CellDef {
  template: TemplateRef<any>;
}

/**
 * Cell definition for a table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
  selector: '[fmntsCellDef]',
  standalone: true,
})
export class FmntsCellDefDirective implements CellDef {
  constructor(public template: TemplateRef<any>) {}
}

/**
 * Header cell definition for a table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[fmntsHeaderCellDef]',
  standalone: true,
})
export class FmntsHeaderCellDefDirective implements CellDef {
  constructor(public template: TemplateRef<any>) {}
}

/**
 * Footer cell definition for a table.
 * Captures the template of a column's footer cell and as well as cell-specific properties.
 */
@Directive({
  selector: '[fmntsFooterCellDef]',
  standalone: true,
})
export class FmntsFooterCellDefDirective implements CellDef {
  constructor(public template: TemplateRef<any>) {}
}

/**
 * Boilerplate for applying mixins to FmntsColumnDefDirective.
 *
 * @internal
 */
class FmntsColumnDefBase {}

/**
 * Column definition for the table.
 * Defines a set of cells available for a table column.
 */
@Directive({
  selector: '[fmntsColumnDef]',
  standalone: true,
  providers: [
    {
      // token will be used in @fmnts/sort
      provide: 'SORT_HEADER_COLUMN_DEF',
      useExisting: FmntsColumnDefDirective,
    },
  ],
})
export class FmntsColumnDefDirective extends FmntsColumnDefBase {
  /** Unique name for this column. */
  @Input('fmntsColumnDef')
  get name(): string {
    return this._name;
  }
  set name(name: string) {
    this._setNameInput(name);
  }
  protected _name!: string;

  /**
   * Whether the column should be sticky or not.
   * If sticky, this property specifies how the column should stick.
   */
  @Input()
  get sticky(): CellSticky | null {
    return this._sticky;
  }
  set sticky(value: CellSticky | Nullish) {
    this._sticky = isNil(value) ? null : value;
    this._updateColumnCssClassName();
  }
  private _sticky: CellSticky | null = null;

  /** @internal */
  @ContentChild(FmntsCellDefDirective) cell!: FmntsCellDefDirective;

  /** @internal */
  @ContentChild(FmntsHeaderCellDefDirective)
  headerCell!: FmntsHeaderCellDefDirective;

  /** @internal */
  @ContentChild(FmntsFooterCellDefDirective)
  footerCell!: FmntsFooterCellDefDirective;

  /**
   * Transformed version of the column name that can be used as part of a CSS classname. Excludes
   * all non-alphanumeric characters and the special characters '-' and '_'. Any characters that
   * do not match are replaced by the '-' character.
   */
  cssClassFriendlyName!: string;

  /**
   * Class name for cells in this column.
   */
  _columnCssClassName!: string[];

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(
    @Inject(FMNTS_TABLE) @Optional() public _table?: IFmntsTableComponents,
  ) {
    super();
  }

  /**
   * Overridable method that sets the css classes that will be added to every cell in this
   * column.
   * In the future, columnCssClassName will change from type string[] to string and this
   * will set a single string value.
   *
   * @internal
   */
  protected _updateColumnCssClassName(): void {
    this._columnCssClassName = classnames([
      `fmnts-column-${this.cssClassFriendlyName}`,
      this.sticky && `fmnts-column--sticky-${this.sticky}`,
    ]).split(' ');
  }

  /**
   * This has been extracted to a util because of TS 4 and VE.
   * View Engine doesn't support property rename inheritance.
   * TS 4.0 doesn't allow properties to override accessors or vice-versa.
   *
   * @internal
   */
  protected _setNameInput(value: string): void {
    // If the directive is set without a name (updated programmatically), then this setter will
    // trigger with an empty string and should not overwrite the programmatically set value.
    if (value) {
      this._name = value;
      this.cssClassFriendlyName = value.replace(/[^a-z0-9_-]/gi, '-');
      this._updateColumnCssClassName();
    }
  }
}

/** Base class for the cells. Adds a CSS classname that identifies the column it renders in. */
export class BaseFmntsCell {
  constructor(
    columnDef: FmntsColumnDefDirective,
    elementRef: ElementRef<HTMLElement>,
  ) {
    elementRef.nativeElement.classList.add(...columnDef._columnCssClassName);
  }
}

/** Header cell template container that adds the right classes and role. */
@Directive({
  selector: 'fmnts-header-cell, th[fmnts-header-cell]',
  host: {
    class: 'fmnts-header-cell',
    role: 'columnheader',
  },
  standalone: true,
})
export class FmntsHeaderCellDirective extends BaseFmntsCell {
  constructor(
    columnDef: FmntsColumnDefDirective,
    elementRef: ElementRef<HTMLElement>,
  ) {
    super(columnDef, elementRef);
  }
}

/** Footer cell template container that adds the right classes and role. */
@Directive({
  selector: 'fmnts-footer-cell, td[fmnts-footer-cell]',
  host: {
    class: 'fmnts-footer-cell',
  },
  standalone: true,
})
export class FmntsFooterCellDirective extends BaseFmntsCell {
  constructor(
    columnDef: FmntsColumnDefDirective,
    elementRef: ElementRef<HTMLElement>,
  ) {
    super(columnDef, elementRef);
    if (columnDef._table?._elementRef.nativeElement.nodeType === 1) {
      const tableRole =
        columnDef._table._elementRef.nativeElement.getAttribute('role');
      const role =
        tableRole === 'grid' || tableRole === 'treegrid' ? 'gridcell' : 'cell';
      elementRef.nativeElement.setAttribute('role', role);
    }
  }
}

/** Cell template container that adds the right classes and role. */
@Directive({
  selector: 'fmnts-cell, td[fmnts-cell]',
  host: {
    class: 'fmnts-cell',
  },
  standalone: true,
})
export class FmntsCellDirective extends BaseFmntsCell {
  constructor(
    columnDef: FmntsColumnDefDirective,
    elementRef: ElementRef<HTMLElement>,
  ) {
    super(columnDef, elementRef);
    if (columnDef._table?._elementRef.nativeElement.nodeType === 1) {
      const tableRole =
        columnDef._table._elementRef.nativeElement.getAttribute('role');
      const role =
        tableRole === 'grid' || tableRole === 'treegrid' ? 'gridcell' : 'cell';
      elementRef.nativeElement.setAttribute('role', role);
    }
  }
}

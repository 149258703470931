import { Params } from '@angular/router';
import {
  ISerializer,
  ReverseAdapter,
  isDefined,
  isNonEmptyString,
} from '@fmnts/core';
import { TimeSpan } from '@fmnts/core/chronos';
import moment from 'moment';
import { QueryParamsSerializer } from './query-param.adapter';

export class DateQueryParamAdapter
  implements ReverseAdapter<string | null, moment.MomentInput | null>
{
  private readonly format = 'YYYY-MM-DD';

  adapt(value: moment.MomentInput): string | null {
    return value ? moment(value).format(this.format) : null;
  }

  reverse(value: string | null): moment.Moment | null {
    if (!value) {
      return null;
    }

    const parsed = moment(value, this.format, true);
    return parsed.isValid() ? parsed : null;
  }
}

class DateQueryParamSerializer
  implements ISerializer<moment.Moment | undefined, string | undefined>
{
  private readonly dateFilterTransform = new DateQueryParamAdapter();

  serialize(data: moment.Moment | undefined): string | undefined {
    return this.dateFilterTransform.adapt(data) ?? undefined;
  }

  deserialize(s: unknown): moment.Moment | undefined {
    return isNonEmptyString(s)
      ? (this.dateFilterTransform.reverse(s) ?? undefined)
      : undefined;
  }
}

export class DateRangeQueryParamsSerializer
  implements QueryParamsSerializer<TimeSpan | undefined>
{
  private readonly dateQueryParamSerializer = new DateQueryParamSerializer();

  constructor(
    private readonly startKey: string,
    private readonly endKey: string,
  ) {}

  serialize(data: TimeSpan | undefined): Params {
    const [start, end] = data ?? [];
    return {
      [this.startKey]: this.dateQueryParamSerializer.serialize(start),
      [this.endKey]: this.dateQueryParamSerializer.serialize(end),
    };
  }

  deserialize(params: Params): TimeSpan | undefined {
    const startValue: unknown = params[this.startKey];
    const endValue: unknown = params[this.endKey];

    const start = this.dateQueryParamSerializer.deserialize(startValue);
    const end = this.dateQueryParamSerializer.deserialize(endValue);

    return isDefined(start) && isDefined(end) ? [start, end] : undefined;
  }
}

export const dateQueryParamSerializer = new DateQueryParamSerializer();

/**
 * Coordinates.
 * Projection used is WGS 84 Web Mercator (EPSG:3857).
 */
export interface GeoCoordinates {
  readonly latitude: number;
  readonly longitude: number;
}

/**
 * Error codes for retrieving the geolocation coordinates.
 */
export enum GeoCoordinatesErrorCode {
  Unknown,
  PermissionDenied,
  Unavailable,
  Timeout,
}

export interface GeoCoordinatesError {
  /** Code describing the error. */
  readonly code: GeoCoordinatesErrorCode;
}

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDe_CH from '@angular/common/locales/de-CH';
import localeEn from '@angular/common/locales/en';
import localeEn_IE from '@angular/common/locales/en-IE';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeLb from '@angular/common/locales/lb';
import localeLt from '@angular/common/locales/lt';
import localeNl from '@angular/common/locales/nl';
import localeRo from '@angular/common/locales/ro';
import { NgModule } from '@angular/core';
import { I18nModule, L10nModule } from '@fmnts/i18n';
import 'moment/locale/de';
import 'moment/locale/de-ch';
import 'moment/locale/en-ie';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/lb';
import 'moment/locale/lt';
import 'moment/locale/nl';
import 'moment/locale/ro';
import Translations from '../translations';
import { AppLanguageSource } from './app-l10n';

// Register locale data
// This is needed for angular pipes to work properly
registerLocaleData(localeDe);
registerLocaleData(localeDe_CH);
registerLocaleData(localeEn);
registerLocaleData(localeEn_IE);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localeLb);
registerLocaleData(localeLt);
registerLocaleData(localeNl);
registerLocaleData(localeRo);

/**
 * Module that provides the localization for the application.
 */
@NgModule({
  imports: [
    L10nModule.forRoot({
      translations: Translations,
      order: [
        AppLanguageSource.User,
        AppLanguageSource.Browser,
        AppLanguageSource.Default,
      ],
    }),
  ],
  exports: [I18nModule],
})
export class AppLocalizationModule {}

import { Injectable, inject } from '@angular/core';
import { createHttpRequestEffectHandler } from '@fmnts/common/store';
import { fromHttpOrGeneralError } from '@formunauts/shared/errors/domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, exhaustMap, switchMap } from 'rxjs';
import { AuthRepository } from '../auth-repository.model';
import { AuthApiActions, AuthLoginPageActions } from './auth.actions';
import { selectSession } from './auth.reducer';

/**
 * Effects related to OAuth.
 */
@Injectable()
export class AuthEffects {
  private readonly repo = inject(AuthRepository);
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);

  /**
   * Authenticates user via username / password.
   */
  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.authenticate.request, AuthLoginPageActions.login),
      switchMap(
        createHttpRequestEffectHandler(AuthApiActions.authenticate, {
          run: (cred) => this.repo.authenticate(cred),
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });

  /**
   * Refreshes the token.
   */
  refresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.refresh.request),
      concatLatestFrom((action) => this.store.select(selectSession)),
      exhaustMap(
        createHttpRequestEffectHandler(AuthApiActions.refresh, {
          run: ([_, session]) =>
            session?.refresh_token
              ? this.repo.refresh(session.refresh_token)
              : EMPTY,
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });

  /**
   * Revokes the token if available.
   */
  unauthenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthApiActions.unauthenticate.request),
      concatLatestFrom((action) => this.store.select(selectSession)),
      exhaustMap(
        createHttpRequestEffectHandler(AuthApiActions.unauthenticate, {
          run: ([_, session]) =>
            session?.access_token
              ? this.repo.revoke(session.access_token)
              : EMPTY,
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });
}

import { Router } from '@angular/router';

/**
 * A helper function to run in the `constructor` of a page component
 * that detects if there was a previous navigation.
 *
 * @param router Router instance
 *
 * @returns
 * `true` if another navigation happened before, otherwise `false`.
 */
export function detectCanNavigateBack(router: Router): boolean {
  const finalUrlTree =
    router.getCurrentNavigation()?.previousNavigation?.finalUrl;

  // Nothing to navigate back to within the context of our app.
  if (!finalUrlTree) {
    return false;
  }

  // In all other cases it should be OK to use `location.back()`
  // as for now, only the location list should navigate here directly
  // from within the app.
  return true;
}

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import { I18nModule } from '@fmnts/i18n';
import { faArrowRight, faMobileAlt } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-rotate-device',
  templateUrl: './rotate-device.component.html',
  styleUrls: ['./rotate-device.component.scss'],
  standalone: true,
  imports: [FmntsIconsModule, FmntsLayoutModule, I18nModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RotateDeviceComponent {
  @HostBinding('class')
  protected readonly componentClass = 'rotate-device';

  /** @internal */
  protected readonly _iconMobile = faMobileAlt;
  /** @internal */
  protected readonly _iconArrow = faArrowRight;
}

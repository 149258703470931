import { Boolean, Data } from 'effect';
import {
  Observable,
  ObservableInput,
  OperatorFunction,
  TimeoutConfig as RxjsTimeoutConfig,
  catchError,
  concatMap,
  map,
  of,
  timeout as rxjsTimeout,
} from 'rxjs';
import * as Result from './result';

/**
 * An `ResultStream<A, E = never>` describes a stream that emits results.
 *
 * {@link Result.Result}
 */
export type ResultStream<A, E> = Observable<Result.Result<A, E>>;

/**
 * Error when the client fails to connect to a remote source.
 */
export class ClientOfflineError extends Data.TaggedError(
  'ClientOfflineError',
) {}
/**
 * Error that is thrown when a client request times out.
 */
export class ClientTimeoutError extends Data.TaggedError(
  'ClientTimeoutError',
) {}

/**
 * @param success The value with which to succeed the `Observable`.
 * @returns
 * An `Observable` that emits a succeeded result, then completes.
 */
export const succeed = <A, E>(success: A): ResultStream<A, E> =>
  of(Result.succeed(success));

/**
 * @param failure The value with which to fail the `Observable`.
 * @returns
 * An `Observable` that emits a failed result, then completes.
 */
export const fail = <E, A = unknown>(failure: E): ResultStream<A, E> =>
  of(Result.fail(failure));

/**
 * Creates a result stream from an `Observable.`
 */
export function fromObservable<A, E>(opts: {
  onFailure: (error: unknown) => Result.Result<never, E>;
}): OperatorFunction<A, Result.Result<A, E>>;
export function fromObservable<A, B, E>(opts: {
  onSuccess: (value: A) => Result.Result<B, never>;
  onFailure: (error: unknown) => Result.Result<never, E>;
}): OperatorFunction<A, Result.Result<B, E>>;
export function fromObservable({
  onFailure,
  onSuccess = Result.succeed,
}: {
  onSuccess?: (value: unknown) => Result.Result<unknown, never>;
  onFailure: (error: unknown) => Result.Result<never, unknown>;
}): OperatorFunction<unknown, Result.Result<unknown, unknown>> {
  return (obs$) =>
    obs$.pipe(
      map(onSuccess),
      catchError((error) => of(onFailure(error))),
    );
}

export type TimeoutConfig = Omit<
  RxjsTimeoutConfig<unknown, ObservableInput<unknown>, unknown>,
  'with'
>;

/**
 * Fails a stream with a result after a given timeout.
 *
 * @param config Configuration for the timeout
 */
export function timeout<A, E>(
  config: TimeoutConfig,
): OperatorFunction<
  Result.Result<A, E>,
  Result.Result<A, E | ClientTimeoutError>
> {
  return rxjsTimeout({
    ...config,
    with: (timeoutInfo) =>
      fail<ClientTimeoutError, A>(new ClientTimeoutError()),
  });
}

/**
 * Fails a stream in case that the
 *
 * @param isOnline$
 * @returns
 */
export function failWhenOffline<A, E>(
  isOnline$: Observable<boolean>,
): OperatorFunction<
  Result.Result<A, E>,
  Result.Result<A, E | ClientOfflineError>
> {
  return (obs$) =>
    isOnline$.pipe(
      concatMap(
        Boolean.match({
          onTrue: () => obs$,
          onFalse: () => of(Result.fail(new ClientOfflineError())),
        }),
      ),
    );
}

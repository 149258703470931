import { QuizStatus } from '@fmnts/api/shared';
import { IFundraiserMinimal } from './fundraiser';

export type QuizId = QuizMinimal['id'];

/**
 * Versions of the quiz forms.
 */
export enum QuizFormVersion {
  V1 = 1,
}

export type QuestionType = 'singleChoice' | 'multipleChoice';

export interface IQuiz {
  id?: string;
  /** Whether the quiz is active or not. */
  isActive: boolean;
  /** Whether the quiz is editable or not. */
  isEditable: boolean;
  /**
   * Version of the forms implementation with which the quiz
   * was created.
   */
  formVersion: number;
  title: string;
  description: string;
  /** full resolution image, used for details */
  image: string | null;
  /** lower resolution image, used for lists */
  thumbnailUrl: string | null;
  /** just generic blocks for questions and content */
  steps: Record<string, unknown>[];
  assetIds: number[];
  /** 0-100  */
  scoreNeeded: number;
  /** Maximum allowed attempts */
  attemptsAllowed: number;
  /** in seconds */
  expectedDuration: number;
  activeFrom?: string | null;
  /** ISO timestamp */
  activeTo?: string | null;
  created?: string | null;
  /** ISO timestamp */
  modified?: string | null;
  /** Whether the results should be shown or not. */
  showTrainingResults?: boolean;
  /** string to identify changes of quiz questions */
  revision: string;
}

export interface QuizMinimal {
  id: string;
  title: string;
  thumbnailUrl: string | null;
  scoreNeeded: number;
  /** in seconds */
  expectedDuration: number;
}

export interface IQuizAttempt {
  id: string;
  /** ISO timestamp */
  startedAt: string;
  /** ISO timestamp */
  finishedAt: string;
  quizId: string;
  fundraiserId: string;
  /** 0-100 */
  score: number;
  passed: boolean;
  answers: IQuizAnswer[];
  formVersion: number;
  revision: string;
  isExpired: boolean;
}

export interface IQuizAnswer {
  /**
   * answers given on a question,
   * can be single element for sc, multiple for mc
   * */
  answer: (number | string)[];
  /** the correct answers */
  correctAnswers: (number | string)[];
  /** whether the answer was correct */
  correct: boolean;
  /** the id of the question (uses a uuid) */
  id: string;
  /** type of question (`singleChoice` | `multipleChoice`) */
  type: QuestionType;
}

export interface QuizFundraiserStatus {
  fundraiser: Readonly<IFundraiserMinimal>;
  status: `${QuizStatus}`;
  attemptId: number;
}

export class Quiz implements IQuiz {
  public id: string;
  public isActive: boolean;
  public isEditable: boolean;
  public formVersion: number;
  public title: string;
  public description: string;
  public image: string | null;
  public thumbnailUrl: string | null;
  public steps: Record<string, unknown>[];
  public assetIds: number[];
  public scoreNeeded: number;
  public attemptsAllowed: number;
  public expectedDuration: number;
  public activeFrom?: string;
  public activeTo?: string;
  public created?: string;
  public modified?: string;
  public showTrainingResults: boolean;
  public revision: string;

  constructor(dto: Readonly<IQuiz>) {
    this.id = dto.id;
    this.isActive = dto.isActive;
    this.isEditable = dto.isEditable;
    this.formVersion = dto.formVersion;
    this.title = dto.title;
    this.description = dto.description;
    this.image = dto.image;
    this.thumbnailUrl = dto.thumbnailUrl;
    this.steps = dto.steps;
    this.assetIds = dto.assetIds;
    this.scoreNeeded = dto.scoreNeeded;
    this.attemptsAllowed = dto.attemptsAllowed;
    this.expectedDuration = dto.expectedDuration;
    this.activeFrom = dto.activeFrom;
    this.activeTo = dto.activeTo;
    this.created = dto.created;
    this.modified = dto.modified;
    this.showTrainingResults = dto.showTrainingResults;
    this.revision = dto.revision;
  }
}

export class QuizAttempt implements IQuizAttempt {
  public id: string;
  public startedAt: string;
  public finishedAt: string;
  public quizId: string;
  public fundraiserId: string;
  public score: number;
  public passed: boolean;
  public answers: QuizAnswer[];
  public formVersion: number;
  public revision: string;
  public isExpired: boolean;

  constructor(dto: Readonly<IQuizAttempt>) {
    this.id = dto.id;
    this.startedAt = dto.startedAt;
    this.finishedAt = dto.finishedAt;
    this.quizId = dto.quizId;
    this.fundraiserId = dto.fundraiserId;
    this.score = dto.score;
    this.passed = dto.passed;
    this.answers = dto.answers.map((answer) => new QuizAnswer(answer));
    this.formVersion = dto.formVersion;
    this.revision = dto.revision;
    this.isExpired = dto.isExpired;
  }
}

export class QuizAnswer implements IQuizAnswer {
  public id: string;
  public answer: (number | string)[];
  public correctAnswers: (number | string)[];
  public correct: boolean;
  public type: QuestionType;

  constructor(dto: Readonly<IQuizAnswer>) {
    this.id = dto.id;
    this.answer = dto.answer;
    this.correctAnswers = dto.correctAnswers;
    this.correct = dto.correct;
    this.type = dto.type;
  }
}

import { ISerializer, isNil, JsonSerializer } from '@fmnts/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';

export interface State {
  [key: string]: any;
}

/**
 * Used to manipulate a certain item in a specified storage
 */
export class StorageAccessor<TState extends State = State> {
  private readonly subject: BehaviorSubject<TState>;
  public readonly store: Observable<TState>;

  /**
   * Get the current value
   */
  get value(): TState {
    return this.subject.value;
  }

  constructor(
    /**
     * The storage in which the data should be persisted
     */
    private readonly storage: Storage,
    /**
     * The key to use to access the item in the storage
     */
    private readonly STATE_STORAGE_KEY: string,
    /**
     * The default state of the stored data
     */
    private readonly DEFAULT_STATE: TState,
    /**
     * Used to transform the data before it is stored in the storage
     */
    private readonly serializer: ISerializer<TState, string>,
  ) {
    // Initialize subject and store
    this.subject = new BehaviorSubject<TState>(this.DEFAULT_STATE);
    this.store = this.subject.asObservable().pipe(distinctUntilChanged());

    try {
      this.merge(this.persisted());

      this.store.subscribe((newState) => {
        this.storage.setItem(
          this.STATE_STORAGE_KEY,
          this.serializer.serialize(newState),
        );
      });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Watch changes to a value in store. Allows watching nested values using
   * dot syntax (e.g. car.brand.name for `{ car: { brand: { name: 'any' } } }`).
   *
   * @param key The key to access
   */
  public select<T>(key: string): Observable<T> {
    return this.store.pipe(pluck(...key.split('.')), distinctUntilChanged<T>());
  }

  /**
   * Immediately returns the current value in the store. It also allows getting
   * nested values using the dot syntax (e.g. car.brand.name for
   * `{ car: { brand: { name: 'any' } } }`).
   *
   * @param key The key to get
   */
  public get<T = any>(key?: string): T {
    return !key ? this.value : this.getWithValue(key, this.value);
  }

  private getWithValue(key: string, value: any, createMissing = false): any {
    const keys = key.split('.');

    return keys.reduce((acc, k, i) => {
      if (!isNil(acc[k])) {
        return acc[k];
      } else if (createMissing) {
        acc[k] = {};

        return acc[k];
      } else {
        return i < keys.length - 1 ? {} : null;
      }
    }, value);
  }

  /**
   * Updates values in the store using the dot syntax (e.g. car.brand.name for
   * `{ car: { brand: { name: 'any' } } }`). Non-existing intermediate keys get
   * created.
   *
   * @param key Key
   * @param value Value
   */
  public set(key: string, value: any) {
    const newState = { ...this.value };
    const keys = key.split('.');
    const lastKey = keys.pop();
    const lastState =
      keys.length > 0
        ? this.getWithValue(keys.join('.'), newState, true)
        : newState;

    lastState[lastKey] = value;

    this.subject.next(newState);
  }

  /**
   * Removes the key including any values in the store using the dot syntax
   *
   * @param key Key
   */
  public remove(key: string) {
    const newState = { ...this.value };
    const keys = key.split('.');
    const lastKey = keys[keys.length - 1];

    if (keys.length === 0) {
      delete newState[lastKey];
    }

    keys.reduce((acc, k, i) => {
      if (i === keys.length - 1) {
        delete acc[lastKey];
      } else if (!isNil(acc[k])) {
        return acc[k];
      }
    }, newState);
    this.subject.next(newState);
  }

  /**
   * Merges the current `value` with the given `partialValue`.
   *
   * @param partialValue The new partial values
   */
  public merge(partialValue: Partial<TState>) {
    this.subject.next({
      ...this.value,
      ...partialValue,
    });
  }

  /**
   * Patches values in the store using the dot syntax (e.g. car.brand.name for
   * `{ car: { brand: { name: 'any' } } }`). Non-existing intermediate keys get
   * created. Only works for objects.
   */
  public patch(key: string, value: { [key: string]: any }) {
    if (typeof this.get(key) !== 'object') {
      return;
    }

    const newState = { ...this.value };
    const keys = key.split('.');
    const lastKey = keys.pop();
    const lastState =
      keys.length > 0
        ? this.getWithValue(keys.join('.'), newState, true)
        : newState;

    lastState[lastKey] = { ...lastState[lastKey], ...value };

    this.subject.next(newState);
  }

  /**
   * Clear to the default state
   *
   * @param partialValues values to override default state
   */
  public reset(partialValues: Partial<TState> = {}): void {
    this.subject.next({
      ...this.DEFAULT_STATE,
      ...partialValues,
    });
  }

  /**
   * This is mainly for internal use.
   * Consider using `get` or `select`.
   *
   * @returns
   * The data that is persisted.
   */
  public persisted(): Partial<TState> {
    const storedState = this.storage.getItem(this.STATE_STORAGE_KEY);
    return this.serializer.deserialize(storedState);
  }

  /**
   * Removes the persisted data.
   *
   * This is mainly for internal use.
   * If you want to reset the data, please consider using `reset`.
   */
  public removePersisted() {
    this.storage.removeItem(this.STATE_STORAGE_KEY);
  }
}

/**
 * @param key The key used to access the storage item
 * @param state The initial state
 * @param serializer The serializer used to store/load from storage
 *
 * @returns
 * An instance of a storage accessor for accessing the local storage.
 */
export const LocalStorageProvideFactory =
  <T>(
    key: string,
    state: T,
    serializer: ISerializer<T> = new JsonSerializer(),
  ) =>
  () =>
    new StorageAccessor<T>(localStorage, key, state, serializer);

import { CustomerId, LocationMinimal } from '@fmnts/api/shared';
import { Campaign, ICampaign } from './campaign';
import {
  Fundraiser,
  FundraiserMinimal,
  IFundraiser,
  IFundraiserMinimal,
} from './fundraiser';
import { IPetitionCampaign, PetitionCampaign } from './petition-campaign';

export interface ITeam {
  id: string;
  image: string;
  name: string;
  startDate: Date;
  endDate: Date;

  coach: IFundraiserMinimal | null;
  leader: IFundraiserMinimal | null;
  tags: string[];
}

export interface ITeamDetailed {
  id: string;
  campaignType: string;
  customerId: CustomerId;
  goalValue: number;
  goalValueUnit: string;
  image: string;
  name: string;
  prolongWorkshiftSetup: boolean;
  teamType: string | null;

  startDate: Date;
  endDate: Date;

  leader: IFundraiser | null;
  coach: IFundraiser | null;

  tags: readonly string[];

  assistantCoaches: readonly Readonly<IFundraiser>[];
  campaigns: readonly Readonly<ICampaign>[];
  locations: readonly Readonly<LocationMinimal>[];
  petitionCampaigns: readonly Readonly<IPetitionCampaign>[];
}

export class Team implements ITeam {
  id: string;
  image: string;
  name: string;
  endDate: Date;
  startDate: Date;

  coach: FundraiserMinimal | null;
  leader: FundraiserMinimal | null;
  tags: string[];

  constructor(dto: Readonly<ITeam>) {
    this.id = dto.id;
    this.image = dto.image;
    this.name = dto.name;
    this.startDate = dto.startDate;
    this.endDate = dto.endDate;
    this.coach = dto.coach ? new FundraiserMinimal(dto.coach) : null;
    this.leader = dto.leader ? new FundraiserMinimal(dto.leader) : null;
    this.tags = [...dto.tags];
  }
}

export class TeamDetailed implements ITeamDetailed {
  id: string;
  assistantCoaches: readonly Readonly<Fundraiser>[];
  campaigns: Campaign[];
  campaignType: string;
  coach: Fundraiser | null;
  customerId: CustomerId;
  endDate: Date;
  goalValue: number;
  goalValueUnit: string;
  image: string;
  leader: Fundraiser | null;
  locations: readonly Readonly<LocationMinimal>[];
  name: string;
  petitionCampaigns: readonly Readonly<PetitionCampaign>[];
  prolongWorkshiftSetup: boolean;
  startDate: Date;
  tags: readonly string[];
  teamType: string | null;

  constructor(json: Readonly<ITeamDetailed>) {
    this.id = json.id;
    this.campaignType = json.campaignType;
    this.customerId = json.customerId;
    this.goalValue = json.goalValue;
    this.goalValueUnit = json.goalValueUnit;
    this.image = json.image;
    this.name = json.name;
    this.prolongWorkshiftSetup = json.prolongWorkshiftSetup;
    this.startDate = new Date(json.startDate);
    this.endDate = new Date(json.endDate);
    this.teamType = json.teamType;

    this.tags = json.tags;
    this.coach = json.coach ? new Fundraiser(json.coach) : null;
    this.leader = json.leader ? new Fundraiser(json.leader) : null;

    this.assistantCoaches = (json.assistantCoaches ?? []).map(
      (coach) => new Fundraiser(coach),
    );
    this.campaigns = (json.campaigns ?? []).map((c) => new Campaign(c));
    this.locations = json.locations ?? [];
    this.petitionCampaigns = (json.petitionCampaigns ?? []).map(
      (c) => new PetitionCampaign(c),
    );
  }
}

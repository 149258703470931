import { Directive, HostBinding } from '@angular/core';

/**
 * Title for an empty state.
 */
@Directive({
  selector: 'fmnts-empty-state-title, [fmnts-empty-state-title]',
})
export class EmptyStateTitleDirective {
  @HostBinding('class.fmnts-empty-state-title') componentClass =
    'fmnts-empty-state-title';
}

/**
 * Description for an empty state.
 */
@Directive({
  selector: 'fmnts-empty-state-description, [fmnts-empty-state-description]',
})
export class EmptyStateDescriptionDirective {
  @HostBinding('class.fmnts-empty-state-description') componentClass =
    'fmnts-empty-state-description';
}

<div class="fmnts-toast-container__surface">
  <!--
      This outer label wrapper will have the class `fmnts-toast__label` applied if
      the attached template/component does not contain it.
    -->
  <div class="fmnts-toast-container__label">
    <!-- Initialy holds the toast content, will be empty after announcing to screen readers. -->
    <div aria-hidden="true">
      <ng-template cdkPortalOutlet></ng-template>
    </div>

    <!-- Will receive the toast content from the non-live div, move will happen a short delay after opening -->
    <div
      [attr.aria-live]="_live"
      [attr.role]="_role"
      [attr.id]="_liveElementId"
    ></div>
  </div>
</div>

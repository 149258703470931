import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CustomerFeatures } from '@fmnts/api';
import {
  FeatureFlagGuardConfig,
  featureFlagCanActivate,
  featureFlagCanLoad,
} from '@fmnts/common/feature-flags';
import { CustomerFeatureService } from '@fmnts/shared/customer/data-access';

/**
 * Can Activate guard that checks for customer feature flags.
 *
 * @param config Guard configuration.
 * @returns
 * Guard function
 */
export function customerFeatureFlagCanActivate(
  config: FeatureFlagGuardConfig<CustomerFeatures>,
): CanActivateFn {
  return () =>
    featureFlagCanActivate(
      {
        fallbackUrl: '/not-found',
        ...config,
      },
      inject(CustomerFeatureService),
    );
}

/**
 * Can Load guard that checks for customer feature flags.
 *
 * @param config Guard configuration
 * @returns
 * Guard function.
 */
export function customerFeatureFlagCanLoad(
  config: FeatureFlagGuardConfig<CustomerFeatures>,
): CanActivateFn {
  return () =>
    featureFlagCanLoad(
      {
        fallbackUrl: '/not-found',
        ...config,
      },
      inject(CustomerFeatureService),
    );
}

import { IStatisticsItem, StatisticsItem } from './statistics-item';

export interface ILocationStatistics {
  id: string;
  name: string;
  items: readonly Readonly<IStatisticsItem>[];
}

export class LocationStatistics implements ILocationStatistics {
  id: string;
  name: string;
  items: readonly Readonly<StatisticsItem>[];

  constructor(dto: ILocationStatistics) {
    this.id = dto.id;
    this.name = dto.name;
    this.items = dto.items.map((item) => new StatisticsItem(item));
  }
}

import { gteLogLevel } from './log-level.util';
import { LogEventPredicateFn, LogLevel } from './log.model';

/**
 * Creates a predicate function to be used with log event filters.
 *
 * @param level The minimum log level
 */
export function minLogLevelFilter(level: LogLevel): LogEventPredicateFn {
  return (log) => gteLogLevel(log.level, level);
}

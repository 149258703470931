/* eslint-disable @angular-eslint/no-host-metadata-property */

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';

export interface ChipSelectionChange {
  /**
   * Whether the selection change was a result of a user interaction.
   */
  isUserInput: boolean;
  /**
   * Whether the chip that emitted the event is selected.
   */
  selected: boolean;
  /**
   * Reference to the chip that emitted the event.
   */
  source: SelectableChipComponent;
}

@Directive({
  host: {
    '[attr.aria-disabled]': 'disabled.toString()',
    '[class.fmnts-chip--disabled]': 'disabled',
  },
})
export class ChipBaseComponent {
  @HostBinding('class.fmnts-chip')
  protected readonly componentClass = 'fmnts-chip';

  /** Color variant to use for the button */
  @Input() color?: ThemeColor;

  /**
   * Whether the chip-option is selected or not.
   */
  @Input()
  get selected(): boolean {
    return this._selected;
  }
  set selected(val: BooleanInput) {
    this._selected = coerceBooleanProperty(val);
  }
  @HostBinding('class.fmnts-chip--selected')
  private _selected = false;

  /**
   * Whether the avatar should be disabled
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(val: BooleanInput) {
    this._disabled = coerceBooleanProperty(val);
  }
  private _disabled = false;

  @HostBinding('class') get hostClasses(): string {
    return classnames([
      // Apply color
      this.color && `${this.componentClass}--${this.color}`,
    ]);
  }
}

@Directive({
  host: {
    '[attr.tabindex]': 'disabled ? -1 : tabIndex',
    '[class.fmnts-chip--selectable]': 'true',
  },
})
export class SelectableChipComponent extends ChipBaseComponent {
  /** Tabindex of the button. */
  @Input() tabIndex?: number;

  /**
   * Emitted when the chip is selected or deselected.
   */
  @Output()
  selectionChange = new EventEmitter<ChipSelectionChange>();

  protected _toggle(isUserInput: boolean): void {
    if (this.disabled) {
      return;
    }

    this.selected = !this.selected;
    this.selectionChange.emit({
      isUserInput,
      selected: this.selected,
      source: this,
    });
  }
}

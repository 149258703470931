import {
  apiAdaptOrNull,
  apiArrayAdapter,
  apiDateAdapter,
} from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import { IApiTeamStatistics } from '../api-model/api-model';
import { TeamStatistics } from '../model/team-statistics';
import { ApiCampaignAdapter } from './campaign.adapter';
import { ApiFundraiserAdapter } from './fundraiser.adapter';
import { ApiPetitionCampaignAdapter } from './petition-campaign.adapter';
import { ApiStatisticsItemAdapter } from './statistics-item.adapter';

export class ApiTeamStatisticsAdapter implements Adapter<TeamStatistics> {
  private readonly campaignAdapter = new ApiCampaignAdapter();
  private readonly fundraiserAdapter = new ApiFundraiserAdapter();
  private readonly petitionCampaignAdapter = new ApiPetitionCampaignAdapter();
  private readonly statisticsItemAdapter = new ApiStatisticsItemAdapter();

  adapt(apiDto: IApiTeamStatistics): TeamStatistics {
    return new TeamStatistics({
      id: apiDto.id,
      image: apiDto.image_thumbnail_url,
      name: apiDto.name,
      endDate: apiDateAdapter.adapt(apiDto.end_date),
      startDate: apiDateAdapter.adapt(apiDto.start_date),

      // Objects
      leader: apiAdaptOrNull.adaptOrNull(apiDto.leader, this.fundraiserAdapter),

      // Collections
      tags: apiDto.tags,
      items: apiDto.statistics.map((item) =>
        this.statisticsItemAdapter.adapt(item),
      ),
      campaigns: apiArrayAdapter.adaptItemsOrNull(
        apiDto.campaigns,
        this.campaignAdapter,
      ),
      petitionCampaigns: apiArrayAdapter.adaptItemsOrNull(
        apiDto.petition_campaigns,
        this.petitionCampaignAdapter,
      ),
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { findValueTranslation } from './find-value-translation';
import { TranslationService } from './translation.service';
import type { ValueTranslation } from './translation.types';

enum Format {
  ActiveInactive = 'activeInactive',
  YesNo = 'yesNo',
}

/**
 * Mapping for the different `Format`s
 */
const formatMapping: Record<
  Format,
  readonly [ValueTranslation, ValueTranslation]
> = {
  [Format.ActiveInactive]: [
    {
      label: 'active',
      value: true,
    },
    {
      label: 'inactive',
      value: false,
    },
  ],
  [Format.YesNo]: [
    {
      label: 'yes',
      value: true,
    },
    {
      label: 'no',
      value: false,
    },
  ],
};

/**
 * Pipe that translates a boolean value using
 * a fixed value mapping as a format.
 *
 * The default mapping used is the `yesNo` format.
 *
 *
 * @example
 * Use `yesNo` by default
 * ```html
 * <span>
 *  {{ true | translateBoolean }}
 * </span>
 * ```
 *
 * @example
 * Specify the format
 * ```html
 * <span>
 *  {{ true | translateBoolean: 'activeInactive' }}
 * </span>
 * ```
 */
@Pipe({
  name: 'translateBoolean',
})
export class TranslateBooleanPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  transform(value: boolean, format: `${Format}` = Format.YesNo): string {
    const map = formatMapping[format];

    if (typeof value === 'boolean') {
      const translationId = findValueTranslation(value, map);
      if (translationId) {
        return this.translationService.getTranslation(translationId);
      }
    }

    return '-';
  }
}

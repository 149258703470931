<fmnts-modal-title>{{ 'leave_page_title' | translate }}</fmnts-modal-title>
<fmnts-modal-content>
  {{ 'leave_page_content' | translate }}
</fmnts-modal-content>
<fmnts-modal-actions>
  <button
    type="button"
    fmnts-button
    class="cancel-button"
    fill="solid"
    shape="rounded"
    (click)="close('cancel')"
  >
    {{ 'cancel' | translate }}
  </button>
  <button
    type="button"
    fmnts-button
    fill="solid"
    shape="rounded"
    color="primary"
    (click)="close('ok')"
  >
    {{ 'ok' | translate }}
  </button>
</fmnts-modal-actions>

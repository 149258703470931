import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppFeatureFlags } from '@app/core/config/feature-flag.service';
import {
  selectAuth,
  selectIsAuthenticated,
} from '@fmnts/shared/auth/data-access';
import { Store } from '@ngrx/store';
import { combineLatest, map, type Observable } from 'rxjs';

/**
 * Performs the redirect when accessing `<host>/`.
 *
 * @description
 * - If unauthenticated, it redirects to login-page
 * - Fundraiser users will be redirected to Next
 * - All other users will be redirected to the Cockpit
 */
@Injectable({ providedIn: 'root' })
export class RootRedirect {
  private readonly isAuthenticated$ = this.store.select(selectIsAuthenticated);
  private readonly auth$ = this.store.select(selectAuth);

  constructor(
    private readonly router: Router,
    private readonly featureFlags: AppFeatureFlags,
    private readonly store: Store,
  ) {}

  canActivate(): Observable<UrlTree> {
    return combineLatest([this.isAuthenticated$, this.auth$]).pipe(
      map(([isAuthenticated, auth]) => {
        if (!isAuthenticated || !auth) {
          return this.router.createUrlTree(['/auth', 'login']);
        }

        if (this.featureFlags.on('next') && auth.isFundraiser) {
          return this.router.createUrlTree(['/next']);
        }

        return this.router.createUrlTree(['/cockpit']);
      }),
    );
  }
}

import {
  AnimationTriggerMetadata,
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { timingByThemeTokens } from '@fmnts/components/core';

/**
 * Animation states for sheet
 */
export enum SheetAnimationState {
  /**
   * You can associate styles with this name to be used when the
   * element is detached from the application. For example,
   * when an `*ngIf` evaluates to false, the state of the associated
   * element is void.
   *
   * @see {@link state}
   */
  Void = 'void',
  /**
   * Sheet should be visible.
   */
  Visible = 'visible',
  /**
   * Sheet should be hidden.
   */
  Hidden = 'hidden',
}

/**
 * Animations used by the Sheet container.
 */
export const sheetAnimations: {
  readonly sheetState: AnimationTriggerMetadata;
} = {
  /** Animation that shows and hides a sheet. */
  sheetState: trigger('state', [
    state(
      [SheetAnimationState.Void, SheetAnimationState.Hidden].join(','),
      style({ transform: 'translateY(100%)' }),
    ),
    state(SheetAnimationState.Visible, style({ transform: 'translateY(0%)' })),
    transition(
      [
        `${SheetAnimationState.Visible} => ${SheetAnimationState.Void}`,
        `${SheetAnimationState.Visible} => ${SheetAnimationState.Hidden}`,
      ].join(','),
      group([
        animate(
          timingByThemeTokens({
            duration: 'duration.short-4',
            curve: 'ease.emphasized-accelerate',
          }),
        ),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
    transition(
      `${SheetAnimationState.Void} => ${SheetAnimationState.Visible}`,
      group([
        animate(
          timingByThemeTokens({
            duration: 'duration.medium-4',
            curve: 'ease.emphasized-decelerate',
          }),
        ),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
  ]),
};

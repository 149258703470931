import {
  ChangeDetectionStrategy,
  Component,
  Host,
  HostBinding,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { Nullish } from '@fmnts/core';
import { AvatarShape, AvatarSize } from './avatar-model';
import { AvatarComponent } from './avatar.component';

/**
 * Displays an avatar icon.
 *
 * @example
 * <fmnts-avatar-icon [image]="some-url"
 * ></fmnts-avatar-icon>
 *
 *
 * @example
 * // Renders the icon as alternative content
 * <fmnts-avatar-icon [image]="undefined"
 * >
 *  <fa-icon [icon]="undo"></fa-icon>
 * </fmnts-avatar-icon>
 */
@Component({
  selector: 'fmnts-avatar-icon',
  templateUrl: './avatar-icon.component.html',
  styleUrls: ['./avatar-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarIconComponent {
  @HostBinding('class.fmnts-avatar-icon')
  protected readonly componentClass = 'fmnts-avatar-icon';

  /**
   * Image that should be displayed.
   * In case that no image is provided, the alternative content is
   * displayed.
   */
  @Input() image: string | Nullish = null;

  /** Size of the icon */
  @Input()
  get size(): AvatarSize {
    return this._size ?? this._parent?.size ?? 'medium';
  }
  set size(value: AvatarSize | null) {
    this._size = value;
  }
  private _size: AvatarSize | null = null;

  /**
   * Controls the shape of the icon
   */
  @Input() shape: AvatarShape = 'circle';

  constructor(@Optional() @Host() private _parent: AvatarComponent | null) {}

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      !this.image && `${this.componentClass}--custom-content`,
      // Sizing
      `${this.componentClass}--${this.size}`,
      // Shape variants
      `${this.componentClass}--${this.shape}`,
    ]);
  }
}

import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { type LazyArg } from 'effect/Function';
import {
  RaiseNowClientConfig,
  RaiseNowSettings,
} from './raisenow-settings.service';

export function provideRaiseNowClient(opts: {
  /** Loads the configuration. */
  loadConfig: LazyArg<RaiseNowClientConfig>;
}): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: RaiseNowClientConfig,
      useFactory: opts.loadConfig,
    },
    RaiseNowSettings,
  ]);
}

import { ApiLocationMinimalAdapter } from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import { IApiLocationReporting } from '../api-model/api-model';
import { LocationReporting } from '../model/location-reporting';
import { ApiFundraiserMinimalAdapter } from './fundraiser.adapter';
import { ApiWorkshiftAdapter } from './workshift.adapter';

export class ApiLocationReportingAdapter implements Adapter<LocationReporting> {
  private readonly fundraiserAdapter = new ApiFundraiserMinimalAdapter();
  private readonly locationMinimalAdapter = new ApiLocationMinimalAdapter();
  private readonly workshiftAdapter = new ApiWorkshiftAdapter();

  adapt(apiDto: IApiLocationReporting): LocationReporting {
    const fundraiser = this.fundraiserAdapter.adapt(apiDto.fundraiser);
    const location = this.locationMinimalAdapter.adapt(apiDto.location);
    const workshift = this.workshiftAdapter.adapt(apiDto.work_shift);

    return new LocationReporting({
      campaignName: apiDto.campaign_name,
      checkIn: apiDto.createtime,
      customerName: apiDto.customer_name,
      organizationName: apiDto.organization_name,
      location,
      fundraiser,
      workshift,
    });
  }
}

import { Injectable, inject } from '@angular/core';
import { UserApi } from '@fmnts/api/cockpit';
import { Auth } from '@formunauts/shared/domain';
import { Observable, map } from 'rxjs';
import { AuthInfoAdapter } from './auth-info.adapter';
import { UserRepository } from './auth-repository.model';

@Injectable()
export class UserApiRepository extends UserRepository {
  private readonly userApi = inject(UserApi);
  private readonly authAdapter = new AuthInfoAdapter();

  getCurrentUser(): Observable<Auth.LegacyAuthInfo> {
    return this.userApi
      .fetchAuthInfo()
      .pipe(map((info) => this.authAdapter.adapt(info)));
  }
}

import { interpolatePlasma, quantize, scaleOrdinal, ScaleOrdinal } from 'd3';

/**
 * Color Schemes that can be used with Ordinal colors of d3
 *
 * @see https://d3js.org/d3-scale/ordinal
 */
export enum D3ColorSchemes {
  Plasma = 'plasma',
}

/**
 * @see https://d3js.org/d3-scale-chromatic/sequential
 *
 * @param n length of domain-items
 */
export function plasma(n: number): string[] {
  return quantize((t) => interpolatePlasma(t * 0.8 + 0.1), n).reverse();
}

/**
 * Uses one of the sequential schemes from d3, to calculate
 * the color
 *
 * @see [Sequential Schemes](https://d3js.org/d3-scale-chromatic/sequential)
 * @returns
 */
export function computeColorScale(
  domain: string[],
  scheme?: D3ColorSchemes,
): ScaleOrdinal<string, string, string> {
  const schemeFn = useScheme(scheme);

  return scaleOrdinal<string>()
    .domain(domain)
    .range(schemeFn(domain.length))
    .unknown('black');
}

function useScheme(schemeName?: D3ColorSchemes): (n: number) => string[] {
  switch (schemeName) {
    case D3ColorSchemes.Plasma:
      return plasma;
    default:
      return plasma;
  }
}

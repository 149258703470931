import { Provider } from '@angular/core';
import { FormDataTrashRepository } from './form-data-trash.repository';
import {
  FormDataTrashConfig,
  FormDataTrashImpl,
  FormDataTrashService,
} from './form-data-trash.service';

/**
 * The form data trash holds data that was deleted.
 */
export function provideFormDataTrash(opts: {
  /** Repository implementation. */
  repository: () => FormDataTrashRepository;
  /** Additional configuration. */
  config: FormDataTrashConfig;
}): Provider[] {
  return [
    {
      provide: FormDataTrashService,
      useClass: FormDataTrashImpl,
    },
    {
      provide: FormDataTrashRepository,
      useFactory: opts.repository,
    },
    {
      provide: FormDataTrashConfig,
      useValue: opts.config,
    },
  ];
}

/**
 * Escapes the input string for usage with the `RegExp` constructor.
 * This will ensure a valid regular expression by escaping special
 * characters like `()` or `{}`
 *
 * @param s String that should be escaped
 *
 * @returns
 * String with escaped regular expression characters.
 *
 * @example
 * escapeRegExpString('abcd') === 'abcd'
 * escapeRegExpString('(abcd)') === '\\(abcd\\)'
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 */
export function escapeRegExpString(s: string): string {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { FmntsCommonModule } from '@fmnts/common';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsFormFieldModule } from '@fmnts/components/form-field';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { FmntsInputModule } from '@fmnts/components/input';
import { FmntsLayoutModule } from '@fmnts/components/layout';
import {
  ModalActionsComponent,
  ModalContentComponent,
  ModalRef,
  ModalTitleComponent,
} from '@fmnts/components/modal';
import { FmntsRadioGroup, RadioCardComponent } from '@fmnts/components/radio';
import { FmntsSelectModule } from '@fmnts/components/select';
import {
  FmntsToastModule,
  TextOnlyToastData,
  Toaster,
} from '@fmnts/components/toast';
import { I18nModule, TranslationService } from '@fmnts/i18n';
import {
  SupportRequestAddWithDefaultsDto,
  SupportRequestDialogResult,
  SupportRequestService,
  SupportRequestType,
} from '@fmnts/shared/support/data-access';
import { SHARED_SUPPORT_UI_I18N_OPTIONS } from '@fmnts/shared/support/ui';
import {
  GeneralHttpError,
  HttpClientOrNetworkError,
} from '@formunauts/shared/errors/domain';
import {
  faCircleCheck,
  faDiamondExclamation,
} from '@fortawesome/pro-solid-svg-icons';

/**
 * Component that displays a modal for collecting a support request.
 */
@Component({
  selector: 'support-request-modal',
  templateUrl: './support-request-modal.component.html',
  styleUrls: ['./support-request-modal.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FmntsCommonModule,
    FmntsLayoutModule,
    FmntsFormFieldModule,
    FmntsSelectModule,
    FmntsButtonModule,
    ModalTitleComponent,
    ModalContentComponent,
    ModalActionsComponent,
    FmntsIconsModule,
    FmntsInputModule,
    I18nModule,
    CommonModule,
    FmntsToastModule,
    FmntsRadioGroup,
    RadioCardComponent,
  ],
})
export class SupportRequestModalComponent {
  private readonly supportRequests = inject(SupportRequestService);
  private readonly toaster = inject(Toaster);
  protected readonly ref =
    inject<ModalRef<never, SupportRequestDialogResult>>(ModalRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(FormBuilder);
  private readonly translation = inject(TranslationService);
  protected readonly _i18n = inject(SHARED_SUPPORT_UI_I18N_OPTIONS);

  private readonly defaultUserData = this.supportRequests.getDefaultUserData();

  protected readonly supportRequestForm = this.fb.group({
    email: [
      this.defaultUserData.email,
      [Validators.required, Validators.email],
    ],
    description: ['', Validators.required],
    type: ['', Validators.required],
  });

  protected readonly supportRequestTypes = Object.values(SupportRequestType);

  protected submit(): void {
    if (!this.supportRequestForm.valid) {
      return;
    }

    const result: SupportRequestAddWithDefaultsDto = this.supportRequestForm
      .value as SupportRequestAddWithDefaultsDto;

    this.supportRequests
      .add(result)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this._onRequestAddSuccess();
        },
        error: (error) => {
          this._onRequestAddError(error);
        },
      });
  }

  protected showError(controlName: string): boolean {
    const control = this.supportRequestForm.get(controlName);
    return control ? control.invalid && control.touched : false;
  }

  private _onRequestAddSuccess() {
    const successToast: TextOnlyToastData = {
      title: this.translation.getTranslation('request_support_success_title'),
      message: this.translation.getTranslation(
        'request_support_success_description',
      ),
      icon: faCircleCheck,
      color: 'success',
    };

    this.toaster.open(successToast, { duration: 5000 });
    this.ref.close('ok');
  }

  private _onRequestAddError(error: unknown) {
    const errorToast: TextOnlyToastData = {
      title: this.translation.getTranslation('request_support_error_title'),
      message: this.translation.getTranslation('error_occurred'),
      icon: faDiamondExclamation,
      color: 'danger',
    };

    if (error instanceof GeneralHttpError) {
      if (error.error instanceof HttpClientOrNetworkError) {
        errorToast.message = this.translation.getTranslation('network_error');
      }
    }

    this.toaster.open(errorToast, { duration: 5000 });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays a collection of card actions.
 */
@Component({
  selector: 'fmnts-card-actions',
  template: '<ng-content></ng-content>',
  styleUrls: ['./card-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardActionsComponent {
  @HostBinding('class.fmnts-card-actions') private readonly componentClass =
    'fmnts-card-actions';
}

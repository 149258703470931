import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeComponent } from './badge.component';
import { CounterBadgeComponent } from './counter-badge.component';

const declarations = [BadgeComponent, CounterBadgeComponent];

@NgModule({
  declarations,
  exports: declarations,
  imports: [CommonModule],
})
export class FmntsBadgeModule {}

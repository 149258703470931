import {
  EnvironmentProviders,
  Provider,
  makeEnvironmentProviders,
} from '@angular/core';
import { ProviderFeature, providerFeature } from '@fmnts/common';
import {
  ContextProviderResolveConfig,
  SUPPORT_REQUEST_CONTEXT_RESOLVE_CONFIG,
} from './support-request-context';
import { SUPPORT_REQUEST_USER_DATA_RESOLVE_FN } from './support-request-defaults';
import {
  SupportRequestDialog,
  SupportRequestDialogService,
} from './support-request-dialog.service';
import {
  SupportRequestContext,
  SupportRequestUserData,
} from './support-request.entity';
import { SupportRequestRepository } from './support-request.repository';
import { SupportRequestService } from './support-request.service';

enum SharedSupportRequestFeatureKind {
  Defaults,
  ContextProvider,
  Dialog,
}

/**
 * A feature for use when configuring `provideSharedSupportDataAccess`.
 */
type SharedSupportDataAccessFeature<
  TKind extends SharedSupportRequestFeatureKind,
> = ProviderFeature<TKind>;

const { make: makeSharedSupportDataAccessFeature } =
  providerFeature<SharedSupportRequestFeatureKind>();

/**
 * Provides the support request data access.
 */
export function provideSharedSupportDataAccess<
  TContext extends SupportRequestContext,
>(
  {
    repository,
  }: {
    /** Factory function to provide the support request repository. */
    repository: () => SupportRequestRepository<TContext>;
  },
  ...features: SharedSupportDataAccessFeature<SharedSupportRequestFeatureKind>[]
): EnvironmentProviders {
  const providers: Provider[] = [
    SupportRequestService,
    { provide: SupportRequestRepository, useFactory: repository },
  ];

  return makeEnvironmentProviders([
    ...providers,
    ...features.flatMap((f) => f.providers),
  ]);
}

/**
 * Allows the collection of additional context for support requests.
 *
 * @param configFactory Factory function providing the context resolve config.
 */
export function withAdditionalContext<T extends SupportRequestContext>(
  configFactory: () => ContextProviderResolveConfig<T>,
): SharedSupportDataAccessFeature<SharedSupportRequestFeatureKind.ContextProvider> {
  return makeSharedSupportDataAccessFeature(
    SharedSupportRequestFeatureKind.ContextProvider,
    [
      {
        provide: SUPPORT_REQUEST_CONTEXT_RESOLVE_CONFIG,
        useFactory: configFactory,
      },
    ],
  );
}

/**
 * Allows the defaults for the user data of a new support request to be configured
 * from external dependencies.
 */
export function withDefaultsForSupportRequestForm({
  userData,
}: {
  /**
   * A function that is called whenever the defaults for a new support request
   * are acquired.
   */
  userData: () => Partial<SupportRequestUserData>;
}): SharedSupportDataAccessFeature<SharedSupportRequestFeatureKind.Defaults> {
  return makeSharedSupportDataAccessFeature(
    SharedSupportRequestFeatureKind.Defaults,
    [{ provide: SUPPORT_REQUEST_USER_DATA_RESOLVE_FN, useValue: userData }],
  );
}

/**
 * Adds the support request dialog feature that allows to open
 * a support request dialog from anywhere by calling
 * `SupportRequestDialogService.open()`.
 */
export function withSupportRequestDialog({
  dialog,
}: {
  /** Factory to provide the `SupportRequestDialog` DI token. */
  dialog: () => SupportRequestDialog;
}): SharedSupportDataAccessFeature<SharedSupportRequestFeatureKind.Dialog> {
  return makeSharedSupportDataAccessFeature(
    SharedSupportRequestFeatureKind.Dialog,
    [
      SupportRequestDialogService,
      { provide: SupportRequestDialog, useFactory: dialog },
    ],
  );
}

import { hasModifierKey } from '@angular/cdk/keycodes';
import { OverlayRef } from '@angular/cdk/overlay';
import { filterAnimationPhaseEvent } from '@fmnts/components/core';
import {
  Observable,
  Subject,
  debounce,
  filter,
  merge,
  of,
  take,
  timer,
} from 'rxjs';
import { SheetAnimationState } from './sheet-animations';
import { SheetContainerComponent } from './sheet-container.component';

/**
 * Reference to a sheet that was attached from the sheet service.
 */
export class SheetRef<TComponent = unknown, TResult = unknown> {
  /**
   * Instance of the component making up the content of the sheet.
   *
   * This is `null` if a `TemplateRef` was used.
   */
  get instance(): TComponent | null {
    return this._instance;
  }
  set instance(value: TComponent | null) {
    this._instance = value;
  }
  private _instance: TComponent | null = null;

  /**
   * Instance of the component into which the sheet content is projected.
   * @internal
   */
  containerInstance: SheetContainerComponent;

  /** Whether the user is allowed to close the sheet. */
  disableClose: boolean | undefined;

  /** Subject for notifying the user that the sheet has been dismissed. */
  private readonly _afterDismissed = new Subject<TResult | undefined>();

  /** Subject for notifying the user that the sheet has opened and appeared. */
  private readonly _afterOpened = new Subject<void>();

  /** Result to be passed down to the `afterDismissed` stream. */
  private _result: TResult | undefined;

  /** Subject to initiate disposing in the given amount of time, then complete */
  private _disposeIn = new Subject<number>();

  constructor(
    /**
     * Instance of the container component that
     * hosts the sheet content.
     */
    containerInstance: SheetContainerComponent,
    /**
     * Reference to the overlay
     */
    private _overlayRef: OverlayRef,
  ) {
    this.containerInstance = containerInstance;
    this.disableClose = containerInstance.sheetConfig.disableClose;

    // Emit when opening animation completes
    containerInstance._animationStateChanged
      .pipe(
        filterAnimationPhaseEvent(
          'done',
          (event) => event.toState === SheetAnimationState.Visible,
        ),
        take(1),
      )
      .subscribe(() => {
        this._afterOpened.next();
        this._afterOpened.complete();
      });

    // Dispose when closing animation is complete
    containerInstance._animationStateChanged
      .pipe(
        filterAnimationPhaseEvent(
          'done',
          (event) => event.toState === SheetAnimationState.Hidden,
        ),
        take(1),
      )
      .subscribe(() => {
        this._disposeIn.next(0);
      });

    // Emit result after the overlay has been detached.
    _overlayRef
      .detachments()
      .pipe(take(1))
      .subscribe(() => {
        this._afterDismissed.next(this._result);
        this._afterDismissed.complete();
      });

    // Dismiss sheet on ESC or backdrop click
    merge(
      _overlayRef.backdropClick(),
      _overlayRef
        .keydownEvents()
        .pipe(filter((event) => event.key === 'Escape')),
    ).subscribe((event) => {
      if (
        !this.disableClose &&
        (event.type !== 'keydown' || !hasModifierKey(event as KeyboardEvent))
      ) {
        event.preventDefault();
        this.dismiss();
      }
    });

    // Dispose overlay reference, then complete
    this._disposeIn
      .pipe(
        debounce((v) => (v > 0 ? timer(v) : of(0))),
        take(1),
      )
      .subscribe(() => {
        _overlayRef.dispose();
        this._disposeIn.complete();
      });
  }

  /**
   * Dismisses the sheet.
   * @param result Data to be passed back to the sheet opener.
   */
  dismiss(result?: TResult): void {
    if (this._afterDismissed.closed) {
      return;
    }

    // Transition the backdrop in parallel to the sheet.
    this.containerInstance._animationStateChanged
      .pipe(filterAnimationPhaseEvent('start'), take(1))
      .subscribe((event) => {
        // The logic that disposes the overlay depends on the exit animation completing, however
        // it isn't guaranteed if the parent view is destroyed while it's running. Add a fallback
        // timeout which will clean everything up if the animation hasn't fired within the specified
        // amount of time plus 100ms. We don't need to run this outside the NgZone, because for the
        // vast majority of cases the timeout will have been cleared before it has fired.
        this._disposeIn.next(event.totalTime + 100);
        this._overlayRef.detachBackdrop();
      });

    this._result = result;
    this.containerInstance.exit();
  }

  /** Gets an observable that is notified when the sheet is finished closing. */
  afterDismissed(): Observable<TResult | undefined> {
    return this._afterDismissed;
  }

  /** Gets an observable that is notified when the sheet has opened and appeared. */
  afterOpened(): Observable<void> {
    return this._afterOpened;
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  backdropClick(): Observable<MouseEvent> {
    return this._overlayRef.backdropClick();
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  keydownEvents(): Observable<KeyboardEvent> {
    return this._overlayRef.keydownEvents();
  }
}

import { Directive, HostBinding } from '@angular/core';

/**
 * Directive for labels.
 * Intended to be used with
 * - `fmnts-form-field`.
 * - `fmnts-text-field`.
 */
@Directive({
  selector: 'fmnts-label',
})
export class FmntsLabelDirective {
  @HostBinding('class.fmnts-label') protected readonly componentClass =
    'fmnts-label';
}

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { timingByThemeTokens } from '@fmnts/components/core';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/**
 * Displays a sidebar section.
 */
@Component({
  selector: 'fmnts-sidebar-section, [fmnts-sidebar-section]',
  templateUrl: './sidebar-section.component.html',
  styleUrls: [
    './sidebar-item.component.scss',
    './sidebar-section.component.scss',
  ],
  animations: [
    trigger('sideBarAnimation', [
      state('from', style({ 'max-height': '0px', overflow: 'hidden' })),
      state(
        'to',
        style({
          'max-height': '1000px',
          display: 'flex',
        }),
      ),
      transition(
        'from <=> to',
        animate(
          timingByThemeTokens({
            duration: 'duration.medium-1',
            curve: 'ease.standard',
          }),
        ),
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidebarSectionComponent {
  @HostBinding('class.fmnts-sidebar-section')
  private readonly componentClass = 'fmnts-sidebar-section';

  /** @internal */
  protected readonly _iconSectionToggle = faChevronRight;

  protected expanded?: boolean;

  protected showItems(): void {
    this.expanded = !this.expanded;
  }
}

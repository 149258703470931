import { HttpErrorResponse } from '@angular/common/http';
import { isString } from '@fmnts/core';

function errorMessageFromHttpErrorResponse(
  httpError: HttpErrorResponse,
): string {
  return isString(httpError.error)
    ? `Server returned code ${httpError.status} with body "${httpError.error}"`
    : httpError.message;
}

/**
 * Error on an unknown or unspecified API occured.
 */
export class UnknownApiError extends Error {
  override name = 'UnknownApiError';
  /** HTTP error status code. */
  readonly statusCode: number;
  /** Error details received from API. */
  readonly details: unknown;

  constructor({ httpError }: { httpError: HttpErrorResponse }) {
    super(errorMessageFromHttpErrorResponse(httpError));
    this.statusCode = httpError.status;
    this.details = httpError.error;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';

/**
 * Status indicator component.
 */
@Component({
  selector: 'fmnts-status-indicator',
  templateUrl: 'status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIndicatorComponent {
  @HostBinding('class.fmnts-status-indicator')
  protected readonly componentClass = 'fmnts-status-indicator';

  /**
   * Status indicator color.
   */
  @Input()
  color?: ThemeColor;

  /**
   * Color filling scheme.
   */
  @Input()
  fill: 'solid' | 'outline' = 'solid';

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      `${this.componentClass}--fill-${this.fill}`,
      this.color && `${this.componentClass}--${this.color}`,
    ]);
  }
}

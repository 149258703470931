import { Params } from '@angular/router';
import { OperatorFunction, map } from 'rxjs';

type Mapping<T> = { [P in keyof T]?: (value: T[P], key: P) => Params };

/**
 * Operator that maps object properties to `Params`.
 *
 * @param mapping Object that defines mapper functions for keys
 */
export function mapToParams<
  T extends Record<string | number | symbol, any>,
  K extends keyof T = keyof T,
>(mapping: Mapping<T>): OperatorFunction<Partial<T>, Params> {
  return map((filters) =>
    (Object.entries(filters) as [K, T[K]][]).reduce<Params>(
      (values, [key, value]) => {
        const mapperFn = mapping[key];

        // If a mapper function is defined for this key, call it
        return typeof mapperFn !== 'function'
          ? values
          : {
              ...values,
              ...mapperFn(value, key),
            };
      },
      {},
    ),
  );
}

/**
 * Mapper that does not perform any transformation to the value
 * and that returns an object with `key` as the property name
 * and `value` as the value.
 *
 * @param value
 * @param key
 */
export const noTransformParamMapper = <T = any>(
  value: T,
  key: string,
): { [k: typeof key]: T } => ({
  [key]: value,
});

/**
 * @param newKey The key to use to use for the property name
 *
 * @returns
 * Mapper function, that maps a value to an object containing the
 * given `newKey` as a property with the passed value.
 */
export function keyParamMapper<T = any>(newKey: string) {
  return (value: T): { [k: typeof newKey]: T } => ({ [newKey]: value });
}

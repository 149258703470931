import { BooleanInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ColorVariant } from './ci-colors';

type LogoVariant = 'icon' | 'regular' | 'display';

/**
 * Component that displays the formunauts logo.
 */
@Component({
  selector: 'fmnts-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  private componentClass = 'formunauts-logo';

  /** Variant of the logo that should be used. */
  @Input() variant: LogoVariant = 'regular';
  /** Color theme of the logo that should be used. */
  @Input() color: ColorVariant = 'primary';

  /** If `true`, the thrusts are animated. */
  @Input() animateThrusts: BooleanInput;

  /** Whether the claim should be visible. */
  @Input() tagline: BooleanInput;

  @HostBinding('class')
  public get cssClass(): string {
    return classnames([
      this.componentClass,
      `${this.componentClass}--${this.variant}`,
      `${this.componentClass}--color-${this.color}`,
    ]);
  }
}

/* eslint-disable @angular-eslint/no-host-metadata-property */
import { AnimationEvent } from '@angular/animations';
import {
  ConfigurableFocusTrap,
  ConfigurableFocusTrapFactory,
} from '@angular/cdk/a11y';
import { coerceArray } from '@angular/cdk/coercion';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import {
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  Inject,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { sheetAnimations, SheetAnimationState } from './sheet-animations';
import { SheetConfig } from './sheet-config';

/**
 * This component is used as a container to display a sheet.
 * It wires up communication between the content that should
 * be displays and the sheet reference.
 */
@Component({
  selector: 'fmnts-sheet-container',
  templateUrl: './sheet-container.component.html',
  styleUrls: ['./sheet-container.component.scss'],
  // In Ivy embedded views will be change detected from their declaration place, rather than where
  // they were stamped out. This means that we can't have the sheet container be OnPush,
  // because it might cause the sheets that were opened from a template not to be out of date.
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  animations: [sheetAnimations.sheetState],
  host: {
    tabindex: '-1',
    role: 'dialog',
    'aria-modal': 'true',
    '[attr.aria-label]': 'sheetConfig?.ariaLabel',
    '[@state]': '_animationState',
    '(@state.start)': '_onAnimationStart($event)',
    '(@state.done)': '_onAnimationDone($event)',
  },
})
export class SheetContainerComponent implements OnDestroy {
  @HostBinding('class.fmnts-sheet-container')
  protected readonly componentClass = 'fmnts-sheet-container';

  /** The portal outlet inside of this container into which the content will be loaded. */
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet!: CdkPortalOutlet;

  /**
   * The state of the sheet animations.
   *
   * @internal
   */
  _animationState: SheetAnimationState = SheetAnimationState.Void;

  /**
   * Emits whenever the state of the animation changes.
   *
   * @internal
   */
  _animationStateChanged = new EventEmitter<AnimationEvent>();

  /** The class that traps and manages focus within the sheet. */
  private _focusTrap?: ConfigurableFocusTrap;

  /** Element that was focused before the sheet was opened. */
  private _elementFocusedBeforeOpened: HTMLElement | null = null;

  /** Whether the component has been destroyed. */
  private _destroyed?: boolean;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _focusTrapFactory: ConfigurableFocusTrapFactory,
    private readonly _ngZone: NgZone,
    @Inject(DOCUMENT) private _document: Document,
    /**
     * The sheet configuration, injected by the Sheet service.
     * @internal
     */
    public readonly sheetConfig: SheetConfig,
  ) {}

  /**
   * Begin animation of sheet entrance into view.
   */
  enter(): void {
    if (this._destroyed) {
      return;
    }

    this._animationState = SheetAnimationState.Visible;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Begin animation of the sheet exiting from view.
   */
  exit(): void {
    if (this._destroyed) {
      return;
    }

    this._animationState = SheetAnimationState.Hidden;
    this._changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this._destroyed = true;
  }

  /**
   * Attach a component portal as content to this sheet container.
   *
   * @param portal Portal to be attached to the portal outlet.
   * @returns
   * Reference to the created component.
   */
  attachComponentPortal<TComponent>(
    portal: ComponentPortal<TComponent>,
  ): ComponentRef<TComponent> {
    this._validatePortalAttached();
    this._setPanelClass();
    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachComponentPortal(portal);
  }

  /**
   * Attach a template portal as content to this sheet container.
   *
   * @param portal Portal to be attached.
   * @returns
   * Reference to the created embedded view.
   */
  attachTemplatePortal<TContext>(
    portal: TemplatePortal<TContext>,
  ): EmbeddedViewRef<TContext> {
    this._validatePortalAttached();
    this._setPanelClass();
    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachTemplatePortal(portal);
  }

  /**
   * @internal
   */
  _onAnimationDone(event: AnimationEvent): void {
    if (event.toState === SheetAnimationState.Hidden) {
      this._restoreFocus();
    } else if (event.toState === SheetAnimationState.Visible) {
      void this._trapFocus();
    }

    this._animationStateChanged.emit(event);
  }

  /**
   * @internal
   */
  _onAnimationStart(event: AnimationEvent): void {
    this._animationStateChanged.emit(event);
  }

  private _validatePortalAttached() {
    if (this._portalOutlet.hasAttached()) {
      throw Error(
        'Attempting to attach sheet content after content is already attached',
      );
    }
  }

  private _setPanelClass() {
    const element: HTMLElement = this._elementRef.nativeElement;
    element.classList.add(...coerceArray(this.sheetConfig.panelClass || []));
  }

  /**
   * Moves the focus inside the focus trap.
   * Use the provided `autoFocus` function to set up the focus or
   * focus the first focusable element.
   * If it can't be focused, the container received focus.
   */
  private async _trapFocus() {
    const element = this._elementRef.nativeElement;

    // Create a focus trap for the container
    if (!this._focusTrap) {
      this._focusTrap = this._focusTrapFactory.create(element);
    }

    // Call the provided function to let users focus an element
    if (typeof this.sheetConfig.autoFocus === 'function') {
      this.sheetConfig.autoFocus(element);
      return;
    }

    // Otherwise, set up the initial focus element
    switch (this.sheetConfig.autoFocus) {
      case 'container':
        const activeElement = _getFocusedElementPierceShadowDom();
        if (activeElement !== element && !element.contains(activeElement)) {
          element.focus();
        }
        break;
      case 'first-tabbable':
        await this._focusTrap.focusFirstTabbableElementWhenReady();
        break;
    }
  }

  /**
   * Restores focus to the element that was focused before the
   * sheet was opened.
   */
  private _restoreFocus() {
    const toFocus = this._elementFocusedBeforeOpened;

    if (
      this.sheetConfig.restoreFocus &&
      typeof toFocus?.focus === 'function' &&
      this._shouldRestoreFocus()
    ) {
      toFocus.focus();
    }

    this._focusTrap?.destroy();
  }

  /**
   * Saves a reference to the element that was focused before the sheet
   * was opened.
   */
  private _savePreviouslyFocusedElement() {
    this._elementFocusedBeforeOpened = _getFocusedElementPierceShadowDom();

    // Focus container
    this._ngZone.runOutsideAngular(() => {
      void Promise.resolve().then(() => this._elementRef.nativeElement.focus());
    });
  }

  /**
   * Make sure that focus is still inside the sheet or is on the body (usually because a
   * non-focusable element like the backdrop was clicked) before moving it. It's possible that
   * the consumer moved it themselves before the animation was done, in which case we shouldn't
   * do anything.
   *
   * @returns
   * `true` if the focus should be restored
   */
  private _shouldRestoreFocus() {
    const activeElement = _getFocusedElementPierceShadowDom();
    const element = this._elementRef.nativeElement;

    return (
      !activeElement ||
      activeElement === this._document.body ||
      activeElement === element ||
      element.contains(activeElement)
    );
  }
}

import { Directionality } from '@angular/cdk/bidi';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  StaticProvider,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { of } from 'rxjs';
import { FMNTS_SHEET_DATA, SheetConfig } from './sheet-config';
import { SheetContainerComponent } from './sheet-container.component';
import { SheetRef } from './sheet-ref';

/**
 * Injection token that can be used to specify default sheet options.
 */
export const FMNTS_SHEET_DEFAULT_OPTIONS = new InjectionToken<SheetConfig>(
  'fmnts.components.sheet.default-options',
);

interface SheetTemplateRefContext<TData, TResult> {
  $implicit: TData;
  /**
   * Reference to the sheet. When using a `TemplateRef`
   * the `instance` is `null`.
   */
  sheetRef: SheetRef<never, TResult>;
}

/**
 * The Sheet service can be used to open a sheet.
 */
@Injectable()
export class Sheet implements OnDestroy {
  /**
   * Reference to the currently opened sheet.
   */
  get _openedSheetRef(): SheetRef | null {
    return this._parentSheet?._openedSheetRef ?? this._sheetRefAtThisLevel;
  }
  set _openedSheetRef(value: SheetRef | null) {
    if (this._parentSheet) {
      this._parentSheet._openedSheetRef = value;
    } else {
      this._sheetRefAtThisLevel = value;
    }
  }
  private _sheetRefAtThisLevel: SheetRef | null = null;

  constructor(
    /** Service for creating overlays */
    private readonly _overlay: Overlay,
    private readonly _injector: Injector,
    /** Potential parent sheet service */
    @Optional() @SkipSelf() private readonly _parentSheet: Sheet,
    /**
     * Default options that may be provided using the injection
     * token `FMNTS_SHEET_DEFAULT_OPTIONS`.
     */
    @Optional()
    @Inject(FMNTS_SHEET_DEFAULT_OPTIONS)
    private readonly _defaultOptions?: SheetConfig,
  ) {
    this._defaultOptions = this._defaultOptions ?? new SheetConfig<null>();
  }

  ngOnDestroy(): void {
    // Only close sheets at this level on destroy
    // since the parent service may still be active.
    this._sheetRefAtThisLevel?.dismiss();
  }

  /**
   * Opens a sheet containing the given component.
   *
   * @param component Type of the component to load into the sheet.
   * @param config Extra configuration options.
   *
   * @returns Reference to the newly-opened sheet.
   */
  open<TComponent, TData = unknown, TResult = unknown>(
    component: ComponentType<TComponent>,
    config?: SheetConfig<TData>,
  ): SheetRef<TComponent, TResult>;

  /**
   * Opens a sheet containing the given template.
   *
   * @param template TemplateRef to instantiate as the sheet content.
   * @param config Extra configuration options.
   *
   * @returns Reference to the newly-opened sheet.
   */
  open<TContext, TData = unknown, TResult = unknown>(
    template: TemplateRef<TContext>,
    config?: SheetConfig<TData>,
  ): SheetRef<never, TResult>;

  open<TInstance, TData = unknown, TResult = unknown>(
    componentOrTemplateRef:
      | ComponentType<TInstance>
      | TemplateRef<SheetTemplateRefContext<TData, TResult>>,
    config?: SheetConfig<TData>,
  ): SheetRef<TInstance, TResult | unknown> {
    const _config = _applyConfigDefaults(
      this._defaultOptions || new SheetConfig(),
      config,
    );
    const overlayRef = this._createOverlay(_config);
    const container = this._attachContainer(overlayRef, _config);
    const ref = this._attachSheetContent(
      componentOrTemplateRef,
      container,
      overlayRef,
      _config,
    );

    // When the sheet is dismissed, clear the reference to it.
    ref.afterDismissed().subscribe(() => {
      // Clear the sheet ref if it hasn't already been replaced by a newer one.
      if (this._openedSheetRef === ref) {
        this._openedSheetRef = null;
      }
    });

    if (this._openedSheetRef) {
      // If a sheet is already in view, dismiss it and enter the
      // new sheet after exit animation is complete.
      this._openedSheetRef
        .afterDismissed()
        .subscribe(() => ref.containerInstance.enter());
      this._openedSheetRef.dismiss();
    } else {
      // If no sheet is in view, enter the new sheet.
      ref.containerInstance.enter();
    }

    this._openedSheetRef = ref as SheetRef<unknown, unknown>;

    return ref;
  }

  /**
   * Dismisses the currently-visible sheet.
   *
   * @param result Data to pass to the sheet instance.
   */
  dismiss<R = unknown>(result?: R): void {
    this._openedSheetRef?.dismiss(result);
  }

  /**
   * Creates a new overlay from the given `config`.
   *
   * @param config The sheet config.
   * @returns The overlay reference
   */
  private _createOverlay(config: SheetConfig): OverlayRef {
    const overlayConfig = this._createOverlayConfig(config);
    return this._overlay.create(overlayConfig);
  }

  /**
   * Creates an overlay config from a sheet config.
   *
   * @param config The sheet configuration.
   * @returns The overlay configuration.
   */
  private _createOverlayConfig(config: SheetConfig): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      direction: config.direction,
      hasBackdrop: config.hasBackdrop,
      disposeOnNavigation: config.closeOnNavigation,
      maxWidth: '100%',
      scrollStrategy:
        config.scrollStrategy ?? this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .global()
        .centerHorizontally()
        .bottom('0'),
    });

    if (config.backdropClass) {
      overlayConfig.backdropClass = config.backdropClass;
    }

    return overlayConfig;
  }

  /**
   * Attaches the sheet container component to the overlay.
   *
   * @param overlayRef
   * Reference to the overlay to which the container should be attached.
   *
   * @param config The sheet configuragion.
   *
   * @returns
   * Instance of the created and attached sheet container component.
   */
  private _attachContainer(
    overlayRef: OverlayRef,
    config: SheetConfig,
  ): SheetContainerComponent {
    const userInjector = config?.viewContainerRef?.injector;
    const injector = Injector.create({
      parent: userInjector ?? this._injector,
      providers: [{ provide: SheetConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(
      SheetContainerComponent,
      config.viewContainerRef,
      injector,
    );
    const containerRef = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  /**
   * Attaches the user-provided content (`Component` or `TemplateRef`) to
   * the already-created sheet container.
   *
   * @param componentOrTemplateRef
   * The type of component being loaded into the sheet, or a TemplateRef to instantiate as the content.
   *
   * @param sheetContainer Reference to the sheet container.
   * @param overlayRef Reference to the overlay in which the sheet resides.
   * @param config Sheet configuration.
   *
   * @returns
   * A newly-created `SheetRef` that should be returned to the user.
   */
  private _attachSheetContent<TInstance, TData = unknown, TResult = unknown>(
    componentOrTemplateRef:
      | ComponentType<TInstance>
      | TemplateRef<SheetTemplateRefContext<TData, TResult>>,
    sheetContainer: SheetContainerComponent,
    overlayRef: OverlayRef,
    config: SheetConfig<TData>,
  ): SheetRef<TInstance, TResult | unknown> {
    // Create a reference to the sheet we're creating in order to give
    // the user a handle to modify and close it.
    const ref = new SheetRef<TInstance, TResult | unknown>(
      sheetContainer,
      overlayRef,
    );

    if (componentOrTemplateRef instanceof TemplateRef) {
      sheetContainer.attachTemplatePortal(
        new TemplatePortal<SheetTemplateRefContext<unknown, TResult>>(
          componentOrTemplateRef,
          null as unknown as ViewContainerRef,
          {
            $implicit: config.data,
            sheetRef: ref as SheetRef<never, TResult>,
          },
        ),
      );
    } else {
      const injector = this._createInjector(config, ref, sheetContainer);
      const contentRef = sheetContainer.attachComponentPortal(
        new ComponentPortal(
          componentOrTemplateRef,
          config.viewContainerRef,
          injector,
        ),
      );
      ref.instance = contentRef.instance;
    }

    return ref;
  }

  /**
   * Creates an injector to be used inside of a sheet component.
   *
   * @param config Config that was used to create the sheet.
   * @param sheetRef Reference to the sheet.
   * @param sheetContainer Reference to the container component.
   *
   * @returns
   * DI Injector for the sheet content component.
   */
  private _createInjector<T>(
    config: SheetConfig,
    sheetRef: SheetRef<T>,
    sheetContainer: SheetContainerComponent,
  ): Injector {
    const userInjector = config?.viewContainerRef?.injector;

    // The sheet container should be provided, as the sheet container and the sheet's
    // content are created out of the same `ViewContainerRef` and as such, are siblings
    // for injector purposes. To allow the hierarchy that is expected, the sheet
    // container is explicitly provided in the injector.
    const providers: StaticProvider[] = [
      { provide: SheetRef, useValue: sheetRef },
      { provide: FMNTS_SHEET_DATA, useValue: config.data },
      { provide: SheetContainerComponent, useValue: sheetContainer },
    ];

    // Provide the passed directionality, if not already provided.
    if (
      config.direction &&
      !userInjector?.get<Directionality | null>(Directionality, null)
    ) {
      providers.push({
        provide: Directionality,
        useValue: { value: config.direction, change: of() },
      });
    }

    return Injector.create({
      parent: userInjector ?? this._injector,
      providers,
    });
  }
}

/**
 * Applies default options to the sheet config.
 *
 * @param defaults Object containing the default values to which to fall back.
 * @param config The configuration to which the defaults will be applied.
 *
 * @returns The new configuration object with defaults applied.
 */
function _applyConfigDefaults(
  defaults: SheetConfig,
  config?: SheetConfig,
): SheetConfig<unknown> {
  return { ...defaults, ...config };
}

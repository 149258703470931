import { Injectable } from '@angular/core';
import { selectAuth } from '@fmnts/shared/auth/data-access';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { CustomerRootActions } from '../customer.actions';

@Injectable()
export class CustomerAuthEffects {
  updateAssignedCustomerIdFromAuth$ = createEffect(() => {
    return this.store.select(selectAuth).pipe(
      map((auth) =>
        auth
          ? CustomerRootActions.setAssignedCustomer({
              customerId: auth.customerId,
            })
          : CustomerRootActions.unsetAssignedCustomer(),
      ),
    );
  });

  constructor(private store: Store) {}
}

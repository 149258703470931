import { Pipe, PipeTransform } from '@angular/core';

export type ToValuePipeMapping<Keys extends string, Value> = Record<
  Keys,
  Value
>;

/**
 * Maps an `input` value to one of the values from the mapping.
 *
 * @example
 * {{ 'yolo' | mapString: {'yolo': 'swag'} }}
 * // --> 'swag'
 */
@Pipe({
  name: 'mapString',
})
export class MapToValuePipe implements PipeTransform {
  /**
   * Maps the given `input` to the corresponding value in
   * `mapping` for this `input`.
   *
   * @param input Input value
   * @param mapping Mapping
   * @param fallback Fallback
   *
   * @returns
   * Value that the `mapping` specified for the given `input`,
   * or `fallback` if none provided.
   */
  transform<TInput extends string, R>(
    input: TInput,
    mapping: ToValuePipeMapping<TInput, R>,
    fallback?: R,
  ): R {
    return mapping[input] ?? (fallback as R);
  }
}

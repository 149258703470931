/**
 * This shuffle function uses an improved variant of the
 * Fisher–Yates algorithm by Durstenfield and has a
 * time complexity of O(n)
 *
 * @param array An array of items of any kind, can also be mixed
 *
 * @returns An array with shuffled items
 *
 * @example
 * // same type shuffle
 * shuffle([0,1,2,3])
 * // mixed types shuffle
 * shuffle(['a', 0, 'b'])
 *
 * @link https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
 */
export function shuffle<T>(array: T[]): T[] {
  const shuffled = array.slice();

  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'fmnts-card-title',
  template: '<ng-content></ng-content>',
  styleUrls: ['./card-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardTitleComponent {
  @HostBinding('class.fmnts-card-title') private readonly componentClass =
    'fmnts-card-title';
}

@Component({
  selector: 'fmnts-card-subtitle',
  template: '<ng-content></ng-content>',
  styleUrls: ['./card-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardSubtitleComponent {
  @HostBinding('class.fmnts-card-subtitle') private readonly componentClass =
    'fmnts-card-subtitle';
}

import { Adapter } from '@fmnts/core';
import { IApiAsset } from '../api-model/api-model';
import { Asset } from '../model/asset';

export class ApiAssetAdapter implements Adapter<Asset> {
  public adapt(apiDto: IApiAsset): Asset {
    return new Asset({
      id: `${apiDto.id}`,
      file: apiDto.file,
    });
  }
}

export interface IAssetDto {
  id: string;
  file: string;
}

export class AssetToApiAdapter implements Adapter<IAssetDto> {
  adapt(asset: Asset): IAssetDto {
    return {
      id: asset.id,
      file: asset.file,
    };
  }
}

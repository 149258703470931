import { FactoryProvider, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export interface ImageContent {
  /** size in bytes */
  readonly size: number;

  /** name of image */
  readonly name?: string;

  /** modified image */
  readonly modified?: Blob;

  /** original image */
  readonly original?: File;
}

/**
 * Creates an `ImageContent` from a given original `file`.
 */
export function imageContentFromFile(
  original: File,
): ImageContent & Required<Omit<ImageContent, 'modified'>> {
  return { original, name: original.name, size: original.size };
}

export interface ImageSelectionModelChange {
  selection: ImageContent;

  source: unknown;

  oldValue?: ImageContent;
}

@Injectable()
export class ImageSelectionModel implements OnDestroy {
  private readonly _selectionChanged = new Subject<ImageSelectionModelChange>();

  selection?: ImageContent;
  oldValue?: ImageContent;

  public readonly selectionChanged = this._selectionChanged.asObservable();

  /**
   * Updates the current selection in the model.
   * @param value New selection that should be assigned.
   * @param source Object that triggered the selection change.
   */
  updateSelection(value: ImageContent, source: unknown): void {
    const { selection: oldValue } = this;
    this.selection = value;
    this.oldValue = oldValue;
    this._selectionChanged.next({ selection: value, source, oldValue });
  }

  /**
   * Removes the last added selection
   * Used to undo previous changes
   * @param source
   */
  removeSelection(source: unknown): void {
    const { oldValue } = this;
    if (oldValue) {
      this._selectionChanged.next({ selection: oldValue, source });
    }
  }

  ngOnDestroy(): void {
    this._selectionChanged.complete();
  }
}

function IMAGE_SELECTION_MODEL_PROVIDER_FACTORY(): ImageSelectionModel {
  return new ImageSelectionModel();
}

export const IMAGE_SELECTION_MODEL_PROVIDER: FactoryProvider = {
  provide: ImageSelectionModel,
  useFactory: IMAGE_SELECTION_MODEL_PROVIDER_FACTORY,
};

import {
  ApiLocationMinimalAdapter,
  apiArrayAdapter,
  apiNamedEntityRefAdapter,
} from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import { IApiCampaign } from '../api-model/api-model';
import { Campaign } from '../model/campaign';

export class ApiCampaignAdapter implements Adapter<Campaign> {
  private readonly locationMinimalAdapter = new ApiLocationMinimalAdapter();

  adapt(apiDto: IApiCampaign): Campaign {
    return new Campaign({
      // Primitives
      id: `${apiDto.id}`,
      customerId: apiDto.customer,
      image: apiDto.image_thumbnail_url,
      isOnline: apiDto.is_online,
      name: apiDto.name,

      // Objects and collections
      campaignTypes: apiArrayAdapter.adaptItemsOrEmpty(
        apiDto.campaign_types,
        apiNamedEntityRefAdapter,
      ),
      goalValueUnits: apiArrayAdapter.adaptItemsOrEmpty(
        apiDto.goal_value_units,
        apiNamedEntityRefAdapter,
      ),
      teamTypes: apiArrayAdapter.adaptItemsOrEmpty(
        apiDto.team_types,
        apiNamedEntityRefAdapter,
      ),

      // other api entities
      locations: apiArrayAdapter.adaptItemsOrEmpty(
        apiDto.locations,
        this.locationMinimalAdapter,
      ),
    });
  }
}

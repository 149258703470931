import { apiArrayAdapter } from '@fmnts/api/shared';
import { type Adapter } from '@fmnts/core';
import {
  ComplimentStats,
  CustomAnswerStats,
  DonationFeedbackEvaluation,
  RatingStats,
} from '../model/donation-feedback-evaluation';

class ComplimentStatsAdapter implements Adapter<ComplimentStats> {
  adapt(item: Record<string, any>): ComplimentStats {
    return {
      count: item.count,
      image: item.image,
      label: item.label,
      value: item.value,
    };
  }
}

class CustomAnswerStatsAdapter implements Adapter<CustomAnswerStats> {
  adapt(item: Record<string, any>): CustomAnswerStats {
    return {
      approved: item.approved,
      open: item.open,
      rejected: item.rejected,
    };
  }
}

class RatingStatsAdapter implements Adapter<RatingStats> {
  adapt(item: Record<string, any>): RatingStats {
    return {
      count: item.count,
      rating: item.rating,
    };
  }
}

/**
 * Adapter for the `DonationFeedbackEvaluation`.
 */
export class ApiDonationFeedbackEvaluationAdapter
  implements Adapter<DonationFeedbackEvaluation>
{
  private complimentsAdapter = new ComplimentStatsAdapter();
  private customAnswerAdapter = new CustomAnswerStatsAdapter();
  private ratingsAdapter = new RatingStatsAdapter();

  /**
   * Adapts the data received from the API to the class
   *
   * @param json Data from the API
   */
  adapt(json: Record<string, any>): DonationFeedbackEvaluation {
    const donationsTotal = json.donations_total;
    const customAnswers = this.customAnswerAdapter.adapt(json.custom_answers);
    const ratings = apiArrayAdapter.adaptItemsOrEmpty(
      json.ratings,
      this.ratingsAdapter,
    );
    const compliments = apiArrayAdapter.adaptItemsOrEmpty(
      json.compliments,
      this.complimentsAdapter,
    );

    const data = {
      donationsTotal,
      customAnswers,
      ratings,
      compliments,
    };

    return new DonationFeedbackEvaluation(data);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressLookupDirective } from './directives/address-lookup/address-lookup.directive';
import { GoogleMapsLoaderService } from './services/google/google-maps-loader.service';
import { GoogleMapsService } from './services/google/google-maps.service';

@NgModule({
  declarations: [AddressLookupDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [AddressLookupDirective],
  providers: [GoogleMapsService, GoogleMapsLoaderService],
})
export class MapsModule {}

import moment from 'moment';
import { Duration } from './chronos.type';

/**
 * Add function for dates
 *
 * can be used to add any duration to the given date
 * @see {@link Duration}
 */
export function add(date: moment.MomentInput, duration: Duration): Date {
  return moment(date).add(duration).toDate();
}

/**
 * Subtract function for dates
 *
 * can be used to subtract any duration from the given date
 * @see {@link Duration}
 */
export function subtract(date: moment.MomentInput, duration: Duration): Date {
  return moment(date).subtract(duration).toDate();
}

/**
 * Start of the Week
 *
 * @returns the date for the first day in the current week
 */
export function startOfWeek(date: moment.MomentInput): Date {
  return moment(date).startOf('week').toDate();
}

/**
 * End of the week

* @returns the date for the last day in the current week
 */
export function endOfWeek(date: moment.MomentInput): Date {
  return moment(date).endOf('week').toDate();
}

import type { Adapter } from '@fmnts/core';
import { IApiPetitionCampaign } from '../api-model/api-model';
import { PetitionCampaign } from '../model/petition-campaign';

export class ApiPetitionCampaignAdapter implements Adapter<PetitionCampaign> {
  adapt(apiDto: IApiPetitionCampaign): PetitionCampaign {
    return new PetitionCampaign({
      id: `${apiDto.id}`,
      customerId: apiDto.customer,
      image: apiDto.image_thumbnail_url,
      isOnline: apiDto.is_online,
      name: apiDto.name,
    });
  }
}

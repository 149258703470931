import { Auth } from '@formunauts/shared/domain';
import { Observable } from 'rxjs';
import { Credentials } from './shared-auth-data-access.model';

export abstract class AuthRepository {
  /** Authenticates user via credentials. */
  abstract authenticate(credentials: Credentials): Observable<Auth.AuthSession>;

  /** Revokes the given access token. */
  abstract revoke(accessToken: string): Observable<void>;

  /** Refreshes the token. */
  abstract refresh(refreshToken: string): Observable<Auth.AuthSession>;
}

export abstract class UserRepository {
  /** Get information for current user. */
  abstract getCurrentUser(): Observable<Auth.LegacyAuthInfo>;
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Modal } from '@fmnts/components/modal';
import { Observable, map } from 'rxjs';
import { LeavePageModalComponent } from './leave-page-modal.component';

export interface AskUserBeforeLeavePage {
  /** True if user should be asked before leaving */
  askBeforeLeave: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AskUserBeforeLeaveCanDeactivate {
  constructor(private modal: Modal) {}

  /**
   * @param component - component being navigated away from
   * @param currentRoute - current route information
   * @param currentState - current router state information
   * @param nextState - next router state information
   * @returns true if navigation is allowed, false otherwise
   */
  canDeactivate(
    component: AskUserBeforeLeavePage,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (nextState.url === '/' || nextState.url.startsWith('/auth')) {
      return true;
    }
    if (component.askBeforeLeave === false) {
      return true;
    } else {
      const ref = this.modal.open(LeavePageModalComponent);
      return ref.afterClosed().pipe(map((result) => result === 'ok'));
    }
  }
}

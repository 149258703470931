import { CustomerId } from '@fmnts/api/shared';

export interface IPetitionCampaign {
  id: string;
  customerId: CustomerId;
  image: string;
  isOnline: boolean;
  name: string;
}

export class PetitionCampaign implements IPetitionCampaign {
  id: string;
  customerId: CustomerId;
  image: string;
  isOnline: boolean;
  name: string;

  constructor(dto: Readonly<IPetitionCampaign>) {
    this.id = dto.id;
    this.customerId = dto.customerId;
    this.image = dto.image;
    this.isOnline = dto.isOnline;
    this.name = dto.name;
  }
}

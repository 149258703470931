import { Event, NavigationEnd, Router } from '@angular/router';
import {
  debounceTime,
  first,
  map,
  MonoTypeOperatorFunction,
  of,
  switchMap,
} from 'rxjs';

/**
 * Common operator for debouncing values from source based on router navigation.
 *
 * This operator emits the latest value from the source once a navigation cycle
 * is successfully completed.
 *
 * This is useful in combination with `@ngrx/router-store` using "pre" navigation
 * action timing, as this will update the store before navgiation is completed.
 *
 * @example
 * class MyComponent {
 *  private filters$ = this.store.select(selectQueryParams);
 *
 *  ngOnInit() {
 *    this.filters$
 *      .pipe(debounceRouterNavigation(this.router))
 *      .subscribe(params => {
 *         this.store.dispatch(...);
 *       });
 *  }
 * }
 */
export function debounceRouterNavigation<T>(
  router: Router,
): MonoTypeOperatorFunction<T> {
  const dueTime = 200;
  const awaitNavigationEnd = router.events.pipe(first(isNavigationEnd));

  return switchMap((value, i) =>
    i === 0
      ? of(value)
      : awaitNavigationEnd.pipe(
          debounceTime(dueTime),
          map(() => value),
        ),
  );
}

function isNavigationEnd(e: Event): e is NavigationEnd {
  return e instanceof NavigationEnd;
}

import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AbstractFeatureFlags } from './feature-flags';

export interface FeatureFlagGuardConfig<T extends string> {
  /** Flags to check for. */
  flags: T[];
  /** Check method used for.  */
  fulfills?: 'every' | 'some';
  /** Fallback URL to return if not fulfilled. */
  fallbackUrl?: string | string[];
}

/**
 * Base for a functional `CanActivate` guard for feature flags.
 *
 * @param config Guard configuration
 * @param flagsService Feature flag service provider
 * @param router
 *
 * @returns
 * `true` if can activate. `false` or `URLTree` if can't activate.
 */
export function featureFlagCanActivate<T extends string>(
  config: FeatureFlagGuardConfig<T>,
  flagsService: AbstractFeatureFlags<T>,
  router = inject(Router),
): boolean | UrlTree {
  return _featureFlagGuard(config, flagsService, router);
}

/**
 * Base for a function `CanLoad` guard for feature flags.
 *
 * @param config Guard configuration
 * @param flagsService Feature flag service provider
 * @param router
 *
 * @returns
 * `true` if can load. `false` or `URLTree` if can't load.
 */
export function featureFlagCanLoad<T extends string>(
  config: FeatureFlagGuardConfig<T>,
  flagsService: AbstractFeatureFlags<T>,
  router = inject(Router),
): boolean | UrlTree {
  return _featureFlagGuard(config, flagsService, router);
}

function _featureFlagGuard<T extends string>(
  config: FeatureFlagGuardConfig<T>,
  flagsService: AbstractFeatureFlags<T>,
  router = inject(Router),
): boolean | UrlTree {
  const { fallbackUrl } = config;
  const hasFlags = _featureFlagsFulfilled(config, flagsService);

  if (!hasFlags && fallbackUrl) {
    return router.createUrlTree(_getUrlTreeCommands(fallbackUrl));
  }

  return hasFlags;
}

function _featureFlagsFulfilled<T extends string>(
  config: FeatureFlagGuardConfig<T>,
  flagsService: AbstractFeatureFlags<T>,
): boolean {
  const { flags, fulfills: fulfill = 'every' } = config;
  return fulfill === 'every'
    ? flagsService.every(...flags)
    : flagsService.some(...flags);
}

function _getUrlTreeCommands(commands: string | string[]): string[] {
  return Array.isArray(commands) ? commands : [commands];
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiToEntityMetricsAdapter,
  ApiToMetricsObjectAdapter,
  EntityMetrics,
  ILegacyApiMetricsPage,
  KeyValueListMetricsWidget,
  LegacyApiToMetricsPageAdapter,
  LegacyApiToMetricsWidgetAdapter,
  MetricWidgetMap,
  MetricWidgetsBySlug,
  MetricsPage,
  MetricsWidgetBase,
  NumericMetricsWidget,
} from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { Adapter } from '@fmnts/core';
import { map, type Observable } from 'rxjs';

export interface CampaignPerformanceMetricsTotals
  extends MetricWidgetsBySlug<MetricsWidgetBase> {
  // These metric widgets are shown on the cockpit dashboard.
  avg_supporter_age: NumericMetricsWidget;
  avg_supporter_per_work_shift: NumericMetricsWidget;
  avg_points_per_work_shift: NumericMetricsWidget;
  avg_yearly_donation_amount: NumericMetricsWidget;
  avg_yearly_donation_amount_per_work_shift: NumericMetricsWidget;
  count_supporter_by_direct_debit_interval: KeyValueListMetricsWidget;
  count_supporter_by_age_ranges: KeyValueListMetricsWidget;
  count_supporter: NumericMetricsWidget;
  count_work_shifts: NumericMetricsWidget;
  sum_yearly_donation_amount: NumericMetricsWidget;
  sum_points: NumericMetricsWidget;
}

class ApiToDonationCampaignDashboardTotalsAdapter
  implements Adapter<MetricWidgetMap<CampaignPerformanceMetricsTotals>>
{
  constructor(
    private readonly _totalsAdapter: Adapter<
      MetricWidgetMap<MetricWidgetsBySlug<MetricsWidgetBase<unknown>>>
    >,
  ) {}

  adapt(
    totals: ILegacyApiMetricsPage['_total'],
  ): MetricWidgetMap<CampaignPerformanceMetricsTotals> {
    return this._totalsAdapter.adapt(
      totals.widgets,
    ) as MetricWidgetMap<CampaignPerformanceMetricsTotals>;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MetricsApi {
  private static readonly rootUrl = '/v1/donation-campaign-dashboard';

  private readonly _metricWidgetAdapter = new LegacyApiToMetricsWidgetAdapter();
  private readonly _entityMetricsAdapter = new ApiToEntityMetricsAdapter(
    new ApiToMetricsObjectAdapter(this._metricWidgetAdapter),
  );
  private readonly _metricTotalsAdapter =
    new ApiToDonationCampaignDashboardTotalsAdapter(
      new ApiToMetricsObjectAdapter(this._metricWidgetAdapter),
    );
  private readonly _pageAdapter = new LegacyApiToMetricsPageAdapter(
    this._entityMetricsAdapter,
    this._metricTotalsAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public list(
    urlOrFilter: string | Record<string, any> = {},
    forceRefresh = true,
  ): Observable<MetricsPage<EntityMetrics, CampaignPerformanceMetricsTotals>> {
    const url = this.configService.buildCockpitApiUrl([MetricsApi.rootUrl]);
    const params = this.apiHelper.makeParams(urlOrFilter);
    const headers = this.apiHelper.makeDefaultHeaders({
      force: forceRefresh,
    });

    return this.http
      .get<ILegacyApiMetricsPage>(url, { headers, params })
      .pipe(map((response) => this._pageAdapter.adapt(response)));
  }
}

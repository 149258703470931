import { inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Auth } from '@formunauts/shared/domain';
import { type Observable } from 'rxjs';
import { AuthStore } from './auth.store';

/**
 * Interface for configuration that can be passed
 * to the `data` of a route.
 */
interface AuthGuardRouteData {
  /**
   * If set to `true`, user must not be authenticated.
   */
  requireUnauthenticated?: boolean;
  /**
   * Permission that is required to access the route
   */
  requiredPermission?: Auth.Permission;
  /**
   * A route that is redirected to, in case that the required
   * permission is not fulfilled.
   */
  fallbackRoute?: string;
}

/**
 * Guard that checks if the user is currently authenticated
 * and emits with `true` or `false`.
 */
export function isAuthenticatedGuard(
  authStore = inject(AuthStore),
): Observable<boolean> {
  return toObservable(authStore.isAuthenticated);
}

/**
 * Guards a route or its children.
 */
@Injectable({ providedIn: 'root' })
export class AuthGuard {
  private readonly authStore = inject(AuthStore);
  private readonly router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    const routeConfig = route.data ?? {};

    return this._redirect(
      this.authStore.isAuthenticated(),
      routeConfig,
      state.url,
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    return this.canActivate(route, state);
  }

  private _redirect(
    isAuthenticated: boolean,
    config: AuthGuardRouteData,
    redirectTo: string,
  ) {
    const { requireUnauthenticated, requiredPermission, fallbackRoute } =
      config;

    if (!isAuthenticated && !requireUnauthenticated) {
      return this.router.createUrlTree(['/auth/login'], {
        queryParams: {
          redirectTo,
        },
      });
    }

    if (
      !requiredPermission ||
      this.authStore.hasPermission(requiredPermission)
    ) {
      return true;
    }

    return fallbackRoute
      ? this.router.createUrlTree([fallbackRoute])
      : this.router.createUrlTree(['/unauthorized'], {
          queryParams: { url: redirectTo },
        });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BundleVersion } from '@formunauts/shared/bundle/domain';
import { VersionPipe } from './version.pipe';

/**
 * Component for displaying a version
 */
@Component({
  selector: 'fs-bundle-version',
  template: '<span>{{ version | fsBundleVersion }}</span>',
  standalone: true,
  imports: [VersionPipe],
  styleUrls: ['./bundle-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundleVersionComponent {
  /**
   * Version to display
   */
  @Input({ required: true }) version!: BundleVersion;
}

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DBSchema } from 'idb';
import { Repository } from './repository';
import { IDBSchemaConfig, IDB_SCHEMA_TOKEN } from './schema';

@NgModule({
  imports: [CommonModule],
  providers: [Repository],
})
export class IndexedDbModule {
  static provideWithSchema<DBTypes extends DBSchema>(
    schema: IDBSchemaConfig<DBTypes>,
  ): ModuleWithProviders<IndexedDbModule> {
    return {
      ngModule: IndexedDbModule,
      providers: [{ provide: IDB_SCHEMA_TOKEN, useValue: schema }],
    };
  }
}

import { IStatisticsItem, StatisticsItem } from './statistics-item';

export interface IStatisticsPage<T> {
  count: number;
  currentPage: number;
  pageSize: number;
  next: string;
  previous: string;
  results: readonly Readonly<T>[];

  totals?: { items: readonly Readonly<IStatisticsItem>[] };

  sortKey?: string;
  sortDirection?: 'asc' | 'desc';
}

export class StatisticsPage<T = unknown> implements IStatisticsPage<T> {
  count: number;
  currentPage: number;
  pageSize: number;
  next: string;
  previous: string;
  results: readonly Readonly<T>[];

  totals?: { items: readonly Readonly<StatisticsItem>[] };

  sortKey: string;
  sortDirection: 'asc' | 'desc';

  constructor(dto: Readonly<IStatisticsPage<T>>) {
    this.count = dto.count;
    this.currentPage = dto.currentPage;
    this.next = dto.next;
    this.pageSize = dto.pageSize;
    this.previous = dto.previous;
    this.results = dto.results;

    if (dto.totals) {
      this.totals = {
        items: dto.totals.items.map((item) => new StatisticsItem(item)),
      };
    }
  }
}

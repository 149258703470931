import {
  ApplicationRef,
  inject,
  Injectable,
  InjectionToken,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  SwUpdate,
  VersionEvent,
  VersionReadyEvent,
} from '@angular/service-worker';
import { Result, ResultStream } from '@causality/core';
import { WINDOW } from '@fmnts/common/browser';
import { logger } from '@fmnts/common/log';
import { LockSetSubject } from '@fmnts/core/rxjs';
import { constVoid } from 'effect/Function';
import * as Predicate from 'effect/Predicate';
import {
  combineLatest,
  concatMap,
  EMPTY,
  exhaustMap,
  filter,
  first,
  from,
  interval,
  map,
  Observable,
} from 'rxjs';

type CheckUpdateStream = Observable<void>;

/**
 * Token for providing a stream to check for app updates.
 */
export const CHECK_UPDATE_STREAM_TOKEN = new InjectionToken<CheckUpdateStream>(
  '@formunauts/shared/bundle/data-access/update-check',
);

/**
 * Service for updating app bundle.
 */
@Injectable({ providedIn: 'root' })
export class UpdateService {
  private readonly swUpdate = inject(SwUpdate);
  private readonly loc = inject(WINDOW).location;
  private readonly locks$ = new LockSetSubject<unknown>([]);
  private readonly log = logger({ name: 'UpdateService' });

  /**
   * Emits whenever a new version has been downloaded and is ready for activation.
   */
  private readonly versionReady$ = this.swUpdate.versionUpdates.pipe(
    filter(isVersionReadyEvent),
  );
  private readonly locked$ = this.locks$.locked$;
  private readonly shouldCheck$ =
    inject(CHECK_UPDATE_STREAM_TOKEN, { optional: true }) ?? EMPTY;

  constructor() {
    // Only run, if service worker is enabled
    if (!this.swUpdate.isEnabled) {
      this.log.debug('Service worker not enabled');
      return;
    }

    this.shouldCheck$
      .pipe(
        exhaustMap(() => checkForUpdate(this.swUpdate)),
        takeUntilDestroyed(),
      )
      .subscribe((updateCheck) => {
        // Nothing more to be done, as the update procedure is picked up by `versionReady$`
        if (Result.isFailure(updateCheck)) {
          this.log.error(`Update check failed`, updateCheck.left);
        }
      });

    combineLatest([this.locked$, this.versionReady$])
      .pipe(takeUntilDestroyed())
      .subscribe(([isLocked]) => {
        if (!isLocked) {
          this.log.info('Activating new version');
          this.loc.reload();
        }
      });
  }

  /**
   * Add item to update locks, preventing updates from happening.
   */
  public lockUpdates(lock: unknown): void {
    this.locks$.add(lock);
  }

  /**
   * Remove item from update locks, allowing updates to happen, if other locks
   * don't prevent it.
   */
  public unlockUpdates(lock: unknown): void {
    this.locks$.remove(lock);
  }
}

/**
 * @returns
 * An `Observable` that emits with the result of the update check, then completes.
 */
const checkForUpdate = (swUpdate = inject(SwUpdate)) =>
  from(swUpdate.checkForUpdate()).pipe(
    ResultStream.fromObservable({
      onFailure: Result.fail,
    }),
  );

/**
 * @returns
 * An `Observable` that emits when an update check should be performed.
 */
export const intervalUpdateCheck = (
  checkInterval: number,
  appRef = inject(ApplicationRef),
): CheckUpdateStream =>
  // Start interval once app stabelized
  appRef.isStable.pipe(
    first(Predicate.isTruthy),
    concatMap(() => interval(checkInterval)),
    map(constVoid),
  );

function isVersionReadyEvent(evt: VersionEvent): evt is VersionReadyEvent {
  return evt.type === 'VERSION_READY';
}

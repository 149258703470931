import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ImageSelectionModel } from './image-selection-model';

export interface ImageControl {
  disabled: boolean;

  stateChanges: Observable<void>;
}

export interface ImageEditorPanel<C extends ImageControl> extends ImageControl {
  /** Whether the editor should be disabled. */
  disabled: boolean;

  /** Whether the editor is open. */
  opened: boolean;

  /** Stream that emits whenever the editor is opened. */
  openedStream: EventEmitter<void>;
  /** Stream that emits whenever the editor is closed. */
  closedStream: EventEmitter<void>;

  /** Emits when the editors state changes. */
  stateChanges: Subject<void>;

  /** Starts editing mode. */
  open(): void;

  /** Register an image input with the editor. */
  registerInput(input: C): ImageSelectionModel;
}

@Directive()
export abstract class ImageEditorBase<C extends ImageControl>
  implements ImageEditorPanel<C>, OnDestroy
{
  public readonly openedStream = new EventEmitter<void>();
  public readonly closedStream = new EventEmitter<void>();

  @Input()
  get disabled(): boolean {
    return this._disabled === undefined && this.imageInput
      ? this.imageInput.disabled
      : !!this._disabled;
  }
  set disabled(value: BooleanInput) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this.stateChanges.next(undefined);
    }
  }
  private _disabled = false;

  /** Whether the editor is open. */
  @Input()
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: BooleanInput) {
    if (coerceBooleanProperty(value)) {
      this.open();
    } else {
      this.close();
    }
  }
  private _opened = false;

  /** The input element this editor is associated with. */
  imageInput!: C;

  readonly stateChanges = new Subject<void>();

  constructor(protected _model: ImageSelectionModel) {}

  ngOnDestroy(): void {
    this.close();
    this.stateChanges.complete();
  }

  /**
   * Checks for a valid image and opens the editor
   */
  open(): void {
    if (this._opened || this.disabled) {
      return;
    }

    if (!this.imageInput) {
      throw Error('Attempted to open an ImageEditor with no associated input.');
    }

    this._opened = true;
    this.openedStream.emit();
  }

  close(): void {
    if (!this._opened) {
      return;
    }

    const completeClose = () => {
      // The `_opened` could've been reset already if
      // we got two events in quick succession.
      if (this._opened) {
        this._opened = false;
        this.closedStream.emit();
      }
    };

    completeClose();
  }

  /**
   * @param input
   * @returns model for the selected image
   */
  registerInput(input: C): ImageSelectionModel {
    if (this.imageInput) {
      throw Error(
        'The image editor can only be associated with a single input.',
      );
    }
    this.imageInput = input;

    return this._model;
  }
}

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { DrawerContainerComponent } from './drawer-container.component';
import { Drawer } from './drawer.service';

@NgModule({
  imports: [OverlayModule, PortalModule],
  exports: [DrawerContainerComponent],
  declarations: [DrawerContainerComponent],
  providers: [Drawer],
})
export class FmntsDrawerModule {}

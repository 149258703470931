import {
  CombinedQueryParamsAdapter,
  numberQueryParamSerializer,
  queryParamKeySerializer,
} from './query-param.adapter';

/**
 * Default query params adapter for pagination.
 */
export const paginationQueryParamsSerializer = new CombinedQueryParamsAdapter({
  page: queryParamKeySerializer('page', numberQueryParamSerializer),
  page_size: queryParamKeySerializer('page_size', numberQueryParamSerializer),
});

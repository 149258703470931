import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';
import { classnames } from '@fmnts/common';
import { cssVarFactory, ThemeSpacing } from '@fmnts/components/core';

export const gridCssVars = cssVarFactory<
  'auto-flow' | 'gap' | 'template-columns' | 'template-rows'
>('grid');

type GridAutoFlow = 'column' | 'row';

/**
 * Displays a grid layout.
 */
@Directive({
  selector: `fmnts-grid, [fmnts-grid]`,
})
export class GridLayoutDirective {
  protected readonly componentClass = 'fmnts-grid';

  /**
   * Spacing between grid cells.
   */
  @Input() gap?: ThemeSpacing | 'inherit';

  /** Template columns. */
  @Input() templateColumns?: string;
  /** Template rows. */
  @Input() templateRows?: string;
  /** Auto flow. */
  @Input() autoFlow?: GridAutoFlow;
  /** Creates an auto grid. */
  @Input() get autoGrid(): boolean {
    return this._autoGrid;
  }
  set autoGrid(value: BooleanInput) {
    this._autoGrid = coerceBooleanProperty(value);
  }
  private _autoGrid = false;

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      this.componentClass,
      this.autoFlow && `${this.componentClass}--auto-flow`,
      this.gap && `${this.componentClass}--gap`,
      this.templateColumns && `${this.componentClass}--template-columns`,
      this.templateRows && `${this.componentClass}--template-rows`,
      this.autoGrid && `${this.componentClass}--auto-grid`,
    ]);
  }

  @HostBinding('style')
  protected get hostStyles(): Record<string, string> {
    return gridCssVars({
      'auto-flow': this.autoFlow,
      gap: this.gap && this.gap !== 'inherit' && ThemeSpacing[this.gap],
      'template-columns': this.templateColumns,
      'template-rows': this.templateRows,
    });
  }
}

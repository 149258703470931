import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ApiConfigService } from '@fmnts/api/util';
import { AuthSession } from './model/auth-session';

interface AuthEndpointConfig {
  /**
   * Prefix to append for all OAuth request URLs.
   * @default 'o'
   */
  prefix?: string;
  /**
   * Token URL
   * @default 'token'
   */
  token?: string;
}

const defaultAuthEndpointConfig: Required<AuthEndpointConfig> = {
  prefix: '/o/',
  token: '/token/',
};

const authEndpointConfig = new InjectionToken<AuthEndpointConfig>(
  '@fmnts.api.auth.config-endpoint',
  {
    providedIn: 'root',
    factory: () => defaultAuthEndpointConfig,
  },
);

@Injectable({
  providedIn: 'root',
})
export class AuthClientConfig {
  /**
   * Client ID to use for OAuth
   */
  public get clientId(): string {
    return this._clientId;
  }
  public set clientId(clientId: string) {
    this._clientId = clientId;
  }
  private _clientId = '';

  /**
   * URL prefix for OAuth endpoint
   */
  public readonly urlPrefix: string;
  public readonly authTokenUrl: string;

  constructor(
    private apiConfig: ApiConfigService,
    @Inject(authEndpointConfig) endpointOpts: AuthEndpointConfig,
  ) {
    const {
      prefix = defaultAuthEndpointConfig.prefix,
      token = defaultAuthEndpointConfig.token,
    } = endpointOpts;

    this.urlPrefix = prefix;
    this.authTokenUrl = this.buildAuthUrl([token]);
  }

  /**
   * Builds the URL for accessing some OAuth endpoint.
   *
   * @param parts Path parts
   */
  public buildAuthUrl(parts: (string | number)[]): string {
    return this.apiConfig.buildApiUrl([this.urlPrefix, ...parts]);
  }

  public getAuthHeader(session: AuthSession): { Authorization: string } {
    //  eslint-disable-next-line @typescript-eslint/naming-convention
    return { Authorization: `Bearer ${session.access_token}` };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPageAdapter, PagedApiResponse, type Page } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiWorkshiftAdapter } from '../adapter/workshift.adapter';
import { IApiWorkshift } from '../api-model/api-model';
import type { Workshift } from '../model/workshift';

@Injectable({
  providedIn: 'root',
})
export class WorkshiftApi {
  private static readonly rootUrl = '/v1/workshifts';

  private readonly apiToWorkshiftAdapter = new ApiWorkshiftAdapter();
  private readonly apiToWorkshiftPageAdapter = new ApiPageAdapter(
    this.apiToWorkshiftAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(id: string, forceRefresh = false): Observable<Workshift> {
    const url = this.configService.buildCockpitApiUrl([
      WorkshiftApi.rootUrl,
      id,
    ]);

    const headers = this.apiHelper.makeDefaultHeaders({
      force: forceRefresh,
    });

    return this.http
      .get<IApiWorkshift>(url, { headers })
      .pipe(map((response) => this.apiToWorkshiftAdapter.adapt(response)));
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<Workshift>> {
    const url = this.configService.buildCockpitApiUrl([WorkshiftApi.rootUrl]);

    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiWorkshift>>(url, { params })
      .pipe(map((response) => this.apiToWorkshiftPageAdapter.adapt(response)));
  }
}

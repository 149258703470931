import { Pipe, PipeTransform } from '@angular/core';
import { TimeSpanInput } from '@fmnts/core/chronos';
import moment from 'moment';

/**
 * Transforms a given time span by formatting each of both values
 * `[from, to]` using the same format.
 */
@Pipe({
  name: 'timeSpan',
})
export class TimeSpanPipe implements PipeTransform {
  transform(value: TimeSpanInput, locale: string, format = 'LT'): string {
    const [fromDate, toDate] = value;

    if (!fromDate || !toDate) {
      return '-';
    }

    // ensure the correct locale
    const from = moment(fromDate).locale(locale);
    const to = moment(toDate).locale(locale);

    return `${from.format(format)} – ${to.format(format)}`;
  }
}

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeSize } from '@fmnts/components';
import {
  Observable,
  ReplaySubject,
  Subject,
  filter,
  first,
  fromEvent,
  map,
  merge,
  of,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs';

/**
 * Component that displays a compliment.
 */
@Component({
  selector: 'fmnts-compliment',
  templateUrl: './compliment.component.html',
  styleUrls: ['./compliment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplimentComponent implements OnInit, OnDestroy {
  private readonly componentClass = 'fmnts-compliment';

  /**
   * Label for the compliment
   */
  @Input() label!: string;

  /**
   * Image of the compliment. This should be a square image.
   * Consider using an image that is circle-like with transparency.
   */
  @Input() image!: string;

  /**
   * State that the compliment uses
   */
  @Input() state: 'default' | 'disabled' | 'description' | 'badge' = 'default';

  /**
   * Size of the compliment
   */
  @Input() size: ThemeSize = 'md';

  /**
   * Position of the label.
   * If `pill` is set to true, this will be `'none'`
   */
  @Input() labelPosition: 'bottom' | 'right' | 'none' = 'right';

  /**
   * If `true` the compliment will reveal its label via interaction
   */
  @HostBinding('class.fmnts-compliment--pill')
  @Input()
  get pill(): boolean {
    return this._pill;
  }
  set pill(value: BooleanInput) {
    this._pill = coerceBooleanProperty(value);
  }
  private _pill = false;

  public get isDisabled(): boolean {
    return this.state === 'disabled';
  }

  public get labelVisible(): boolean {
    return this.labelPosition !== 'none' && !this.pill;
  }

  @HostBinding('class')
  protected get hostClasses(): string {
    return classnames([
      this.componentClass,
      this.labelPosition === 'bottom' && `${this.componentClass}--stacked`,
    ]);
  }

  private destroyed$ = new ReplaySubject<boolean>(1);
  /**
   * Emits `true` if the pill should be visible
   * or `false` if the pill should be hidden
   */
  private shouldShowPill$ = new Subject<boolean>();
  /**
   * Emits `true`, when the pill becomes visible
   * and `false` if the pill is hidden.
   */
  protected pillVisible$: Observable<boolean>;
  /**
   * `true`, if the pill is visible, otherwise `false`
   */
  protected pillVisible = false;

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private _doc: Document,
  ) {
    this.pillVisible$ = this.shouldShowPill$.pipe(
      filter(() => this.pill),
      switchMap((shouldShow) => {
        const obs$ = [of(shouldShow)];

        // When pill becomes visible, register for the next click
        // anywhere in the document to hide the pill
        if (shouldShow) {
          obs$.push(
            fromEvent(this._doc, 'click').pipe(
              skip(1), // Otherwise, this would emit immediately
              first(),
              map(() => false),
            ),
          );
        }

        return merge(...obs$);
      }),
    );
  }

  ngOnInit(): void {
    this.pillVisible$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((pillVisible) => {
        this.pillVisible = pillVisible;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public toggle(): void {
    this.shouldShowPill$.next(!this.pillVisible);
  }

  @HostListener('click')
  private onClick(): void {
    this.toggle();
  }
}

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { viewOptionsFeature } from './+state/view-options.reducer';

/**
 * Feature store module for managing options for different UI views.
 *
 * Each view is identified by a unique ID.
 */
@NgModule({
  imports: [StoreModule.forFeature(viewOptionsFeature)],
})
export class SharedViewOptionsDataAccessModule {}

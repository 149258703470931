import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPageAdapter, PagedApiResponse, type Page } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import {
  ApiTraningcenterGroupAdapter,
  ApiTraningcenterGroupMinimalAdapter,
} from '../adapter/trainingcenter.adapter';
import {
  IApiTranincenterGroup,
  IApiTranincenterGroupMinimal,
} from '../api-model/api-model';
import type {
  TrainingcenterGroup,
  TrainingcenterGroupMinimal,
} from '../model/trainingcenter';

@Injectable({
  providedIn: 'root',
})
export class TrainingcenterGroupApi {
  public static readonly rootUrl = '/v1/trainingcenter/groups';

  private readonly _fromApiGroupAdapter = new ApiTraningcenterGroupAdapter();
  private readonly _fromApiGroupMinimalAdapter =
    new ApiTraningcenterGroupMinimalAdapter();
  private readonly _fromApiGroupPageAdapter = new ApiPageAdapter(
    this._fromApiGroupMinimalAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private config: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(
    id: string,
    filters: Record<string, any> = {},
    forceRefresh = false,
  ): Observable<TrainingcenterGroup> {
    const url = this.config.buildCockpitApiUrl([
      TrainingcenterGroupApi.rootUrl,
      id,
    ]);
    const params = this.apiHelper.makeParams(filters);
    const headers = this.apiHelper.makeDefaultHeaders({
      force: forceRefresh,
    });

    return this.http
      .get<IApiTranincenterGroup>(url, { headers, params })
      .pipe(map((response) => this._fromApiGroupAdapter.adapt(response)));
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<TrainingcenterGroupMinimal>> {
    const url = this.config.buildCockpitApiUrl([
      TrainingcenterGroupApi.rootUrl,
    ]);

    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiTranincenterGroupMinimal>>(url, { params })
      .pipe(map((response) => this._fromApiGroupPageAdapter.adapt(response)));
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { ActionCreatorProps, createAction } from '@ngrx/store';
import {
  HttpUploadActionEvents,
  HttpUploadRequestFailureActionProps,
  RequestActionCreator,
  RequestUploadActionCreatorConfig,
} from './http-upload.model ';

/**
 * Creates 3 actions for a HTTP request:
 * - the request itself
 * - request success action
 * - request failure action
 */
export function createHttpUploadActions<
  RequestPayload extends object = Record<string, never>,
  ResponsePayload extends object = Record<string, never>,
  ErrorPayload extends
    HttpUploadRequestFailureActionProps<unknown> = HttpUploadRequestFailureActionProps<HttpErrorResponse>,
>(
  source: string,
  actionType: string,
  config: RequestUploadActionCreatorConfig<
    RequestPayload,
    ResponsePayload,
    ErrorPayload
  >,
): HttpUploadActionEvents<RequestPayload, ResponsePayload, ErrorPayload> {
  const prefix = `[${source}] ${actionType}`;

  const request = _createAction(prefix, config.request);
  const success = _createAction(`${prefix} Success`, config.success);
  const failure = _createAction(`${prefix} Failure`, config.failure);

  return {
    request,
    success,
    failure,
  };
}

function _createAction<T extends object>(
  name: string,
  config: ActionCreatorProps<T> | undefined,
): RequestActionCreator<T> {
  return (
    config
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        createAction(name, config as any)
      : createAction(name)
  ) as RequestActionCreator<T>;
}

import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@fmnts/i18n';

type DecimalByteUnit = 'kb' | 'mb' | 'gb';
type BinaryByteUnit = 'kib' | 'mib' | 'gib';
export type ByteUnit = DecimalByteUnit | BinaryByteUnit;

/**
 * Pipe for formatting a number representing bytes
 *
 * @example
 * // Will print 1 kB
 * {{ 1024 | byte: 'kb' }}
 *
 * @example
 * // Will print 0.50 MB
 * {{ 1024 | byte: 'mb':'1.2-2' }}
 */
@Pipe({
  name: 'byte',
})
export class BytePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}

  /**
   * @param value Value in bytes
   * @param unit Target unit
   * @param digitsInfo Target formatting
   * @param locale Target locale, default is current language
   *
   * @returns
   * Transformed `value` from bytes to the given `unit`,
   * applying formatting as described by `digitsInfo` and `locale`
   */
  transform(
    value: number,
    unit: ByteUnit,
    digitsInfo: string = '1.2-2',
    locale: string = this.translationService.currentLanguage,
  ): string {
    const converted = this.bytesToUnit(value, unit);
    const transformed = formatNumber(converted, locale, digitsInfo);
    const oUnit = this.getUnitLabel(unit);

    return `${transformed} ${oUnit}`;
  }

  /**
   * @param value Value in bytes
   * @param unit Target unit
   *
   * @returns
   * Converts a `value` in bytes into the given `unit`.
   */
  public bytesToUnit(value: number, unit: ByteUnit): number {
    const divisor = Math.pow(this.getFactor(unit), this.getExponent(unit));
    return value / divisor;
  }

  /**
   * @param unit Unit for which the label should be returned
   *
   * @returns
   * Label for the given `unit`
   */
  public getUnitLabel(unit: ByteUnit): string {
    switch (unit) {
      // Decimal
      case 'kb':
        return 'kB';
      case 'mb':
        return 'MB';
      case 'gb':
        return 'GB';
      // Binary
      case 'kib':
        return 'KiB';
      case 'mib':
        return 'MiB';
      case 'gib':
        return 'GiB';
    }
  }

  private getFactor(unit: ByteUnit) {
    // 1000 for decimal units, 1024 for binary units
    return unit.length === 2 ? 1000 : 1024;
  }

  private getExponent(unit: ByteUnit) {
    switch (unit) {
      case 'kb':
      case 'kib':
        return 1;
      case 'mb':
      case 'mib':
        return 2;
      case 'gb':
      case 'gib':
        return 3;
    }
  }
}

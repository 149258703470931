import { isNumber, isRecord } from '@fmnts/core';
import {
  GeoCoordinates,
  GeoCoordinatesError,
  GeoCoordinatesErrorCode,
} from './geolocation.model';

/** Type guard for GeoCoordinates. */
export function isGeoCoordinates(value: unknown): value is GeoCoordinates {
  return (
    isRecord(value) &&
    isNumber(value['latitude']) &&
    isNumber(value['longitude'])
  );
}

/**
 * Converts geo coordinates to a tuple of latitude and longitude.
 */
export function toLatLon(
  value: GeoCoordinates,
): [latitude: number, longitude: number] {
  return [value.latitude, value.longitude];
}
/**
 * Converts geo coordinates to a tuple of longitude and latitude.
 */
export function toLonLat(
  value: GeoCoordinates,
): [longitude: number, latitude: number] {
  return [value.longitude, value.latitude];
}

/**
 * Maps the given `error` to a `GeoCoordinatesError.
 */
export function toGeoCoordinatesError(error: unknown): GeoCoordinatesError {
  return {
    code: toGeoCoordinatesErrorCode(error),
  };
}

/**
 * Maps the given `error` to a `GeoCoordinatesErrorCode`.
 */
function toGeoCoordinatesErrorCode(error: unknown): GeoCoordinatesErrorCode {
  if (error instanceof GeolocationPositionError) {
    const { code } = error;
    switch (code) {
      case GeolocationPositionError.PERMISSION_DENIED:
      case GeolocationPositionError.POSITION_UNAVAILABLE:
      case GeolocationPositionError.TIMEOUT:
        return code;
    }
  }

  return GeoCoordinatesErrorCode.Unknown;
}

import { Injectable, inject } from '@angular/core';
import { ApiConfigService } from '@fmnts/api';
import { AuthClientConfig } from '@fmnts/api/auth';
import { isNonEmptyString } from '@fmnts/core';
import { Settings } from './settings';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly apiConfigService = inject(ApiConfigService);
  private readonly authConfig = inject(AuthClientConfig);

  private _settings: Settings;

  public get settings() {
    return this._settings;
  }
  public set settings(value: Settings) {
    this._settings = value;
    this.apiConfigService.apiUrl = value?.apiUrl ?? '';
    this.authConfig.clientId = value?.oauthClientId ?? '';
  }

  /**
   * Set of URLs that can be added as preconnect URLs.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/preconnect
   */
  public get preconnectUrls(): string[] {
    return [this.settings.assetUrl]
      .filter(isNonEmptyString)
      .map((url) => new URL(url).origin);
  }
}

interface WeightedValue<T> {
  /** The weight for this value */
  weight: number;
  /** The value that was */
  value: T;
}

type WeighingFn<T> = (item: T, index: number, array: T[]) => number;

/**
 * Creates a function that takes an array of values and that
 * uses the provided function `calcWeight` to calculate
 * the weight for each value in this array.
 *
 * The result of this function is an array of `WeightedValue`s
 * that is sorted by the weight in descending order.
 *
 * @param calcWeight Function to calculate the weight for an item
 *
 * @returns
 * A function to created a weighted list from an array of values.
 *
 * @example
 * const weightedEvens = weighBy(
 *  x => x % 2 === 0 ? x / 2 : -1
 * );
 *
 * weightedEvens([1, 2, 3, 4])
 * // [
 * // { value: 4, weight: 2},
 * // { value: 2, weight: 1},
 * // { value: 1, weight: -1},
 * // { value: 3, weight: -1},
 * // ]
 */
export function weighBy<T>(calcWeight: WeighingFn<T>) {
  return (values: T[]): WeightedValue<T>[] =>
    values
      .map((value, index, array) => ({
        value,
        weight: calcWeight(value, index, array),
      }))
      .sort((a, b) => b.weight - a.weight);
}

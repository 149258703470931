/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 *
 * Returns an error to be thrown when attempting to find an nonexistent column.
 * @param id Id whose lookup failed.
 *
 * @internal
 */
export function getTableUnknownColumnError(id: string): Error {
  return Error(`Could not find column with id "${id}".`);
}

/**
 * Returns an error to be thrown when two column definitions have the same name.
 *
 * @internal
 */
export function getTableDuplicateColumnNameError(name: string): Error {
  return Error(`Duplicate column definition name provided: "${name}".`);
}

/**
 * Returns an error to be thrown when there are multiple rows that are missing a when function.
 *
 * @internal
 */
export function getTableMultipleDefaultRowDefsError(): Error {
  return Error(
    `There can only be one default row without a when predicate function.`,
  );
}

/**
 * Returns an error to be thrown when there are no matching row defs for a particular set of data.
 *
 * @internal
 */
export function getTableMissingMatchingRowDefError(data: any): Error {
  return Error(
    `Could not find a matching row definition for the` +
      `provided row data: ${JSON.stringify(data)}`,
  );
}

/**
 * Returns an error to be thrown when there is no row definitions present in the content.
 *
 * @internal
 */
export function getTableMissingRowDefsError(): Error {
  return Error(
    'Missing definitions for header, footer, and row; ' +
      'cannot determine which columns should be rendered.',
  );
}

/**
 * Returns an error to be thrown when the data source does not match the compatible types.
 *
 * @internal
 */
export function getTableUnknownDataSourceError(): Error {
  return Error(
    `Provided data source did not match an array, Observable, or DataSource`,
  );
}

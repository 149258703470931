import {
  EnvironmentProviders,
  Injectable,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';

/** Configuration for the Zendesk API. */
export abstract class ZendeskApiClientConfig {
  abstract readonly accountUrl: string;
}

/**
 * Sets up the Zendesk API client.
 */
export function provideZendeskApiClient(opts: {
  /** Loads the configuration for zendesl API client. */
  loadConfig: () => ZendeskApiClientConfig;
}): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ZendeskApiClientConfig,
      useFactory: opts.loadConfig,
    },
  ]);
}

@Injectable({
  providedIn: 'root',
})
export class ZendeskApiClientConfigService implements ZendeskApiClientConfig {
  private readonly _config = inject(ZendeskApiClientConfig);

  get accountUrl(): string {
    const url = this._fromConfig('accountUrl');

    // Simple sanity check to ensure that we don't send data anywhere else
    if (/\w+\.zendesk\.com$/.test(url) === false) {
      throw new Error(`ZendeskConfigError: ${url} is not a valid account URL.`);
    }

    return url;
  }

  endpointUrl(parts: (string | number)[]): string {
    const base = `https://${this.accountUrl}`;
    return new URL(_sanitizePath(['api', ...parts].join('/')), base).toString();
  }

  private _fromConfig<K extends keyof ZendeskApiClientConfig>(
    key: K,
  ): ZendeskApiClientConfig[K] {
    const value = this._config[key];

    if (!value) {
      throw new Error(`Zendesk API Client config missing for ${key}`);
    }

    return value;
  }
}

/** Helper that removes duplicate slashes on paths. */
function _sanitizePath(path: string): string {
  return path.replace(/\/{2,}/g, '/');
}

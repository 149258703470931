import {
  EnvironmentProviders,
  inject,
  Injectable,
  makeEnvironmentProviders,
  Type,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  bufferTime,
  catchError,
  concatMap,
  debounceTime,
  exhaustMap,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { AbstractLogDriver } from './log-driver';
import {
  LogDriverFeature,
  LogDriverFeatureKind,
  provideLogDriver,
} from './log-driver.provider';
import { LogEvent } from './log.model';

/**
 * DI token for providing the indexed db log store.
 */
export abstract class IndexedDbLogStore {
  /**
   * Persists an array of logs. For consistency this should happen in a single
   * transaction.
   *
   * If there is an error then the operation should throw an error and roll back.
   */
  abstract addMany(logs: LogEvent[]): Observable<unknown[]>;
  /**
   * Expires entries and emits with the number of entries that
   * were deleted.
   *
   * If there is an error then the operation should throw an error.
   */
  abstract expireEntries(): Observable<number>;
}

/**
 * Configuration for the indexed db log driver.
 */
export class IndexedDbLogDriverConfig {
  /**
   * Maximum number of log events to be stored.
   *
   * NOTE: This is not a hard limit, so there can
   * be more log events persisted.
   */
  maxEntries = 10;
  /**
   * Time in ms to buffer log events before persisting them.
   */
  bufferTime = 1000;
  /**
   * Time to wait after persisting log events before triggering
   * a cycle of expiring log events.
   */
  debounceExpire = 2000;
}

/**
 * Log driver that persists log events in an indexed db store.
 */
@Injectable()
export class IndexedDbLogDriver extends AbstractLogDriver {
  private readonly dbStore = inject(IndexedDbLogStore);
  private readonly _config = inject(IndexedDbLogDriverConfig);

  /** Emits when an event should be logged. */
  private readonly _events = new Subject<LogEvent>();
  /** Emits when to check for expired entries. */
  private readonly _expire = new Subject<void>();

  constructor() {
    super();

    this._events
      .pipe(
        bufferTime(this._config.bufferTime),
        concatMap((logs) => this.dbStore.addMany(logs)),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this._expire.next();
      });

    this._expire
      .pipe(
        debounceTime(this._config.debounceExpire),
        exhaustMap(() =>
          this.dbStore.expireEntries().pipe(catchError(() => of(true))),
        ),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  override write(log: LogEvent): void {
    this._events.next(log);
  }
}

/**
 * Prodives persistant logs by storing them in an IndexedDb store.
 *
 * @param idbStore Used to write the data in the indexed db.
 * @param config Configuration
 * @param features Additional features
 */
export function provideIndexedDbLogDriver(
  idbStore: Type<IndexedDbLogStore>,
  config?: Partial<IndexedDbLogDriverConfig>,
  ...features: LogDriverFeature<LogDriverFeatureKind>[]
): EnvironmentProviders {
  const defaults = new IndexedDbLogDriverConfig();
  return makeEnvironmentProviders([
    provideLogDriver(IndexedDbLogDriver, ...features),
    {
      provide: IndexedDbLogStore,
      useExisting: idbStore,
    },
    {
      provide: IndexedDbLogDriverConfig,
      useValue: { ...defaults, ...config },
    },
  ]);
}

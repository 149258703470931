import {
  AsyncData,
  AsyncDataAdapter,
  AsyncDataState,
} from './async-data.model';

/**
 * Adapter for the async data store addon.
 * @returns
 */
export function createAsyncDataAdapter<TData, TError>(): AsyncDataAdapter<
  TData,
  TError
> {
  return new BaseAsyncDataAdapter<TData, TError>();
}

class BaseAsyncDataAdapter<TData, TError>
  implements AsyncDataAdapter<TData, TError>
{
  getInitialState(): AsyncData<TData, TError>;
  getInitialState<S extends object>(state: S): AsyncData<TData, TError> & S;
  getInitialState(
    state?: object,
  ): AsyncData<TData, TError> | (AsyncData<TData, TError> & object) {
    return { ...state, state: AsyncDataState.Init };
  }

  setLoading<S extends AsyncData<TData, TError>>(state: S): S {
    return { ...state, state: AsyncDataState.Loading };
  }

  setData<S extends AsyncData<TData, TError>>(data: TData, state: S): S {
    return {
      ...state,
      state: AsyncDataState.Loaded,
      refresh: new Date().toISOString(),
      data,
    };
  }

  setError<S extends AsyncData<TData, TError>>(error: TError, state: S): S {
    return { ...state, state: { error } };
  }
}

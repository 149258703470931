import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { AuthInfo, Permission } from '@fmnts/api/auth';
import { PermissionService, selectAuth } from '@fmnts/shared/auth/data-access';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

/**
 * Pipe that transforms the given permission input into a boolean
 * that is `true` if current user has this permission. Otherwise,
 * false is returned.
 *
 * Impure pipe, as this depends on the the current user.
 */
@Pipe({
  name: 'userHasPermission',
  pure: false,
  standalone: true,
})
export class UserHasPermissionPipe implements PipeTransform, OnDestroy {
  private readonly auth$ = this.store.select(selectAuth);
  private _auth: AuthInfo | null = null;
  private _subscription = new Subscription();

  constructor(
    private permissionService: PermissionService,
    private store: Store,
    private cd: ChangeDetectorRef,
  ) {
    this._subscription.add(
      this.auth$.subscribe((auth) => {
        this._auth = auth;
        this.cd.markForCheck();
      }),
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  transform(permission: Permission): boolean {
    return this._auth
      ? this.permissionService.hasPermission(this._auth, permission)
      : false;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'fmnts-empty-state-visual',
  template: '<ng-content></ng-content>',
  styleUrls: ['./empty-state-visual.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateVisualComponent {
  @HostBinding('class.fmnts-empty-state-visual')
  protected readonly componentClass = 'fmnts-empty-state-visual';

  @ContentChildren(FaIconComponent, { descendants: true })
  protected _iconChildren!: QueryList<FaIconComponent>;
  @ContentChildren('img', { descendants: true })
  protected _imageChildren!: QueryList<HTMLImageElement>;

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      this._iconChildren.length > 0 &&
        this._imageChildren.length === 0 &&
        `${this.componentClass}--icon-only`,
    ]);
  }
}

export enum Permission {
  ViewTerritory = 'view_territory',
  ViewTerritoryList = 'view_territory_list',
  // -----------------------------------------------------
  // Permissions that are yet only defined by the frontend
  // -----------------------------------------------------
  // Cockpit
  ViewCockpit = 'view_cockpit',

  Login = 'login',

  ViewDashboard = 'view_dashboard',
  ViewDashboardActivity = 'view_dashboard_activity',
  ViewDashboardPerformance = 'view_dashboard_performance',

  ViewActivities = 'view_activities',
  ViewActivitiesDonations = 'view_donation_activities',
  ViewActivitiesAchievements = 'view_achievement_activities',
  ViewActivitiesDonorFeeback = 'view_supporter_feedback_activities',

  ViewTeamList = 'view_team_list',
  ViewTeam = 'view_team',
  CreateTeam = 'create_team',
  EditTeam = 'edit_team',
  DeleteTeam = 'delete_team',

  ViewFundraiserList = 'view_fundraiser_list',
  ViewFundraiser = 'view_fundraiser',
  CreateFundraiser = 'create_fundraiser',
  EditFundraiser = 'edit_fundraiser',
  PromoteFundraiserToCoach = 'promote_fundraiser_to_coach',
  PromoteFundraiserToTeamLeader = 'promote_fundraiser_to_team_leader',
  DeleteFundraiser = 'delete_fundraiser',

  ViewDonationList = 'view_donation_list',
  ViewDonation = 'view_donation',
  CreateDonation = 'create_donation',
  EditDonation = 'edit_donation',
  DeleteDonation = 'delete_donation',

  ViewUnfinishedDonationList = 'view_unfinished_donation_list',
  ViewUnfinishedDonation = 'view_unfinished_donation',
  CreateUnfinishedDonation = 'create_unfinished_donation',
  EditUnfinishedDonation = 'edit_unfinished_donation',
  DeleteUnfinishedDonation = 'delete_unfinished_donation',

  ViewDonationStatistics = 'view_donation_stats',
  ViewTeamStatistics = 'view_team_stats',
  ViewFundraiserStatistics = 'view_fundraiser_stats',
  ViewCampaignStatistics = 'view_campaign_stats',
  ViewLocationStatistics = 'view_location_stats',

  ViewLocationList = 'view_location_list',
  ViewLocation = 'view_location',
  EditLocation = 'edit_location',
  ViewLocationCommentList = 'view_location_comment_list',
  CreateLocationComment = 'create_location_comment',
  ViewLocationReporting = 'view_location_reporting',
  CreateLocation = 'create_location',

  ViewDonorFeedbackEvaluation = 'view_supporter_feedback_evaluation',
  EditDonorFeedbackComment = 'edit_supporter_feedback_comment',

  ViewPetitions = 'view_petitions',
  ViewPetitionStatistics = 'view_petition_stats',
  ViewPetitionCampaignStatistics = 'view_petition_campaign_stats',
  ViewPetitionFundraiserStatistics = 'view_petition_fundraiser_stats',
  ViewPetitionTeamStatistics = 'view_petition_team_stats',

  ViewRecruitingStatistics = 'view_recruiting_stats',

  ViewCsvArchives = 'view_csv_archives',
  ViewSupporterFeedback = 'view_supporter_feedback',
  ViewTimeTracking = 'view_time_tracking',
  ViewGamificationAchievements = 'view_gamification_achievements',

  ViewRecruitingTeams = 'view_recruiting_teams',
  ViewRecruitingJobScouts = 'view_recruiting_job_scouts',
  ViewRecruitingJobApplications = 'view_recruiting_job_applications',
  ViewRecruitingCampaigns = 'view_recruiting_campaigns',
  ViewRecruitingSettings = 'view_recruiting_settings',

  ViewUserSettings = 'view_user_settings',
  ViewLocationSettings = 'view_location_settings',
  ViewCampaignSettings = 'view_campaign_settings',
  ViewPetitionCampaignSettings = 'view_petition_campaign_settings',
  ViewWorkShiftSettings = 'view_workshift_settings',
  ViewBlackListItemSettings = 'view_backlist_item_settings',

  ViewTrainingcenterGroups = 'view_training_groups',
  EditTrainingcenterGroups = 'edit_training_groups',
  ViewTrainingcenterQuizzes = 'view_training_list',
  EditTrainingcenterQuizzes = 'edit_training',
  ViewTrainingcenterQuizReports = 'view_training_report_list',
  ViewTrainingcenterQuizReportAttempts = 'view_training_attempt',

  ViewAdminInterface = 'view_admin',

  // Next
  ViewNext = 'view_next',
  ViewLauncherFundraiserStatistics = 'view_next_fundraiser_stats',
  ViewLauncherTeamStatistics = 'view_next_team_stats',
}

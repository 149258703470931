import { Component } from '@angular/core';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsModalModule, ModalRef } from '@fmnts/components/modal';
import { I18nModule } from '@fmnts/i18n';

@Component({
  selector: 'app-leave-page-modal',
  templateUrl: './leave-page-modal.component.html',
  standalone: true,
  imports: [FmntsButtonModule, FmntsModalModule, I18nModule],
})
export class LeavePageModalComponent {
  constructor(private readonly _modalRef: ModalRef<LeavePageModalComponent>) {}

  public close(result: string): void {
    this._modalRef.close(result);
  }
}

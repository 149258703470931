import { InjectionToken } from '@angular/core';
import { SupportRequestUserData } from './support-request.entity';

/**
 * Injection token to resolve the defaults for the support request user data.
 */
export const SUPPORT_REQUEST_USER_DATA_RESOLVE_FN = new InjectionToken<
  () => Partial<SupportRequestUserData>
>('shared.support.request-user-data.resolve-fn', {
  factory: () => () => ({}),
});

<div class="fmnts-sidebar-item" (click)="showItems()">
  <div class="fmnts-sidebar-item__icon">
    <ng-content select="fa-icon"></ng-content>
  </div>
  <div class="fmnts-sidebar-item__text">
    <ng-content select="span"></ng-content>
  </div>

  <div
    class="fmnts-sidebar-section-toggle"
    [class.fmnts-sidebar-section-toggle--expanded]="expanded"
  >
    <fa-icon [icon]="_iconSectionToggle"></fa-icon>
  </div>
</div>

<div [@sideBarAnimation]="expanded ? 'to' : 'from'">
  <div class="fmnts-sidebar-section-items">
    <ng-content select="fmnts-sidebar-item"></ng-content>
  </div>
</div>

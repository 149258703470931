import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPageAdapter, PagedApiResponse, type Page } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import {
  ApiTeamAdapter,
  ApiTeamDetailedAdapter,
  ITeamDto,
  TeamToApiAdapter,
} from '../adapter/team.adapter';
import { IApiTeam, IApiTeamDetailed } from '../api-model/api-model';
import type { Team, TeamDetailed } from '../model/team';

@Injectable({
  providedIn: 'root',
})
export class TeamApi {
  private static readonly rootUrl = '/v1/teams';

  private readonly _fromApiTeamDetailedAdapter = new ApiTeamDetailedAdapter();
  private readonly _fromApiTeamAdapter = new ApiTeamAdapter();
  private readonly _fromApiTeamPageAdapter = new ApiPageAdapter(
    this._fromApiTeamAdapter,
  );

  private readonly _toApiAdapter = new TeamToApiAdapter();

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(id: string, forceRefresh = false): Observable<TeamDetailed> {
    const url = this.configService.buildCockpitApiUrl([TeamApi.rootUrl, id]);

    const headers = this.apiHelper.makeDefaultHeaders({
      force: forceRefresh,
    });

    return this.http
      .get<IApiTeamDetailed>(url, { headers })
      .pipe(
        map((response) => this._fromApiTeamDetailedAdapter.adapt(response)),
      );
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<Team>> {
    const url = this.configService.buildCockpitApiUrl([TeamApi.rootUrl]);

    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiTeam>>(url, { params })
      .pipe(map((response) => this._fromApiTeamPageAdapter.adapt(response)));
  }

  public create(team: ITeamDto): Observable<TeamDetailed> {
    const url = this.configService.buildCockpitApiUrl([TeamApi.rootUrl]);
    const data = this._toApiAdapter.adaptForCreate(team);

    return this.http
      .post<IApiTeamDetailed>(url, data)
      .pipe(
        map((response) => this._fromApiTeamDetailedAdapter.adapt(response)),
      );
  }

  public update(team: ITeamDto): Observable<TeamDetailed> {
    const url = this.configService.buildCockpitApiUrl([
      TeamApi.rootUrl,
      team.id,
    ]);
    const data = this._toApiAdapter.adaptForUpdate(team);

    return this.http
      .patch<IApiTeamDetailed>(url, data)
      .pipe(
        map((response) => this._fromApiTeamDetailedAdapter.adapt(response)),
      );
  }
}

import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { CustomerFeatureService } from '../../customer-feature.service';
import { selectFeatures } from '../customer.selectors';

@Injectable()
export class CustomerFeatureEffects {
  /**
   * Update customer feature flags when the assigned customer changes.
   */
  syncCustomerFeatures$ = createEffect(
    () => {
      return this.store.select(selectFeatures).pipe(
        tap((features) => {
          this.customerFeatures.setFeatures(features);
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private store: Store,
    private customerFeatures: CustomerFeatureService,
  ) {}
}

<div
  class="fmnts-expansion-panel-header-content"
  (click)="this.toggle()"
  data-testid="expansion-panel-header-content"
>
  <span>
    <ng-content></ng-content>
  </span>
  <span class="fmnts-expansion-toggle" [@indicatorRotate]="_getExpandedState()">
    <fa-icon [icon]="this.toggleIcon"></fa-icon>
  </span>
</div>

import {
  ApiActorObjectType,
  ApiTargetObjectType,
  IApiObjectReference,
  apiDatetimeAdapter,
} from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import {
  IApiAchievement,
  IApiActivity,
  IApiDonation,
  IApiDonationFeedback,
  IApiFundraiserMinimal,
} from '../api-model/api-model';
import { Activity } from '../model/activity';
import { ApiAchievementAdapter } from './achievement.adapter';
import { ApiDonationFeedbackAdapter } from './donation-feedback.adapter';
import { ApiDonationAdapter } from './donation.adapter';
import { ApiFundraiserMinimalAdapter } from './fundraiser.adapter';

class ApiActivityActorAdapter implements Adapter<Activity['actor'] | null> {
  private readonly fundraiserAdapter = new ApiFundraiserMinimalAdapter();

  public adapt(actorObj: IApiObjectReference<ApiActorObjectType>) {
    const actorData = {
      id: actorObj.id,
      ...actorObj.object_data,
    };

    switch (actorObj.type) {
      case ApiActorObjectType.Fundraiser:
        return this.fundraiserAdapter.adapt(actorData as IApiFundraiserMinimal);
      default:
        return null;
    }
  }
}

class ApiActivityTargetAdapter
  implements Adapter<Pick<Activity, 'target' | 'targetType'> | null>
{
  private readonly donationAdapter = new ApiDonationAdapter();
  private readonly achievementAdapter = new ApiAchievementAdapter();
  private readonly feedbackAdapter = new ApiDonationFeedbackAdapter();

  public adapt(ref: IApiObjectReference<ApiTargetObjectType>) {
    const data: any = {
      id: ref.id,
      ...ref.object_data,
    };

    switch (ref.type) {
      case ApiTargetObjectType.Donation:
        return {
          targetType: ref.type,
          target: this.donationAdapter.adapt(data as IApiDonation),
        };
      case ApiTargetObjectType.Achievement:
        return {
          targetType: ref.type,
          target: this.achievementAdapter.adapt(data as IApiAchievement),
        };

      case ApiTargetObjectType.Feedback:
        return {
          targetType: ref.type,
          target: this.feedbackAdapter.adapt(data as IApiDonationFeedback),
        };
      default:
        return null;
    }
  }
}

export class ApiActivityAdapter implements Adapter<Activity> {
  private readonly actorAdapter = new ApiActivityActorAdapter();
  private readonly targetAdapter = new ApiActivityTargetAdapter();

  public adapt(apiDto: IApiActivity): Activity {
    const targetInfo = this.targetAdapter.adapt(apiDto.target);

    return {
      id: apiDto.id,
      createdAt: apiDatetimeAdapter.adapt(apiDto.timestamp),
      verb: apiDto.verb,
      actor: this.actorAdapter.adapt(apiDto.actor),
      ...targetInfo,
    };
  }
}

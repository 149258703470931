import { Pipe, PipeTransform } from '@angular/core';
import { pluck } from '@fmnts/core/object';

type KeyType = string | number;

/**
 * Pure pipe that takes an array and a key and that
 * returns an array by trying to resolve the passed
 * key for each item in the original array.
 *
 * @example
 * {{ [{x: 1}, {x: 2}] | pluck: 'x' }}
 * // --> [1,2]
 *
 * @example
 * {{ [{start: {x: 1}}, {start: {x: 2}}] | pluck: 'start.x' }}
 * // --> [1,2]
 */
@Pipe({
  name: 'pluck',
})
export class PluckPipe implements PipeTransform {
  /**
   * Retrieves the value for each item in the input array
   * on the given key
   *
   * @param input Input array
   *
   * @param key
   * Key to use to resolve the value of each array item.
   * To access a nested value, keys can be separated by `.`
   *
   * @returns
   * An array of values resolved for each input array item
   * using the given `key`.
   */
  transform(input: Record<KeyType, any>, key: string): any {
    if (!Array.isArray(input) || !key) {
      return input;
    }

    const pluckFromPath = pluck(key.split('.'));
    return input.map(pluckFromPath);
  }
}

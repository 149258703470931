import { Injectable, inject } from '@angular/core';
import { Maybe } from '@fmnts/core';

/**
 * Result of the support request dialog.
 */
export type SupportRequestDialogResult = Maybe<'ok'>;

/**
 * Reference to the support request dialog.
 */
export interface SupportRequestDialogRef {
  close(result: SupportRequestDialogResult): void;
}

/**
 * DI token to provide support request dialogs.
 */
export abstract class SupportRequestDialog {
  abstract open(): SupportRequestDialogRef;
}

/**
 * Service to interact with support request dialog.
 */
@Injectable()
export class SupportRequestDialogService {
  private readonly dialog = inject(SupportRequestDialog);

  /** Opens the support request dialog. */
  open(): SupportRequestDialogRef {
    return this.dialog.open();
  }
}

<div class="fmnts-image-editor-tool__main">
  <div class="fmnts-image-editor-crop-tool">
    <div
      class="fmnts-image-editor-crop-tool__box"
      [style.width]="this.cropWidth | async"
      [style.height]="this.cropHeight | async"
      [style.transform]="this.transformCrop() | async"
      (mousedown)="_startCropResize('box', $event)"
      (touchstart)="_startCropResize('box', $event)"
      (mouseup)="_stopCropResize()"
      (touchend)="_stopCropResize()"
    ></div>
    <div
      *ngFor="let edge of _edges"
      tabindex="-1"
      [class.fmnts-image-editor-crop-tool__edge]="true"
      [class]="'fmnts-image-editor-crop-tool__edge--' + edge"
      [style.transform]="transformForEdge$(edge) | async"
      (mousedown)="_startCropResize(edge, $event)"
      (touchstart)="_startCropResize(edge, $event)"
      (mouseup)="_stopCropResize()"
      (touchend)="_stopCropResize()"
    ></div>

    <div
      *ngFor="let corner of _corners"
      tabindex="-1"
      [class.fmnts-image-editor-crop-tool__corner]="true"
      [class]="'fmnts-image-editor-crop-tool__corner--' + corner"
      [style.transform]="transformForCorner$(corner) | async"
      (mousedown)="_startCropResize(corner, $event)"
      (touchstart)="_startCropResize(corner, $event)"
      (mouseup)="_stopCropResize()"
      (touchend)="_stopCropResize()"
    ></div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';
import { Nullish } from '@fmnts/core';

export type BadgeFill = 'outline' | 'solid' | 'soft';
export type BadgeShape = 'rounded' | 'pill';

const DEFAULT_BADGE_SHAPE: BadgeShape = 'pill';
const DEFAULT_BADGE_FILL: BadgeFill = 'soft';

/**
 * Badges show notifications, counts, or status information on navigation items and icons.
 */
@Component({
  selector: 'fmnts-badge',
  templateUrl: 'badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  protected readonly componentClass = 'fmnts-badge';

  /**
   * Color variant.
   */
  @Input() color: ThemeColor | Nullish;

  /** Fill variant of the badge. */
  @Input() get fill(): BadgeFill {
    return this._fill;
  }
  set fill(value: BadgeFill | Nullish) {
    this._fill = value ?? DEFAULT_BADGE_FILL;
  }
  private _fill: BadgeFill = DEFAULT_BADGE_FILL;

  /** Shape variant of the badge. */
  @Input() get shape(): BadgeShape {
    return this.shape;
  }
  set shape(value: BadgeShape | Nullish) {
    this._shape = value ?? DEFAULT_BADGE_SHAPE;
  }
  private _shape: BadgeShape = DEFAULT_BADGE_SHAPE;

  @HostBinding('class')
  get dynamicClasses(): string {
    return classnames([
      this.componentClass,
      `${this.componentClass}--${this._shape}`,
      `${this.componentClass}--${this.fill}`,
      this.color && `${this.componentClass}--${this.color}`,
    ]);
  }
}

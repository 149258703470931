import { Version } from '@angular/core';
import { BundleVersion } from '@formunauts/shared/bundle/domain';

/**
 * Decodes a version info from `@angular/core` to `BundleVersion`.
 *
 * @param v Version
 *
 * @returns
 * Bundle version
 */
export function decodeAngularVersion(v: Version): BundleVersion {
  return {
    major: v.major,
    minor: v.minor,
    patch: v.patch,
  };
}

import { Directive, HostBinding, Input } from '@angular/core';
import { classnames } from '@fmnts/common';

@Directive()
abstract class BaseInputFixDirective {
  abstract componentClass: string;

  /**
   * Alignment of the item in a text-field.
   */
  @Input() alignBlock: 'center' | 'baseline' = 'center';

  @HostBinding('class')
  protected get hostClasses(): string {
    const prefix = this.componentClass;

    return classnames([
      // positions element
      `${prefix}--align-block-${this.alignBlock}`,
    ]);
  }
}

/**
 * Use on a prefix item for `fmnts-text-input`.
 */
@Directive({
  selector: '[fmntsFieldPrefix]',
})
export class FmntsInputPrefixDirective extends BaseInputFixDirective {
  @HostBinding('class.fmnts-text-input__prefix-item')
  override componentClass = 'fmnts-text-input__prefix-item';
}

/**
 * Use on a suffix item for `fmnts-text-input`.
 */
@Directive({
  selector: '[fmntsFieldSuffix]',
})
export class FmntsInputSuffixDirective extends BaseInputFixDirective {
  @HostBinding('class.fmnts-text-input__suffix-item')
  override componentClass = 'fmnts-text-input__suffix-item';
}

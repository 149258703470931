import { Adapter } from '@fmnts/core';
import { PagedApiResponse } from './api-model';
import { Page } from './page';

export class ApiPageAdapter<T, TSource> implements Adapter<Page<T>> {
  constructor(private readonly itemAdapter: Adapter<T, TSource>) {}

  adapt(value: PagedApiResponse<TSource>): Page<T> {
    return new Page({
      count: value.count,
      currentPage: value.current_page,
      next: value.next,
      pageSize: value.page_size,
      previous: value.previous,
      results: value.results.map((item) => this.itemAdapter.adapt(item)),
    });
  }
}

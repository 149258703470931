import type { Adapter } from '@fmnts/core';
import {
  apiAdaptOrNull,
  apiArrayAdapter,
  toApiDtoAdapter,
} from '../shared-adapters';
import {
  FileUpload,
  IApiLocation,
  IApiLocationComment,
  IApiLocationCreateDto,
  IApiLocationDetailed,
  IApiLocationMinimal,
  IApiLocationPermit,
  IApiLocationUpdateDto,
} from './api-model';
import { LocationType } from './domain-enums';
import {
  Location,
  LocationComment,
  LocationDetail,
  LocationId,
  LocationMinimal,
  LocationPermit,
} from './location';

export class ApiLocationMinimalAdapter implements Adapter<LocationMinimal> {
  adapt(apiDto: IApiLocationMinimal): LocationMinimal {
    return {
      id: apiDto.id,
      code: apiDto.code,
      name: apiDto.name,
      city: apiDto.city,
    };
  }
}

export class ApiLocationAdapter implements Adapter<Location> {
  adapt(apiDto: IApiLocation): Location {
    return {
      code: apiDto.code,
      id: apiDto.id,
      name: apiDto.name,
      type: apiDto.type as LocationType,
      isActive: apiDto.is_active,
      capacity: apiDto.capacity,
      city: apiDto.city,
      coordinates: apiArrayAdapter.itemsOrNull(apiDto.coordinates),
      image: apiDto.image,
      isBoothRequired: apiDto.is_booth_required,
      isRainProof: apiDto.is_rain_proof,
      pricePerDay: apiDto.price_per_day,
      streetAddress: apiDto.street_address,
    };
  }
}

export class ApiLocationCommentAdapter implements Adapter<LocationComment> {
  adapt(apiDto: IApiLocationComment): LocationComment {
    return {
      id: apiDto.id,
      userId: apiDto.user_id,
      fullName: apiDto.full_name,
      text: apiDto.text,
      created: apiDto.createtime,
    };
  }
}

export class ApiLocationPermitAdapter implements Adapter<LocationPermit> {
  adapt(apiDto: IApiLocationPermit): LocationPermit {
    return {
      id: apiDto.id,
      file: apiDto.file,
      created: apiDto.createtime,
    };
  }
}

export class ApiLocationDetailedAdapter implements Adapter<LocationDetail> {
  private readonly permitAdapter = new ApiLocationPermitAdapter();
  private readonly commentAdapter = new ApiLocationCommentAdapter();

  adapt(apiDto: IApiLocationDetailed): LocationDetail {
    const latestPermit = apiAdaptOrNull.adaptOrNull(
      apiDto.latest_permit,
      this.permitAdapter,
    );

    const comments = apiArrayAdapter.adaptItemsOrEmpty(
      apiDto.comments,
      this.commentAdapter,
    );

    return {
      capacity: apiDto.capacity,
      city: apiDto.city,
      code: apiDto.code,
      id: apiDto.id,
      customerId: apiDto.customer,
      isActive: apiDto.is_active,
      isBoothRequired: apiDto.is_booth_required,
      isRainProof: apiDto.is_rain_proof,
      name: apiDto.name,
      pricePerDay: apiDto.price_per_day,
      streetAddress: apiDto.street_address,
      type: apiDto.type as LocationType,
      image: apiDto.image,
      coordinates: apiArrayAdapter.itemsOrNull(apiDto.coordinates),
      latestPermit,
      comments,
    };
  }
}

export interface ILocationDto {
  id?: LocationId;
  name: string;
  code: string;
  isActive: boolean;
  type: `${LocationType}`;
  isBoothRequired: boolean | null;
  isRainProof: boolean | null;
  capacity: number | null;
  city: string;
  pricePerDay: string | null;
  streetAddress: string;
  coordinates: [number, number] | null;
}

/**
 * Adapter for locations for the cockpit API
 */
export class LocationToApiAdapter {
  adaptModelToDto(value: Location): ILocationDto {
    return {
      capacity: value.capacity,
      city: value.city,
      code: value.code,
      isActive: value.isActive,
      isBoothRequired: value.isBoothRequired,
      isRainProof: value.isRainProof,
      name: value.name,
      pricePerDay: value.pricePerDay,
      streetAddress: value.streetAddress,
      type: value.type,
      coordinates: value.coordinates,
    };
  }

  adaptForCreate(value: ILocationDto): IApiLocationCreateDto {
    return this._toApiDto(value);
  }

  adaptForUpdate(value: ILocationDto): IApiLocationUpdateDto {
    return this._toApiDto(value);
  }

  /**
   * Adapts the given location data to be compatible with the API endpoint
   *
   * @param dto The location object
   * @param image The location image. When `null`, the image will be removed
   *
   * @returns
   * Parsed data that can be passed to the API endpoint
   */
  adaptWithImage(
    dto: IApiLocationCreateDto | IApiLocationUpdateDto,
    image: FileUpload | null | undefined,
  ): Record<string, any> {
    // To delete image send `image: null`
    if (!image) {
      return {
        ...dto,
        image: null,
      };
    }

    // If no change to image, don't use key
    if (!image.file) {
      return dto;
    }

    // As form data
    const data = toApiDtoAdapter.adaptToFormData(dto as Record<string, any>);
    data.append('image', image.file);

    return data;
  }

  private _toApiDto(
    dto: ILocationDto,
  ): IApiLocationCreateDto | IApiLocationUpdateDto {
    return {
      capacity: dto.capacity,
      city: dto.city,
      code: dto.code,
      is_active: dto.isActive,
      is_booth_required: dto.isBoothRequired,
      is_rain_proof: dto.isRainProof,
      name: dto.name,
      price_per_day: dto.pricePerDay,
      street_address: dto.streetAddress,
      type: dto.type,
      coordinates: dto.coordinates,
    };
  }
}

import { escapeRegExpString } from '@fmnts/core';

type StringWeighingFn = (value: string) => number;

/**
 * Creates a weighing function than can be used to
 * calulate a weight for an input string based on the
 * given `text`.
 *
 * @param text Text to match calling values against
 *
 * @returns
 * A function that when called with a value returns
 * A weight that is higher the closer this value
 * is to the given `text`.
 */
export function stringMatching(text: string): StringWeighingFn {
  const textRegex = new RegExp(escapeRegExpString(text), 'i');

  return (other) => {
    // 1. When they're equal
    if (other === text) {
      return 100;
    }
    // 2. When `needle` is part of `other`
    if (textRegex.test(other)) {
      return 50;
    }
    // 3. When `other` is part of `needle`
    if (new RegExp(other, 'i').test(text)) {
      return 10;
    }

    // No match
    return -1;
  };
}

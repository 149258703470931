<img
  *ngIf="this.image; else customContent"
  class="fmnts-avatar-icon__image"
  [src]="this.image"
/>

<!-- Alternative content when there is no image -->
<ng-template #customContent>
  <ng-content></ng-content>
</ng-template>

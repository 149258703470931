import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Nullish } from '@fmnts/core';
import { TimeSpanInput } from '@fmnts/core/chronos';
import { LocaleService } from '@fmnts/i18n';
import { Subscription } from 'rxjs';
import { DateRangePipe } from './date-range.pipe';

/**
 * Transforms a time span into a *localized* representation of a date range.
 * The transformation tries to narrow down the date range by its
 * components, so that e.g. a time span from the first to the last
 * of a month is represented by the month and year.
 *
 * @example
 * // whole year
 * transform(['2022-01-01', '2022-12-31']);
 * // => '2022' with locale 'en'
 *
 * @example
 * // whole month
 * transform(['2022-01-01', '2022-01-31']);
 * // => 'January 2022' with locale 'en'
 *
 * @example
 * // whole day
 * transform(['2022-01-01', '2022-01-01']);
 * // => 'January 1, 2022' with locale 'en'
 *
 * @example
 * // arbitrary date range
 * transform(['2022-01-01', '2022-01-03']);
 * // => '01/01/2022 - 01/03/2022' with locale 'en'
 */
@Pipe({
  name: 'i18n_dateRange',
  pure: false,
})
export class I18nDateRangePipe
  extends DateRangePipe
  implements OnDestroy, PipeTransform
{
  private _locale = this.localeService.fallbackLocale;
  private readonly sub: Subscription;

  constructor(
    private localeService: LocaleService,
    cd: ChangeDetectorRef,
  ) {
    super();

    // This will emit immediately, so locale should always have a value
    this.sub = localeService.locale$.subscribe((locale) => {
      this._locale = locale;
      cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * ***Impure***
   *
   * Uses the latest emitted locale to transform the given time span
   * to a "minimal" date range.
   *
   * @param value Time span
   *
   * @returns
   * Localized "minimal" date range.
   */
  override transform(value: TimeSpanInput | Nullish): string {
    return super.transform(value, this._locale);
  }
}

import { NgModule } from '@angular/core';
import { MultiSelectComponent } from './multi-select.component';
import { OptionComponent } from './option.component';
import { SelectComponent } from './select.component';

@NgModule({
  exports: [SelectComponent, MultiSelectComponent, OptionComponent],
  imports: [SelectComponent, MultiSelectComponent, OptionComponent],
})
export class FmntsSelectModule {}

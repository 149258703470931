import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { classnames } from '@fmnts/common';
import { AvatarSize } from './avatar-model';

/**
 * Avatar component
 *
 * @example
 * ```html
 * <fmnts-avatar>
 * </fmnts-avatar>
 * ```
 */
@Component({
  selector: 'fmnts-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @HostBinding('class.fmnts-avatar')
  protected readonly componentClass = 'fmnts-avatar';

  /**
   * Whether the avatar should be disabled
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(val: BooleanInput) {
    this._disabled = coerceBooleanProperty(val);
  }
  private _disabled = false;

  /**
   * Size to use for the avatar.
   * This property propagates down to `<fmnts-avatar-icon>`
   */
  @Input() size: AvatarSize = 'medium';

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      `${this.componentClass}--${this.size}`,
      this.disabled && `${this.componentClass}--disabled`,
    ]);
  }
}

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  // todo(arch): remove or move these types here
  AuthSession,
  AuthInfo as LegacyAuthInfo,
  Permission,
} from '@fmnts/api/auth';
import { enumValueGuard } from '@fmnts/core';
import * as Arr from 'effect/Array';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';
import * as P from 'effect/Predicate';
import { CustomerId } from './customer.model';

export {
  AuthSession,
  /** @deprecated use {@link UserAuth} or {@link AuthInfo}. */
  LegacyAuthInfo,
  Permission,
};

export enum Role {
  AgencyAdminOne = 'Agency Admin One',
  CallingAgent = 'Calling Agent',
  CampaignManager = 'Campaign Manager',
  Coach = 'Coach',
  CustomerAdmin = 'Customer Admin',
  FormunautsAdmin = 'Formunauts admin',
  Fundraiser = 'Fundraiser',
  HeadRecruiter = 'Head Recruiter',
  JobScout = 'Job Scout',
  Organization = 'Organization',
  PetitionCampaignManager = 'Petition Campaign Manager',
  PetitionCustomerAdmin = 'Petition Customer Admin',
  Recruiter = 'Recruiter',
  TeamLeader = 'Team Leader',
}
export type Roles = `${Role}`;

export interface User {
  /** Username (unique). */
  readonly username: string;
  /** User email */
  readonly email: string;
  /** If `true`, the password is considered stale and should be updated. */
  readonly stalePassword: boolean;
}

/**
 * Info about what a user is authorized to do.
 */
export interface AuthInfo {
  readonly roles: ReadonlySet<Role>;
  readonly permissions: ReadonlySet<Permission>;
}

/**
 * User info with authorization
 */
export interface UserAuth {
  readonly user: User;
  readonly customerId: O.Option<CustomerId>;
  readonly auth: AuthInfo;
}

/**
 * Transforms a {@link LegacyAuthInfo} into an option of {@link UserAuth}.
 */
export function legacyAuthInfoToUserAuth(
  legacyAuthInfo: LegacyAuthInfo,
): O.Option<UserAuth> {
  return Fn.pipe(
    _legacyAuthInfoToUser(legacyAuthInfo),
    O.map((user) => ({
      user,
      auth: legacyAuthInfoToAuthInfo(legacyAuthInfo),
      customerId: Fn.pipe(
        legacyAuthInfo.customerId,
        O.fromNullable,
        O.map(CustomerId),
      ),
    })),
  );
}

/**
 * Transforms a {@link LegacyAuthInfo} to an option of {@link User}.
 *
 * @returns
 * none if no username, email or fullname is provided.
 */
function _legacyAuthInfoToUser(legacyAuthInfo: LegacyAuthInfo): O.Option<User> {
  return O.all({
    email: O.fromNullable(legacyAuthInfo.email),
    stalePassword: O.some(legacyAuthInfo.stalePassword),
    username: O.fromNullable(legacyAuthInfo.username),
  });
}

/**
 * Transforms a {@link LegacyAuthInfo} into an {@link AuthInfo}.
 */
export function legacyAuthInfoToAuthInfo(
  legacyAuthInfo: LegacyAuthInfo,
): AuthInfo {
  return Fn.pipe(
    legacyAuthInfo,
    _rolesForAuthInfo,
    _authInfoFromRoles,
    (a): AuthInfo => ({
      ...a,
      permissions: new Set(
        Arr.appendAll(a.permissions, legacyAuthInfo.permissions),
      ),
    }),
  );
}

/**
 * @param roles Collection of roles
 * @returns
 * Evaluated `AuthInfo`.
 */
function _authInfoFromRoles(roles: Iterable<Role>): AuthInfo {
  return {
    roles: new Set(roles),
    permissions: new Set(_getPermissionsForRoles(roles)),
  };
}

function _rolesForAuthInfo(authInfo: LegacyAuthInfo): ReadonlyArray<Role> {
  const roles: Role[] = [];

  if (!authInfo || !authInfo.isActive) {
    return roles;
  }

  if (authInfo.isAgencyAdminOne) {
    roles.push(Role.AgencyAdminOne);
  }
  if (authInfo.isCallingAgent) {
    roles.push(Role.CallingAgent);
  }
  if (authInfo.isCampaignManager) {
    roles.push(Role.CampaignManager);
  }
  if (authInfo.isCoach) {
    roles.push(Role.Coach);
  }
  if (authInfo.isCustomerAdmin) {
    roles.push(Role.CustomerAdmin);
  }
  if (authInfo.isFormunautsAdmin) {
    roles.push(Role.FormunautsAdmin);
  }
  if (authInfo.isFundraiser) {
    roles.push(Role.Fundraiser);
  }
  if (authInfo.isHeadRecruiter) {
    roles.push(Role.HeadRecruiter);
  }
  if (authInfo.isJobScout) {
    roles.push(Role.JobScout);
  }
  if (authInfo.isOrganizationUser) {
    roles.push(Role.Organization);
  }
  if (authInfo.isPetitionCampaignManager) {
    roles.push(Role.PetitionCampaignManager);
  }
  if (authInfo.isPetitionCustomerAdmin) {
    roles.push(Role.PetitionCustomerAdmin);
  }
  if (authInfo.isRecruiter) {
    roles.push(Role.Recruiter);
  }
  if (authInfo.isTeamLeader) {
    roles.push(Role.TeamLeader);
  }

  return roles;
}

export const hasPermission =
  (p: Permission): P.Predicate<AuthInfo> =>
  ({ permissions: actual }) =>
    actual.has(p);
export const hasRole =
  (r: Role): P.Predicate<AuthInfo> =>
  ({ roles: actual }) =>
    actual.has(r);

export type Permissions = `${Permission}`;
/** Type guard that checks if any value is a member of `Permission`. */
export const isPermission = enumValueGuard(Permission);

export const hasSomePermission =
  (required: Arr.NonEmptyReadonlyArray<Permission>): P.Predicate<AuthInfo> =>
  ({ permissions: actual }) =>
    // first permission that is fulfilled, return true
    required.some((p) => actual.has(p));

export const hasEveryPermission =
  (required: Arr.NonEmptyReadonlyArray<Permission>): P.Predicate<AuthInfo> =>
  ({ permissions: actual }) =>
    // first permission that isn't fulfilled, return false
    required.some((r) => !actual.has(r)) === false;

/**
 * Permissions that aren't managed within the realms of the frontend.
 */
const _EXTRINSIC_PERMISSIONS = [
  Permission.ViewTerritory,
  Permission.ViewTerritoryList,
] as const;
/** Permissions that can't be determined by the frontend. */
export type ExtrinsicPermission = Arr.ReadonlyArray.Infer<
  typeof _EXTRINSIC_PERMISSIONS
>;
/** Permissions that are determined by the frontend. */
export type IntrinsicPermission = Exclude<Permission, ExtrinsicPermission>;

/** Type guard for extrinsic permissions. */
export function isExtrinsicPermission(p: Permission): p is ExtrinsicPermission {
  return (_EXTRINSIC_PERMISSIONS as ReadonlyArray<Permission>).includes(p);
}
/** Type guard for intrinsic permissions. */
export const isIntrinsicPermission = P.not(
  isExtrinsicPermission,
) as P.Refinement<Permission, IntrinsicPermission>;

function _getPermissionsForRoles(
  roles: Iterable<Role>,
): ReadonlySet<IntrinsicPermission> {
  const result = new Set<IntrinsicPermission>();

  for (const r of roles) {
    const permissions = ROLES_PER_PERMISSION.get(r) ?? [];
    for (const p of permissions) {
      result.add(p);
    }
  }

  return result;
}

type RolesPerPermissionMapping = Readonly<{
  [P in IntrinsicPermission]: ReadonlyArray<Role>;
}>;
type RolePermissionMap = ReadonlyMap<Role, ReadonlySet<IntrinsicPermission>>;

function _createRolePermissionMap(
  rolesPerPermission: RolesPerPermissionMapping,
): RolePermissionMap {
  const ALL_ROLES = Object.values(Role);
  const INTRINSIC_PERMISSIONS = Object.values(Permission).filter(
    isIntrinsicPermission,
  );

  const roleHasPermission = (r: Role, p: keyof typeof rolesPerPermission) =>
    rolesPerPermission[p].includes(r);

  const permissionsForRole = (r: Role): ReadonlyArray<IntrinsicPermission> =>
    Arr.filter(INTRINSIC_PERMISSIONS, (p) => roleHasPermission(r, p));

  return new Map(
    ALL_ROLES.map((role) => [role, new Set(permissionsForRole(role))]),
  );
}

/**
 * Map that specifies which roles have which permissions.
 */
const ROLES_PER_PERMISSION: RolePermissionMap = _createRolePermissionMap({
  // static permissions by roles
  [Permission.Login]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.HeadRecruiter,
    Role.JobScout,
    Role.Recruiter,
    Role.TeamLeader,
  ],
  [Permission.ViewDashboard]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewDashboardActivity]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewDashboardPerformance]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivities]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivitiesDonations]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivitiesAchievements]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewActivitiesDonorFeeback]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],
  [Permission.ViewTeamList]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.ViewTeam]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.CreateTeam]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.EditTeam]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.DeleteTeam]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],

  [Permission.ViewFundraiserList]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.ViewFundraiser]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.CreateFundraiser]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.EditFundraiser]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.PromoteFundraiserToCoach]: [Role.Coach, Role.CustomerAdmin],
  [Permission.PromoteFundraiserToTeamLeader]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.DeleteFundraiser]: [
    Role.TeamLeader,
    Role.Coach,
    Role.CustomerAdmin,
  ],

  [Permission.ViewDonationList]: [Role.AgencyAdminOne, Role.CustomerAdmin],
  [Permission.ViewDonation]: [Role.AgencyAdminOne, Role.CustomerAdmin],
  [Permission.CreateDonation]: [Role.CustomerAdmin],
  [Permission.EditDonation]: [Role.CustomerAdmin],
  [Permission.DeleteDonation]: [Role.CustomerAdmin],

  [Permission.ViewUnfinishedDonationList]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewUnfinishedDonation]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.CreateUnfinishedDonation]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.EditUnfinishedDonation]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.DeleteUnfinishedDonation]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewDonationStatistics]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewCampaignStatistics]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewFundraiserStatistics]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocationStatistics]: [
    Role.AgencyAdminOne,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewTeamStatistics]: [
    Role.AgencyAdminOne,
    Role.TeamLeader,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewLocationList]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocation]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.EditLocation]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocationCommentList]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.CreateLocationComment]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewLocationReporting]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.Organization,
  ],
  [Permission.CreateLocation]: [Role.CampaignManager, Role.CustomerAdmin],

  [Permission.ViewDonorFeedbackEvaluation]: [
    Role.AgencyAdminOne,
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.EditDonorFeedbackComment]: [
    Role.Coach,
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewPetitions]: [Role.PetitionCustomerAdmin],
  [Permission.ViewPetitionStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],
  [Permission.ViewPetitionCampaignStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],
  [Permission.ViewPetitionFundraiserStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],
  [Permission.ViewPetitionTeamStatistics]: [
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
  ],

  [Permission.ViewRecruitingStatistics]: [
    Role.CustomerAdmin,
    Role.HeadRecruiter,
    Role.Recruiter,
  ],

  [Permission.ViewCsvArchives]: [Role.CustomerAdmin],
  [Permission.ViewSupporterFeedback]: [Role.CustomerAdmin],
  [Permission.ViewTimeTracking]: [Role.CustomerAdmin],
  [Permission.ViewGamificationAchievements]: [Role.CustomerAdmin],

  [Permission.ViewRecruitingTeams]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingJobScouts]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingJobApplications]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingCampaigns]: [Role.CustomerAdmin],
  [Permission.ViewRecruitingSettings]: [Role.CustomerAdmin],

  [Permission.ViewUserSettings]: [Role.CustomerAdmin],
  [Permission.ViewLocationSettings]: [Role.CustomerAdmin],
  [Permission.ViewCampaignSettings]: [Role.CustomerAdmin],
  [Permission.ViewPetitionCampaignSettings]: [Role.CustomerAdmin],
  [Permission.ViewWorkShiftSettings]: [Role.CustomerAdmin],
  [Permission.ViewBlackListItemSettings]: [Role.CustomerAdmin],

  [Permission.ViewTrainingcenterGroups]: [
    Role.AgencyAdminOne,
    Role.Coach,
    Role.CustomerAdmin,
    Role.CampaignManager,
  ],
  [Permission.EditTrainingcenterGroups]: [
    Role.AgencyAdminOne,
    Role.CustomerAdmin,
  ],
  [Permission.ViewTrainingcenterQuizzes]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
  ],
  [Permission.EditTrainingcenterQuizzes]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],
  [Permission.ViewTrainingcenterQuizReports]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.TeamLeader,
  ],

  [Permission.ViewTrainingcenterQuizReportAttempts]: [
    Role.CampaignManager,
    Role.CustomerAdmin,
  ],

  [Permission.ViewAdminInterface]: [
    Role.AgencyAdminOne,
    Role.CallingAgent,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.FormunautsAdmin,
    Role.HeadRecruiter,
    Role.JobScout,
    Role.Recruiter,
    Role.TeamLeader,
  ],

  [Permission.ViewNext]: [
    Role.Fundraiser,
    Role.Coach,
    Role.TeamLeader,
    Role.JobScout,
  ],
  [Permission.ViewLauncherFundraiserStatistics]: [
    Role.Fundraiser,
    Role.Coach,
    Role.TeamLeader,
    Role.JobScout,
  ],
  [Permission.ViewLauncherTeamStatistics]: [Role.Coach, Role.TeamLeader],

  [Permission.ViewCockpit]: [
    Role.AgencyAdminOne,
    Role.CampaignManager,
    Role.Coach,
    Role.CustomerAdmin,
    Role.FormunautsAdmin,
    Role.HeadRecruiter,
    Role.Organization,
    Role.PetitionCampaignManager,
    Role.PetitionCustomerAdmin,
    Role.Recruiter,
    Role.TeamLeader,
  ],
});

type Compareable = number | string | bigint;
type QueryFn<T> = (arr: readonly T[]) => T | undefined;

/**
 * @returns
 * A query function that when called with an array returns
 * the first item with the highest value or `undefined` if
 * the array is empty.
 *
 * @example
 * maxItem()([1, 2, 3]); // 3
 */
export function maxItem<T extends Compareable>(): QueryFn<T> {
  return (arr) => findMaxItem<T>(arr, (a, b) => a > b);
}

/**
 * @returns
 * A query function that when called with an array returns
 * the first item with the highest value returned by the
 * provided `select` function for an item or `undefined` if
 * the array is empty.
 *
 * @example
 * const withMaxCount = maxItem((item) => item.count)
 * withMaxCount([
 *  { count: 1, name: 'first' },
 *  { count: 2, name: 'second' },
 *  { count: 2, name: 'third' }
 * ]); // => { count: 2, name: 'second' }
 */
export function maxItemBy<T>(select: (e: T) => Compareable): QueryFn<T> {
  return (arr) => findMaxItem<T>(arr, (a, b) => select(a) > select(b));
}

function findMaxItem<T>(
  arr: readonly T[],
  isBigger: (a: T, b: T) => boolean,
): T | undefined {
  return arr.reduce((acc, cur) => (isBigger(acc, cur) ? acc : cur), arr[0]);
}

import { InlineTranslateable } from '@fmnts/i18n';
import {
  Comparer,
  createEntityAdapter,
  Dictionary,
  EntityAdapter,
  EntityState,
  IdSelector,
} from '@ngrx/entity';
import {
  AsyncUploadAdapter,
  AsyncUploadStatus,
  EntityAsyncUpload,
  EntityAsyncUploadAdapter,
  OfflineStoredData,
  UploadData,
} from './async-upload.model';

/**
 * Adapter for the async upload store addon.
 * @returns
 */
export function createAsyncUploadAdapter<
  T extends OfflineStoredData<any>,
>(): AsyncUploadAdapter<T> {
  return new BaseAsyncDataAdapter<T>();
}

class BaseAsyncDataAdapter<T extends OfflineStoredData<any>>
  implements AsyncUploadAdapter<T>
{
  getInitialState(): UploadData<T>;
  getInitialState(data: T): UploadData<T>;
  getInitialState(data?: T): UploadData<T> | undefined {
    return data
      ? {
          ...data,
          status: AsyncUploadStatus.Pristine,
        }
      : undefined;
  }

  setStatus<S extends UploadData<T>>(state: S, status: AsyncUploadStatus): S {
    return { ...state, status };
  }
  setError<S extends UploadData<T>>(error: InlineTranslateable, state: S): S {
    return { ...state, status: AsyncUploadStatus.Failed, error };
  }
}

/**
 * Adapter for the async-entity upload store addon.
 *
 * Uses both `@fmnts/common/store/async-upload` & `@ngrx/entity` addons to create a combined
 * adapter, to use for persisting data offline and allow upload of data.
 *
 * @returns
 */
export function createEntityAsyncUploadAdapter<
  T extends OfflineStoredData<TData>,
  TData = unknown,
>(options?: {
  selectId?: IdSelector<UploadData<T>>;
  sortComparer?: false | Comparer<UploadData<T>>;
}): CombinedEntityAsyncUploadAdapter<T> {
  return new CombinedEntityAsyncUploadAdapter<T>(options);
}

class CombinedEntityAsyncUploadAdapter<
  T extends OfflineStoredData<TData>,
  TData = unknown,
> implements EntityAsyncUploadAdapter<T>
{
  private entityAdapter!: EntityAdapter<UploadData<T>>;
  private asyncAdapter!: BaseAsyncDataAdapter<T>;

  constructor(options?: {
    selectId?: IdSelector<UploadData<T>>;
    sortComparer?: false | Comparer<UploadData<T>>;
  }) {
    this.entityAdapter = createEntityAdapter<UploadData<T>>({
      selectId: options?.selectId,
      sortComparer: options?.sortComparer,
    });

    this.asyncAdapter = createAsyncUploadAdapter<T>();
  }

  getInitialState(state?: EntityState<T>): EntityAsyncUpload<T> {
    const updatedState: EntityAsyncUpload<T> = {
      entities: {} as Dictionary<UploadData<T>>,
      ids: [] as number[] | string[],
    };

    if (state) {
      for (const entity in state.entities) {
        if (state.entities[entity]) {
          updatedState.entities[entity] = this.asyncAdapter.getInitialState(
            state.entities[entity] as T,
          );
        }
      }
    }

    return updatedState;
  }

  getSelectors(
    selector: (state: Record<string, any>) => EntityState<UploadData<T>>,
  ) {
    return this.entityAdapter.getSelectors(selector);
  }

  /**
   * Wrapper for `@ngrx/entity` `addOne` function to add one entity to
   * the EntityState. Upon adding it will set the state to `Pristine`.
   *
   * @param entity item to add
   * @param state current state
   * @returns new state
   */
  addOne<S extends EntityAsyncUpload<T>>(entity: T, state: S): S {
    const updatedEntity = this.asyncAdapter.getInitialState(entity);

    return this.entityAdapter.addOne(updatedEntity, state);
  }

  /**
   * Wrapper for `@ngrx/entity` `setMany` function to add entities to
   * the EntityState. Upon adding it will set the state to `Pristine` by default.
   *
   * @param entities items to add
   * @param state current state
   * @returns new state
   */
  setMany<S extends EntityAsyncUpload<T>>(
    entities: T[],
    state: S,
    status: AsyncUploadStatus = AsyncUploadStatus.Pristine,
  ): S {
    const updatedEntities: UploadData<T>[] = entities.map((e) => ({
      ...e,
      status,
    }));
    return this.entityAdapter.setMany(updatedEntities, state);
  }

  /**
   * Wrapper for `@ngrx/entity` `removeOne` function to delete an item
   * in the EntityState.
   *
   * @param dataKey reference to item
   * @param state current state
   * @returns new state
   */
  removeOne<S extends EntityAsyncUpload<T>>(dataKey: string, state: S): S {
    return this.entityAdapter.removeOne(dataKey, state);
  }

  setLoading<S extends EntityAsyncUpload<T>>(dataKey: string, state: S): S {
    return this.entityAdapter.updateOne(
      {
        id: dataKey,
        changes: {
          status: AsyncUploadStatus.Uploading,
        } as Partial<UploadData<T>>,
      },
      state,
    );
  }
  setError<S extends EntityAsyncUpload<T>>(
    dataKey: string,
    error: InlineTranslateable,
    state: S,
  ): S {
    return this.entityAdapter.updateOne(
      {
        id: dataKey,
        changes: {
          status: AsyncUploadStatus.Failed,
          error,
        } as Partial<UploadData<T>>,
      },
      state,
    );
  }

  setCompleted<S extends EntityAsyncUpload<T>>(dataKey: string, state: S): S {
    return this.entityAdapter.updateOne(
      {
        id: dataKey,
        changes: {
          status: AsyncUploadStatus.Completed,
        } as Partial<UploadData<T>>,
      },
      state,
    );
  }
}

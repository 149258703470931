import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { PrimitiveNullUndefined, isNil } from '@fmnts/core';
import { debounceTime, merge } from 'rxjs';
import {
  FMNTS_SELECT_OPTION_PARENT_COMPONENT,
  OptionComponent,
} from './option.component';
import { fmntsSelectAnimations } from './select-animations';
import { SelectBaseComponent } from './select-base';

@Component({
  selector: 'fmnts-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [FmntsIconsModule, ReactiveFormsModule],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fmntsSelectAnimations.rotateIcon],
  providers: [
    {
      provide: FMNTS_SELECT_OPTION_PARENT_COMPONENT,
      useExisting: MultiSelectComponent,
    },
  ],
})
export class MultiSelectComponent<
    TValue extends PrimitiveNullUndefined = PrimitiveNullUndefined,
  >
  extends SelectBaseComponent<TValue[], TValue>
  implements AfterContentInit, OnInit
{
  @ContentChildren(OptionComponent, { descendants: true })
  override options!: QueryList<OptionComponent<TValue>>;

  protected readonly typeAheadControl = new FormControl<string>('', {
    nonNullable: true,
  });

  protected override readonly _clearValue: TValue[] = [];
  /** Selected values */
  public value: TValue[] = this._clearValue;

  public override multiple = true;
  public hasTypeAheadResult = true;

  protected get selected(): OptionComponent<TValue>[] {
    return this.selectionModel?.selected ?? [];
  }

  public get isEmpty(): boolean {
    return this.selected.length <= 0;
  }

  public get formattedValues(): string {
    if (!this.selectionModel.selected) {
      return '';
    }

    const selectedOptions = this.selectionModel.selected.map(
      (option) => option.displayValue,
    );

    return selectedOptions.join(', ');
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Listen to changes in typeahead search-control and filter options
    merge(this.typeAheadControl.valueChanges, this.valueChange)
      .pipe(debounceTime(25))
      .subscribe(() => this.filterForTypeAhead(this.typeAheadControl.value));
  }

  writeValue(newValue: TValue[]): void {
    if (newValue !== this.value || Array.isArray(newValue)) {
      if (this.options) {
        this._setSelectionByValue(newValue);
      }

      this.value = newValue || [];
    }
  }

  /**
   * Finds and selects and option based on its value.
   * @returns Option that has the corresponding value.
   */
  protected override _selectOptionsByValue(
    value: PrimitiveNullUndefined[],
  ): OptionComponent<TValue>[] {
    const _valueSafe = Array.isArray(value)
      ? value
      : isNil(value)
        ? []
        : [value];

    // Clear all previous values
    this.selectionModel.clear();

    const correspondingOptions = this.options.filter((option) => {
      // Skip options that are already in the model
      if (this.selectionModel.isSelected(option)) {
        return false;
      }

      // Treat null as a special reset value.
      return (
        option.value !== null &&
        option.value !== undefined &&
        _valueSafe.some((v) => this._compareWith(option.value, v))
      );
    });

    if (correspondingOptions) {
      this.selectionModel.select(...correspondingOptions);
    }

    return correspondingOptions;
  }

  protected override _onSelect(
    option: OptionComponent<TValue>,
    isUserInput: boolean,
  ): void {
    const wasSelected = this.selectionModel.isSelected(option);

    if (wasSelected !== option.selected) {
      if (option.selected) {
        this.selectionModel.select(option);
      } else {
        this.selectionModel.deselect(option);
      }
    }

    if (isUserInput) {
      // In case the user selected the option with their mouse, we
      // want to restore focus back to the trigger, in order to
      // prevent the select keyboard controls from clashing with
      // the ones from `mat-option`.
      // this.focus();
    }

    if (wasSelected !== this.selectionModel.isSelected(option)) {
      this._propagateChanges();
    }
  }

  protected _propagateChanges(): void {
    // Using non-null assertion here because the value an option could have no value
    // such as the deselect option. These should not be in the selected items.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-type-assertion
    this.propagateValueChange(this.selected.map((option) => option.value!));
  }

  /**
   * Filter values for typeahead
   *
   * Sets options as `disabled`, if they do not match with the
   * search query.
   */
  private filterForTypeAhead(value: string) {
    if (!this.showSearchField) {
      return;
    }

    this.hasTypeAheadResult = false;
    this.options.forEach((option) => {
      // set options to disabled, if they do not match with typeahead
      const matchedOption =
        option.displayValue?.toLowerCase().includes(value.toLowerCase()) ??
        true;
      option.hidden = !matchedOption;

      if (matchedOption && !option.selected) {
        this.hasTypeAheadResult = true;
      }
    });
    this.cd.markForCheck();
  }
}

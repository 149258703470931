import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { FmntsLabelDirective } from '@fmnts/components/form-field';
import { Nullish } from '@fmnts/core';
import {
  FmntsInputPrefixDirective,
  FmntsInputSuffixDirective,
} from './input-prefix-suffix.directive';

type TextFieldAppearance = 'fill' | 'outline' | 'underline';
type TextFieldShape = 'rounded' | 'pill';

/**
 * Renders a text field component.
 *
 * When using shape pill, consider not using a visible label inside.
 *
 */
@Component({
  selector: 'fmnts-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FmntsTextFieldComponent {
  @HostBinding('class.fmnts-text-input') protected readonly componentClass =
    'fmnts-text-input';

  @ContentChild(FmntsLabelDirective, { descendants: true })
  protected _label: FmntsLabelDirective | Nullish;
  @ContentChildren(FmntsInputPrefixDirective, { descendants: true })
  protected _prefixChildren!: QueryList<FmntsInputPrefixDirective>;
  @ContentChildren(FmntsInputSuffixDirective, { descendants: true })
  protected _suffixChildren!: QueryList<FmntsInputSuffixDirective>;

  /**
   * Appearance of the text field.
   */
  @Input() appearance: TextFieldAppearance = 'fill';

  /**
   * Shape of the text field.
   */
  @Input() shape: TextFieldShape = 'rounded';

  @HostBinding('class')
  protected get hostClasses(): string {
    const prefix = this.componentClass;
    return classnames([
      `${prefix}--${this.appearance}`,
      `${prefix}--${this.shape}`,
      this._label && `${prefix}--has-label`,
      this._prefixChildren.length > 0 && `${prefix}--has-prefix`,
      this._suffixChildren.length > 0 && `${prefix}--has-suffix`,
    ]);
  }
}

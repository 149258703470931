/**
 * @description
 *
 * A `Use Case` describes a use case of an application.
 *
 * In general, a use case can be either:
 * - a query (get something)
 * - a command (do something)
 *
 * Within Clean Architecture, a use case may have
 * - input ports: used to retrieve information
 * - output ports: used to spread information
 */

import {
  EMPTY,
  catchError,
  finalize,
  isObservable,
  of,
  tap,
  throwError,
  type MonoTypeOperatorFunction,
  type Observable,
} from 'rxjs';

export interface UseCaseFn<UseCaseName extends string, TRequest, TSuccess> {
  /** Name of the use case. */
  useCase: UseCaseName;
  /** Executes the usecase */
  (request: TRequest | Observable<TRequest>): Observable<TSuccess>;
}

type UseCaseInteractor<TRequest, TSuccess> = (
  source$: Observable<TRequest>,
) => Observable<TSuccess>;

type UseCaseInteractorFactory<TRequest, TSuccess> = (
  useCaseName: string,
) => UseCaseInteractor<TRequest, TSuccess>;

/**
 * An error to be thrown by a use case.
 */
export class UseCaseError extends Error {
  constructor(
    /** The name of the use case. */
    public readonly useCase: string,
    /** The actual error that occured. */
    public readonly error: Error,
  ) {
    super(`${useCase}: ${error.message}`);
  }
}

/**
 * Base class to implement a collection of use cases.
 */
export abstract class AbstractUseCaseCollection {
  /**
   * Creates a use case function
   * @param name Name of the use case
   * @param interactor
   * @returns
   */
  protected createUseCase<UseCaseName extends string, TRequest, TSuccess>(
    name: UseCaseName,
    interactorFactory: UseCaseInteractorFactory<TRequest, TSuccess>,
  ): UseCaseFn<UseCaseName, TRequest, TSuccess> {
    return _createUseCaseFn(name, interactorFactory(name));
  }
}

/**
 * Creates a use case interactor for a use case.
 *
 * @param generator The generator for the use case interactor
 *
 * @returns
 * Factory for use case interactor
 */
export function withUseCaseInteractor<TRequest, TSuccess>(
  generator: UseCaseInteractorFactory<TRequest, TSuccess>,
): UseCaseInteractorFactory<TRequest, TSuccess> {
  return generator;
}

/**
 * Use to operator on the result of a use case.
 *
 * @param observer result observers
 */
export function tapUseCaseResponse<TSuccess>(observer: {
  next: (value: TSuccess) => void;
  error: (error: UseCaseError) => void;
  complete?: () => void;
  finalize?: () => void;
}): MonoTypeOperatorFunction<TSuccess> {
  return (source) =>
    source.pipe(
      tap({
        next: observer.next,
        complete: observer.complete,
      }),
      catchError((error) => {
        observer.error(error);
        return EMPTY;
      }),
      observer.finalize ? finalize(observer.finalize) : (source$) => source$,
    );
}

/** Helper to create a use case function. */
function _createUseCaseFn<UseCaseName extends string, TRequest, TSuccess>(
  name: UseCaseName,
  interactor: UseCaseInteractor<TRequest, TSuccess>,
): UseCaseFn<UseCaseName, TRequest, TSuccess> {
  const useCaseFn: UseCaseFn<UseCaseName, TRequest, TSuccess> = (request) =>
    interactor(_makeObservableSource(request)).pipe(
      catchError((error) => throwError(() => new UseCaseError(name, error))),
    );

  useCaseFn.useCase = name;

  return useCaseFn;
}

/** Helper to make a source an observable */
function _makeObservableSource<T>(source: T | Observable<T>): Observable<T> {
  return isObservable(source) ? source : of(source);
}

import { Observable } from 'rxjs';

/**
 * Reads a blob as a data URI.
 *
 * @param blob Blob to read.
 *
 * @returns
 * Observable that emits with the data as a data URI string,
 * then completes.
 */
export function blobAsDataUri(blob: Blob): Observable<string> {
  return new Observable<string>((subscriber) => {
    const reader = new FileReader();
    const loadendHandler = () => {
      subscriber.next(reader.result as string);
      subscriber.complete();
    };
    const errorHandler = (ev: ProgressEvent<FileReader>) => {
      subscriber.error(ev);
    };

    reader.addEventListener('loadend', loadendHandler);
    reader.addEventListener('error', errorHandler);
    reader.readAsDataURL(blob);

    return () => {
      reader.removeEventListener('loadend', loadendHandler);
      reader.removeEventListener('error', errorHandler);
      reader.abort();
    };
  });
}

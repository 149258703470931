import {
  matchMaybe,
  matchMaybeOption,
  Maybe,
  MaybeOption,
  Pagination,
} from '@fmnts/core';
import { pluck } from '@fmnts/core/object';
import { createSelector, Selector } from '@ngrx/store';
import {
  collectAllFromEntityDictionary,
  collectFromEntityDictionary,
} from '../entity/collect-entity-map';
import { EntityDictionary } from '../entity/store-entity.model';
import { StoreProp } from '../store.model';
import {
  PageId,
  PageOfIds,
  PaginationData,
  PaginationSelectors,
  PaginationState,
} from './pagination.model';

/**
 * @returns
 * A function that combines pages with current pagination meta data.
 */
export function combineWithPagination<IdType extends StoreProp>() {
  return (
    pages: EntityDictionary<IdType[], number>,
    maybePagination: MaybeOption<Pagination>,
  ): MaybeOption<PageOfIds<IdType>> =>
    matchMaybeOption({
      onValue: (pagination: Pagination) =>
        matchMaybe({
          onValue: (results: IdType[]): PageOfIds<IdType> => ({
            ...pagination,
            results,
          }),
        })(pages[pagination.currentPage]),
    })(maybePagination);
}

/**
 * Creates a selector function to de-normalize a pagination
 * object.
 *
 * @returns
 * Selector function that takes an entity dictionary and
 * a pagination object containing the IDs on the current
 * page.
 */
export function collectEntityPage<TEntity, IdType extends StoreProp>() {
  const _getEntitiesByIds = collectPaginatedEntitiesById<TEntity, IdType>();
  const _getEntityIds = collectPaginatedEntityIds<IdType>();
  return (
    entityDict: EntityDictionary<TEntity, IdType>,
    pages: EntityDictionary<IdType[], PageId>,
    maybePagination: MaybeOption<Pagination>,
  ): MaybeOption<Maybe<TEntity>[]> =>
    _getEntitiesByIds(
      entityDict,
      _getEntityIds(pages, maybePagination?.currentPage),
    );
}

/**
 * @returns
 * A function that combines an entity dictionary and a
 * page of entity IDs to a set of entities.
 */
export function collectEntitiesFromPageOfIds<
  TEntity,
  IdType extends StoreProp,
>() {
  const getEntities = collectPaginatedEntitiesById<TEntity, IdType>();
  return (
    entityDict: EntityDictionary<TEntity, IdType>,
    maybeIdPage: MaybeOption<PageOfIds<IdType>>,
  ): MaybeOption<Maybe<TEntity>[]> =>
    getEntities(entityDict, maybeIdPage?.results);
}

/**
 * Selects the pagination data from a state holding pagination data.
 */
export function selectPaginationDataFromState<
  T,
  IdType extends StoreProp = number,
>({ pagination }: PaginationState<T, IdType>): PaginationData<T, IdType> {
  return pagination;
}

const pluckCurrentPage = pluck(['currentPage']);
const pluckPages = pluck(['pages']);
const pluckPageSize = pluck(['pageSize']);
const pluckCount = pluck(['count']);

/**
 * Used to generate pagination selectors that are memoized.
 *
 * @param selectState Selector for the pagination state
 * @param selectEntities Selector for the entities that are referenced by pagination.
 */
export function getPaginationSelectors<V, T, IdType extends StoreProp>(
  selectEntities: Selector<V, EntityDictionary<T, IdType>>,
  selectState: Selector<V, PaginationState<T, IdType>>,
): PaginationSelectors<V, T, IdType> {
  const selectPaginationData = createSelector(
    selectState,
    selectPaginationDataFromState,
  );

  const selectCurrentPage = createSelector(
    selectPaginationData,
    pluckCurrentPage,
  );
  const selectPages = createSelector(selectPaginationData, pluckPages);
  const selectPageSize = createSelector(selectPaginationData, pluckPageSize);
  const selectCount = createSelector(selectPaginationData, pluckCount);

  const selectCurrentPageEntityIds = createSelector(
    selectPages,
    selectCurrentPage,
    collectPaginatedEntityIds(),
  );

  const selectCurrentPageEntities = createSelector(
    selectEntities,
    selectCurrentPageEntityIds,
    collectPaginatedEntitiesById(),
  );

  return {
    selectPaginationData,
    selectCurrentPageEntities,
    selectPageSize,
    selectCount,
  };
}

/**
 * Helper to collect the entity IDs from a page dictionary.
 */
function collectPaginatedEntityIds<IdType extends StoreProp>() {
  return (
    pages: EntityDictionary<IdType[], PageId>,
    maybePage: MaybeOption<PageId>,
  ): MaybeOption<IdType[]> =>
    matchMaybeOption({
      onValue: collectFromEntityDictionary(pages),
    })(maybePage);
}

/**
 * Helper to collect entities by their IDs from a dictionary of entities
 * and a set of IDs.
 */
function collectPaginatedEntitiesById<TEntity, IdType extends StoreProp>() {
  return (
    entityDict: EntityDictionary<TEntity, IdType>,
    maybeIds: MaybeOption<readonly IdType[]>,
  ): MaybeOption<Maybe<TEntity>[]> =>
    matchMaybeOption({
      onValue: collectAllFromEntityDictionary(entityDict),
    })(maybeIds);
}

import { IDBPDatabase, IDBPTransaction, StoreNames } from 'idb';
import { OperatorFunction, map } from 'rxjs';

export enum TransactionMode {
  Readonly = 'readonly',
  ReadWrite = 'readwrite',
  VersionChange = 'versionchange',
}

/**
 * Starts a transaction for a set of operations done on a database.
 *
 * @returns Transaction
 */
export function startTransaction<
  DBTypes,
  TStoreName extends StoreNames<DBTypes>,
  Mode extends IDBTransactionMode,
>(
  storeName: TStoreName,
  mode: Mode,
): OperatorFunction<
  IDBPDatabase<DBTypes>,
  IDBPTransaction<DBTypes, [TStoreName], Mode>
> {
  return map((db) => db.transaction(storeName, mode));
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';

type Alignment = 'center';

/**
 * Component to display an image fluid in size.
 */
@Component({
  selector: 'fmnts-fluid-image',
  template: '<ng-content></ng-content>',
  styleUrls: ['./fluid-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FluidImageComponent {
  private readonly componentClass = 'fluid-image';

  /**
   * Defines how the image should be horizontally aligned inside its container
   */
  @Input() horizontalAlignment: Alignment = 'center';
  /**
   * Defines how the image should be vertically aligned inside its container
   */
  @Input() verticalAlignment: Alignment = 'center';

  /**
   * Axis along which the image should fill its container
   */
  @Input() fillAxis: 'horizontal' | 'vertical' = 'horizontal';

  constructor() {}

  @HostBinding('class')
  public get dynamicClasses(): string {
    return classnames([
      this.componentClass,
      `${this.componentClass}--fill-${this.fillAxis}`,
      this.horizontalAlignment === 'center' &&
        `${this.componentClass}--hcenter`,
      this.verticalAlignment === 'center' && `${this.componentClass}--vcenter`,
    ]);
  }
}

import { CockpitApi } from '@fmnts/api/cockpit';
import { Auth } from '@formunauts/shared/domain';
import * as Arr from 'effect/Array';

export class AuthInfoAdapter {
  adapt(data: CockpitApi.UserProfile): Auth.LegacyAuthInfo {
    return {
      username: data.username,
      email: data.email,
      customerId: data.customer,
      stalePassword: data.stale_password,
      isStaff: data.is_staff,
      isActive: data.is_active,
      isFormunautsAdmin: data.is_formunauts_admin,
      isFundraiser: data.is_fundraiser,
      isTeamLeader: data.is_teamleader,
      isCoach: data.is_coach,
      isCampaignManager: data.is_campaignmanager,
      isCustomerAdmin: data.is_customeradmin,
      isOrganizationUser: data.is_organization_user,
      isPetitionCampaignManager: data.is_petitioncampaignmanager,
      isPetitionCustomerAdmin: data.is_petitioncustomeradmin,
      isRecruiter: data.is_recruiter,
      isHeadRecruiter: data.is_head_recruiter,
      isJobScout: data.is_jobscout,
      isCallingAgent: data.is_calling_agent,
      isAgencyAdminOne: data.is_agency_admin_one,
      permissions: Arr.filter(data.permissions ?? [], Auth.isPermission),
    };
  }
}

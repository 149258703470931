import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CustomerFeatures } from '@fmnts/api';
import { AbstractHasFeatureFlagDirective } from '@fmnts/common/feature-flags';
import { CustomerFeatureService } from '@fmnts/shared/customer/data-access';

/**
 * Structural directive that conditionally renders a template when the given
 * customer feature flag is active.
 *
 * Use to show content based on a feature.
 * ```
 * <ng-container *customerHasFeature="...">
 *   Customer has feature!
 * </ng-container>
 * ```
 *
 * Show alternate content if the feature is not active.
 * ```
 * <ng-container *customerHasFeature="...; else featureNotActive">
 *   Customer has feature!
 * </ng-container>
 * <ng-template #featureNotActive>
 *   Customer should buy this feature!
 * </ng-template>
 * ```
 */
@Directive({
  selector: '[customerHasFeature]',
  standalone: true,
})
export class CustomerHasFeatureDirective extends AbstractHasFeatureFlagDirective<CustomerFeatures> {
  /**
   * The feature flag that has to be active for showing the template.
   */
  @Input()
  set customerHasFeature(value: CustomerFeatures) {
    this._flag.next(value);
  }

  @Input()
  set customerHasFeatureElse(templateRef: TemplateRef<unknown> | null) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }

  constructor(
    templateRef: TemplateRef<unknown>,
    viewContainer: ViewContainerRef,
    protected override featureFlags: CustomerFeatureService,
  ) {
    super(templateRef, viewContainer);
  }
}

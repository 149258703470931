import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { GeolocationService } from './geolocation.service';
import { GeolocationStore } from './geolocation.store';

/**
 * Adds providers for accessing geolocation.
 */
export function provideGeolocation(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: GeolocationService,
      useExisting: GeolocationStore,
    },
  ]);
}

import { isString } from '@fmnts/core';
import {
  DBSchema,
  IDBPDatabase,
  IDBPObjectStore,
  IDBPTransaction,
  IndexNames,
  StoreNames,
  StoreValue,
  openDB,
} from 'idb';
import { Observable, Subject, from, of } from 'rxjs';
import { IDBSchemaConfig, ObjectStoreConfig, ObjectStoreIndex } from './schema';

/**
 * Opens the connection to a given database or creates the database,
 * if it's not existing yet.
 *
 * @param schema The schema for the database
 */
export function openFromSchema<DBTypes extends DBSchema>(
  schema: IDBSchemaConfig<DBTypes>,
): Observable<IDBPDatabase<DBTypes>> {
  return from(
    openDB<DBTypes>(schema.name, schema.version, {
      // Called if this version of the database has never been opened before.
      upgrade(db, oldVersion, newVersion, transaction) {
        // Create a store of objects
        createObjectStores(db, schema, oldVersion, newVersion, transaction);
      },
    }),
  );
}

/**
 * Creates an object store with the given name in the given db.
 *
 * When `upgradeneeded` is called and there are currently already objectStores for the same
 * schema, these stores including the data that is stored will be deleted.
 *
 * @param db The database
 * @param schema The store schema
 */
function createObjectStores<DBTypes extends DBSchema>(
  db: IDBPDatabase<DBTypes>,
  schema: IDBSchemaConfig<DBTypes>,
  oldVersion: number,
  newVersion: number | null,
  transaction?: IDBPTransaction<
    DBTypes,
    StoreNames<DBTypes>[],
    'versionchange'
  >,
): void {
  const { stores } = schema;

  Object.entries(stores).forEach(([name, config]) => {
    type TType = StoreValue<DBTypes, typeof storeName>;
    const storeName = name as StoreNames<DBTypes>;
    const storeConfig = config as ObjectStoreConfig<TType>;

    // find whether data migration is provided for this version-ugprade
    const migration = storeConfig.migrations?.find(
      (m) => m.to === newVersion && m.from === oldVersion,
    );
    const storedData = new Subject<TType[]>();

    if (migration) {
      from(transaction?.objectStore(storeName).getAll() ?? of([])).subscribe(
        (data) => storedData.next(migration.up(data)),
      );
    }

    // First delete any remaining object stores in this DB
    if (db.objectStoreNames.contains(storeName)) {
      db.deleteObjectStore(storeName);
    }

    const primaryKey = isString(storeConfig.key) ? storeConfig.key : undefined;

    const objStore = db.createObjectStore(storeName, {
      autoIncrement: storeConfig.autoIncrement ?? true,
      keyPath: primaryKey,
    });

    // migrate to new version
    if (migration) {
      storedData.subscribe((data) => {
        data.forEach((item) => void objStore.add(item));
      });
    }

    createIndexes(objStore, storeConfig.indexesConfig);
  });
}

/**
 * Creates indexes on an objectStore.
 *
 * @param indexes
 */
function createIndexes<DBTypes>(
  objStore: IDBPObjectStore<
    DBTypes,
    ArrayLike<StoreNames<DBTypes>>,
    StoreNames<DBTypes>,
    'versionchange'
  >,
  indexes: ObjectStoreIndex[] | undefined,
) {
  indexes?.forEach((index) =>
    objStore.createIndex(
      index.name as IndexNames<DBTypes, StoreNames<DBTypes>>,
      index.keyPath,
      index.options,
    ),
  );
}

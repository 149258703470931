import { Auth } from '@formunauts/shared/domain';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import * as Arr from 'effect/Array';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';
import * as Struct from 'effect/Struct';
import { produce } from 'immer';
import {
  AuthApiActions,
  AuthLoginPageActions,
  AuthRootActions,
} from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthFeatureState {
  auth: Auth.LegacyAuthInfo | null;
  isAuthenticating: boolean;
  session: Auth.AuthSession | null;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthFeatureState;
}

export const initialAuthFeatureState: AuthFeatureState = {
  auth: null,
  isAuthenticating: false,
  session: null,
};

export const authFeature = createFeature({
  name: AUTH_FEATURE_KEY,
  reducer: createReducer(
    initialAuthFeatureState,
    on(
      AuthApiActions.authenticate.request,
      AuthLoginPageActions.login,
      produce((draft) => {
        draft.isAuthenticating = true;
      }),
    ),
    on(
      AuthApiActions.authenticate.success,
      AuthApiActions.refresh.success,
      produce((draft, { payload: session }) => {
        draft.isAuthenticating = false;
        draft.session = session;
      }),
    ),
    on(
      AuthApiActions.authenticate.failure,
      AuthApiActions.refresh.failure,
      AuthApiActions.authInfo.failure,
      produce((draft) => {
        draft.isAuthenticating = false;
      }),
    ),
    on(
      AuthApiActions.authInfo.success,
      produce((draft, { payload: auth }) => {
        draft.isAuthenticating = false;
        draft.auth = auth;
      }),
    ),
    on(
      AuthApiActions.unauthenticate.success,
      AuthApiActions.unauthenticate.failure,
      AuthRootActions.forgetUser,
      produce((draft) => {
        draft.session = null;
        draft.auth = null;
      }),
    ),
    on(
      AuthRootActions.hydrate,
      produce((draft, { authInfo, session }) => {
        draft.auth = authInfo;
        draft.session = session;
      }),
    ),
  ),
  extraSelectors: ({ selectAuth, selectSession }) => {
    const selectOptionAuth = createSelector(selectAuth, O.fromNullable);
    const selectOptionSession = createSelector(selectSession, O.fromNullable);

    const selectIsAuthenticated = createSelector(selectOptionSession, O.isSome);
    const selectUserAuth = createSelector(
      selectOptionAuth,
      O.flatMap(Auth.legacyAuthInfoToUserAuth),
    );
    const selectAuthInfo = createSelector(
      selectUserAuth,
      O.map(Struct.get('auth')),
    );

    const selectRoles = createSelector(selectAuthInfo, (auth) =>
      Fn.pipe(
        auth,
        O.map(Fn.flow(Struct.get('roles'), Arr.fromIterable)),
        O.getOrElse(Fn.constant<Auth.Role[]>([])),
      ),
    );

    return {
      selectIsAuthenticated,
      selectRoles,
      selectAuthInfo,
      selectOptionSession,
    };
  },
});

export const {
  reducer: authFeatureReducer,
  selectAuth,
  selectIsAuthenticating,
  selectSession,
  selectOptionSession,
  selectIsAuthenticated,
  selectRoles,
  selectAuthInfo,
} = authFeature;

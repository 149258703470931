import { apiArrayAdapter } from '@fmnts/api/shared';
import type { Adapter } from '@fmnts/core';
import {
  IApiDonationFeedback,
  IApiDonationFeedbackUpdateDto,
} from '../api-model/api-model';
import { DonationFeedback } from '../model/donation-feedback';
import { ApiComplimentAdapter } from './compliment.adapter';

export class ApiDonationFeedbackAdapter implements Adapter<DonationFeedback> {
  private readonly complimentAdapter = new ApiComplimentAdapter();

  public adapt(apiDto: IApiDonationFeedback): DonationFeedback {
    return {
      id: apiDto.id,
      customAnswer: apiDto.custom_answer,
      customAnswerApproved: apiDto.custom_answer_approved,
      rating: apiDto.rating,
      ratingChoice: apiDto.rating_choice,

      answers: apiDto.answers ?? [],
      compliments: apiArrayAdapter.adaptItemsOrEmpty(
        apiDto.compliments,
        this.complimentAdapter,
      ),
    };
  }
}

export class DonationFeedbackToApiAdapter
  implements Adapter<IApiDonationFeedbackUpdateDto>
{
  adapt(value: DonationFeedback): IApiDonationFeedbackUpdateDto {
    return {
      answers: value.answers,
      custom_answer: value.customAnswer,
      custom_answer_approved: value.customAnswerApproved,
      rating: value.rating,
    };
  }
}

import { Directive, HostBinding, Input } from '@angular/core';
import {
  ThemeTextVariants,
  typographyLevelCssFactory,
} from '@fmnts/components/core';

/**
 * Directive to apply a specific typography level.
 */
@Directive({
  standalone: true,
  selector: '[fmnts-text]',
})
export class FmntsTextDirective {
  /**
   * Typography level that should be used.
   */
  @Input('fmnts-text') textVariant?: ThemeTextVariants;

  @HostBinding('style')
  protected get hostStyles(): Record<string, string> {
    if (!this.textVariant) {
      return {};
    }

    const variantCssVars = typographyLevelCssFactory(this.textVariant);
    return variantCssVars({});
  }
}

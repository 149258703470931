import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  ViewId,
  ViewOptionActions,
  ViewOptions,
  ViewOptionsFeatureState,
  selectViews,
} from '@formunauts/shared/view-options/data-access';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, map, of, tap } from 'rxjs';

const PREFIX = '@fmnts/app/view-options/';

/**
 * Effects for persisting the view options.
 */
@Injectable()
export class PersistViewOptionsEffects {
  hydrate$ = createEffect(() => {
    return of(null).pipe(
      map(() => this._hydrate()),
      map((value) => ViewOptionActions.set(value)),
    );
  });

  persistState$ = createEffect(
    () => {
      return this.store.select(selectViews).pipe(
        debounceTime(500),
        tap((views) => {
          Object.keys(views).forEach((viewId) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._persist(viewId, views[viewId]!);
          });
        }),
      );
    },
    { dispatch: false },
  );

  private _storage: Storage;

  constructor(
    private store: Store,
    @Inject(DOCUMENT) doc: Document,
  ) {
    if (!doc.defaultView) {
      throw new Error('No window');
    }
    this._storage = doc.defaultView.localStorage;
  }

  private _hydrate(): ViewOptionsFeatureState {
    const keys = Object.keys(this._storage);
    const state: ViewOptionsFeatureState = {
      views: {},
    };

    for (const key of keys) {
      const viewId = this._getViewIdFromStorageKey(key);
      if (!viewId) {
        continue;
      }

      const item = this._hydrateViewId(viewId);
      if (!item) {
        continue;
      }

      state.views = {
        ...state.views,
        [viewId]: item,
      };
    }

    return state;
  }

  private _persist(
    viewId: ViewId,
    viewOptions: ViewOptions<Record<string, unknown>, Record<string, unknown>>,
  ) {
    this._storage.setItem(
      this._getStorageKey(viewId),
      JSON.stringify(viewOptions),
    );
  }

  private _hydrateViewId(viewId: ViewId): null {
    const item = this._storage.getItem(this._getStorageKey(viewId));

    return item ? JSON.parse(item) : null;
  }

  private _getStorageKey(viewId: ViewId): string {
    return [PREFIX, viewId].join('');
  }

  private _getViewIdFromStorageKey(storageKey: string): ViewId | null {
    if (!storageKey.startsWith(PREFIX)) {
      return null;
    }

    return storageKey.substring(PREFIX.length);
  }
}

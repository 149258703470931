import { Pipe, PipeTransform } from '@angular/core';
import { Nullish } from '@fmnts/core';
import { TimeSpanInput } from '@fmnts/core/chronos';
import moment from 'moment';

/**
 * Transforms a time span into a string representing a date range.
 * The transformation tries to narrow down the date range by its
 * components, so that e.g. a time span from the first to the last
 * of a month is represented by the month and year.
 *
 * @example
 * // whole year
 * transform(['2022-01-01', '2022-12-31'], 'en');
 * // => '2022'
 *
 * @example
 * // whole month
 * transform(['2022-01-01', '2022-01-31'], 'en');
 * // => 'January 2022'
 *
 * @example
 * // whole day
 * transform(['2022-01-01', '2022-01-01'], 'en');
 * // => 'January 1, 2022'
 *
 * @example
 * // arbitrary date range
 * transform(['2022-01-01', '2022-01-03'], 'en');
 * // => '01/01/2022 - 01/03/2022'
 */
@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  /**
   * Transform the given time span to a "minimal" date range
   * using the passed locale.
   *
   * @param value Time span
   * @param locale Locale
   *
   * @returns
   * Localized "minimal" date range.
   */
  transform(value: TimeSpanInput | Nullish, locale: string): string {
    const [fromDate, toDate] = value ?? [];

    if (!fromDate || !toDate) {
      return '-';
    }

    // ensure the correct locale
    const from = moment(fromDate).locale(locale);
    const to = moment(toDate).locale(locale);

    if (from.isSame(to, 'day')) {
      return from.format('LL');
    } else if (
      from.isSame(moment(to).startOf('year'), 'day') &&
      to.isSame(moment(from).endOf('year'), 'day')
    ) {
      return from.format('YYYY');
    } else if (
      from.isSame(moment(to).startOf('month'), 'day') &&
      to.isSame(moment(from).endOf('month'), 'day')
    ) {
      return from.format('MMMM YYYY');
    } else {
      return `${from.format('L')} – ${to.format('L')}`;
    }
  }
}

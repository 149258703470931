import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import type { Subscription } from 'rxjs';
import { LocaleService } from './locale.service';

@Pipe({
  name: 'i18n_number',
  pure: false,
})
export class I18nDecimalPipe
  extends DecimalPipe
  implements PipeTransform, OnDestroy
{
  /**
   * The locale is used to format the number
   */
  private locale?: string;

  private subscription: Subscription;

  constructor(
    private localeService: LocaleService,
    private cd: ChangeDetectorRef,
  ) {
    super(localeService.fallbackLocale);
    this.subscription = this.localeService.locale$.subscribe((locale) => {
      this.locale = locale;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Overrides Angulars DecimalPipe and uses global Locale for formatting
   *
   * @param value The number to format.
   * @param digitsInfo Decimal representation options, specified by a string in the following format:
   * `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`. See `DecimalPipe` for more details.
   * @param emptyChar This argument can be used to use a symbol or text to replace the formatting, in
   * case the number equals 0.
   *
   * @returns The formatted decimal value.
   *
   * @see [DecimalPipe](https://angular.io/api/common/DecimalPipe)
   */
  override transform(
    value: string | number,
    digitsInfo?: string,
    emptyChar?: string,
  ): string;
  override transform(
    value: null,
    digitsInfo?: string,
    emptyChar?: string,
  ): null;
  override transform(
    value: string | number | null,
    digitsInfo: string = '1.0-2',
    emptyChar?: string,
  ): string | null {
    if (emptyChar) {
      if (value === 0 || value === '0') {
        return emptyChar;
      }
    }

    return super.transform(value, digitsInfo, this.locale);
  }
}

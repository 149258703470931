import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Auth } from '@formunauts/shared/domain';
import { Store } from '@ngrx/store';
import { selectAuth } from './+state/auth.reducer';

@Injectable()
export class AuthService {
  private readonly _auth = toSignal(inject(Store).select(selectAuth), {
    requireSync: true,
  });

  /**
   * @deprecated
   * use `AuthStore` instead.
   *
   * @returns
   * Stored authentication data.
   */
  public getAuthInfo(): Auth.LegacyAuthInfo {
    return this._auth() ?? emptyAuthInfo;
  }
}

/**
 * @returns
 * An empty `AuthInfo` object
 */
const emptyAuthInfo: Auth.LegacyAuthInfo = {
  username: null,
  email: null,
  customerId: null,
  stalePassword: false,
  isStaff: false,
  isActive: false,
  isFormunautsAdmin: false,
  isFundraiser: false,
  isTeamLeader: false,
  isCoach: false,
  isCampaignManager: false,
  isCustomerAdmin: false,
  isOrganizationUser: false,
  isPetitionCampaignManager: false,
  isPetitionCustomerAdmin: false,
  isRecruiter: false,
  isHeadRecruiter: false,
  isJobScout: false,
  isCallingAgent: false,
  isAgencyAdminOne: false,
  permissions: [],
};

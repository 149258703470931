import { MonoTypeOperatorFunction, distinctUntilChanged } from 'rxjs';

/**
 * @param compare Compare function
 *
 * @returns
 * An observable that takes an array as an input and that
 * emits when the array has changed, using the provided
 * `compare` function.
 */
export function distinctUntilArrayChanged<T>(
  compare: (x: T[], y: T[]) => boolean,
): MonoTypeOperatorFunction<T[]> {
  return distinctUntilChanged<T[]>(
    (prev, current) => current.length === prev.length && compare(prev, current),
  );
}

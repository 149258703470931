import { apiDatetimeAdapter } from '@fmnts/api/shared';
import { Adapter } from '@fmnts/core';
import {
  IApiSupportRequestCreateDto,
  IApiSupportRequestCreateResponse,
  IApiSupportRequestUpdateDto,
  IApiSupportRequestUpdateResponse,
} from '../api-model/api-model';
import {
  SupportRequest,
  SupportRequestCreateDto,
  SupportRequestUpdateDto,
} from '../model/support-request';

export class ApiCreateSupportRequestAdapter implements Adapter<SupportRequest> {
  adapt(dto: {
    createDto: SupportRequestCreateDto;
    response: IApiSupportRequestCreateResponse;
  }): SupportRequest {
    return {
      id: dto.response.id,
      url: dto.response.url,
      email: dto.createDto.email,
      path: dto.createDto.path,
      version: dto.createDto.version,
      type: dto.createDto.type,
      description: dto.createDto.description,
      logs: dto.createDto.logs,
      timestamp: dto.createDto.timestamp,
      lastRefresh: dto.createDto.lastRefresh,
      lastSentryTransactions: dto.createDto.lastSentryTransactions,
      deletedFormData: dto.createDto.deletedFormData,
      localStorage: dto.createDto.localStorage,
      appContext: dto.createDto.appContext,
      screenshot: null,
    };
  }
}

export class ApiUpdateSupportRequestAdapter implements Adapter<SupportRequest> {
  adapt(dto: {
    response: IApiSupportRequestUpdateResponse;
    entity: SupportRequest;
  }): SupportRequest {
    return {
      ...dto.entity,
      screenshot: dto.response.screenshot,
    };
  }
}

export class SupportRequestToApiAdapter {
  adaptForCreate(dto: SupportRequestCreateDto): IApiSupportRequestCreateDto {
    return {
      email: dto.email,
      path: dto.path,
      version: dto.version,
      type: dto.type,
      description: dto.description,
      logs: dto.logs,
      timestamp: apiDatetimeAdapter.adaptToApi(dto.timestamp),
      last_refresh: dto.lastRefresh
        ? apiDatetimeAdapter.adaptToApi(dto.lastRefresh)
        : null,
      last_sentry_transactions: dto.lastSentryTransactions,
      deleted_form_data: dto.deletedFormData,
      local_storage: dto.localStorage,
      app_context: dto.appContext,
    };
  }

  adaptForUpdate(
    dto: SupportRequestUpdateDto,
  ): IApiSupportRequestUpdateDto | FormData {
    const data = new FormData();
    data.set('screenshot', dto.screenshot);
    return data;
  }
}

import { Observable } from 'rxjs';

/**
 * Emits with the current position of the device.
 *
 * @returns
 * Creates an `Observable` that emits with the position of the device, then completes.
 */
export function getCurrentPosition$(
  geolocation: Geolocation,
  options?: PositionOptions,
): Observable<GeolocationPosition> {
  return new Observable((sub) => {
    geolocation.getCurrentPosition(
      (position) => {
        sub.next(position);
        sub.complete();
      },
      (error) => sub.error(error),
      options,
    );
  });
}

/**
 * Emits when the position of the device changes.
 * Does not complete on its own.
 *
 * @returns
 * Creates an `Observable` that emits with the position of the device.
 */
export function watchPosition$(
  geolocation: Geolocation,
  options?: PositionOptions,
): Observable<GeolocationPosition> {
  return new Observable((sub) => {
    const watchId = geolocation.watchPosition(
      (position) => {
        sub.next(position);
      },
      (error) => sub.error(error),
      options,
    );

    return () => geolocation.clearWatch(watchId);
  });
}

<div *ngIf="showTotals" class="pagination__totals">
  <ng-content select="[totalsLabel]"></ng-content>
  <span>: {{ count }}</span>
</div>

<ng-content></ng-content>

<ul class="pages">
  <button
    fmnts-icon-button
    class="pagination--first-page"
    type="button"
    fill="clear"
    [disabled]="currentPage === 1"
    color="primary"
    size="md"
    (click)="navigateTo(1)"
  >
    <fa-icon [icon]="_iconFirst"></fa-icon>
  </button>

  <button
    fmnts-icon-button
    class="pagination--previous-page"
    type="button"
    fill="clear"
    [disabled]="currentPage === 1"
    color="primary"
    size="md"
    (click)="navigateTo(previousPage)"
  >
    <fa-icon [icon]="_iconPrev"></fa-icon>
  </button>

  <button
    *ngFor="let page of pageRange"
    fmnts-icon-button
    type="button"
    [class.pagination--active-page]="currentPage === page"
    [fill]="currentPage === page ? 'solid' : 'clear'"
    color="primary"
    size="md"
    (click)="navigateTo(page)"
  >
    {{ page }}
  </button>

  <button
    fmnts-icon-button
    class="pagination--next-page"
    type="button"
    fill="clear"
    [disabled]="count === 0 || currentPage === lastPage"
    color="primary"
    size="md"
    (click)="navigateTo(nextPage)"
  >
    <fa-icon [icon]="_iconNext"></fa-icon>
  </button>

  <button
    fmnts-icon-button
    class="pagination--last-page"
    type="button"
    fill="clear"
    [disabled]="count === 0 || currentPage === lastPage"
    color="primary"
    size="md"
    (click)="navigateTo(lastPage)"
  >
    <fa-icon [icon]="_iconLast"></fa-icon>
  </button>
</ul>

import {
  PhotoVerification,
  PhotoVerificationStatus,
  apiAdaptOrNull,
  apiDateAdapter,
  apiDatetimeAdapter,
} from '@fmnts/api/shared';
import { isNil, type Adapter } from '@fmnts/core';
import { IApiPhotoVerification } from '../api-model/api-model';

/**
 * Adapts API model to our data structure
 */
export class ApiPhotoVerificationAdapter implements Adapter<PhotoVerification> {
  public adapt(apiDto: IApiPhotoVerification): PhotoVerification {
    return {
      status:
        (apiDto.status as PhotoVerificationStatus) ??
        PhotoVerificationStatus.Pending,
      statusComment: apiAdaptOrNull.valueOrNull(apiDto.status_comment) ?? '',
      statusReasons: apiDto.status_reasons ?? [],
      timestamp: apiDateAdapter.adapt(apiDto.timestamp),
      image: apiDto.image_upload,
    };
  }
}

export interface IPhotoVerificationDto {
  timestamp: Date;
  image: Blob;
}

/**
 * Adapts data for API upload
 */
export class PhotoVerificationToApiAdapter {
  /**
   * Serializes the object with an image and returns a FormData object
   *
   * @param verification Verification data
   * @param blob
   *
   * @returns FormData
   */
  adaptForCreate(dto: Readonly<IPhotoVerificationDto>): FormData {
    const json = this._toApiDto(dto);
    const { image } = dto;

    // As form data
    const data = new FormData();
    for (const key in json) {
      if (!isNil(json[key])) {
        data.append(key, `${json[key]}`);
      }
    }

    // Append blob and use name in case that it was actually a file
    data.append('image_upload', image, (image as File).name ?? 'modified.jpeg');

    return data;
  }

  private _toApiDto(dto: IPhotoVerificationDto): Record<string, any> {
    return {
      timestamp: apiDatetimeAdapter.adaptToApi(dto.timestamp),
    };
  }
}

import { Signal } from '@angular/core';
import { Maybe } from '@fmnts/core';
import {
  GeoCoordinates,
  GeoCoordinatesError,
} from './shared-geolocation.model';

/**
 * Service to access the client position.
 */
export abstract class GeolocationService {
  /** Most recent coordinates or `undefined`. */
  abstract readonly coordinates: Signal<Maybe<GeoCoordinates>>;
  /** Latest error or `undefined`. */
  abstract readonly error: Signal<Maybe<GeoCoordinatesError>>;
  /** Whether the user denied permission to track geolocation. */
  abstract readonly permissionDenied: Signal<boolean>;

  /** Updates the current coordinates. */
  abstract updatePosition(): void;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiPageAdapter,
  PagedApiResponse,
  PhotoVerification,
  type Page,
} from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import {
  ApiPhotoVerificationAdapter,
  IPhotoVerificationDto,
  PhotoVerificationToApiAdapter,
} from '../adapter/photo-verification.adapter';
import {
  IApiPhotoVerification,
  IApiPhotoVerificationListQueryParams,
} from '../api-model/api-model';

/**
 * Provides communication with the photo verification compliance API.
 */
@Injectable({
  providedIn: 'root',
})
export class PhotoVerificationApi {
  private static readonly rootUrl = '/api/v2.1/compliance/photo_verification/';

  private readonly _fromApiAdapter = new ApiPhotoVerificationAdapter();
  private readonly _fromApiPageAdapter = new ApiPageAdapter(
    this._fromApiAdapter,
  );
  private readonly _toApiAdapter = new PhotoVerificationToApiAdapter();

  constructor(
    private _apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  /**
   * Verifies that a fundraiser uploads a photo.
   *
   * @param photoVerification Photo Verification object
   */
  public verify(
    photoVerification: IPhotoVerificationDto,
  ): Observable<PhotoVerification> {
    const url = this.configService.buildApiUrl([PhotoVerificationApi.rootUrl]);
    const data = this._toApiAdapter.adaptForCreate(photoVerification);

    return this.http
      .post<IApiPhotoVerification>(url, data)
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }

  /**
   * Retrieves a list of photo verifications.
   *
   * @param query List query parameters.
   *
   * @returns
   * An `Observable` that emits with the list of photo verifications,
   * then completes.
   */
  public list(
    query: IApiPhotoVerificationListQueryParams = {},
  ): Observable<Page<PhotoVerification>> {
    const url = this.configService.buildApiUrl([PhotoVerificationApi.rootUrl]);
    const params = this._apiHelper.makeParams(query);

    return this.http
      .get<PagedApiResponse<IApiPhotoVerification>>(url, {
        params,
      })
      .pipe(map((response) => this._fromApiPageAdapter.adapt(response)));
  }

  /**
   * Retrieves the latest photo verification.
   *
   * @returns
   * An `Observable` that emits with the latest photo verification
   * or `null` if none is available, then completes.
   */
  public getLatest(
    filters: IApiPhotoVerificationListQueryParams = {},
  ): Observable<PhotoVerification | null> {
    return this.list({
      ...filters,
      ordering: '-timestamp',
      page_size: 1,
    }).pipe(map(({ results }) => (results.length > 0 ? results[0] : null)));
  }
}

/**
 * A value that determines the `enabled` state of a feature flag
 * The following values are consideres as `enabled`:
 * - `true`
 * - `"true"`
 * - `1`
 *
 * Everything else is considered disabled.
 */
export type FeatureFlagInput = boolean | string | number;

/**
 * An object that holds a key-value pair, where each key represents
 * a flag and the value represents if it is enabled
 */
export type FeatureFlagInputMap = Record<string, FeatureFlagInput>;

/**
 *
 * @param value
 * @returns
 */

export function coerceFeatureFlagInput(value: FeatureFlagInput): boolean {
  // Convert string representations of feature flag to actual booleans
  return typeof value === 'boolean' ? value : value === 1 || value === 'true';
}

/**
 * Takes the map of feature flags and returns the list of feature flags
 * that are enabled.
 *
 * @param flags Map of feature flags
 *
 * @returns
 * List of enabled feature flags from the given map.
 */
export function coerceFeatureFlagInputMap(
  flags: FeatureFlagInputMap,
): string[] {
  const enabledFlags: string[] = [];

  for (const [flag, value] of Object.entries(flags)) {
    if (coerceFeatureFlagInput(value)) {
      enabledFlags.push(flag);
    }
  }

  return enabledFlags;
}

import { Injectable } from '@angular/core';
import {
  coerceFeatureFlagInputMap,
  FeatureFlagInputMap,
  AbstractFeatureFlags,
} from '@fmnts/common/feature-flags';

export { FeatureFlagInputMap };
export enum AppFeatureFlag {
  /** When active, all features are enabled. */
  All = 'all',
  /** When active, the app supports the check-in flow */
  CheckInFlow = 'check_in_flow',
  /** When active, the app supports the launcher */
  Launcher = 'launcher',
  Next = 'next',
  UserTracking = 'userTracking',
}

export type AppFeatures = `${AppFeatureFlag}`;

/**
 * Can be used to query which app features are enabled.
 */
@Injectable({ providedIn: 'root' })
export class AppFeatureFlags extends AbstractFeatureFlags<AppFeatures> {
  public set featureFlags(flags: FeatureFlagInputMap) {
    this.setEnabled(coerceFeatureFlagInputMap(flags) as AppFeatures[]);
  }

  public override on(flag: AppFeatures): boolean {
    return super.on(AppFeatureFlag.All) || super.on(flag);
  }

  public override off(flag: AppFeatures): boolean {
    return !this.on(flag);
  }
}

import {
  EntityUpdate,
  FileUpload,
  IApiEntityId,
  apiAdaptOrNull,
  apiDatetimeAdapter,
  formDataAdapter,
} from '@fmnts/api/shared';
import { Adapter } from '@fmnts/core';
import {
  IApiFundraiser,
  IApiFundraiserCreateDto,
  IApiFundraiserMinimal,
  IApiFundraiserUpdateDto,
} from '../api-model/api-model';
import {
  Fundraiser,
  FundraiserMinimal,
  IFundraiser,
} from '../model/fundraiser';

export class ApiFundraiserMinimalAdapter implements Adapter<FundraiserMinimal> {
  public adapt(apiDto: IApiFundraiserMinimal): FundraiserMinimal {
    const image = apiDto.image_thumbnail_url || apiDto.image;
    const hasDefaultImage = image && image.match(/default-avatar\.png$/);

    return new FundraiserMinimal({
      id: `${apiDto.id}`,
      email: apiDto.email,
      firstName: apiDto.first_name,
      fundraiserCode: apiDto.fundraiser_code,
      fundraiserExternalId: apiDto.fundraiser_external_id,
      image: hasDefaultImage ? null : image,
      language: apiDto.language,
      locale: apiDto.locale,
      lastName: apiDto.last_name,
    });
  }
}

export class ApiFundraiserAdapter implements Adapter<Fundraiser> {
  public adapt(apiDto: IApiFundraiser): Fundraiser {
    const image = apiDto.image_thumbnail_url || apiDto.image;
    const hasDefaultImage = image && image.match(/default-avatar\.png$/);

    return new Fundraiser({
      id: `${apiDto.id}`,
      firstName: apiDto.first_name,
      fundraiserCode: apiDto.fundraiser_code,
      isActive: apiDto.is_active,
      isCoach: apiDto.is_coach,
      isTeamLeader: apiDto.is_teamleader,
      lastName: apiDto.last_name,

      username: apiDto.username,
      customerId: apiDto.customer,
      // values with conversion
      createdAt: apiDatetimeAdapter.adapt(apiDto.createtime),
      modifiedAt: apiDatetimeAdapter.adapt(apiDto.modifytime),
      // customized values
      image: hasDefaultImage ? null : image,
      image_upload: image ? true : null,
      // optional values
      city: apiAdaptOrNull.valueOrNull(apiDto.city),
      email: apiAdaptOrNull.valueOrNull(apiDto.email),
      fundraiserExternalId: apiAdaptOrNull.valueOrNull(
        apiDto.fundraiser_external_id,
      ),
      language: apiAdaptOrNull.valueOrNull(apiDto.language),
      locale: apiAdaptOrNull.valueOrNull(apiDto.locale),
      phoneNumber: apiAdaptOrNull.valueOrNull(apiDto.phone_number),
      street: apiAdaptOrNull.valueOrNull(apiDto.street),
      zipCode: apiAdaptOrNull.valueOrNull(apiDto.zip_code),
    });
  }
}

export interface IFundraiserDto {
  id?: string;
  city: string | null;
  customerId: IApiEntityId;
  firstName: string;
  lastName: string;
  fundraiserCode: string;
  isActive: boolean;
  isCoach: boolean;
  isTeamLeader: boolean;
  fundraiserExternalId: string | null;
  language: string | null;
  email: string | null;
  phoneNumber: string | null;
  street: string | null;
  zipCode: string | null;
}

export type IFundraiserUpdateDto = EntityUpdate<IFundraiserDto, 'id'>;

/**
 * Adapter for fundraisers for the cockpit API
 */
export class FundraiserToApiAdapter {
  adaptForCreate(dto: IFundraiserDto): IApiFundraiserCreateDto {
    return this._toApiDto(dto);
  }

  adaptForUpdate(dto: IFundraiserUpdateDto): IApiFundraiserUpdateDto {
    return this._toApiDto(dto);
  }

  /**
   * Adapts the given fundraiser data to be compatible with the API endpoint
   *
   * @param fundraiser The fundraiser object
   * @param image The fundraiser profile image. When `null`, the image will be removed
   *
   * @returns
   * Parsed data that can be passed to the API endpoint
   */
  adaptWithImage(
    dto: IApiFundraiserCreateDto | IApiFundraiserUpdateDto,
    image: FileUpload,
  ): Record<string, any> {
    // To delete image send `image: null`
    if (!image) {
      return {
        ...dto,
        image_upload: null,
      };
    }

    // If no change to image, don't use image key
    if (!image.file) {
      return dto;
    }

    // As form data
    const data = formDataAdapter.adaptRecord(dto);
    formDataAdapter.appendFile(data, 'image_upload', image.file);
    return data;
  }

  adaptModelToDto(fundraiser: IFundraiser): IFundraiserDto;
  adaptModelToDto(fundraiser: Partial<IFundraiser>): IFundraiserUpdateDto;
  adaptModelToDto(fundraiser: Partial<IFundraiser>): Partial<IFundraiserDto> {
    return {
      id: fundraiser.id,
      city: fundraiser.city,
      customerId: fundraiser.customerId,
      email: fundraiser.email,
      firstName: fundraiser.firstName,
      fundraiserCode: fundraiser.fundraiserCode,
      fundraiserExternalId: fundraiser.fundraiserExternalId,
      isActive: fundraiser.isActive,
      isCoach: fundraiser.isCoach,
      isTeamLeader: fundraiser.isTeamLeader,
      language: fundraiser.language,
      lastName: fundraiser.lastName,
      phoneNumber: fundraiser.phoneNumber,
      street: fundraiser.street,
      zipCode: fundraiser.zipCode,
    };
  }

  private _toApiDto(dto: IFundraiserDto): IApiFundraiserCreateDto;
  private _toApiDto(dto: IFundraiserUpdateDto): IApiFundraiserUpdateDto;
  private _toApiDto(dto: Partial<IFundraiserDto>): IApiFundraiserUpdateDto {
    return {
      city: dto.city,
      customer: dto.customerId,
      email: dto.email,
      first_name: dto.firstName,
      fundraiser_code: dto.fundraiserCode,
      fundraiser_external_id: dto.fundraiserExternalId,
      is_active: dto.isActive,
      is_coach: dto.isCoach,
      is_teamleader: dto.isTeamLeader,
      language: dto.language,
      last_name: dto.lastName,
      phone_number: dto.phoneNumber,
      street: dto.street,
      zip_code: dto.zipCode,
    };
  }
}

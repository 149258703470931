import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { TimeSpanInput } from '@fmnts/core/chronos';
import { LocaleService } from '@fmnts/i18n';
import { Subscription } from 'rxjs';
import { TimeSpanPipe } from './time-span.pipe';

/**
 * Transforms a given time span by formatting each of both values
 * `[from, to]` using the same format.
 */
@Pipe({
  name: 'i18n_timeSpan',
  pure: false,
})
export class I18nTimeSpanPipe
  extends TimeSpanPipe
  implements OnDestroy, PipeTransform
{
  private _locale = this.localeService.fallbackLocale;
  private readonly sub: Subscription;

  constructor(
    private localeService: LocaleService,
    cd: ChangeDetectorRef,
  ) {
    super();

    // This will emit immediately, so locale should always have a value
    this.sub = localeService.locale$.subscribe((locale) => {
      this._locale = locale;
      cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  override transform(value: TimeSpanInput, format?: string): string {
    return super.transform(value, this._locale, format);
  }
}

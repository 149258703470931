import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiFileResponse,
  ApiLocationAdapter,
  ApiLocationCommentAdapter,
  ApiLocationDetailedAdapter,
  ApiLocationPermitAdapter,
  ApiPageAdapter,
  FileUpload,
  IApiLocation,
  IApiLocationComment,
  IApiLocationDetailed,
  IApiLocationPermit,
  ILocationDto,
  Location,
  LocationComment,
  LocationDetail,
  LocationId,
  LocationPermit,
  LocationToApiAdapter,
  PagedApiResponse,
  type Page,
} from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiLocationReportingAdapter } from '../adapter/location-reporting.adapter';
import { IApiLocationReporting } from '../api-model/api-model';
import type { LocationReporting } from '../model/location-reporting';

@Injectable({
  providedIn: 'root',
})
export class LocationApi {
  private static readonly rootUrl = '/v1/locations';

  private readonly locationToApiAdapter = new LocationToApiAdapter();
  private readonly _fromApiAdapter = new ApiLocationAdapter();
  private readonly _fromApiPageAdapter = new ApiPageAdapter(
    this._fromApiAdapter,
  );

  private readonly _fromApiDetailedAdapter = new ApiLocationDetailedAdapter();
  private readonly _fromApiCommentAdapter = new ApiLocationCommentAdapter();
  private readonly _fromApiPermitAdapter = new ApiLocationPermitAdapter();

  private readonly _fromApiReportingAdapter = new ApiLocationReportingAdapter();
  private readonly _fromApiReportingPageAdapter = new ApiPageAdapter(
    this._fromApiReportingAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(id: string, forceRefresh = false): Observable<LocationDetail> {
    const url = this.configService.buildCockpitApiUrl([
      LocationApi.rootUrl,
      id,
    ]);
    const headers = this.apiHelper.makeDefaultHeaders({ force: forceRefresh });

    return this.http
      .get<IApiLocationDetailed>(url, { headers })
      .pipe(map((response) => this._fromApiDetailedAdapter.adapt(response)));
  }

  /**
   * @param urlOrFilter options for filtering
   *
   * @returns
   * An observable that emits a paged list of locations
   */
  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<Location>> {
    const url = this.configService.buildCockpitApiUrl([LocationApi.rootUrl]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiLocation>>(url, { params })
      .pipe(map((response) => this._fromApiPageAdapter.adapt(response)));
  }

  /**
   * Creates a new location with the given location object
   *
   * @param location Complete or partial Location object
   * @param image Location image
   */
  public create(
    location: ILocationDto,
    image?: FileUpload | null,
  ): Observable<Location> {
    const url = this.configService.buildCockpitApiUrl([LocationApi.rootUrl]);
    const dto = this.locationToApiAdapter.adaptForCreate(location);
    const data = this.locationToApiAdapter.adaptWithImage(dto, image);

    return this.http
      .post<IApiLocation>(url, data)
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }

  /**
   * Updates the location with the given `location.id` and
   * the data it holds.
   *
   * @param location The location with the id and the new data
   * @param image Location image. If `null` is passed, the current image will be deleted.
   */
  public update(
    location: ILocationDto,
    image?: FileUpload | null,
  ): Observable<Location> {
    const url = this.configService.buildCockpitApiUrl([
      LocationApi.rootUrl,
      location.id,
    ]);
    const dto = this.locationToApiAdapter.adaptForUpdate(location);
    const data = this.locationToApiAdapter.adaptWithImage(dto, image);

    return this.http
      .patch<IApiLocation>(url, data)
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }

  public report(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<LocationReporting>> {
    const url = this.configService.buildCockpitApiUrl([
      LocationApi.rootUrl,
      'report',
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiLocationReporting>>(url, { params })
      .pipe(
        map((response) => this._fromApiReportingPageAdapter.adapt(response)),
      );
  }

  /**
   * @param urlOrFilter filters passed to the API call
   *
   * @returns
   * An observable that emits the information for
   * a location reporting file
   */
  public createReportFile(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<ApiFileResponse> {
    const url = this.configService.buildCockpitApiUrl([
      LocationApi.rootUrl,
      'report',
      'download_xlsx',
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http.get<ApiFileResponse>(url, { params });
  }

  /*
   * Creates a comment for the location with the given `locationId`.
   *
   * @param locationId The location id corresponding to the comment
   * @param text The comment
   */
  public createComment(
    locationId: LocationId,
    text: string,
  ): Observable<LocationComment> {
    const url = this.configService.buildCockpitApiUrl([
      LocationApi.rootUrl,
      locationId,
      'comments',
    ]);
    const data = { text };

    return this.http
      .post<IApiLocationComment>(url, data)
      .pipe(map((response) => this._fromApiCommentAdapter.adapt(response)));
  }

  public uploadPermit(
    locationId: LocationId,
    permit: File,
  ): Observable<LocationPermit> {
    const url = this.configService.buildCockpitApiUrl([
      LocationApi.rootUrl,
      locationId,
      'permits',
    ]);

    const data = new FormData();
    data.append('file', permit);

    return this.http
      .post<IApiLocationPermit>(url, data)
      .pipe(map((response) => this._fromApiPermitAdapter.adapt(response)));
  }
}

import {
  DBSchema,
  IDBPCursorWithValue,
  IDBPObjectStore,
  StoreNames,
} from 'idb';
import { Observable } from 'rxjs';

/**
 * Creates an `Observable` that on subscribe will iterate the passed `objectStore`
 * using a cursor and emit each item.
 *
 * Use the `selectNext` function to control the flow of the cursor.
 *
 * @param objectStore Object store to iterate
 * @param selectNext Function that is called to select the next
 *
 * @returns
 */
export function iterateByCursor<
  DBTypes extends DBSchema,
  TxStores extends ArrayLike<StoreNames<DBTypes>>,
  StoreName extends StoreNames<DBTypes>,
  Mode extends IDBTransactionMode,
>(
  objectStore: IDBPObjectStore<DBTypes, TxStores, StoreName, Mode>,
  selectNext: <
    T extends IDBPCursorWithValue<DBTypes, TxStores, StoreName, unknown, Mode>,
  >(
    cursor: T,
  ) => Promise<T | null>,
): Observable<
  IDBPCursorWithValue<DBTypes, TxStores, StoreName, unknown, Mode>
> {
  return new Observable((subscriber) => {
    async function iterateCursor() {
      let cursor = await objectStore.openCursor();
      while (cursor) {
        subscriber.next(cursor);
        cursor = await selectNext(cursor);
      }
    }

    iterateCursor()
      .then(() => {
        subscriber.complete();
      })
      .catch((e) => {
        subscriber.error(e);
      });
  });
}

import { FundraiserMinimal, IFundraiserMinimal } from './fundraiser';
import { IWorkshift, Workshift } from './workshift';

export interface IWorkshiftAssignment {
  /** ID for the workshift assignment */
  id: string;
  /** ID of the assigned team */
  teamId: string;
  /** Workshift date */
  date: Date;
  /** Assigned Fundraiser */
  fundraiser: Readonly<IFundraiserMinimal>;
  /** Assigned work shift  */
  workshift: Readonly<IWorkshift>;
}

export class WorkshiftAssignment implements IWorkshiftAssignment {
  id: string;
  teamId: string;
  date: Date;
  fundraiser: Readonly<FundraiserMinimal>;
  workshift: Readonly<Workshift>;

  constructor(dto: IWorkshiftAssignment) {
    this.id = dto.id;
    this.date = new Date(dto.date);
    this.teamId = dto.teamId;
    this.fundraiser = new FundraiserMinimal(dto.fundraiser);
    this.workshift = new Workshift(dto.workshift);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays a card footer.
 */
@Component({
  selector: 'fmnts-card-footer',
  template: '<ng-content></ng-content>',
  styleUrls: ['./card-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardFooterComponent {
  @HostBinding('class.fmnts-card-footer')
  private readonly componentClass = 'fmnts-card-footer';
}

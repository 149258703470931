import { Inject, Injectable } from '@angular/core';
import { isDefined } from '@fmnts/core';
import { DBSchema, IDBPDatabase, StoreKey, StoreNames, StoreValue } from 'idb';
import {
  Observable,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  zip,
} from 'rxjs';
import { iterateByCursor } from './idb-cursor';
import { TransactionMode, startTransaction } from './idb-transaction';
import { openFromSchema } from './open-from-schema';
import { IDBSchemaConfig, IDB_SCHEMA_TOKEN } from './schema';

@Injectable()
export class Repository<TStores extends DBSchema> {
  private schema: IDBSchemaConfig<TStores>;

  constructor(@Inject(IDB_SCHEMA_TOKEN) schema: IDBSchemaConfig<TStores>) {
    this.schema = schema;
  }

  /**
   * Opens the connection to a given database or creates the database,
   * if it's not existing yet.
   *
   * @param schema The schema for the database
   */
  protected open(
    schema: IDBSchemaConfig<TStores>,
  ): Observable<IDBPDatabase<TStores>> {
    return openFromSchema(schema).pipe(shareReplay(1));
  }

  /**
   * Adds an items to the store.
   *
   * @param storeName The store to which the items should be added
   * @param items The item that should be added
   */
  add<TStoreName extends StoreNames<TStores>>(
    storeName: TStoreName,
    items: StoreValue<TStores, TStoreName> | StoreValue<TStores, TStoreName>[],
  ): Observable<
    StoreKey<TStores, TStoreName> | StoreKey<TStores, TStoreName>[]
  >;
  add(
    storeName: StoreNames<TStores>,
    items:
      | StoreValue<TStores, StoreNames<TStores>>
      | StoreValue<TStores, StoreNames<TStores>>[],
  ): Observable<
    | StoreKey<TStores, StoreNames<TStores>>
    | StoreKey<TStores, StoreNames<TStores>>[]
  > {
    if (Array.isArray(items)) {
      return this.open(this.schema).pipe(
        startTransaction(storeName, TransactionMode.ReadWrite),
        map((tx) => tx.objectStore(storeName)),
        mergeMap((store) => {
          const added = items.map((item) => store.add?.(item));
          return zip(...added);
        }),
      );
    }
    return this.open(this.schema).pipe(
      switchMap((db) => db.put(storeName, items)),
    );
  }

  /**
   * @param storeName The name of the store from which to get an item
   * @param key The key of the item to get
   */
  get<TStoreName extends StoreNames<TStores>>(
    storeName: TStoreName,
    key: IDBKeyRange | StoreKey<TStores, TStoreName>,
  ): Observable<StoreValue<TStores, TStoreName>>;
  get(
    storeName: StoreNames<TStores>,
    key: IDBKeyRange | StoreKey<TStores, StoreNames<TStores>>,
  ): Observable<StoreValue<TStores, StoreNames<TStores>>> {
    return this.open(this.schema).pipe(
      switchMap((db) => db.get(storeName, key)),
      filter(isDefined),
    );
  }

  /**
   * Get all items stored in the ObjectStore
   *
   * @param storeName The name of the store from which to get an item
   *
   * @returns array of all stored items
   */
  getAll<TStoreName extends StoreNames<TStores>>(
    storeName: TStoreName,
    key?: IDBKeyRange | StoreKey<TStores, TStoreName> | null,
    count?: number,
  ): Observable<StoreValue<TStores, TStoreName>[]>;
  getAll(
    storeName: StoreNames<TStores>,
    key?: IDBKeyRange | StoreKey<TStores, StoreNames<TStores>> | null,
    count?: number,
  ): Observable<StoreValue<TStores, StoreNames<TStores>>[]> {
    return this.open(this.schema).pipe(
      switchMap((db) => db.getAll(storeName, key, count)),
    );
  }

  /**
   * Emits all values stored in `storeName` in an observable that meet the predicate
   * function
   *
   * @param storeName The name of the store to execute the query on
   * @param predicate Predicate
   */
  query<TStoreName extends StoreNames<TStores>>(
    storeName: TStoreName,
    predicate: (rec: StoreValue<TStores, TStoreName>) => boolean,
  ): Observable<StoreValue<TStores, TStoreName>[]>;
  query(
    storeName: StoreNames<TStores>,
    predicate: (rec: StoreValue<TStores, StoreNames<TStores>>) => boolean,
  ): Observable<StoreValue<TStores, StoreNames<TStores>>[]> {
    return this.open(this.schema).pipe(
      startTransaction(storeName, TransactionMode.Readonly),
      map((transaction) => transaction.objectStore(storeName)),
      mergeMap((objStore) =>
        // Subscribe to open cursors, iterate through them and return only the items
        // that match the predicate function
        iterateByCursor(objStore, (cursor) => cursor.continue()).pipe(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          map(({ value }) => value),
          filter(predicate),
        ),
      ),
    );
  }

  /**
   * Removes the given items from the store.
   *
   * @param storeName The name of the store
   * @param keys The records that should be removed
   */
  remove<TStoreName extends StoreNames<TStores>>(
    storeName: TStoreName,
    keys: StoreKey<TStores, TStoreName> | IDBKeyRange,
  ): Observable<void>;
  remove(
    storeName: StoreNames<TStores>,
    keys: StoreKey<TStores, StoreNames<TStores>> | IDBKeyRange,
  ): Observable<void> {
    return this.open(this.schema).pipe(
      switchMap((db) => db.delete(storeName, keys)),
    );
  }

  /**
   * Clear the all entries within an objectStore
   *
   * @param storeName
   * @returns
   */
  clear<TStoreName extends StoreNames<TStores>>(
    storeName: TStoreName,
  ): Observable<void>;
  clear(storeName: StoreNames<TStores>): Observable<void> {
    return this.open(this.schema).pipe(switchMap((db) => db.clear(storeName)));
  }
}

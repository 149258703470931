/* eslint-disable @angular-eslint/no-host-metadata-property */

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { classnames } from '@fmnts/common';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { ThemeColor, ThemeSize } from '@fmnts/components';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  defer,
  merge,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  OptionComponent,
  OptionParentComponent,
  OptionSelectionChange,
} from './option.component';

type CompareFn = (a: unknown, b: unknown) => boolean;

const SELECT_OPENED_EVENT_TYPE = 'select-opened';

type SelectOpenedEvent = CustomEvent<SelectBaseComponent<unknown>>;

/**
 * Type guard for the custom
 * @param ev Event to check
 * @returns
 * `true` if `ev` is a ´SelectOpenedEvent´
 */
function isSelectOpenedEvent(ev: Event): ev is SelectOpenedEvent {
  return ev.type === SELECT_OPENED_EVENT_TYPE;
}

@Directive({
  host: {
    '[attr.disabled]': 'disabled || null',
    '[attr.aria-disabled]': 'disabled.toString()',
  },
})
export abstract class SelectBaseComponent<TValue, TOptionValue = TValue>
  implements
    AfterContentInit,
    OptionParentComponent,
    OnDestroy,
    OnInit,
    ControlValueAccessor
{
  public abstract multiple: boolean;
  public abstract options: QueryList<OptionComponent<TOptionValue>>;
  public abstract value: TValue;
  /** Value to set when the value is cleared.  */
  protected abstract readonly _clearValue: TValue;

  // In order to get the connected `NgControl` for this component we
  // need to provide this component instance as the `NG_VALUE_ACCESSOR`
  // instead of using `providers` to avoid circular dependencies.
  // Declare the `ControlValueAccessor` interface as abstract here so
  // that extending classes provide these methods.
  abstract writeValue(obj: unknown): void;

  /** Deals with the selection logic. */
  protected selectionModel!: SelectionModel<OptionComponent<TOptionValue>>;

  @HostBinding('class.fmnts-select') protected readonly componentClass =
    'fmnts-select';

  /**
   * Disable the select input
   */
  @Input()
  @HostBinding('class.fmnts-select--disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  /** Size of the select input */
  @Input() public size: ThemeSize = 'md';

  /** Placeholder for the input field */
  @Input() public placeholder = '';

  /**
   * When set to true the shown text will always be the placeholder
   * even if values are selected.
   */
  @Input() get preferPlaceholderOverValue(): boolean {
    return this._preferPlaceholderOverValue;
  }
  set preferPlaceholderOverValue(value: BooleanInput) {
    this._preferPlaceholderOverValue = coerceBooleanProperty(value);
  }
  private _preferPlaceholderOverValue = false;

  /** for non-required inputs it should be possible to deselect value */
  @Input()
  get allowDeselect(): boolean {
    return this._allowDeselect;
  }
  set allowDeselect(value: BooleanInput) {
    this._allowDeselect = coerceBooleanProperty(value);
  }
  private _allowDeselect = false;

  /** Whether typeahead function is activated */
  @Input()
  get showSearchField(): boolean {
    return this._showSearchField;
  }
  set showSearchField(value: BooleanInput) {
    this._showSearchField = coerceBooleanProperty(value);
  }
  private _showSearchField = false;

  @Input() public typeAheadText = '';

  /**
   * Event that emits whenever the raw value of the select changes. This is here primarily
   * to facilitate the two-way binding for the `value` input.
   * @docs-private
   */
  @Output() readonly valueChange = new EventEmitter<TValue>();

  /**
   * Event emitted when the select panel has been toggled.
   */
  @Output()
  readonly openedChange = new EventEmitter<boolean>();

  /** Combined stream of all of the child options' change events. */
  protected readonly optionSelectionChanges: Observable<
    OptionSelectionChange<TOptionValue>
  > = defer(() => {
    const { options } = this;

    if (options) {
      return options.changes.pipe(
        startWith(options),
        switchMap(() =>
          merge(...options.map((option) => option.selectionChange)),
        ),
      );
    }

    // TODO: probably needs some more time to init
    return EMPTY;
  });

  /**
   * Whether or not the select panel is open.
   */
  public panelOpen = false;

  /**
   * Whether the select is focused.
   */
  @HostBinding('class.fmnts-select--focused')
  get focused(): boolean {
    return this.panelOpen;
  }

  /**
   * `true` if the connected `FormControl` is invalid.
   */
  @HostBinding('class.fmnts-select--invalid')
  public get invalid(): boolean {
    return (this._ngControl?.touched && this._ngControl?.invalid) ?? false;
  }

  @HostBinding('class') get hostClasses(): string {
    return classnames([
      // Variants: size
      `fmnts-select--${this.size}`,
    ]);
  }

  /**
   * Function that is used to compare values with each other.
   * These could be values selected or values from the options.
   * The function should return `true` when the passed values
   * are considered to be the same.
   */
  @Input()
  get compareWith(): CompareFn {
    return this._compareWith;
  }
  set compareWith(fn: CompareFn) {
    this._compareWith = fn;
  }

  /** Emits whenever the component is destroyed. */
  protected readonly destroyed$ = new ReplaySubject<boolean>();

  /**
   * Icon to indicate select dropdown visibiltiy
   * The caret is rotated using animations
   */
  protected readonly dropdownArrow = faCaretDown;

  /**
   * Function that is used to compare to values with each other.
   * These could be values selected or values from the options.
   *
   * @param a
   * @param b
   *
   * @returns
   * When `true` is returned the to values `a` and `b` are considered
   * to be the same.
   */
  protected _compareWith: CompareFn = (a, b) =>
    // eslint-disable-next-line eqeqeq
    a == b;
  protected _onChange: ChangeCallback<TValue> = () => {};
  protected _onTouched: TouchedCallback = () => {};

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    public cd: ChangeDetectorRef,
    /** Form control connected to this select component.  */
    @Self() @Optional() protected _ngControl: NgControl | null,
  ) {
    if (this._ngControl) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this._ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.selectionModel = new SelectionModel<OptionComponent<TOptionValue>>(
      this.multiple,
    );
  }

  ngAfterContentInit(): void {
    // Listen to Model changes, and update component value accordingly
    this.selectionModel.changed
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event) => {
        event.added.forEach((option) => option.select());
        event.removed.forEach((option) => option.deselect());
        this.cd.markForCheck();
      });

    // Initialize and listen to changes in options
    this.options.changes
      .pipe(startWith(null), takeUntil(this.destroyed$))
      .subscribe(() => {
        this._resetOptions();
        this._initializeSelection();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  registerOnChange(fn: ChangeCallback): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  @HostListener('document:keydown.escape')
  onKeydownHandler(): void {
    this.close();
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:select-opened', ['$event'])
  clickOutsideHandler($event: Event): void {
    if (isSelectOpenedEvent($event)) {
      if ($event.detail !== this) {
        this.close();
      }
    } else if (this.panelOpen) {
      $event.stopPropagation();
      this.close();
    }
  }

  @HostListener('click', ['$event'])
  clickInsideHandler($event: Event): void {
    $event.stopPropagation();

    if (this.panelOpen) {
      this._doc.dispatchEvent(
        new CustomEvent(SELECT_OPENED_EVENT_TYPE, { detail: this }),
      );
    }
  }

  /**
   * Toggles the dropdown for the select input
   */
  public toggleInput(): void {
    if (this.panelOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public close(): void {
    if (!this.panelOpen) {
      return;
    }

    this.panelOpen = false;
    this.cd.markForCheck();
    this._onTouched();
    this.openedChange.emit(false);
  }

  public open(): void {
    if (this.disabled || this.panelOpen) {
      return;
    }

    this.panelOpen = true;
    this.cd.markForCheck();
    this.openedChange.emit(true);
  }

  /** Drops current option subscriptions and IDs and resets from scratch. */
  private _resetOptions(): void {
    const changedOrDestroyed = merge(this.options.changes, this.destroyed$);

    this.optionSelectionChanges
      .pipe(takeUntil(changedOrDestroyed))
      .subscribe((event) => {
        if (event.clear) {
          this.clear();
        } else {
          this._onSelect(event.source, event.isUserInput);
        }

        if (event.isUserInput && !this.multiple && this.panelOpen) {
          this.close();
        }
      });
  }

  public clear(): void {
    this.selectionModel.clear();
    this.propagateValueChange(this._clearValue);
  }

  protected propagateValueChange(value: TValue): void {
    this.value = value;
    this.valueChange.emit(value);
    this._onChange(value);
    this.cd.markForCheck();
  }

  /** Invoked when an option is clicked. */
  protected abstract _onSelect(
    option: OptionComponent<TOptionValue>,
    isUserInput: boolean,
  ): void;

  protected abstract _selectOptionsByValue(
    value: TValue,
  ): OptionComponent<TOptionValue>[];

  private _initializeSelection(): void {
    // Defer setting the value in order to avoid the "Expression
    // has changed after it was checked" errors from Angular.
    void Promise.resolve().then(() => {
      if (this._ngControl) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.value = this._ngControl.value || this._clearValue;
      }

      this._setSelectionByValue(this.value);
    });
  }

  /**
   * Sets the selected option based on a value. If no option can be
   * found with the designated value, the select trigger is cleared.
   *
   * @param value Value that should be selected
   */
  protected _setSelectionByValue(value: TValue): void {
    this.selectionModel.clear();
    this._selectOptionsByValue(value);
    this.cd.markForCheck();
  }
}

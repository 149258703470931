import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';

/**
 * Cards are containers that display content such as text, images,
 * buttons, and lists. A card can be a single component, but is
 * often made up of a header, title, subtitle, and content.
 * Cards are broken up into several components to accommodate this
 * structure:
 * - card header
 * - card title
 * - card subtitle
 * - and card content
 */
@Component({
  selector: 'fmnts-card',
  template: '<ng-content></ng-content>',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
  @HostBinding('class.fmnts-card')
  private readonly componentClass = 'fmnts-card';

  /**
   * Defines the elevation of the card.
   */
  @Input()
  @HostBinding('class.fmnts-card--flat')
  get flat(): boolean {
    return this._flat;
  }
  set flat(value: BooleanInput) {
    this._flat = coerceBooleanProperty(value);
  }
  private _flat = false;

  /**
   * Defines the direction of the inner card layout.
   */
  @Input()
  direction: 'column' | 'row' = 'column';

  @HostBinding('class') protected get hostClasses(): string {
    return classnames([
      // Direction of the inner card layout
      `fmnts-card--${this.direction}`,
    ]);
  }
}

import { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilArrayChanged } from './distinct-until-array-changed.operator';

/**
 * @param compare
 * compare function that is called with the previous value
 * and the current value with the same index.
 *
 * @returns
 * An `Observable` that emits the items from the source `Observable`
 * only if they have changed.
 */
export function distinctUntilArrayItemChanged<T>(
  compare: (prev: T, current: T) => boolean,
): MonoTypeOperatorFunction<T[]> {
  return distinctUntilArrayChanged((prev, current) =>
    current.every((c, i) => compare(prev[i], c)),
  );
}

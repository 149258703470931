import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { ChipBaseComponent } from './base-chip.component';

/**
 * Displays a chip.
 *
 * @example
 * <fmnts-chip>
 *  Chip content
 * </fmnts-chip>
 */
@Component({
  selector: 'fmnts-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['./chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent extends ChipBaseComponent {}

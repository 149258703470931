import { InlineTranslateable } from '@fmnts/i18n';
import { EntityState } from '@ngrx/entity';

export enum AsyncUploadStatus {
  Pristine = 'pristine',
  Uploading = 'uploading',
  Failed = 'upload_failed',
  Completed = 'completed',
}

export interface OfflineStoredData<TData> {
  /** Async data to be uploaded. */
  payload: TData;
  /**
   * ISO Date time when the data was stored.
   */
  timestamp: string;
  /** Error message why data could not be uploaded */
  error?: InlineTranslateable;
}

interface BaseData {
  /** State of the async data. */
  status: AsyncUploadStatus;
}

/**
 * Async upload data encapsulates data that should be uploaded, but was
 * stored offline due to some reason
 */
export type UploadData<T extends OfflineStoredData<S>, S = unknown> = T &
  BaseData;

/**
 * Interface for
 */
export interface AsyncUploadAdapter<
  T extends OfflineStoredData<TData>,
  TData = unknown,
> {
  getInitialState(): UploadData<T>;
  getInitialState<S extends object>(state: S): UploadData<T> & S;
  setStatus<S extends UploadData<T>>(state: S, status: AsyncUploadStatus): S;
  setError<S extends UploadData<T>>(error: unknown, state: S): S;
}

export type EntityAsyncUpload<T extends OfflineStoredData<unknown>> =
  EntityState<UploadData<T>>;

/**
 * Interface for EnttyAsyncUpload-Data,
 * which combines `AsyncUploadAdapter` & `EntityAdapter`
 */
export interface EntityAsyncUploadAdapter<
  T extends OfflineStoredData<TData>,
  TData = unknown,
> {
  getInitialState(state?: EntityState<T>): EntityState<UploadData<T>>;
  setLoading<S extends EntityState<UploadData<T>>>(
    dataKey: string,
    state: S,
  ): S;
  setError<S extends EntityState<UploadData<T>>>(
    dataKey: string,
    error: unknown,
    state: S,
  ): S;
  setCompleted<S extends EntityAsyncUpload<T>>(dataKey: string, state: S): S;
  addOne<S extends EntityAsyncUpload<T>>(entity: T, state: S): S;
  setMany<S extends EntityAsyncUpload<T>>(
    entities: T[],
    state: S,
    status: AsyncUploadStatus,
  ): S;
  removeOne<S extends EntityAsyncUpload<T>>(dataKey: string, state: S): S;
}

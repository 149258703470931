import { ViewOptionsFeatureState } from './+state/view-options.reducer';
import { ViewId, ViewOptions } from './view-option.model';

export enum ViewOptionMergeStrategy {
  Set = 'set',
  Merge = 'merge',
}

function _merge<T>(
  current: T | undefined,
  update: T,
  strategy: ViewOptionMergeStrategy,
): T {
  if (!current) {
    return update;
  }

  switch (strategy) {
    case ViewOptionMergeStrategy.Merge:
      return {
        ...(current ?? {}),
        ...update,
      };
    case ViewOptionMergeStrategy.Set:
      return update;
  }
}

function _mergeViewOptions<
  TFilters extends Record<string, unknown>,
  TDisplay extends Record<string, unknown>,
>(
  current: ViewOptions<TFilters, TDisplay>,
  update: ViewOptions<TFilters, TDisplay>,
  strategy: ViewOptionMergeStrategy,
): ViewOptions<TFilters, TDisplay> {
  let { display, filters, ordering } = current;

  if (update.display) {
    display = _merge(display, update.display, strategy);
  }
  if (update.filters) {
    filters = _merge(filters, update.filters, strategy);
  }
  if (update.ordering) {
    ordering = _merge(ordering, update.ordering, strategy);
  }

  return {
    display,
    filters,
    ordering,
  };
}

/**
 * Merges the view options from the current state for a view with id `viewId`
 * with the `update` using the given merge strategy.
 *
 * @param state Current state
 * @param viewId ID of the view to update
 * @param update Updated to the view options
 * @param strategy Merge strategy
 *
 * @returns
 * View options for the given view
 */
export function mergeOptionsForView<
  TFilters extends Record<string, unknown>,
  TDisplay extends Record<string, unknown>,
>(
  state: ViewOptionsFeatureState,
  viewId: ViewId,
  update: ViewOptions<TFilters, TDisplay>,
  strategy: ViewOptionMergeStrategy,
): ViewOptions<TFilters, TDisplay> {
  const currentViewOptions = {
    ...(state.views[viewId] ?? {}),
  } as ViewOptions<TFilters, TDisplay>;

  return _mergeViewOptions(currentViewOptions, update, strategy);
}

/**
 * Merges the views from the current `state` with the `ùpdated` views.
 *
 * @param state Current state
 * @param update Views with updated options
 *
 * @returns
 * Dictionary of view options by view id.
 */
export function mergeViews(
  state: ViewOptionsFeatureState,
  update: ViewOptionsFeatureState['views'],
): ViewOptionsFeatureState['views'] {
  return {
    ...state.views,
    ...update,
  };
}

/**
 * Alignment modifiers for block axis.
 */
export enum AlignBlock {
  Start = 'start',
  End = 'end',
  Center = 'center',
  Baseline = 'baseline',
  Stretch = 'stretch',
}

/**
 * Alignment modifiers for inline axis.
 */
export enum AlignInline {
  Start = 'start',
  End = 'end',
  Center = 'center',
  Around = 'around',
  Between = 'between',
}

export type CssFlexDirection =
  | 'column'
  | 'column-reverse'
  | 'row'
  | 'row-reverse';

export type CssFlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

/**
 * @see https://w3c.github.io/csswg-drafts/css-position-3/#coords
 */
export enum CssPhysicalInset {
  Left = 'left',
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
}
export const CssPhysicalInsetList = Object.values(CssPhysicalInset);

export enum CssCorner {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
}
export const CssCornerList = Object.values(CssCorner);

/**
 * @see https://w3c.github.io/csswg-drafts/css-values-4/#percentages
 */
export type CssPercentageUnit = '%';
/**
 * @see https://w3c.github.io/csswg-drafts/css-values-4/#absolute-lengths
 */
export type CssAbsoluteLengthUnit =
  | 'cm'
  | 'mm'
  | 'Q'
  | 'in'
  | 'pc'
  | 'pt'
  | 'px';
/**
 * @see https://w3c.github.io/csswg-drafts/css-values-4/#relative-lengths
 */
export type CssRelativeLengthUnit =
  | 'em'
  | 'ex'
  | 'cap'
  | 'ch'
  | 'ic'
  | 'rem'
  | 'lh'
  | 'rlh'
  | 'vw'
  | 'vh'
  | 'vi'
  | 'vb'
  | 'vmin'
  | 'vmax';

export type CssLengthUnit = CssAbsoluteLengthUnit | CssRelativeLengthUnit;

/**
 * Creates a CSS translation
 *
 * @param values Values
 * @param unit Unit
 * @returns
 */
export function cssTranslate(
  values: [number, number] | [number, number, number],
  unit: CssLengthUnit | CssPercentageUnit,
): string {
  const translation = values.map((v) => `${v}${unit}`).join(',');
  return values.length === 3
    ? `translate3d(${translation})`
    : `translate(${translation})`;
}

/**
 * Creates a CSS scale statement
 *
 * @param values Values for scaling
 * @returns
 */
export function cssScale(
  values: [number] | [number, number] | [number, number, number],
): string {
  const scaleFactors = values.join(',');
  return values.length === 3
    ? `scale3d(${scaleFactors})`
    : `scale(${scaleFactors})`;
}

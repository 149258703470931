<div class="fmnts-radio-card__radio">
  <input
    #input
    class="fmnts-radio-card__native-control"
    type="radio"
    [id]="inputId()"
    [checked]="checked"
    [disabled]="_disabled()"
    [attr.name]="name()"
    [attr.value]="value"
    [required]="_required()"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    (change)="_onInputInteraction($event)"
  />
</div>

<ng-content></ng-content>

<fa-icon class="fmnts-radio-card__icon" [icon]="_iconChecked"></fa-icon>

import { Customer, CustomerId } from '@fmnts/api/shared';
import { createHttpActions } from '@fmnts/common/store';
import { GeneralHttpError } from '@formunauts/shared/errors/domain';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

enum CustomerActionSource {
  Api = 'Customer API',
  Root = 'Customer Root',
}

export const CustomerRootActions = createActionGroup({
  source: CustomerActionSource.Root,
  events: {
    'Set Assigned Customer': props<{ customerId: CustomerId | null }>(),
    'Unset Assigned Customer': emptyProps(),
  },
});

export const CustomerApiGetActions = createHttpActions(
  CustomerActionSource.Api,
  'Get Customer',
  {
    request: props<{ customerId: Customer['id'] }>(),
    success: props<{ payload: Customer }>(),
    failure: props<{ error: GeneralHttpError }>(),
  },
);

export const CustomerApiActions = {
  getCustomer: CustomerApiGetActions,
};

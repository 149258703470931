import { IPage } from '@fmnts/core';
import { pluck } from '@fmnts/core/object';
import { IdSelector, StoreProp } from '../store.model';
import {
  PageId,
  PaginationAdapter,
  PaginationData,
  PaginationState,
} from './pagination.model';

const _pluckId = pluck(['id']);

interface PaginationAdapterOptions<T, IdType extends StoreProp> {
  /**
   * What the initial page.
   */
  initialPage?: PageId;
  /**
   * A function that returns an ID for an object.
   * By default, it will return the value for the property `'id'`.
   */
  selectId?: IdSelector<T, IdType>;
}

/**
 * Adapter for the pagination store addon.
 * @param options
 * @returns
 */
export function createPaginationAdapter<T, IdType extends StoreProp = number>(
  options?: PaginationAdapterOptions<T, IdType>,
): PaginationAdapter<T, IdType> {
  return new BasePaginationAdapter<T, IdType>(options);
}

class BasePaginationAdapter<T, IdType extends StoreProp>
  implements PaginationAdapter<T, IdType>
{
  private _selectId: IdSelector<T, IdType>;

  constructor(private opts?: PaginationAdapterOptions<T, IdType>) {
    const { selectId } = this.opts ?? {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Fallback to default selector
    this._selectId = selectId ?? _pluckId;
  }

  getInitialState(): PaginationState<T, IdType>;
  getInitialState<S extends object>(state: S): PaginationState<T, IdType> & S;
  getInitialState(
    state?: object,
  ): PaginationState<T, IdType> | (PaginationState<T, IdType> & object) {
    return this._setPagination(
      {
        currentPage: this.opts?.initialPage ?? null,
        pages: {} as Record<IdType, IdType[]>,
        count: 0,
        pageSize: 0,
      },
      state ?? {},
    );
  }

  upsertFromPage<S extends PaginationState<T, IdType>>(
    page: IPage<T>,
    state: S,
  ): S {
    const { count, currentPage, pageSize, results } = page;
    const {
      pagination: { pages },
    } = state;

    return this.setPagignation(
      {
        count,
        pageSize,
        currentPage,
        pages: {
          ...pages,
          [currentPage]: this._mapResultEntities(results),
        },
      },
      state,
    );
  }

  setFromPage<S extends PaginationState<T, IdType>>(
    page: IPage<T>,
    state: S,
  ): S {
    const { count, currentPage, pageSize, results } = page;
    return this.setPagignation(
      {
        count,
        pageSize,
        currentPage,
        pages: {
          [currentPage]: this._mapResultEntities(results),
        },
      },
      state,
    );
  }

  setPagignation<S extends PaginationState<T, IdType>>(
    pagination: PaginationData<T, IdType>,
    state: S,
  ): S {
    return this._setPagination(pagination, state);
  }

  unsetPagination<S extends PaginationState<T, IdType>>(state: S): S {
    return this._setPagination(
      {
        ...state.pagination,
        pages: {},
        currentPage: null,
        count: 0,
        pageSize: 0,
      },
      state,
    );
  }

  setCurrentPage<S extends PaginationState<T, IdType>>(
    currentPage: PageId,
    state: S,
  ): S {
    return this._setPagination(
      {
        ...state.pagination,
        currentPage,
      },
      state,
    );
  }

  unsetCurrentPage<S extends PaginationState<T, IdType>>(state: S): S {
    return this._setPagination(
      {
        ...state.pagination,
        currentPage: null,
      },
      state,
    );
  }

  protected _setPagination<S extends object>(
    pagination: PaginationData<T, IdType>,
    state: S,
  ): PaginationState<T, IdType> & S {
    return {
      ...state,
      pagination,
    };
  }

  private _mapResultEntities(results: readonly T[]): IdType[] {
    return results.map(this._selectId);
  }
}

import { AsJson, ISerializer } from './common.type';

/**
 * Serializer for serializing data to and from JSON format
 */
export class JsonSerializer<T = unknown> implements ISerializer<T> {
  public serialize(data: T & AsJson<T>): string {
    return JSON.stringify(data);
  }

  public deserialize(s: string): T extends AsJson<T> ? T : never {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return JSON.parse(s) as unknown as T;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListHeaderComponent } from './list-header.component';
import { ListItemComponent } from './list-item.component';
import { ListComponent } from './list.component';

@NgModule({
  declarations: [ListHeaderComponent, ListItemComponent, ListComponent],
  exports: [ListHeaderComponent, ListItemComponent, ListComponent],
  imports: [CommonModule],
})
export class FmntsListModule {}

import { Maybe } from '@fmnts/core';
import { StoreProp } from '../store.model';
import { EntityDictionary } from './store-entity.model';

/**
 * @param id ID of the entity
 * @param entityDict Dictionary of entities
 *
 * @returns
 * Entity with the given ID or `undefined` if not found.
 */
export function collectEntity<Key extends StoreProp, T>(
  id: Key,
  entityDict: EntityDictionary<T, Key>,
): Maybe<T> {
  return entityDict[id];
}

/**
 * Use when you know that all ids must be in the given map.
 *
 * @param ids Ordered set of IDs
 * @param entityDict Dictionary of entities
 *
 * @returns
 * An ordered set of entities. If an entity is not available
 * in the entity dictionary, the value will be `undefined`.
 *
 */
export function collectEntityMap<Key extends StoreProp, T>(
  ids: readonly Key[],
  entityDict: EntityDictionary<T, Key>,
): Maybe<T>[] {
  return ids.map(collectFromEntityDictionary(entityDict));
}

/**
 * @param entityDict Dictionary of entities
 *
 * @returns
 * A function that when called with an ID will return
 * the according entity from the given dictionary or
 * `undefined` if not available
 */
export function collectFromEntityDictionary<Key extends StoreProp, T>(
  entityDict: EntityDictionary<T, Key>,
): (id: Key) => Maybe<T> {
  return (id) => collectEntity(id, entityDict);
}

/**
 * @param entityDict Dictionary of entities
 *
 * @returns
 * A function that when called with an set of IDs will
 * return a set of entities. If an entity is not available
 * in the dictionary, its value is `undefined`.
 */
export function collectAllFromEntityDictionary<Key extends StoreProp, T>(
  entityDict: EntityDictionary<T, Key>,
): (ids: readonly Key[]) => Maybe<T>[] {
  return (ids) => collectEntityMap(ids, entityDict);
}

<fmnts-flex
  direction="row"
  justify="between"
  align="center"
  class="fmnts-toast__header"
>
  <fmnts-toast-title *ngIf="data.title">
    <fa-icon
      class="fmnts-toast__title_icon"
      [icon]="data.icon"
      size="lg"
    ></fa-icon>
    {{ data.title }}
  </fmnts-toast-title>

  <button
    fmnts-icon-button
    size="md"
    type="button"
    class="fmnts-toast__close-btn"
    (click)="close()"
  >
    <fa-icon [icon]="iconClose"></fa-icon>
  </button>
</fmnts-flex>

<fmnts-toast-text *ngIf="data.message">
  {{ data.message }}
</fmnts-toast-text>
<fmnts-flex
  direction="row"
  justify="end"
  fmntsToastActions
  *ngIf="data.actions && data.actions.length > 0"
>
  <ng-container *ngFor="let action of data.actions">
    <button
      fmntsToastAction
      fmnts-button
      fill="clear"
      shape="pill"
      type="button"
      (click)="closeWithAction(action)"
    >
      {{ action.content }}
    </button>
  </ng-container>
</fmnts-flex>

<div
  *ngIf="duration"
  [@timeoutTrigger]="{
    value: _timeoutState,
    params: { time: duration },
  }"
  class="fmnts-toast__timeout"
></div>

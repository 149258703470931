import type { Adapter } from '@fmnts/core';
import { IApiFundraiserStatistics } from '../api-model/api-model';
import { FundraiserStatistics } from '../model/fundraiser-statistics';
import { ApiStatisticsItemAdapter } from './statistics-item.adapter';

export class ApiFundraiserStatisticsAdapter
  implements Adapter<FundraiserStatistics>
{
  private readonly statisticsItemAdapter = new ApiStatisticsItemAdapter();

  public adapt(apiDto: IApiFundraiserStatistics): FundraiserStatistics {
    const items = apiDto.statistics.map((item) =>
      this.statisticsItemAdapter.adapt(item),
    );

    return new FundraiserStatistics({
      id: `${apiDto.id}`,
      image: apiDto.image_thumbnail_url,
      firstName: apiDto.first_name,
      fundraiserCode: apiDto.fundraiser_code,
      isActive: apiDto.is_active,
      isCoach: apiDto.is_coach,
      isTeamLeader: apiDto.is_team_leader,
      lastName: apiDto.last_name,
      items,
    });
  }
}

<div class="fmnts-icon-rating__icons">
  <fa-icon
    *ngFor="let s of _maxItem"
    class="fmnts-icon-rating__icon"
    [class.fmnts-icon-rating__icon--readonly]="this.readonly"
    [icon]="icon"
    [size]="this.iconSize"
    aria-hidden="true"
    (click)="toggleRating(s)"
    (mouseover)="hoverRating(s)"
    (mouseleave)="unhoverRating()"
  ></fa-icon>
</div>

<div
  class="fmnts-icon-rating__icons--filled"
  [style.width.%]="(_filledStars / max) * 100"
>
  <fa-icon
    *ngFor="let s of _maxItem"
    class="fmnts-icon-rating__icon fmnts-icon-rating__icon--filled"
    [class.fmnts-icon-rating__icon--readonly]="this.readonly"
    [icon]="icon"
    [size]="this.iconSize"
    aria-hidden="true"
    (click)="toggleRating(s)"
    (mouseover)="hoverRating(s)"
    (mouseleave)="unhoverRating()"
  ></fa-icon>
</div>

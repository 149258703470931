import { Vector2d } from './vector2d';

/**
 * Immutable rectangle.
 */
export class Rect {
  static fromCenterAndSize(center: Vector2d, size: Vector2d): Rect {
    const topLeft = center.subtract(size.scale(0.5));
    return new Rect(topLeft, size);
  }

  /**
   * Area of the rectangle.
   */
  get area(): number {
    return this.size.toArray().reduce((acc, cur) => (acc *= cur), 1);
  }

  /**
   * Top right position
   */
  get topRight(): Vector2d {
    return this.topLeft.add(new Vector2d(this.size.x, 0));
  }

  /**
   * Bottom left position
   */
  get bottomLeft(): Vector2d {
    return this.topLeft.add(new Vector2d(0, this.size.y));
  }

  /**
   * Bottom right position
   */
  get bottomRight(): Vector2d {
    return this.topLeft.add(this.size);
  }

  /**
   * Center of the rectangle
   */
  get center(): Vector2d {
    return this.topLeft.add(this.size.scale(0.5));
  }

  constructor(
    public readonly topLeft: Vector2d,
    public readonly size: Vector2d,
  ) {}

  /**
   * Increases the size by the given `difference`.
   *
   * @param difference
   *
   * @returns
   * New rectangle
   */
  resizeBy(difference: Vector2d): Rect {
    return new Rect(this.topLeft, this.size.add(difference));
  }

  /**
   * Moves the rectangle by the given `vector`.
   *
   * @param vector
   *
   * @returns
   * New rectangle
   */
  translate(vector: Vector2d): Rect {
    return new Rect(this.topLeft.add(vector), this.size);
  }

  /**
   * @param offset
   *
   * @returns
   * A new rectangle which position is moved by the given `offset` to the
   * top left position, but preserving the bottom right position
   */
  offsetBy(offset: Vector2d): Rect {
    return new Rect(this.topLeft.add(offset), this.size.subtract(offset));
  }

  /**
   * Constrains a rectangle to the size of another rectangle
   * Can be used to keep a rectangle within a specified area, while making
   * tranformations to it.
   *
   * @param maxSize The available size for the rect
   *
   * @returns
   * A new rectangle
   *
   * @example
   * ```
   * const maxSize = new Rect(Vector2d.zero, new Vector2d(10,10));
   *
   * const rect = new Rect(Vector2d.zero, Vector2d.one);
   * rect.translate(new Vector2d(-5, -5)).constrainBy(maxSize);
   * // rect will still have same position and size
   * ```
   */
  constrainBy(maxSize: Rect): Rect {
    const topLeft = Vector2d.min(
      Vector2d.max(this.topLeft, maxSize.topLeft),
      Vector2d.max(maxSize.size.subtract(this.size), maxSize.size),
    );

    const size = Vector2d.min(
      this.size,
      maxSize.size,
      // Should not exceed size that is left from the new start position
      maxSize.size.subtract(topLeft),
    );

    return new Rect(topLeft, size);
  }
}

import { IPage, ISerializer, isDefined } from '@fmnts/core';
import { arrayQueryParamSerializer } from './array-query-param.serializer';
import { queryParamKeySerializer } from './query-param.adapter';

export type Ordering = Pick<IPage<any>, 'sortDirection' | 'sortKey'>;

/**
 * Used to serialize/deserialize order parameter as/from strings
 */
export class OrderElementSerializer
  implements ISerializer<Ordering | undefined, string | undefined>
{
  private readonly sortDescPrefix = '-';
  private readonly sortAscPrefix = '';

  serialize(data: Ordering | undefined): string | undefined {
    if (!data) {
      return undefined;
    }

    const { sortKey, sortDirection } = data;
    return `${
      sortDirection === 'desc' ? this.sortDescPrefix : this.sortAscPrefix
    }${sortKey}`;
  }

  deserialize(s: string | undefined): Ordering | undefined {
    const match = (s ?? '').match(/(-?)(.+)/);
    if (!match) {
      return undefined;
    }

    const [_, direction, sortKey] = match;
    return {
      sortDirection: direction === this.sortDescPrefix ? 'desc' : 'asc',
      sortKey,
    };
  }
}

const itemSerializer = new OrderElementSerializer();

/**
 * Reads the `ordering` query parameter.
 */
export const orderingQueryParamsSerializer = queryParamKeySerializer(
  'ordering',
  itemSerializer,
);

/**
 * Reads the multi `ordering` query parameter.
 */
export const multiOrderQueryParamsSerializer = queryParamKeySerializer(
  'ordering',
  arrayQueryParamSerializer(itemSerializer, isDefined),
);

import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { Subject } from 'rxjs';

import { fmntsExpansionAnimations } from './expansion-animation';
import {
  ExpansionHeaderPosition,
  ExpansionTogglePosition,
  FmntsExpansionPanelState,
  getExpandedState,
} from './expansion-model';

/**
 * Displays an expansible detail view.
 */
@Component({
  selector: 'fmnts-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [fmntsExpansionAnimations.bodyExpansion],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent
  extends CdkAccordionItem
  implements OnChanges
{
  @HostBinding('class.fmnts-expansion-panel')
  protected readonly componentClass = 'fmnts-expansion-panel';

  /** The position of the expansion toggle. */
  @Input()
  get togglePosition(): ExpansionTogglePosition {
    return this._togglePosition;
  }
  set togglePosition(value: ExpansionTogglePosition) {
    this._togglePosition = value ?? 'after';
  }
  private _togglePosition: ExpansionTogglePosition = 'after';

  /** The position of the header. */
  @Input()
  get headerPosition(): ExpansionHeaderPosition {
    return this._headerPosition;
  }
  set headerPosition(value: ExpansionHeaderPosition) {
    this._headerPosition = value ?? 'before';
  }
  private _headerPosition: ExpansionHeaderPosition = 'before';

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      this.expanded && `${this.componentClass}--expanded`,
      this.headerPosition === 'after' && `${this.componentClass}--reverse`,
    ]);
  }

  /** Stream that emits for changes in `@Input` properties. */
  public readonly _inputChanges = new Subject<SimpleChanges>();

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    _uniqueSelectionDispatcher: UniqueSelectionDispatcher,
  ) {
    super(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      null as unknown as any,
      _changeDetectorRef,
      _uniqueSelectionDispatcher,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._inputChanges.next(changes);
  }

  /** Toggles the expanded state of the expansion panel. */
  override toggle(): void {
    this.expanded = !this.expanded;
  }

  /** Sets the expanded state of the expansion panel to false. */
  override close(): void {
    this.expanded = false;
  }

  /** Sets the expanded state of the expansion panel to true. */
  override open(): void {
    this.expanded = true;
  }

  public _getExpandedState(): FmntsExpansionPanelState {
    return getExpandedState(this.expanded);
  }
}

import { Translatable } from '@fmnts/api/shared';

export interface ComplimentStats {
  value: string;
  label: Translatable<string>;
  image: string;
  /**
   * Count how often this compliment was given
   */
  count: number;
}

export interface RatingStats {
  /**
   * Integer in the interval [1,5]
   */
  rating: number;
  /**
   * Count how often this rating was given
   */
  count: number;
}

export interface CustomAnswerStats {
  /**
   * Number of approved custom answers
   */
  approved: number;
  /**
   * Number of comments rejected
   */
  rejected: number;
  /**
   * Number of open comments (not approved/rejected)
   */
  open: number;
}

interface IDonationFeedbackEvaluation {
  /**
   * Total number of donations
   */
  donationsTotal: number;

  /**
   * Stats for custom answers
   */
  customAnswers: Readonly<CustomAnswerStats>;

  // TODO(immutable): Make readonly arrays
  /**
   * Stats for ratings
   */
  ratings: Readonly<RatingStats>[];

  // TODO(immutable): Make readonly arrays
  /**
   * Stats for the compliments
   */
  compliments: Readonly<ComplimentStats>[];
}

/**
 * Data object with evaluation information for donation feedbacks
 */
export class DonationFeedbackEvaluation implements IDonationFeedbackEvaluation {
  public donationsTotal: number;
  public customAnswers: Readonly<CustomAnswerStats>;
  public ratings: Readonly<RatingStats>[];
  public compliments: Readonly<ComplimentStats>[];

  constructor(dto: Readonly<Partial<IDonationFeedbackEvaluation>> = {}) {
    this.donationsTotal = dto.donationsTotal;
    // Comments
    this.customAnswers = dto.customAnswers;
    // Rating
    this.ratings = dto.ratings;
    // Compliments
    this.compliments = dto.compliments;
  }
}

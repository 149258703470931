import { IDBStoreConfigs } from '@fmnts/common/indexed-db';
import { Option } from '@fmnts/core';
import { DBSchema } from 'idb';

/**
 * Representation of a trashed form data for indexed DB.
 */
export interface TrashedFormDataIdbModel<TContent> {
  id?: number;
  name: string;
  content: TContent;
  origin: string;
  timestamp: string;
  username: Option<string>;
}

/**
 * DB schema for all stores related to diagnostics data.
 */
export interface AppDiagnosticsDataDbSchema extends DBSchema {
  /**
   * Stores form data that was successfully uploaded.
   */
  diagnostic_form_data_trash: {
    key: number;
    value: TrashedFormDataIdbModel<unknown>;
  };
}

export const appDiagnosticsDataDbStoreConfigs: IDBStoreConfigs<AppDiagnosticsDataDbSchema> =
  {
    diagnostic_form_data_trash: {
      key: 'id',
      autoIncrement: true,
      migrations: [],
    },
  };

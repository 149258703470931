import { Injectable, inject } from '@angular/core';
import { createHttpRequestEffectHandler } from '@fmnts/common/store';
import { fromHttpOrGeneralError } from '@formunauts/shared/errors/domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap } from 'rxjs';
import { UserRepository } from '../auth-repository.model';
import { AuthApiActions } from './auth.actions';

/**
 * Effects for user api.
 */
@Injectable()
export class UserApiEffects {
  private readonly actions$ = inject(Actions);
  private readonly repo = inject(UserRepository);

  /**
   * Fetches Auth Info.
   */
  authInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AuthApiActions.authInfo.request,
        // Also request auth info after a successful login
        AuthApiActions.authenticate.success,
        AuthApiActions.refresh.success,
      ),
      exhaustMap(
        createHttpRequestEffectHandler(AuthApiActions.authInfo, {
          run: () => this.repo.getCurrentUser(),
          onError: fromHttpOrGeneralError('fmnts'),
        }),
      ),
    );
  });
}

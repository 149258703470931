import {
  BooleanInput,
  NumberInput,
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: `fmnts-pagination`,
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  /**
   * Number of elements in total.
   * Defaults to 0.
   */
  @Input() get count(): number {
    return this._count;
  }
  set count(value: NumberInput) {
    this._count = Math.max(coerceNumberProperty(value), 0);
    this.cd.markForCheck();
  }
  private _count = 0;

  /**
   * Number of the current page.
   * Defaults to 1.
   */
  @Input() get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: NumberInput) {
    this._currentPage = Math.max(coerceNumberProperty(value), 1);
    this.cd.markForCheck();
  }
  private _currentPage = 1;

  /**
   * Size of elements on the page.
   * Defaults to 0.
   */
  @Input() get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: NumberInput) {
    this._pageSize = Math.max(coerceNumberProperty(value), 0);
    this.cd.markForCheck();
  }
  private _pageSize = 0;

  @Input() get showTotals(): boolean {
    return this._showTotals;
  }
  set showTotals(value: BooleanInput) {
    this._showTotals = coerceBooleanProperty(value);
    this.cd.markForCheck();
  }
  private _showTotals = false;

  /** Emits the current page. */
  @Output() currentPageChange = new EventEmitter<number>();

  /** @internal */
  protected readonly _iconFirst = faAngleDoubleLeft;
  /** @internal */
  protected readonly _iconPrev = faAngleLeft;
  /** @internal */
  protected readonly _iconNext = faAngleRight;
  /** @internal */
  protected readonly _iconLast = faAngleDoubleRight;

  protected get pageRange(): number[] {
    if (this.count === 0) {
      return [];
    }

    let range = [this.currentPage - 2, this.currentPage + 2];

    if (range[0] < 1) {
      range = [1, 5];
    } else if (range[1] > this.lastPage) {
      range = [this.lastPage - 4, this.lastPage];
    }

    if (range[0] < 1 || range[1] > this.lastPage) {
      range = [1, this.lastPage];
    }

    return Array(range[1] - range[0] + 1)
      .fill(0)
      .map((_, idx) => range[0] + idx);
  }

  public get previousPage(): number {
    return this._currentPage - 1;
  }

  public get nextPage(): number {
    return this._currentPage + 1;
  }

  public get lastPage(): number {
    return Math.ceil(this.count / this.pageSize);
  }

  constructor(private cd: ChangeDetectorRef) {}

  protected navigateTo(page: number): void {
    if (page !== this.currentPage) {
      this.currentPageChange.emit(page);
    }
  }
}

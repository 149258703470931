import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginationComponent } from './pagination.component';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsIconsModule } from '@fmnts/components/icons';

@NgModule({
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
  imports: [CommonModule, FmntsButtonModule, FmntsIconsModule],
})
export class FmntsPaginationModule {}

/**
 * Holds the information on which feature flags are on or off.
 * Instances are immutable. Modifying methods return a cloned
 * instance with the change. The original object is never changed.
 */
export class FeatureFlagSet<T extends string = string> {
  /** Set of features that are enabled */
  private readonly _flags: Set<T>;

  /**
   * Creates a new instance of feature flags
   *
   * @param enabled
   * List of feature flags that are enabled.
   */
  constructor(enabled: Iterable<T>) {
    this._flags = new Set(enabled);
  }

  /**
   * @param flag
   * Flag that should be checked if it is enabled
   *
   * @returns
   * `true` if the flag is enabled.
   */
  public on(flag: T): boolean {
    return this._flags.has(flag);
  }

  /**
   * @param flag
   * Flag that should be checked if it is disabled
   *
   * @returns
   * `true` if the flag is not enabled.
   */
  public off(flag: T): boolean {
    return !this.on(flag);
  }

  /**
   * Adds the provided list of flags as enabled to the already enabled
   * feature flags and returns them in a clone of the original instance.
   *
   * @param flags Flags that should be enabled
   *
   * @returns
   * A clone of the feature flags that has the previous and passed
   * feature flags enabled.
   */
  public enable(...flags: T[]): FeatureFlagSet<T> {
    return new FeatureFlagSet<T>([...this._flags.values(), ...flags]);
  }
}

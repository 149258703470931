import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  InjectionToken,
  Input,
  Output,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { PrimitiveNullUndefined } from '@fmnts/core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

/**
 * Interface for a parent component for options, containing
 * option configuration.
 * E.g. `SelectComponent` or `MultiSelectComponent`
 */
export interface OptionParentComponent {
  /** Whether the parent component allows to select multiple options */
  readonly multiple: boolean;
}

export class OptionSelectionChange<T> {
  constructor(
    /** Reference to the option that emitted the event. */
    public source: OptionComponent<T>,
    /** Whether all options should be deselected */
    public clear: boolean,
    /** Whether the change was made by the user. */
    public isUserInput: boolean,
  ) {}
}

/**
 * Injection token used to provide the parent component to options.
 */
export const FMNTS_SELECT_OPTION_PARENT_COMPONENT =
  new InjectionToken<OptionParentComponent>(
    '@fmnts.components.select.option_parent_component',
  );

@Component({
  selector: `fmnts-select-option, option[fmnts-select-option]`,
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FmntsIconsModule],
})
export class OptionComponent<T = PrimitiveNullUndefined> {
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly cd = inject(ChangeDetectorRef);
  public readonly parent = inject(FMNTS_SELECT_OPTION_PARENT_COMPONENT, {
    optional: true,
  });

  @HostBinding('class.fmnts-select-option')
  protected readonly componentClass = 'fmnts-select-option';

  /** Whether the option is disabled. */
  @HostBinding('class.fmnts-select-option--disabled')
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @HostBinding('class.fmnts-select-option--hidden')
  /** Whether the option is visible. */
  public hidden = false;

  /** Whether the wrapping component is in multiple selection mode. */
  get multiple(): boolean {
    return this.parent?.multiple === true;
  }

  @HostBinding('class.fmnts-select-option--selected')
  get selected(): boolean {
    return this._selected;
  }
  private _selected = false;

  /** Icon to be shown when no data is available */
  protected readonly check = faCheck;

  /** The form value of the option. */
  @Input() value: T | null | undefined;

  /** Event emitted when the option is selected or deselected. */
  @Output() readonly selectionChange = new EventEmitter<
    OptionSelectionChange<T>
  >();

  @HostListener('click')
  protected clickOption(): void {
    if (this.isNoOptionsAvailableOption) {
      return;
    }

    if (!this.disabled) {
      this._selected = this.isDeselectOption
        ? false
        : this.multiple
          ? !this._selected
          : true;
      this.cd.markForCheck();

      // If option with 'deselect' attribute is clicked,
      // emit `deselect = true`
      this._emitSelectionChangeEvent(this.isDeselectOption, true);
    }
  }

  get displayValue(): string {
    return (this._elementRef.nativeElement.textContent || '').trim();
  }

  private get isDeselectOption() {
    return this._elementRef.nativeElement.hasAttribute('deselect');
  }

  private get isNoOptionsAvailableOption() {
    return this._elementRef.nativeElement.hasAttribute('noavailableoptions');
  }

  /** Selects the option. */
  public select(): void {
    if (!this._selected) {
      this._selected = true;
      this.cd.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  /** Deselects the option. */
  public deselect(): void {
    if (this._selected) {
      this._selected = false;
      this.cd.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  /** Emits the selection change event. */
  private _emitSelectionChangeEvent(clear = false, isUserInput = false): void {
    this.selectionChange.emit(
      new OptionSelectionChange<T>(this, clear, isUserInput),
    );
  }
}

/** Possible states of the lifecycle of a modal. */
import { FocusOrigin } from '@angular/cdk/a11y';
import { DialogRef } from '@angular/cdk/dialog';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Observable, Subject, filter, merge, take } from 'rxjs';
import { ModalConfig, ModalPosition } from './modal-config';
import { ModalContainerBaseComponent } from './modal.component';

export const enum ModalState {
  Open,
  Closing,
  Closed,
}

/**
 * Reference to a modal that was opened via the modal service.
 */
export class ModalRef<TInstance, TResult = any> {
  /**
   * Instance of the component opened into the modal.
   */
  componentInstance: TInstance | null = null;

  /** Whether the user is allowed to close the modal. */
  disableClose: boolean | undefined;

  /** Unique ID for the modal. */
  id: string;

  /** Subject for notifying the user that the modal has opened and appeared. */
  private readonly _afterOpened = new Subject<void>();

  /** Subject for notifying the user that the dialog has started closing. */
  private readonly _beforeClosed = new Subject<TResult | undefined>();

  /** Result to be passed to afterClosed. */
  private _result: TResult | undefined;

  /** Handle to the timeout that's running as a fallback in case the exit animation doesn't fire. */
  private _closeFallbackTimeout?: number;

  /** Current state of the modal. */
  private _state = ModalState.Open;

  /** Interaction that caused the modal to close. */
  private _closeInteractionType: FocusOrigin | undefined;

  constructor(
    private _ref: DialogRef<TResult, TInstance>,
    protected config: ModalConfig,
    /**
     * Instance of the container component that hosts the modal content.
     */
    public _containerInstance: ModalContainerBaseComponent,
  ) {
    this.disableClose = config.disableClose;
    this.id = _ref.id;

    // Emit when opening animation completes
    _containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.state === 'opened'),
        take(1),
      )
      .subscribe(() => {
        this._afterOpened.next();
        this._afterOpened.complete();
      });

    // Dispose when closing animation is complete
    _containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.state === 'closed'),
        take(1),
      )
      .subscribe(() => {
        clearTimeout(this._closeFallbackTimeout);
        this._finishModalClose();
      });

    _ref.overlayRef.detachments().subscribe(() => {
      this._beforeClosed.next(this._result);
      this._beforeClosed.complete();
      this._finishModalClose();
    });

    merge(
      this.backdropClick(),
      this.keydownEvents().pipe(
        filter(
          (event) =>
            event.key === 'Escape' &&
            !this.disableClose &&
            !hasModifierKey(event),
        ),
      ),
    ).subscribe((event) => {
      if (!this.disableClose) {
        event.preventDefault();
        _closeModalVia(this, event.type === 'keydown' ? 'keyboard' : 'mouse');
      }
    });
  }

  /**
   * Close the modal.
   * @param modalResult Optional result to return to the modal opener.
   */
  close(modalResult?: TResult): void {
    this._result = modalResult;

    // Transition the backdrop in parallel to the modal.
    this._containerInstance._animationStateChanged
      .pipe(
        filter((event) => event.state === 'closing'),
        take(1),
      )
      .subscribe((event) => {
        this._beforeClosed.next(modalResult);
        this._beforeClosed.complete();
        this._ref.overlayRef.detachBackdrop();

        // The logic that disposes of the overlay depends on the exit animation completing, however
        // it isn't guaranteed if the parent view is destroyed while it's running. Add a fallback
        // timeout which will clean everything up if the animation hasn't fired within the specified
        // amount of time plus 100ms. We don't need to run this outside the NgZone, because for the
        // vast majority of cases the timeout will have been cleared before it has the chance to fire.
        this._closeFallbackTimeout = window.setTimeout(
          () => this._finishModalClose(),
          event.totalTime + 100,
        );
      });

    this._state = ModalState.Closing;
    this._containerInstance._startExitAnimation();
  }

  /** Gets an observable that is notified when the modal has opened and appeared. */
  afterOpened(): Observable<void> {
    return this._afterOpened;
  }

  /**
   * Gets an observable that is notified when the modal is finished closing.
   */
  afterClosed(): Observable<TResult | undefined> {
    return this._ref.closed;
  }

  /**
   * Gets an observable that is notified when the modal has started closing.
   */
  beforeClosed(): Observable<TResult | undefined> {
    return this._beforeClosed;
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  backdropClick(): Observable<MouseEvent> {
    return this._ref.backdropClick;
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  keydownEvents(): Observable<KeyboardEvent> {
    return this._ref.keydownEvents;
  }

  /**
   * Updates the modal's position.
   * @param position New modal position.
   */
  updatePosition(position?: ModalPosition): this {
    const strategy = this._ref.config
      .positionStrategy as GlobalPositionStrategy;

    if (position) {
      if (position.left) {
        strategy.left(position.left);
      } else if (position.right) {
        strategy.right(position.right);
      } else {
        strategy.centerHorizontally();
      }

      if (position.top) {
        strategy.top(position.top);
      } else if (position.bottom) {
        strategy.bottom(position.bottom);
      } else {
        strategy.centerVertically();
      }
    }

    this._ref.updatePosition();

    return this;
  }

  /**
   * Updates the modal's width and height.
   * @param width New width of the modal.
   * @param height New height of the modal.
   */
  updateSize(width: string = '', height: string = ''): this {
    this._ref.updateSize(width, height);
    return this;
  }

  /** Add a CSS class or an array of classes to the overlay pane. */
  addPanelClass(classes: string | string[]): this {
    this._ref.addPanelClass(classes);
    return this;
  }

  /** Remove a CSS class or an array of classes from the overlay pane. */
  removePanelClass(classes: string | string[]): this {
    this._ref.removePanelClass(classes);
    return this;
  }

  /** Gets the current state of the modal's lifecycle. */
  getState(): ModalState {
    return this._state;
  }

  /**
   * Finishes the modal close by updating the state of the modal
   * and disposing the overlay.
   */
  private _finishModalClose() {
    this._state = ModalState.Closed;
    this._ref.close(this._result, { focusOrigin: this._closeInteractionType });
    this.componentInstance = null;
  }
}

/**
 * Closes the modal with the specified interaction type.
 */
export function _closeModalVia<R>(
  ref: ModalRef<R>,
  interactionType: FocusOrigin,
  result?: R,
): void {
  (
    ref as unknown as { _closeInteractionType: FocusOrigin }
  )._closeInteractionType = interactionType;
  return ref.close(result);
}

import {
  EnvironmentProviders,
  Injectable,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';

/** Configuration for the Loqate address capture API service. */
export interface LoqateAddressCaptureApiConfig {
  apiKey: string;
}

/** Configuration for the Loqate bank validation API service. */
export interface LoqateBankValidationApiConfig {
  apiKey: string;
}

export abstract class LoqateApiClientConfig {
  /** Configuration for the Loqate address capture API service. */
  abstract readonly addressCapture: Readonly<LoqateAddressCaptureApiConfig>;
  /** Configuration for the Loqate bank validation API service. */
  abstract readonly bankValidation: Readonly<LoqateBankValidationApiConfig>;
}

/**
 * Sets up the Loqate API client.
 */
export function provideLoqateApiClient(opts: {
  /** Loads the configuration for loqate API client. */
  loadConfig: () => LoqateApiClientConfig;
}): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: LoqateApiClientConfig,
      useFactory: opts.loadConfig,
    },
  ]);
}

@Injectable({
  providedIn: 'root',
})
export class LoqateApiClientConfigService implements LoqateApiClientConfig {
  private readonly _config = inject(LoqateApiClientConfig);

  get addressCapture(): LoqateAddressCaptureApiConfig {
    return this._fromConfig('addressCapture');
  }
  get bankValidation(): LoqateBankValidationApiConfig {
    return this._fromConfig('bankValidation');
  }

  private _fromConfig<K extends keyof LoqateApiClientConfig>(
    key: K,
  ): LoqateApiClientConfig[K] {
    const value = this._config[key];

    if (!value) {
      throw new Error(`Loqate API Client config missing for ${key}`);
    }

    return value;
  }
}

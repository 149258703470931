import { isDefined, isRecord } from '@fmnts/core';

export declare interface ErrorResponse {
  Error: string;
  Description: string;
  Cause: string;
  Resolution: string;
}

/**
 * Type guard for checking if an item returned by
 * the loqate API is an error response
 *
 * @param e Potential error response
 * @returns
 * `true` if the `Error` property is not `undefined`.
 */
export function isErrorResponse(e: unknown): e is ErrorResponse {
  return isRecord(e) && isDefined(e.Error);
}

/**
 * Error from the Loqate API
 */
export class LoqateApiError extends Error {
  override name = 'LoqateApiError';

  /**
   * Identifier for the error
   * @see https://www.loqate.com/resources/support/apis/
   */
  readonly code: string;
  /** Short description */
  readonly description: string;
  /** What caused the error */
  readonly cause: string;
  /** Possible resolution for this error */
  readonly resolution: string;

  constructor(e: ErrorResponse) {
    super(`${e.Error} - ${e.Description}\n${e.Cause}`);

    this.cause = e.Cause;
    this.description = e.Description;
    this.code = e.Error;
    this.resolution = e.Resolution;
  }
}

/**
 * Unknown Error from the Loqate API
 */
export class LoqateUnknownApiError extends Error {
  override name = 'LoqateUnknownApiError';
}

import { CustomerId } from '@fmnts/api/shared';

export interface IWorkshift {
  id: string;
  customerId: CustomerId;
  label: string;
  weight: number;
  /** Starting time of the shift, 'HH:MM:SS' */
  startTime: string;
  /** End time of the shift, 'HH:MM:SS' */
  endTime: string;
}

export class Workshift implements IWorkshift {
  id: string;
  customerId: CustomerId;
  label: string;
  weight: number;
  startTime: string;
  endTime: string;

  constructor(dto: Readonly<IWorkshift>) {
    this.id = dto.id;
    this.customerId = dto.customerId;
    this.endTime = dto.endTime;
    this.label = dto.label;
    this.startTime = dto.startTime;
    this.weight = dto.weight;
  }
}

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeCallback, TouchedCallback } from '@fmnts/common/forms';
import { ComplimentComponent } from './compliment.component';

type ComplimentValue = string;

/**
 * Component that can be used to display a list of compliments
 * that can be toggled.
 */
@Component({
  selector: 'fmnts-compliment-list',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./compliment-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComplimentListComponent),
      multi: true,
    },
  ],
})
export class ComplimentListComponent
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  @HostBinding('class')
  public readonly componentClass = 'fmnts-compliment-list';

  /**
   * Whether multiple button toggles can be selected.
   */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: BooleanInput) {
    this._multiple = coerceBooleanProperty(value);
  }
  private _multiple = false;

  /** Whether multiple button toggle group is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  /**
   * Current value selection
   */
  public get value(): ComplimentValue[] {
    return this._selectionModel.selected.map(({ value }) => value);
  }

  /**
   * Child compliment toggles
   */
  @ContentChildren(forwardRef(() => ComplimentToggleComponent), {
    // Note that this would technically pick up toggles
    // from nested groups, but that's not a case that we support.
    descendants: true,
  })
  private _toggles!: QueryList<ComplimentToggleComponent>;

  /**
   * Holds the selection
   */
  private _selectionModel!: SelectionModel<ComplimentToggleComponent>;

  /**
   * Reference to the raw value that the consumer tried to assign. The real
   * value will exclude any values from this one that don't correspond to a
   * toggle. Useful for the cases where the value is assigned before the toggles
   * have been initialized or at the same that they're being swapped out.
   */
  private _rawValue: ComplimentValue[] = [];

  private _onChanged: ChangeCallback<ComplimentValue[]> = () => {};
  private _onTouched: TouchedCallback = () => {};

  ngOnInit(): void {
    this._selectionModel = new SelectionModel<ComplimentToggleComponent>(
      this.multiple,
      undefined,
      false,
    );
  }

  ngAfterContentInit(): void {
    // Initialize from toggles `checked` property
    this._selectionModel.select(
      ...this._toggles.filter((toggle) => toggle.checked),
    );
  }

  writeValue(obj: ComplimentValue[] | null): void {
    this._setSelectionByValue(obj ?? []);
  }

  registerOnChange(fn: ChangeCallback): void {
    this._onChanged = fn;
  }
  registerOnTouched(fn: TouchedCallback): void {
    this._onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * This is used by the `ComplimentToggleComponent` to propagate upwards
   * to the `ComplimentListComponent` that it should be toggled.
   *
   * @param toggle Compliment that should be toggled
   */
  public _toggle(toggle: ComplimentToggleComponent): void {
    if (this.disabled) {
      // Nothing to do when disabled
      return;
    }

    // Toggle value for the calling item and update
    // its checked property
    this._selectionModel.toggle(toggle);
    toggle.checked = this._selectionModel.isSelected(toggle);

    // Propagate change
    this._onChanged(this._selectionModel.selected.map(({ value }) => value));
    this._onTouched();
  }

  /**
   * This is used by the `ComplimentToggleComponent` to determine its
   * initial `checked` property based on the selection by the
   * `ComplimentListComponent`.
   *
   * @param toggle Toggle component that wants to know it's selected state
   *
   * @returns
   * `true` if the value of the component is selected
   */
  public _isPrechecked(toggle: ComplimentToggleComponent): boolean {
    return this._rawValue.includes(toggle.value);
  }

  /**
   * Updates the selection and `ComplimentToggleComponent`s
   * checked state.
   *
   * @param values
   */
  private _setSelectionByValue(values: ComplimentValue[]) {
    this._rawValue = values;

    if (!this._toggles) {
      return;
    }

    this._selectionModel.clear();
    this._toggles.forEach((toggle) => {
      const checked = values.includes(toggle.value);
      toggle.checked = checked;
      if (checked) {
        this._selectionModel.select(toggle);
      }
    });
  }
}

/**
 * Component that displays a compliment that can be toggled.
 */
@Component({
  selector: 'fmnts-compliment-toggle',
  templateUrl: './compliment-toggle.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplimentToggleComponent implements OnInit {
  @Input() label!: ComplimentComponent['label'];
  @Input() image!: ComplimentComponent['image'];
  @Input() labelPosition!: ComplimentComponent['labelPosition'];

  /**
   * Value used by `<fmnts-compliment-list>` for selection
   */
  @Input() value!: ComplimentValue;

  /**
   * Whether the compliment is checked
   */
  public get checked(): boolean {
    return this._checked;
  }
  public set checked(newValue: boolean) {
    this._checked = newValue;
    this._cd.markForCheck();
  }
  private _checked = false;

  constructor(
    @Inject(ComplimentListComponent) private _list: ComplimentListComponent,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this._list?._isPrechecked(this)) {
      this.checked = true;
    }
  }

  @HostListener('click')
  protected _onClick(): void {
    this._list?._toggle(this);
  }
}

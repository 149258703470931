import { Option } from '@fmnts/core';

export enum SupportRequestType {
  Minor = 'minor',
  Critical = 'critical',
  Crash = 'crash',
  Other = 'other',
}

export type SupportRequestEntityId = number | string;

/** Additional context. */
export type SupportRequestContextRecord = Record<string, unknown>;
export type SupportRequestContext = object;

/**
 * Support request entity.
 */
export interface SupportRequestEntity<TContext extends SupportRequestContext> {
  /** ID of the item. */
  id?: SupportRequestEntityId;
  /** URL of the support request. */
  url?: string;
  /** URL of the screenshot. */
  screenshotUrl?: Option<string>;
  /** Support request type. */
  type: SupportRequestType;
  /** E-Mail for feedback. */
  email: Option<string>;
  /** User entered description of the request. */
  description: string;
  /** Timestamp of the support request. */
  timestamp: Date;
  /** Additional context. */
  context: TContext;
}

/** Data that is entered by the user. */
export type SupportRequestUserData = Pick<
  SupportRequestEntity<SupportRequestContext>,
  'email' | 'description' | 'type'
>;

/**
 * DTO with the required data to create a support request.
 */
export type SupportRequestAddDto<TContext extends SupportRequestContext> = Pick<
  SupportRequestEntity<TContext>,
  'email' | 'description' | 'type' | 'context' | 'timestamp'
>;

/**
 * DTO with the data to create a support request that allows defaults to be added.
 */
export type SupportRequestAddWithDefaultsDto = SupportRequestUserData &
  Partial<Pick<SupportRequestEntity<SupportRequestContext>, 'timestamp'>>;

export interface SupportRequestScreenshotChangeDto {
  /** Binary screenshot. */
  screenshot: Blob;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { TabBarComponent } from './tab-bar.component';

@NgModule({
  declarations: [TabBarComponent],
  exports: [TabBarComponent],
  imports: [CommonModule, FmntsIconsModule],
})
export class FmntsTabsModule {}

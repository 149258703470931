import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPageAdapter, PagedApiResponse, type Page } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import {
  ApiWorkshiftAssignmentAdapter,
  IWorkshiftDto,
  WorkshiftAssignmentToApiAdapter,
} from '../adapter/workshift-assignment.adapter';
import { IApiWorkshiftAssignment } from '../api-model/api-model';
import { WorkshiftAssignment } from '../model/workshift-assignment';

@Injectable({
  providedIn: 'root',
})
export class WorkshiftAssignmentApi {
  private readonly apiToWorkshiftAssignmentAdapter =
    new ApiWorkshiftAssignmentAdapter();
  private readonly apiToWorkshiftAssignmentPageAdapter = new ApiPageAdapter(
    this.apiToWorkshiftAssignmentAdapter,
  );

  private readonly _toApiAdapter = new WorkshiftAssignmentToApiAdapter();

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(
    team: string,
    id: string,
    forceRefresh = false,
  ): Observable<WorkshiftAssignment> {
    const url = this.configService.buildCockpitApiUrl([
      `/v1/teams`,
      team,
      'workshifts',
      id,
    ]);

    const headers = this.apiHelper.makeDefaultHeaders({
      force: forceRefresh,
    });

    return this.http
      .get<IApiWorkshiftAssignment>(url, { headers })
      .pipe(
        map((response) => this.apiToWorkshiftAssignmentAdapter.adapt(response)),
      );
  }

  public list(
    team: string,
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<WorkshiftAssignment>> {
    const url = this.configService.buildCockpitApiUrl([
      `/v1/teams`,
      team,
      'workshifts',
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiWorkshiftAssignment>>(url, { params })
      .pipe(
        map((response) =>
          this.apiToWorkshiftAssignmentPageAdapter.adapt(response),
        ),
      );
  }

  public create(shift: IWorkshiftDto): Observable<WorkshiftAssignment> {
    const url = this.configService.buildCockpitApiUrl([
      `/v1/teams`,
      shift.teamId,
      'workshifts',
    ]);

    const body = this._toApiAdapter.adaptForCreate(shift);
    return this.http
      .post<IApiWorkshiftAssignment>(url, body)
      .pipe(
        map((response) => this.apiToWorkshiftAssignmentAdapter.adapt(response)),
      );
  }

  public update(shift: IWorkshiftDto): Observable<WorkshiftAssignment> {
    const url = this.configService.buildCockpitApiUrl([
      `/v1/teams`,
      shift.teamId,
      'workshifts',
      shift.id,
    ]);

    const body = this._toApiAdapter.adaptForUpdate(shift);
    return this.http
      .patch<IApiWorkshiftAssignment>(url, body)
      .pipe(
        map((response) => this.apiToWorkshiftAssignmentAdapter.adapt(response)),
      );
  }

  public delete(ref: {
    teamId: string;
    shiftId: string;
  }): Observable<Record<string, any>> {
    const url = this.configService.buildCockpitApiUrl([
      `/v1/teams`,
      ref.teamId,
      'workshifts',
      ref.shiftId,
    ]);

    return this.http.delete(url);
  }
}

import { Directive, HostBinding, Input, isDevMode } from '@angular/core';
import { classnames } from '@fmnts/common';
import { Nullish, isNil, isString } from '@fmnts/core';

/**
 * Displays a layout area.
 */
@Directive({
  selector: `fmnts-layout, [fmnts-layout]`,
})
export class LayoutDirective {
  protected readonly componentClass = 'fmnts-layout';

  @Input() get layout(): LayoutName | string[] | Nullish {
    return this._layout;
  }
  set layout(value: LayoutName | string[] | Nullish) {
    this._layout = value;
    this._updateAreasFromLayout(this.layout);
  }
  private _layout: LayoutName | string[] | Nullish = null;

  @HostBinding('class')
  get hostClasses(): string {
    return classnames([
      this.componentClass,
      // Apply "known" layouts
      isString(this.layout) && `${this.componentClass}--${this.layout}`,
    ]);
  }

  /** @internal */
  public _slots = new Set<string>();

  /**
   * @param slot Name of the slot.
   * @returns
   * `true` if the layout container defines the given layout slot.
   */
  public hasSlot(slot: string): boolean {
    return this._slots.has(slot);
  }

  private _updateAreasFromLayout(
    layout: LayoutName | string[] | Nullish,
  ): void {
    this._slots = isNil(layout)
      ? new Set<string>()
      : isString(layout)
        ? NAMED_LAYOUT_SLOTS[layout]
        : this._getSlotsFromAreas(layout);
  }

  private _getSlotsFromAreas(areas: string[]): Set<string> {
    return new Set(areas.join(' ').split(' '));
  }
}

@Directive({
  selector: `[layoutSlot]`,
})
export class LayoutSlotDirective {
  /** Slot in which the element should be rendered. */
  @Input() get layoutSlot(): string {
    return this._layoutSlot;
  }
  set layoutSlot(value: string) {
    this._layoutSlot = value;
    this._verifySlotExists(this._layoutSlot);
  }
  private _layoutSlot!: string;

  @HostBinding('style') get hostStyles(): Record<string, string> {
    return {
      'grid-area': this.layoutSlot,
    };
  }

  constructor(private container: LayoutDirective) {}

  private _verifySlotExists(slot: string) {
    if (!isDevMode()) {
      return;
    }

    if (this.container.layout && !this.container.hasSlot(slot)) {
      throw new Error(`Parent layout does not define slot ${slot}`);
    }
  }
}

/**
 * Names of known layouts
 */
type LayoutName = 'columns' | 'page';

const defaultSlots = new Set<string>([
  'header',
  'footer',
  'content',
  'panel-start',
  'panel-end',
]);
const NAMED_LAYOUT_SLOTS: Record<LayoutName, Set<string>> = {
  columns: defaultSlots,
  page: defaultSlots,
};

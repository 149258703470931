import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FmntsSortHeaderComponent } from './sort-header.component';
import { FmntsSortDirective } from './sort.directive';

@NgModule({
  imports: [CommonModule, FmntsSortHeaderComponent, FmntsSortDirective],
  exports: [FmntsSortHeaderComponent, FmntsSortDirective],
})
export class FmntsSortModule {}

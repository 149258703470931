export interface IAsset {
  id: string;
  file: string;
}

export class Asset implements IAsset {
  public id: string;
  public file: string;

  constructor(dto: Readonly<IAsset>) {
    this.id = dto.id;
    this.file = dto.file;
  }
}

import { coerceArray } from '@angular/cdk/coercion';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { CustomerFeatures } from '@fmnts/api';
import { CustomerFeatureService } from '@fmnts/shared/customer/data-access';

/**
 * Pipe that transforms the given customer feature input into a boolean
 * that is `true` if the customer has this feature active. Otherwise,
 * false is returned.
 *
 * Impure pipe, as this depends on the current customer's feature flags.
 */
@Pipe({
  name: 'customerHasEveryFeature',
  pure: false,
  standalone: true,
})
export class CustomerHasEveryFeaturePipe implements PipeTransform {
  private customerFeatureService = inject(CustomerFeatureService);

  transform(features: CustomerFeatures | CustomerFeatures[]): boolean {
    return this.customerFeatureService.every(...coerceArray(features));
  }
}

import { Result } from '@causality/core';
import { Auth } from '@formunauts/shared/domain';
import * as Data from 'effect/Data';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';

/** Used when user is not authenticated. */
export class UnauthenticatedError extends Data.TaggedError(
  'UnauthenticatedError',
) {}

export const hasPermission =
  (getUserAuth: Fn.LazyArg<O.Option<Auth.AuthInfo>>) =>
  (permission: Auth.Permission): Result.Result<boolean, UnauthenticatedError> =>
    Fn.pipe(
      getUserAuth(),
      _getAuthenticated,
      Result.map(Auth.hasPermission(permission)),
    );

export const hasRole =
  (getUserAuth: Fn.LazyArg<O.Option<Auth.AuthInfo>>) =>
  (role: Auth.Role): Result.Result<boolean, UnauthenticatedError> =>
    Fn.pipe(getUserAuth(), _getAuthenticated, Result.map(Auth.hasRole(role)));

const _getAuthenticated = Result.fromOption(() => new UnauthenticatedError());

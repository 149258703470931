import { inject } from '@angular/core';
import { Result } from '@causality/core';
import { signalStore, withComputed, withMethods } from '@ngrx/signals';
import { Store } from '@ngrx/store';
import * as Fn from 'effect/Function';
import {
  selectAuthInfo,
  selectIsAuthenticated,
  selectOptionSession,
} from './+state/auth.reducer';
import * as AuthUseCases from './auth.use-case';

export const AuthStore = signalStore(
  withComputed((store, globalStore = inject(Store)) => ({
    auth: globalStore.selectSignal(selectAuthInfo),
    session: globalStore.selectSignal(selectOptionSession),
    isAuthenticating: globalStore.selectSignal(selectIsAuthenticated),
    isAuthenticated: globalStore.selectSignal(selectIsAuthenticated),
  })),
  withMethods((store) => ({
    /**
     * Checks if the current user has the given permission.
     * @returns
     * `true` if user is authenticated and has permission, otherwise false.
     */
    hasPermission: Fn.flow(
      AuthUseCases.hasPermission(() => store.auth()),
      Result.getOrElse(Fn.constFalse),
    ),
    /**
     * Checks if the current user has the given role.
     * @returns
     * `true` if user is authenticated and has permission, otherwise false.
     */
    hasRole: Fn.flow(
      AuthUseCases.hasRole(() => store.auth()),
      Result.getOrElse(Fn.constFalse),
    ),
  })),
);

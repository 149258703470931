import { IStatisticsItem, StatisticsItem } from './statistics-item';

export interface IFundraiserStatistics {
  id: string;
  firstName: string;
  fundraiserCode: string;
  image: string;
  isActive: boolean;
  isCoach: boolean;
  isTeamLeader: boolean;
  lastName: string;

  items: readonly Readonly<IStatisticsItem>[];
}

export class FundraiserStatistics implements IFundraiserStatistics {
  id: string;
  firstName: string;
  fundraiserCode: string;
  image: string;
  isActive: boolean;
  isCoach: boolean;
  isTeamLeader: boolean;
  lastName: string;

  items: readonly Readonly<StatisticsItem>[];

  get fullName(): string {
    return [this.firstName, this.lastName].join(' ');
  }

  constructor(dto: IFundraiserStatistics) {
    this.id = dto.id;
    this.firstName = dto.firstName;
    this.fundraiserCode = dto.fundraiserCode;
    this.image = dto.image;
    this.isActive = dto.isActive;
    this.isCoach = dto.isCoach;
    this.isTeamLeader = dto.isTeamLeader;
    this.lastName = dto.lastName;

    this.items = dto.items.map((item) => new StatisticsItem(item));
  }
}

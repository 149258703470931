import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  CustomerApiEffects,
  CustomerAuthEffects,
  CustomerFeatureEffects,
} from './+state/customer.effects';
import { customerFeature } from './+state/customer.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(customerFeature),
    EffectsModule.forFeature([
      CustomerApiEffects,
      CustomerAuthEffects,
      CustomerFeatureEffects,
    ]),
  ],
})
export class SharedCustomerDataAccessModule {}

import * as Option from 'effect/Option';
import * as Struct from 'effect/Struct';
import { OperatorFunction, filter, map } from 'rxjs';

/**
 * An operator that transforms a stream of options to a stream of
 * values of these options.
 */
export function filterMapSomes<A>(): OperatorFunction<Option.Option<A>, A> {
  return (obs$) => obs$.pipe(filter(Option.isSome), map(Struct.get('value')));
}

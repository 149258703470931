import { Adapter, isNil, isNonEmptyString, isString } from '@fmnts/core';
import { InlineTranslateable } from '@fmnts/i18n';
import {
  isMetricDisplayType,
  isMetricType,
  LegacyMetricsType,
  MetricDisplayType,
  MetricType,
} from './domain-enums';
import {
  EntityMetrics,
  MetricsPage,
  MetricsWidget,
  MetricsWidgetBase,
  MetricWidgetMap,
  MetricWidgetsBySlug,
} from './metrics';
import {
  IApiEntityMetrics,
  IApiMetricsPage,
  IApiMetricsWidget,
  ILegacyApiMetricsPage,
  ILegacyApiMetricsWidget,
} from './metrics.api-model';

/**
 * Adapter for converting API responses to `MetricsWidget`.
 */
export class ApiToMetricsWidgetAdapter implements Adapter<MetricsWidget> {
  adapt(dto: IApiMetricsWidget<unknown>): MetricsWidget {
    const { metric_type: originalMetricType } = dto;
    if (!isMetricType(originalMetricType)) {
      throw new Error(`Unknown metrics widget type ${originalMetricType}`);
    }

    const displayType: `${MetricDisplayType}` = isMetricDisplayType(
      dto.display_type,
    )
      ? dto.display_type
      : MetricDisplayType.Metric;
    // We internally convert duration metrics to a duration value
    const metricType =
      displayType === MetricDisplayType.Duration
        ? MetricType.Duration
        : originalMetricType;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return {
      slug: dto.slug,
      title: dto.title,
      metricType,
      displayType,
      value: this._getMetricValue(
        metricType,
        originalMetricType,
        dto.data_current_period,
      ),
      cssClasses: isNonEmptyString(dto.css_classes)
        ? dto.css_classes
        : undefined,
      isSortable: false,
    };
  }

  private _getMetricValue<T extends MetricType>(
    metricType: T,
    originalType: MetricType,
    data: any,
  ): any {
    if (isNil(data)) {
      return this._getEmptyValue(metricType);
    }

    // Here we can do conversions
    if (
      metricType === MetricType.Duration &&
      originalType === MetricType.Numeric
    ) {
      return { minutes: data ?? 0 };
    }

    return data;
  }

  /**
   * @param metricsType
   * @returns
   * Fallback value for the given `metricsType`
   */
  private _getEmptyValue(metricsType: MetricType) {
    switch (metricsType) {
      case MetricType.Average:
        return { count: 0, average: 0 };
      case MetricType.Duration:
        return { minutes: 0 };
      case MetricType.KeyValueList:
        return [];
      case MetricType.Numeric:
        return 0;
      case MetricType.TextList:
        return [];
    }
  }
}

export class LegacyApiToMetricsWidgetAdapter implements Adapter<MetricsWidget> {
  private readonly apiAdapter = new ApiToMetricsWidgetAdapter();

  adapt(dto: ILegacyApiMetricsWidget<unknown>): MetricsWidget {
    const { type } = dto;

    const metricType = this._coerceMetricType(type);
    const displayType = this._coerceDisplayType(type);

    return {
      ...this.apiAdapter.adapt({
        slug: dto.slug,
        title: dto.title,
        display_type: displayType,
        metric_type: metricType,
        data_current_period: this._coerceValue(
          metricType,
          dto.data_current_period,
        ),
        css_classes: undefined,
      }),
      isSortable: this._coerceIsSortable(type),
    };
  }

  private _coerceIsSortable(type: ILegacyApiMetricsWidget['type']): boolean {
    switch (type) {
      case LegacyMetricsType.Average:
      case LegacyMetricsType.Metric:
      case LegacyMetricsType.Money:
      case LegacyMetricsType.Percentage:
        return true;
      default:
        return false;
    }
  }

  private _coerceMetricType(type: ILegacyApiMetricsWidget['type']): MetricType {
    switch (type) {
      case 'buckets':
        return MetricType.KeyValueList;
      case 'average':
      case 'duration':
      case 'metric':
      case 'money':
      case 'percent-of':
        return MetricType.Numeric;
    }
  }

  private _coerceDisplayType(
    type: ILegacyApiMetricsWidget['type'],
  ): MetricDisplayType {
    switch (type) {
      case 'duration':
        return MetricDisplayType.Duration;
      case 'buckets':
      case 'average':
      case 'metric':
        return MetricDisplayType.Metric;
      case 'money':
        return MetricDisplayType.Money;
      case 'percent-of':
        return MetricDisplayType.Percentage;
    }
  }

  private _coerceValue(metricType: MetricType, metricValue: unknown): unknown {
    switch (metricType) {
      case MetricType.KeyValueList:
        // Data from the legacy API is named value / label
        return (
          metricValue as { value: number; label: InlineTranslateable }[]
        ).map(({ label, value }) => ({
          key: label,
          value,
        }));
      default:
        return metricValue;
    }
  }
}

/**
 * Adapts a list of metric widgetss into an object containing each
 * metric by it's slug.
 */
export class ApiToMetricsObjectAdapter<
  TMetricValue,
  TMetric extends MetricsWidgetBase<TMetricValue>,
> implements Adapter<MetricWidgetMap<Record<string, TMetric>>>
{
  constructor(private readonly _widgetAdapter: Adapter<TMetric>) {}

  adapt(
    widgets: ILegacyApiMetricsWidget<any>[],
  ): MetricWidgetMap<Record<string, TMetric>> {
    return {
      slugs: widgets.map(({ slug }) => slug),
      bySlug: widgets.reduce(
        (acc, widget) => ({
          ...acc,
          [widget.slug]: this._widgetAdapter.adapt(widget),
        }),
        {},
      ),
    };
  }
}

/**
 * Adapts an entity containing metrics for this entity.
 */
export class ApiToEntityMetricsAdapter implements Adapter<EntityMetrics> {
  constructor(
    private readonly _widgetObjAdapter: Adapter<
      MetricWidgetMap<MetricWidgetsBySlug<MetricsWidget>>
    >,
  ) {}

  adapt(dto: IApiEntityMetrics): EntityMetrics {
    let name = dto.name;

    if (!isString(name)) {
      // TODO: Ask BE about "name" for perspective "fundraiser"
      const fundraiserEntityMetric = dto as Record<string, any>;
      const fundraiserName = [
        fundraiserEntityMetric['first_name'],
        fundraiserEntityMetric['last_name'],
      ]
        .filter(isString)
        .join(' ');

      if (isNonEmptyString(fundraiserName)) {
        name = fundraiserName;
      }
    }

    return {
      id: dto.id,
      name,
      image: isNonEmptyString(dto.image_thumbnail_url)
        ? dto.image_thumbnail_url
        : null,
      widgets: this._widgetObjAdapter.adapt(dto.widgets),
    };
  }
}

/**
 * Adapts a paginated list of items that contains metrics data.
 */
export class ApiToMetricsPageAdapter<
  T,
  TMetricTotals extends MetricWidgetsBySlug<MetricsWidgetBase<unknown>>,
> implements Adapter<MetricsPage<T, TMetricTotals>>
{
  constructor(
    private readonly _itemAdapter: Adapter<T>,
    private readonly _totalsAdapter: Adapter<MetricWidgetMap<TMetricTotals>>,
  ) {}

  adapt(dto: IApiMetricsPage): MetricsPage<T, TMetricTotals> {
    return {
      count: dto.count,
      currentPage: dto.current_page,
      pageSize: dto.page_size,
      results: dto.results.map((result) => this._itemAdapter.adapt(result)),
      totals: this._totalsAdapter.adapt(dto.totals),
    };
  }
}

/**
 * Adapts a paginated list of items that contains metrics data.
 */
export class LegacyApiToMetricsPageAdapter<
  T,
  TMetricTotals extends MetricWidgetsBySlug<MetricsWidgetBase<unknown>>,
> implements Adapter<MetricsPage<T, TMetricTotals>>
{
  constructor(
    private readonly _itemAdapter: Adapter<T>,
    private readonly _totalsAdapter: Adapter<MetricWidgetMap<TMetricTotals>>,
  ) {}

  adapt(dto: ILegacyApiMetricsPage): MetricsPage<T, TMetricTotals> {
    return {
      count: dto.count,
      currentPage: dto.current_page,
      pageSize: dto.page_size,
      results: dto.results.map((result) => this._itemAdapter.adapt(result)),
      totals: this._totalsAdapter.adapt(dto._total),
    };
  }
}

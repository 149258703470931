import { Directive, InjectionToken } from '@angular/core';
import { Option } from '@fmnts/core';
import type { FmntsRadioGroup } from './radio-group.directive';

/** DI token used for radio buttons. */
@Directive()
export abstract class FmntsRadioButton<T> {
  /** The value of the control. */
  abstract value: Option<T>;
  /** Whether the radio button is checked. */
  abstract checked: boolean;
}

/** Change event object emitted by radio button and radio group. */
export class FmntsRadioChange<T> {
  constructor(
    /** The radio button that emits the change event. */
    public source: FmntsRadioButton<T>,
    /** The value of the radio button. */
    public value: unknown,
  ) {}
}

/**
 * Injection token that can be used to inject instances of `FmntsRadioGroup`. It serves as
 * alternative token to the actual `FmntsRadioGroup` class which could cause unnecessary
 * retention of the class and its component metadata.
 */
export const FMNTS_RADIO_GROUP = new InjectionToken<FmntsRadioGroup>(
  '@fmnts.components.radio.group',
);

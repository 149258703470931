import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays a toast title.
 */
@Component({
  selector: 'fmnts-toast-title,[fmnts-toast-title]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./toast-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToastTitleComponent {
  @HostBinding('class.fmnts-toast-title')
  private readonly componentClass = 'fmnts-toast-title';
}

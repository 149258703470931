/* eslint-disable arrow-body-style */
import { inject } from '@angular/core';
import {
  LogEvent,
  LogLevel,
  LogMessage,
  RedactedLogData,
  logger,
  redactLogData,
} from '@fmnts/common/log';
import { isNil } from '@fmnts/core';
import { Actions, FunctionalEffect, createEffect } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { filter, tap } from 'rxjs';
import { TAction } from './store.model';

type ActionData = Record<string, unknown>;
type Action = TAction<ActionData>;

type PredicateFn = (action: Action) => boolean;
type MapFn = (type: string, data?: ActionData | RedactedLogData) => LogMessage;
type RedactFn = (type: string, data: ActionData) => ActionData | true;

/** regex to identify actions from ngrx. */
const NGRX_ACTION_TYPE_REGEX = /^@ngrx\//;

/** Name used by this logger. */
const NGRX_LOG_NAME = 'ngrx';

/**
 * Uses `@fmnts/common/log` infrastructure to log ngrx actions.
 *
 * @param opts Options
 *
 * @returns
 * Effect that logs ngrx actions
 */
export function logNgrxActions(opts: {
  /** Use to filter actions. */
  predicate: PredicateFn;
  /** Transforms the action to a log message. */
  transform?: MapFn;
  /**
   * Use to redact data.
   * By default, everything will be redacted.
   */
  redact?: RedactFn;
}): FunctionalEffect {
  const {
    redact = () => true,
    transform = _logMessageTransform(LogLevel.Debug),
  } = opts;
  return createEffect(
    (
      actions$ = inject<Actions<Action>>(Actions),
      log = logger({ name: NGRX_LOG_NAME, category: 'ngrx.action' }),
    ) => {
      return actions$.pipe(
        filter(opts.predicate),
        tap((action: Action) => {
          const { type, ...actionData } = action;
          log.log(transform(type, _redact(type, actionData, redact)));
        }),
      );
    },
    { functional: true, dispatch: false },
  );
}

/**
 * Creates a redact function that redacts the action data if the action
 * was created by one of the given action `creators`.
 *
 * @param creators Creators from which the action payload should be redacted.
 */
export function redactFromActionCreators(creators: ActionCreator[]): RedactFn {
  const redactedActionTypes = creators.map(({ type }) => type);
  return (type, data) => (redactedActionTypes.includes(type) ? true : data);
}

/**
 * Filters log messages from the ngrx logger.
 *
 * @param log Log event
 *
 * @returns
 * `true` if the log event was emitted by the ngrx logger.
 */
export function filterStoreLogEvents(log: LogEvent): boolean {
  return log.subsystem !== NGRX_LOG_NAME;
}

/**
 * Filters actions dispatched from ngrx itself.
 */
export function filterStoreInternalActions(action: Action): boolean {
  return !NGRX_ACTION_TYPE_REGEX.test(action.type);
}

/**
 * Helper function to redact ngrx action data.
 */
function _redact(type: string, actionData: ActionData, redact: RedactFn) {
  if (Object.keys(actionData).length === 0) {
    return undefined;
  }

  const redactResult = redact(type, actionData);
  return redactResult === true ? redactLogData() : redactResult;
}

function _logMessageTransform(level: LogLevel): MapFn {
  return (type, data) => ({
    level,
    message: type,
    data: isNil(data) ? undefined : [data],
  });
}

import { InjectionToken } from '@angular/core';
import { DBSchema } from 'idb';

export interface ObjectStoreMigration<TTo = unknown, TFrom = unknown> {
  /**
   * old DB version number
   */
  from: number;
  /**
   * new DB Version number
   */
  to: number;
  /**
   * Data Migration function
   *
   * Will be executed whenever `versionupgraded` event is called, and both `from`
   * and `to` matches with the version upgrade
   */
  up: (data: TFrom[]) => TTo[];
}

export interface ObjectStoreIndex {
  /**
   * The name of the index to create.
   */
  name: string;
  /**
   * The key path for the index to use.
   */
  keyPath: string | string[];
  /**
   * Additional index parameters `unique` and `multiEntry`
   */
  options: IDBIndexParameters;
}

export interface ObjectStoreConfig<T> {
  /**
   * The key for which values are stored. Property has to be unique.
   *
   * If `primaryKey` is provided, this will use this value as the key.
   * If `autoIncrement` is used, this will use the Key Generator value provided by IndexedDB.
   */
  key?: IDBValidKey;
  /**
   * A mechanism for producing new keys in an ordered sequence.
   * If an object store does not have a key generator, then the application
   * must provide keys for records being stored.
   */
  autoIncrement?: boolean;
  /**
   * Index parameters for ObjectStores
   *
   * Indexes act like a primary key, and helps querying data in an objectStore.
   *
   * Indexes are always updated when the objectStore data changes, so use with caution to not run
   * into performance issues.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/IDBIndex
   */
  indexesConfig?: ObjectStoreIndex[];

  /**
   * Data migration options for Object Stores.
   *
   * Whenever a Database schema is changed, stored data can be migrated to the new
   * schema, by using a migration function.
   */
  migrations?: ObjectStoreMigration<T>[];
}

/**
 * Configuration for the stores in a given schema.
 */
export type IDBStoreConfigs<DBTypes extends DBSchema> = {
  [P in keyof DBTypes]: ObjectStoreConfig<DBTypes[P]['value']>;
};

/**
 * The complete schema for an IndexedDB data base.
 */
export interface IDBSchemaConfig<DBTypes extends DBSchema> {
  /**
   * Version used to upgrade the database schema.
   *
   * Changing the version will trigger the `upgradeneeded` Event for IDB
   */
  version: number;
  /**
   * Name of the Database
   */
  name: string;
  /**
   * Name of the Object Stores and the configuration parameters for it.
   */
  stores: IDBStoreConfigs<DBTypes>;
}

export const IDB_SCHEMA_TOKEN = new InjectionToken<IDBSchemaConfig<DBSchema>>(
  'IDB_SCHEMA',
);

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { FmntsButtonModule } from '@fmnts/components/button';
import { FmntsIconsModule } from '@fmnts/components/icons';
import { CalendarWeek, TimeSpan } from '@fmnts/core/chronos';
import { DateService, I18nModule } from '@fmnts/i18n';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { MomentModule } from 'ngx-moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// TODO(architecture): Move to @fmnts/components.
/**
 * Component that shows a calendar.
 * Allows dates and date ranges to be selected.
 */
@Component({
  selector: 'app-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
  standalone: true,
  imports: [
    MomentModule,
    FmntsIconsModule,
    I18nModule,
    CommonModule,
    FmntsButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarPickerComponent {
  @HostBinding('class') readonly componentClass = 'calendar-picker';

  /**
   * Month that the calendar should be displaying
   */
  @Input()
  set currentMonth(value: moment.Moment) {
    this._current.next(value);
  }
  get currentMonth() {
    return this.dateService.moment(this._current.value);
  }

  @Input() firstPossibleDate: moment.Moment | null = null;
  @Input() lastPossibleDate: moment.Moment | null = null;
  @Input() range = false;
  @Input() selectedFromDate?: moment.Moment | null;
  @Input() selectedToDate?: moment.Moment | null;
  @Input()
  set selectedDate(date: moment.Moment | null | undefined) {
    this.selectedFromDate = date;
    this.selectedToDate = date;
  }

  private readonly _current = new BehaviorSubject<moment.MomentInput>(
    new Date(),
  );

  /**
   * Emits with the current (localized) month
   */
  public readonly displayMonth$ = this._current.pipe(
    this.dateService.moment$(),
  );

  /**
   * Emits with the (localized) weekdays.
   */
  public readonly weekdays$ = of(new Date()).pipe(
    this.dateService.moment$(),
    map((current) => this.dateService.weekdays(current)),
  );

  /**
   * Emits the (localized) calendar weeks in the current month.
   * Emits when the current month or locale changes.
   */
  public readonly displayCalendarWeeks$: Observable<CalendarWeek[]> =
    this.displayMonth$.pipe(
      map((current) =>
        this.dateService.calendarWeeksForRange([
          current.clone().startOf('month'),
          current.clone().endOf('month'),
        ]),
      ),
    );

  @Output() selectedDateChanged = new EventEmitter<moment.Moment>();
  @Output() selectedRangeChanged = new EventEmitter<TimeSpan>();

  private highlightedFromDate: moment.Moment | null = null;
  private highlightedToDate: moment.Moment | null = null;
  /**
   * Calendar week number of the highlighted week
   * `null` if nothing is highlighted
   */
  protected highlightedWeek: number | null = null;
  private proposedDate: moment.Moment | null = null;

  get isSelecting(): boolean {
    return this.proposedDate !== null;
  }

  protected readonly iconNext = faChevronRight;
  protected readonly iconPrev = faChevronLeft;

  constructor(private dateService: DateService) {}

  protected goToPreviousMonth() {
    if (this.canGoToPreviousMonth()) {
      this.currentMonth = this.currentMonth.clone().subtract(1, 'month');
    }
  }

  protected goToNextMonth() {
    if (this.canGoToNextMonth()) {
      this.currentMonth = this.currentMonth.clone().add(1, 'month');
    }
  }

  protected selectWeek(firstDay: moment.Moment) {
    this.selectedRangeChanged.emit([firstDay, moment(firstDay).endOf('week')]);
    this.proposedDate = null;
  }

  protected highlightWeek(firstDay: moment.Moment) {
    this.highlightedFromDate = firstDay;
    this.highlightedToDate = moment(firstDay).endOf('week');
  }

  protected selectDate(date: moment.Moment) {
    if (!this.canSelectDay(date)) {
      return;
    }

    if (!this.range) {
      this.selectedDateChanged.emit(date);
    } else if (!this.proposedDate) {
      this.proposedDate = date;

      this.highlightedFromDate = date;
      this.highlightedToDate = date;
    } else if (date.isBefore(this.proposedDate)) {
      this.selectedRangeChanged.emit([date, this.proposedDate]);
      this.proposedDate = null;
    } else if (date.isAfter(this.proposedDate)) {
      this.selectedRangeChanged.emit([this.proposedDate, date]);
      this.proposedDate = null;
    } else {
      this.selectedRangeChanged.emit([this.proposedDate, this.proposedDate]);
    }
  }

  protected updateProposedDates(date: moment.Moment, event: any) {
    event.stopPropagation();

    if (!this.isSelecting) {
      return;
    }

    if (date.isBefore(this.proposedDate, 'day')) {
      this.highlightedFromDate = date;
      this.highlightedToDate = this.proposedDate;
    } else if (date.isAfter(this.proposedDate, 'day')) {
      this.highlightedFromDate = this.proposedDate;
      this.highlightedToDate = date;
    } else {
      this.highlightedFromDate = this.proposedDate;
      this.highlightedToDate = this.proposedDate;
    }

    if (
      this.firstPossibleDate &&
      this.highlightedFromDate?.isBefore(this.firstPossibleDate, 'day')
    ) {
      this.highlightedFromDate = this.firstPossibleDate;
    }
  }

  isInSameMonth(date: moment.Moment): boolean {
    return this.currentMonth.isSame(date, 'month');
  }

  protected isStartDate(date: moment.Moment): boolean {
    if (!this.isSelecting && this.selectedFromDate) {
      return date.isSame(this.selectedFromDate, 'day');
    } else if (this.isSelecting && this.highlightedFromDate) {
      return date.isSame(this.highlightedFromDate, 'day');
    } else {
      return false;
    }
  }

  protected isInSelectedDateRange(date: moment.Moment): boolean {
    if (this.isSelecting || !this.selectedFromDate || !this.selectedToDate) {
      return false;
    }

    return (
      date.isSame(this.selectedFromDate, 'day') ||
      date.isBetween(this.selectedFromDate, this.selectedToDate, 'day') ||
      date.isSame(this.selectedToDate, 'day')
    );
  }

  protected isInPreviewDateRange(date: moment.Moment): boolean {
    if (!this.isSelecting || !this.proposedDate) {
      return false;
    }

    return (
      date.isSame(this.highlightedFromDate, 'day') ||
      date.isBetween(this.highlightedFromDate, this.highlightedToDate, 'day') ||
      date.isSame(this.highlightedToDate, 'day')
    );
  }

  protected isInRange(date: moment.Moment): boolean {
    if (!this.isSelecting && this.selectedFromDate && this.selectedToDate) {
      return date.isBetween(this.selectedFromDate, this.selectedToDate, 'day');
    } else if (
      this.isSelecting &&
      this.highlightedFromDate &&
      this.highlightedToDate
    ) {
      return date.isBetween(
        this.highlightedFromDate,
        this.highlightedToDate,
        'day',
      );
    }

    return false;
  }

  protected isEndDate(date: moment.Moment): boolean {
    if (!this.isSelecting && this.selectedToDate) {
      return date.isSame(this.selectedToDate, 'day');
    } else if (this.isSelecting && this.highlightedToDate) {
      return date.isSame(this.highlightedToDate, 'day');
    } else {
      return false;
    }
  }

  protected canSelectDay(day: moment.Moment) {
    return (
      (!this.firstPossibleDate ||
        this.firstPossibleDate.isSameOrBefore(day, 'day')) &&
      (!this.lastPossibleDate ||
        this.lastPossibleDate.isSameOrAfter(day, 'day'))
    );
  }

  protected canGoToPreviousMonth(): boolean {
    return (
      !this.firstPossibleDate ||
      this.firstPossibleDate.isSameOrBefore(
        this.currentMonth.clone().subtract(1, 'month').endOf('month'),
        'day',
      )
    );
  }

  protected canGoToNextMonth(): boolean {
    return (
      !this.lastPossibleDate ||
      this.lastPossibleDate.isSameOrAfter(
        this.currentMonth.clone().add(1, 'month').startOf('month'),
        'day',
      )
    );
  }
}

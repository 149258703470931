import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

/**
 * Displays a modal title.
 */
@Component({
  selector: 'fmnts-modal-title, [fmnts-modal-title]',
  template: '<ng-content></ng-content>',
  standalone: true,
  styleUrls: ['./modal-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalTitleComponent {
  @HostBinding('class.fmnts-modal-title')
  private readonly componentClass = 'fmnts-modal-title';
}

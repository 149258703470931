<button
  type="button"
  class="fmnts-chip__content fmnts-chip__action"
  [disabled]="disabled"
  (click)="_toggle(true)"
  [tabIndex]="-1"
>
  <span class="fmnts-chip__text-label fmnts-chip__action-label">
    <ng-content></ng-content>
  </span>
</button>

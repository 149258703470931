export enum AppLanguageSource {
  /** /api/next/fundraiser/self */
  Fundraiser = 'fundraiser',
  /** /api-cockpit/v1/customers/* */
  Customer = 'customer',
  /** /api-cockpit/v1/me */
  User = 'user',
  /** Set in QueryParams in Next */
  FormSetting = 'form-setting',
  /** Set in campaign */
  FormLanguages = 'form-languages',
  /** Browser */
  Browser = 'browser',
  /** Default is always english */
  Default = 'default',
}

import { apiDateAdapter } from '@fmnts/api/shared';
import { Adapter } from '@fmnts/core';
import {
  IApiWorkshiftAssignment,
  IWorkshiftAssignmentCreateDto,
  IWorkshiftAssignmentUpdateDto,
} from '../api-model/api-model';
import { WorkshiftAssignment } from '../model/workshift-assignment';
import { ApiFundraiserMinimalAdapter } from './fundraiser.adapter';
import { ApiWorkshiftAdapter } from './workshift.adapter';

export class ApiWorkshiftAssignmentAdapter
  implements Adapter<WorkshiftAssignment>
{
  private readonly apiToFundraiserAdapter = new ApiFundraiserMinimalAdapter();
  private readonly apiToWorkshiftAdapter = new ApiWorkshiftAdapter();

  adapt(json: IApiWorkshiftAssignment): WorkshiftAssignment {
    return new WorkshiftAssignment({
      id: `${json.id}`,
      teamId: `${json.team}`,
      date: apiDateAdapter.adapt(json.date),
      fundraiser: this.apiToFundraiserAdapter.adapt(json.fundraiser),
      workshift: this.apiToWorkshiftAdapter.adapt(json.work_shift),
    });
  }
}

export interface IWorkshiftDto {
  id?: string;
  date: Date;
  fundraiserId: string;
  teamId: string;
  workshiftId: string;
}
export class WorkshiftAssignmentToApiAdapter {
  adaptForCreate(dto: IWorkshiftDto): IWorkshiftAssignmentCreateDto {
    return this._toApiDto(dto);
  }

  adaptForUpdate(dto: IWorkshiftDto): IWorkshiftAssignmentUpdateDto {
    return this._toApiDto(dto);
  }

  private _toApiDto(dto: IWorkshiftDto) {
    return {
      date: apiDateAdapter.adaptToApi(dto.date),
      fundraiser: dto.fundraiserId,
      team_id: dto.teamId,
      work_shift: dto.workshiftId,
    };
  }
}

import { QuizStatus } from '@fmnts/api/shared';
import { Adapter } from '@fmnts/core';
import { IApiQuizFundraiserAssignment } from '../api-model/api-model';
import { QuizFundraiserStatus } from '../model/quiz';
import { ApiFundraiserMinimalAdapter } from './fundraiser.adapter';

export class ApiQuizFundraiserStatusAdapter
  implements Adapter<QuizFundraiserStatus>
{
  constructor(private fundraiserAdapter = new ApiFundraiserMinimalAdapter()) {}

  adapt(value: IApiQuizFundraiserAssignment): QuizFundraiserStatus {
    const { status, ...fundraiser } = value;
    return {
      fundraiser: { ...this.fundraiserAdapter.adapt(fundraiser) },
      status: status as `${QuizStatus}`,
      attemptId: value.relevant_quiz_attempt_id,
    };
  }
}

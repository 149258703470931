/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable deprecation/deprecation */
import { HttpErrorResponse } from '@angular/common/http';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FmntsApiError, isFmntsApiErrorResponse } from '@fmnts/api';
import { HttpRequestFailureActionProps } from '@fmnts/common/store';
import { Observable, of } from 'rxjs';
import { ApiEndpointId } from './errors.model';
import { HttpClientOrNetworkError } from './http-client-or-network-error';
import { LegacyFmntsApiError } from './legacy-fmnts-api-error';
import { UnknownApiError } from './unknown-api-error';

/**
 * A general API error for all APIs used. Use the `error` property
 * to gain more information about the underlying error that occured.
 *
 * @example
 * private handleError(e: GeneralApiError) {
 *  if (e.status === 0) {
 *    // A client-side or network error occurred. Handle it accordingly.
 *    console.log('Client side or network error occured');
 *    return;
 *  }
 *
 *  if (e.apiError) {
 *    if (e.apiError instanceof FmntsApiError) {
 *      console.log(`Formunauts API error ${e.apiError.message}`);
 *      return;
 *    }
 *  }
 *
 *  // Some HTTP error occured
 *  console.log(`HTTP error with status ${e.status}`);
 * }
 */
export class GeneralHttpError extends Error {
  override name = 'GeneralHttpError';
  /** Status code of the error. */
  readonly status: number;

  /** HTTP error from which this error was created */
  readonly httpError: HttpErrorResponse;

  /**
   * Internal (API) error holding the details of the error.
   * - `FmntsApiError`: when the error is a **known** error from our API
   * - `LegacyFmntsApiError`: when the error comes from our endpoint but doesn't use the **known** error from our API
   * - `HttpClientOrNetworkError`: when there was a user or network error
   * - `UnknownApiError`: An unknown error from an (unknown) API
   */
  readonly error:
    | FmntsApiError
    | LegacyFmntsApiError
    | HttpClientOrNetworkError
    | UnknownApiError;

  /**
   * Error details received by the API. It is only present, if there was an error from the API
   * and no client or network error.
   *
   * @deprecated
   * This is only available for legacy reasons and will be removed
   * once all internal API errors use the new error format.
   * Perfer using {@link error} property to check the actual error.
   */
  readonly apiErrorMsg?: any;

  constructor(init: {
    httpError: HttpErrorResponse;
    error: GeneralHttpError['error'];
  }) {
    super(init.error.message);
    this.httpError = init.httpError;
    this.status = this.httpError.status;
    this.error = init.error;
    if (
      this.error instanceof FmntsApiError ||
      this.error instanceof LegacyFmntsApiError ||
      this.error instanceof UnknownApiError
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.apiErrorMsg = this.httpError.error;
    }
  }

  /**
   * Creates an instance of a `GeneralApiError` from a `httpError`
   *
   * @param httpError HTTP error response that was received.
   * @param endpointId Identifier for the endpoint to correctly interpret the error.
   *
   * @returns
   * Instance of `GeneralApiError`.
   */
  static fromHttpErrorResponse(
    httpError: HttpErrorResponse,
    endpointId: ApiEndpointId = 'unknown',
  ): GeneralHttpError {
    return new GeneralHttpError({
      httpError,
      error: _createInternalErrorFromHttpErrorResponse(httpError, endpointId),
    });
  }
}

function coerceGeneralHttpError(
  maybeError: GeneralHttpError | HttpErrorResponse,
  endpointId: ApiEndpointId = 'unknown',
): GeneralHttpError {
  return maybeError instanceof GeneralHttpError
    ? maybeError
    : GeneralHttpError.fromHttpErrorResponse(maybeError, endpointId);
}

/**
 * Helper function to create an error handler to be used with
 * `createHttpRequestEffectHandler`.
 *
 * @param endpointId
 * @returns
 */
export function fromHttpOrGeneralError(
  endpointId: ApiEndpointId,
): (
  e: HttpErrorResponse | GeneralHttpError,
) => Observable<HttpRequestFailureActionProps<GeneralHttpError>> {
  return (e) => of({ error: coerceGeneralHttpError(e, endpointId) });
}

function _createInternalErrorFromHttpErrorResponse(
  httpError: HttpErrorResponse,
  endpointId: ApiEndpointId,
): GeneralHttpError['error'] {
  // Let's be extra cautious and type it as unknown
  const innerError: unknown = httpError.error;

  // In case that there is an error on the client or network side
  // the error is a progress event.
  // see https://angular.io/guide/http#getting-error-details
  if (httpError.status === 0) {
    return new HttpClientOrNetworkError({
      httpError,
      errorEvent: httpError.error as ErrorEvent,
    });
  }

  if ('fmnts' === endpointId) {
    return isFmntsApiErrorResponse(innerError)
      ? new FmntsApiError({
          apiError: innerError.error,
          statusCode: httpError.status,
        })
      : new LegacyFmntsApiError({ httpError });
  }

  // Can't determine what sort of error we're dealing with
  return new UnknownApiError({ httpError });
}

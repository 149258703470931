import { Customer, CustomerId } from '@fmnts/api/shared';
import { Option } from '@fmnts/core';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { CustomerApiActions, CustomerRootActions } from './customer.actions';

export const CUSTOMER_FEATURE_KEY = 'customers';

export interface CustomerFeatureState extends EntityState<Customer> {
  assignedCustomerId: Option<CustomerId>;
}

export interface CustomerPartialState {
  readonly [CUSTOMER_FEATURE_KEY]: CustomerFeatureState;
}

export const customerAdapter = createEntityAdapter<Customer>();

export const initialCustomerFeatureState: CustomerFeatureState =
  customerAdapter.getInitialState({
    assignedCustomerId: null,
  });

export const customerFeature = createFeature({
  name: CUSTOMER_FEATURE_KEY,
  reducer: createReducer(
    initialCustomerFeatureState,
    on(
      CustomerApiActions.getCustomer.success,
      (state, { payload: customer }): CustomerFeatureState =>
        customerAdapter.setOne(customer, state),
    ),
    on(
      CustomerRootActions.setAssignedCustomer,
      (state, { customerId }): CustomerFeatureState => ({
        ...state,
        assignedCustomerId: customerId,
      }),
    ),
    on(
      CustomerRootActions.unsetAssignedCustomer,
      (state): CustomerFeatureState => ({
        ...state,
        assignedCustomerId: null,
      }),
    ),
  ),
});

export const { reducer: customerFeatureReducer } = customerFeature;

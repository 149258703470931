import { Direction } from '@angular/cdk/bidi';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { InjectionToken, ViewContainerRef } from '@angular/core';

/** Options for where to set focus to automatically on dialog open */
export type AutoFocusTarget = 'container' | 'first-tabbable';

/**
 * Injection token that can be used to access the data that was passed in to a sheet.
 */
export const FMNTS_SHEET_DATA = new InjectionToken<unknown>(
  '@fmnts.components.sheet.data',
);

/**
 * Configuration used when opening a sheet.
 */
export class SheetConfig<TData = unknown> {
  /** The view container to place the overlay for the sheet into. */
  viewContainerRef?: ViewContainerRef;

  /** Extra CSS classes to be added to the sheet container. */
  panelClass?: string | string[];

  /** Text layout direction for the sheet. */
  direction?: Direction;

  /** Data being injected into the child component. */
  data?: TData | null = null;

  /** Whether the sheet has a backdrop. */
  hasBackdrop?: boolean = true;

  /** Custom class for the backdrop. */
  backdropClass?: string;

  /** Whether the user can use escape or clicking outside to close the sheet. */
  disableClose?: boolean = false;

  /** Aria label to assign to the sheet element. */
  ariaLabel?: string | null = null;

  /**
   * Whether the sheet should close when the user goes backwards/forwards in history.
   * Note that this usually doesn't include clicking on links (unless the user is using
   * the `HashLocationStrategy`).
   */
  closeOnNavigation?: boolean = true;

  // Note that this is set to 'container' by default, because while the a11y recommendations
  // are to focus the first focusable element, doing so prevents screen readers from reading out the
  // rest of the sheet content.
  /**
   * Where the sheet should focus on open.
   */
  autoFocus?: AutoFocusTarget | ((container: HTMLElement) => void) =
    'container';

  /**
   * Whether the sheet should restore focus to the
   * previously-focused element, after it's closed.
   */
  restoreFocus?: boolean = true;

  /** Scroll strategy to be used for the sheet. */
  scrollStrategy?: ScrollStrategy;
}

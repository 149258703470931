import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';
import { classnames } from '@fmnts/common';
import {
  AlignBlock,
  AlignInline,
  CssFlexDirection,
  CssFlexWrap,
} from '@fmnts/components';
import { ThemeSpacing, cssVarFactory } from '@fmnts/components/core';

export const flexCssVars = cssVarFactory<'gap'>('flex');

export type FlexLayoutGrow = 'hug' | 'fill';

@Directive({
  selector: `fmnts-flex, [fmnts-flex]`,
})
export class FlexLayoutDirective {
  protected readonly componentClass = 'fmnts-flex';

  /** Defines the main axis. */
  @Input() direction: CssFlexDirection = 'row';

  /** Defines how items will wrap. */
  @Input() wrap: CssFlexWrap = 'nowrap';

  /**
   * Defines how the flex layout will take up the width.
   */
  @Input() grow?: FlexLayoutGrow;

  /**
   * Justify aligns the content along the main axis.
   */
  @Input() justify?: `${AlignInline}`;
  /**
   * Align aligns the content along the cross axis.
   */
  @Input() align?: `${AlignBlock}`;

  /**
   * Defines the spacing between flex items along the main axis.
   */
  @Input() gap?: ThemeSpacing;

  /**
   * Whether this is a reel or not.
   */
  @Input()
  get reel(): boolean {
    return this._reel;
  }
  set reel(val: BooleanInput) {
    this._reel = coerceBooleanProperty(val);
  }
  private _reel = false;

  @HostBinding('class') get hostClasses(): string {
    return classnames([
      this.componentClass,
      `${this.componentClass}--${this.direction}`,
      `${this.componentClass}--${this.wrap}`,
      this.gap && `${this.componentClass}--gap`,
      this.justify && `${this.componentClass}--justify-content-${this.justify}`,
      this.align && `${this.componentClass}--align-items-${this.align}`,
      this.grow && `${this.componentClass}--grow-${this.grow}`,
      this.reel && `${this.componentClass}--reel`,
    ]);
  }

  @HostBinding('style') get hostStyles(): Record<string, string> {
    return flexCssVars({
      gap: this.gap && ThemeSpacing[this.gap],
    });
  }
}

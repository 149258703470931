import type { ValueTranslation } from './translation.types';

/**
 * Finds the translation key for a passed mapping
 *
 * @param value The value to map
 * @param mapping The mapping to use
 *
 * @returns
 * The translation key for the passed `value` according to the mapping.
 * If no matching value is found in the mapping, `null` is returned.
 */
export function findValueTranslation<T>(
  value: T,
  mapping: readonly ValueTranslation<T>[],
): string | null {
  // Find the corresponding value
  return mapping.find(({ value: v }) => v === value)?.label ?? null;
}

import { InjectionToken, inject } from '@angular/core';
import { SupportRequestContext } from './support-request.entity';

export type ContextProviderFn<T> = () => T;

export type ContextProviderResolveConfig<T extends SupportRequestContext> = {
  [P in keyof T]: ContextProviderFn<T[P]>;
};

/** Holds the functions for resolving the support request context. */
export const SUPPORT_REQUEST_CONTEXT_RESOLVE_CONFIG = new InjectionToken<
  Record<string, ContextProviderFn<unknown>>
>('shared.support.request-context.resolve-config');

/**
 * Token to provide a single function for resolving the current
 * support request context.
 */
export const SUPPORT_REQUEST_CONTEXT_RESOLVER_FN = new InjectionToken<
  () => SupportRequestContext
>('shared.support.request-context.resolve-fn', {
  factory: () => {
    const entries = Object.entries(
      inject(SUPPORT_REQUEST_CONTEXT_RESOLVE_CONFIG),
    );

    return () =>
      entries.reduce(
        (acc, [prop, fn]) => ({
          ...acc,
          [prop]: fn(),
        }),
        {} as SupportRequestContext,
      );
  },
});

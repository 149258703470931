import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/config/config.service';
import { BehaviorSubject } from 'rxjs';

/**
 * Name of the callback that the google maps library should call
 * when initialized
 */
const CALLBACK_NAME = '_formunauts_google_maps_loader_cb';

/**
 * Loader service for the google maps library
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private isLoading = false;
  private _api$ = new BehaviorSubject<any>(undefined);

  constructor(private configService: ConfigService) {}

  /**
   * An observable that emits with the loaded API
   */
  public get api$() {
    this.load();
    return this._api$;
  }

  /**
   * Loads the google maps library
   */
  private load() {
    if (
      this.isLoading ||
      this._api$.value !== undefined ||
      !this.configService.settings
    ) {
      // Is already initializing, initialized or can't initialize
      return;
    }

    this.isLoading = true;
    const finalize = (api) => {
      this.isLoading = false;
      this._api$.next(api);
    };

    if (this.configService.settings.googleApiKey) {
      this.loadLibrary()
        .then(finalize)
        .catch((error) => finalize(null));
    } else {
      finalize(null);
    }
  }

  /**
   * Loads the google maps library
   */
  private loadLibrary() {
    return new Promise((resolve, reject) => {
      window[CALLBACK_NAME] = () => {
        resolve(window['google']);
        delete window[CALLBACK_NAME];
      };

      const script = document.createElement('script');
      script.src = this.createUrl();
      script.async = true;
      script.onerror = (e) => reject(e);

      document.head.appendChild(script);
    });
  }

  private createUrl() {
    const key = this.configService.settings.googleApiKey;

    const searchParams = new URLSearchParams();
    searchParams.append('libraries', 'places');
    searchParams.append('key', key);
    searchParams.append('callback', CALLBACK_NAME);

    return `//maps.googleapis.com/maps/api/js?${searchParams.toString()}`;
  }
}

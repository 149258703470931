import { isTuple } from '@fmnts/core';
import moment from 'moment';
import { TimeSpan } from './chronos.type';
import { coerceIsoDate } from './coerce-date-input';

/**
 * Coerce a time span input to a time span.
 *
 * @param input Tuple of date inputs
 * @returns
 * `input` as a TimeSpan or `undefined` if it can't be coerced.
 *
 * @example
 * const date_range = coerceTimeSpan(['2020-01-01', '2020-12-31'])
 */
export function coerceTimeSpan(input: unknown): TimeSpan | undefined;

/**
 * Coerce a time span input to a time span.
 *
 * @param input Tuple of date inputs
 * @param fallback Fallback value that should be returned
 * @returns
 * `input` as a TimeSpan or `fallback` if it can't be coerced.
 *
 * @example
 * const date_range = coerceTimeSpan([], null) // is === null
 */
export function coerceTimeSpan<TFallback>(
  input: unknown,
  fallback: TFallback,
): TimeSpan | TFallback;

export function coerceTimeSpan(
  input: unknown,
  fallback?: unknown,
): TimeSpan | unknown {
  if (!Array.isArray(input) || !isTuple<unknown>(input, 2)) {
    return fallback;
  }
  const [start, end] = input;

  const parsedStart = _coerceToMoment(start);
  if (!parsedStart) {
    return fallback;
  }

  const parsedEnd = _coerceToMoment(end);
  if (!parsedEnd) {
    return fallback;
  }

  return [parsedStart, parsedEnd];
}

function _coerceToMoment(value: unknown): moment.Moment | undefined {
  const asMoment = moment.isMoment(value)
    ? value
    : moment(coerceIsoDate(value));

  return asMoment.isValid() ? asMoment : undefined;
}

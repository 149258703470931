import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Direction } from '@angular/cdk/bidi';
import { InjectionToken, ViewContainerRef } from '@angular/core';

/** Injection token that can be used to access the data that was passed in to a toast. */
export const TOAST_DATA = new InjectionToken<any>('ToastData');

/** Injection token that can be used to access the duration that was passed in to a toast. */
export const TOAST_DURATION = new InjectionToken<number>('ToastDuration');

/** Possible values for horizontalPosition on ToastConfig. */
export type ToastHorizontalPosition =
  | 'start'
  | 'center'
  | 'end'
  | 'left'
  | 'right';

/** Possible values for verticalPosition on ToastConfig. */
export type ToastVerticalPosition = 'top' | 'bottom';

/**
 * Configuration used when opening a toast.
 */
export class ToastConfig<D = any> {
  /** The politeness level for the MatAriaLiveAnnouncer announcement. */
  politeness?: AriaLivePoliteness = 'assertive';

  /**
   * Message to be announced by the LiveAnnouncer. When opening a toast without a custom
   * component or template, the announcement message will default to the specified message.
   */
  announcementMessage?: string = '';

  /**
   * The view container that serves as the parent for the toast for the purposes of dependency
   * injection. Note: this does not affect where the toast is inserted in the DOM.
   */
  viewContainerRef?: ViewContainerRef;

  /** The length of time in milliseconds to wait before automatically dismissing the toast. */
  duration?: number = 0;

  /** Extra CSS classes to be added to the toast container. */
  panelClass?: string | string[];

  /** Text layout direction for the toast. */
  direction?: Direction;

  /** Data being injected into the child component. */
  data?: D | null = null;

  /** The horizontal position to place the toast. */
  horizontalPosition?: ToastHorizontalPosition = 'right';

  /** The vertical position to place the toast. */
  verticalPosition?: ToastVerticalPosition = 'bottom';
}

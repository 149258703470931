import { Tree, TreeNode } from '@fmnts/core';

export interface UrlRoute<T> {
  /**
   * The path for the route
   * @example
   * '/parent/child'
   */
  path: string;
  /**
   * The value for the route
   */
  value: T;
  /**
   * The children for the current route
   */
  children?: UrlRoute<T>[];
}

export class UrlTreeNode<T> extends TreeNode<T> {
  public static readonly separator = '/';
  public static readonly templated = ':';

  constructor(
    private _segment: string,
    value: T,
    children: UrlTreeNode<T>[],
  ) {
    super(value, children);
  }

  public override get children(): UrlTreeNode<T>[] {
    return this._children as UrlTreeNode<T>[];
  }

  public get segment(): string {
    return this._segment;
  }

  private get isTemplated(): boolean {
    return this.segment.startsWith(UrlTreeNode.templated);
  }

  /**
   * @param path The path to traverse
   *
   * @returns
   * The node for the given `path`.
   * If path could not be traversed, undefined is returned
   *
   * @example
   * findByPath('/parent/child')
   */
  public findByPath(path: string): UrlTreeNode<T> | undefined {
    return this.findBySegments(...splitPath(path));
  }

  public findPathBySegments(...segments: string[]): UrlTreeNode<T>[] {
    const { segment } = this;
    const [first, ...rest] = segments;

    if (!this.isTemplated && first !== segment) {
      return [];
    }

    // First and the current path are matching
    if (rest.length === 0 || (rest.length === 1 && rest[0] === '')) {
      // No more segments to match
      return [this];
    }

    let resultingPath: UrlTreeNode<T>[] = [];
    for (const child of this.children) {
      // Either use child result, or the previous one
      // This way, templated results are preserved
      const path = child.findPathBySegments(...rest);
      if (path.length) {
        resultingPath = [this, ...path];
        if (!child.isTemplated) {
          // Stop immediately, if we found a non-templated result
          break;
        }
      }
    }

    return resultingPath;
  }

  public findBySegments(...segments: string[]): UrlTreeNode<T> | undefined {
    return TreeNode.popPath(this.findPathBySegments(...segments));
  }
}

export class UrlTree<T> extends Tree<T> {
  /**
   * @param routes The routes from which the tree should be created
   *
   * @returns
   * A new instance of `UrlTree` from the given `routes`.
   */
  public static fromRoutes<T>(routes: UrlRoute<T>[]): UrlTree<T> {
    return new UrlTree<T>(
      new UrlTreeNode<T>(
        '',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        undefined,
        createChildren(normalizeConfig(routes)),
      ),
    );
  }

  constructor(root: UrlTreeNode<T>) {
    super(root);
  }

  public override get root(): UrlTreeNode<T> {
    return this._root as UrlTreeNode<T>;
  }
}

/**
 * Helper function to create node children from routes.
 *
 * @param routes The routes from which the children should be created
 */
function createChildren<T>(
  routes: UrlRoute<T>[] | undefined,
): UrlTreeNode<T>[] {
  if (!routes) {
    return [];
  }

  return routes.map(
    ({ path, value, children }) =>
      new UrlTreeNode<T>(path, value, createChildren(children)),
  );
}

/**
 * Helper function that ensures the given `routes` are canonical.
 *
 * @param routes
 *
 * @example
 * const r = normalizeConfig([{ path: 'parent/child'}])
 * r = [{
 *  path: 'parent',
 *  children: [{
 *    path: 'child'
 *  }]
 * }]
 */
function normalizeConfig<T>(routes: UrlRoute<T>[]): UrlRoute<T>[] {
  const reducer = (result: UrlRoute<T>[], current: UrlRoute<T>) => {
    const [_root, ...segments] = splitPath(current.path);

    let node: UrlRoute<any> | undefined;
    let childrenUrls = result;
    for (const segment of segments) {
      node = childrenUrls.find((c) => c.path === segment);
      if (!node) {
        node = { path: segment, value: undefined, children: [] };
        childrenUrls.push(node);
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      childrenUrls = node.children!;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    node!.value = current.value;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    node!.children = (current.children || []).reduce(reducer, node!.children!);

    return result;
  };

  return routes.reduce(reducer, [] as UrlRoute<T>[]);
}

function splitPath(path: string) {
  const prefixedPath = path.startsWith(UrlTreeNode.separator)
    ? path
    : `${UrlTreeNode.separator}${path}`;
  return prefixedPath.split(UrlTreeNode.separator);
}

/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive } from '@angular/core';

/** Directive that should be applied to the element containing the toast's action buttons. */
@Directive({
  selector: `[fmntsToastActions]`,
  host: {
    class: 'fmnts-toast-actions',
  },
})
export class FmntsToastActionsDirective {}

/** Directive that should be applied to each of the toast's action buttons. */
@Directive({
  selector: `[fmntsToastAction]`,
  host: {
    class: 'fmnts-toast-action',
  },
})
export class FmntsToastActionDirective {}

<svg
  role="img"
  aria-hidden="true"
  focusable="false"
  viewBox="0 0 1500 1505"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xml:space="preserve"
>
  <g class="rocket__thrusts">
    <rect
      class="rocket__thrust"
      [class.rocket__thrust--stopped]="!this.animateThrusts"
      x="-486"
      y="808"
      width="0"
      height="148"
    />
    <rect
      class="rocket__thrust"
      [class.rocket__thrust--stopped]="!this.animateThrusts"
      x="-592"
      y="988"
      width="0"
      height="152"
    />
    <rect
      class="rocket__thrust"
      [class.rocket__thrust--stopped]="!this.animateThrusts"
      x="-370"
      y="1170"
      width="0"
      height="148"
    />
  </g>
  <g style="fill-rule: nonzero">
    <path
      d="M668.771,1487.96c-5.5,3.417 -11,6.709 -16.584,9.875c6.751,0.834 13.542,1.667 20.292,2.334c-1.333,-4.042 -2.583,-8.125 -3.708,-12.209Z"
    />
    <path
      d="M806.775,527.383l-3.421,-0.179c0.992,-2.025 2.025,-4.008 3.108,-5.987c0.046,2.071 0.134,4.095 0.313,6.166Z"
    />
    <path
      d="M982.888,697.683c-1.984,1.125 -3.967,2.117 -5.946,3.109l-0.179,-3.425c2.024,0.137 4.095,0.27 6.125,0.316Z"
    />
    <path
      d="M1301.83,331.337c-8.646,8.642 -18.592,15.217 -29.212,19.625c-33.134,13.867 -72.796,7.338 -99.804,-19.625c-26.967,-27.008 -33.492,-66.67 -19.63,-99.804c4.413,-10.625 10.984,-20.575 19.63,-29.216c15.441,-15.442 34.974,-24.175 55.095,-26.246c26.379,-2.746 53.709,6.033 73.921,26.246c20.213,20.212 28.95,47.491 26.204,73.875c-2.029,20.12 -10.762,39.704 -26.204,55.145Zm130.729,-280.816l-164.175,40.2c-29.754,7.292 -57.891,19.941 -83.054,37.271c-3.65,2.479 -7.208,5.091 -10.716,7.787l-0.046,0c-8.913,6.8 -17.421,14.229 -25.388,22.196l-40.292,42.046l-189.612,197.941l-67.167,-3.87l-15.304,-0.9c-12.383,-0.721 -24.446,3.871 -33.225,12.65l-114.75,114.745l114.525,6.617c0.992,-2.025 2.025,-4.008 3.108,-5.987c0.046,2.071 0.134,4.095 0.313,6.166l-3.421,-0.179c-2.162,4.413 -4.096,8.871 -5.762,13.463c-18.275,49.875 -14.042,101.241 25.3,140.587c39.345,39.3 90.712,43.575 140.587,25.254c4.546,-1.666 9.05,-3.558 13.463,-5.716l-0.179,-3.425c2.024,0.137 4.095,0.27 6.125,0.316c-1.984,1.125 -3.967,2.117 -5.946,3.109l6.616,114.521l114.75,-114.751c8.775,-8.774 13.371,-20.841 12.65,-33.22l-0.9,-15.35l-3.875,-67.254l197.896,-189.521l42.046,-40.246c8.013,-8.013 15.442,-16.521 22.242,-25.438l-0,-0.041c2.7,-3.467 5.312,-7.025 7.787,-10.671c17.288,-25.163 29.983,-53.3 37.229,-83.1l40.2,-164.179c3.105,-12.65 -8.329,-24.084 -21.025,-21.021Z"
    />
    <path
      d="M806.775,527.383l-3.421,-0.179c0.992,-2.025 2.025,-4.008 3.108,-5.987c0.046,2.071 0.134,4.095 0.313,6.166Z"
    />
    <path
      d="M982.888,697.683c-1.984,1.125 -3.967,2.117 -5.946,3.109l-0.179,-3.425c2.024,0.137 4.095,0.27 6.125,0.316Z"
    />
    <path
      d="M1280.31,1284.5c-55.625,55.667 -117.916,100.792 -184.25,135.25c-108.129,56.292 -227.125,84.417 -346.041,84.417c-25.875,-0 -51.792,-1.334 -77.542,-4c-1.333,-4.042 -2.583,-8.125 -3.708,-12.209c-5.5,3.417 -11,6.709 -16.584,9.875c-158.166,-20.708 -311.041,-91.833 -432.545,-213.333c-267.409,-267.458 -290.659,-686.667 -69.663,-980.5c21,-27.958 44.25,-54.792 69.663,-80.208c146.462,-146.459 338.42,-219.625 530.379,-219.625c133.5,-0 267.042,35.416 385.25,106.25l-65.875,65.875c-96.625,-53.625 -205.792,-82.125 -319.375,-82.125c-176.292,-0 -342.042,68.666 -466.708,193.333c-11.959,11.958 -23.417,24.292 -34.338,37.004c47.171,17.038 91.421,44.496 129.296,82.371c52.625,52.625 85.125,117.667 97.625,185.75c25.5,3.375 50.791,8.708 75.542,16.167l-310.755,310.708l89.417,89.375l343.921,-343.917c14.792,9.459 29.25,19.834 43.166,31.167l-422.416,422.417l89.375,89.416l420.375,-420.375c11.125,15.125 21.208,30.75 30.333,46.834l-255.833,255.833l89.417,89.375l216.833,-216.833c19.375,82.083 17.125,168.208 -6.713,249.375c55.755,-0 111.459,21.25 153.963,63.791c16.458,16.459 29.667,34.834 39.75,54.459c52.291,-29.334 100.875,-66 144.375,-109.584c124.667,-124.662 193.333,-290.416 193.333,-466.708c0,-113.583 -28.5,-222.792 -82.167,-319.417l65.834,-65.875c172.792,288.167 135.042,667.334 -113.334,915.667Z"
    />
  </g>
</svg>

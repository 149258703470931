<fa-icon
  *ngIf="this.variant === 'fontawesome'"
  [icon]="iconSpinner"
  [spin]="true"
  [size]="spinnerSize()"
></fa-icon>

<fmnts-rocket
  *ngIf="this.variant === 'rocket'"
  [size]="this.size"
  [color]="this.color"
  [animateThrusts]="true"
></fmnts-rocket>

export type FilterKey = string | number;

/**
 * Filter types that can be used with the remote filter API.
 */
export enum FilterType {
  Campaigns = 'campaigns',
  CampaignTypes = 'campaign_types',
  Coaches = 'coaches',
  Customers = 'customers',
  Fundraisers = 'fundraisers',
  FundraiserLanguages = 'fundraiser_languages',
  GoalValueUnits = 'goal_value_units',
  Jobscouts = 'jobscouts',
  Locations = 'locations',
  Organizations = 'organizations',
  PetitionCampaignId = 'petition_campaign_id',
  Presentations = 'presentations',
  RecruitingCampaigns = 'recruiting_campaigns',
  RecruitingCampaignTypes = 'recruiting_campaign_types',
  RecruitingCampaignJobtypes = 'recruiting_campaign_jobtypes',
  RecruitingTeams = 'recruiting_teams',
  Tags = 'tags',
  Teams = 'teams',
  TeamLeaders = 'teamleaders',
  TeamTypes = 'team_types',
}

export interface FilterResult {
  name: `${FilterType}`;
  items: FilterValue[];
}

export interface FilterValue {
  id: FilterKey;
  label: string;
}

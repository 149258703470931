import { ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { OperatorFunction, map, merge, startWith } from 'rxjs';
import { HttpActionEvents } from './http.model';

/**
 * Emits `true` if a request action was dispatched, otherwise `false`.
 *
 * @param events API events action group
 * @returns
 */
export function mapToRequestPending<U extends Action = Action>(
  events: HttpActionEvents<any, any, any>,
): OperatorFunction<U, boolean> {
  return (obs$) =>
    merge(
      obs$.pipe(ofType(events.request)).pipe(map(() => true)),
      obs$.pipe(ofType(events.success, events.failure)).pipe(map(() => false)),
    ).pipe(startWith(false));
}

import { OperatorFunction, map } from 'rxjs';

/**
 * Takes an array of elements as a source and emits a
 * `boolean` value, that is `true` if any element
 * fulfills the given `predicate`.
 *
 * @param predicate Predicate function
 *
 * @example
 * // Will first emit false, then true
 * of([1, 2], [1, 2, 3]).pipe(arrayMapToSome(x => x >= 3));
 * // (ab|) -> a: false, b: true
 */
export function arrayMapToSome<T>(
  predicate: (value: T, index: number, array: T[]) => boolean,
): OperatorFunction<T[], boolean> {
  return map((items) => items.some(predicate));
}

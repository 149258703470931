import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, combineLatest, concatMap, defer, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrowserCaches {
  private readonly _caches: CacheStorage | null;

  constructor(@Inject(DOCUMENT) _doc: Document) {
    // If `caches` are not supported, set it to `null`.
    this._caches = _doc.defaultView?.caches ?? null;
  }

  /**
   * Deletes Cache Storages when user session ends.
   *
   * These caches are configured in `ngsw-config.json` and save APIs
   * using the Cache API.
   */
  public clearCacheStorage(
    cacheNameMatchers: RegExp[],
  ): Observable<{ cacheName: string; deleted: boolean }[]> {
    return this._cacheNames$().pipe(
      concatMap((cacheNames) => {
        const cacheNamesToDelete = cacheNames.filter((key) =>
          cacheNameMatchers.some((rx) => rx.test(key)),
        );

        return cacheNamesToDelete.length === 0
          ? EMPTY
          : combineLatest(
              cacheNamesToDelete.map((cacheName) =>
                this._delete$(cacheName).pipe(
                  map((deleted) => ({ cacheName, deleted })),
                ),
              ),
            );
      }),
    );
  }

  private _delete$(cacheName: string) {
    return defer(() => this._caches?.delete(cacheName) ?? EMPTY);
  }

  private _cacheNames$() {
    return defer(() => this._caches?.keys() ?? EMPTY);
  }
}

import { Either, Equivalence, Function, identity } from 'effect';

/**
 * An `Result<A, E = never>` describes a result.
 *
 * There are two possible values for an `Result<A, E>`:
 *   - `Result.Success` contain a success value of type `A`
 *   - `Result.Failure` contains a failure `Cause` of type `E`
 */
export type Result<A, E> = Either.Either<A, E>;

export const fromEither = identity;
export const {
  // constructors
  all,
  left: fail,
  right: succeed,
  fromOption,
  fromNullable,
  // guards,
  isEither: isResult,
  isLeft: isFailure,
  isRight: isSuccess,
  // getters
  getLeft: getFailure,
  getRight: getSuccess,
  getOrElse,
  getOrNull,
  // equivalence

  // sequencing
  andThen,
  flatMap,
  // @category mapping
  map,
  mapLeft: mapFailure,
  mapBoth,
  // lifting
  liftPredicate,
  // error handling
  orElse,
} = Either;

export const getEquivalence = <Success, Failure>({
  failure,
  success,
}: {
  success: Equivalence.Equivalence<Success>;
  failure: Equivalence.Equivalence<Failure>;
}): Equivalence.Equivalence<Result<Success, Failure>> =>
  Either.getEquivalence({ left: failure, right: success });

export const match: {
  <E, B, A, C = B>(options: {
    readonly onFailure: (failure: E) => B;
    readonly onSuccess: (success: A) => C;
  }): (self: Result<A, E>) => B | C;
  <A, E, B, C = B>(
    self: Result<A, E>,
    options: {
      readonly onFailure: (failure: E) => B;
      readonly onSuccess: (success: A) => C;
    },
  ): B | C;
} = Function.dual(
  2,
  <A, E, B, C = B>(
    self: Result<A, E>,
    {
      onFailure,
      onSuccess,
    }: {
      readonly onFailure: (failure: E) => B;
      readonly onSuccess: (success: A) => C;
    },
  ): B | C => (isFailure(self) ? onFailure(self.left) : onSuccess(self.right)),
);

import type { Adapter } from '@fmnts/core';
import { IApiLocationStatistics } from '../api-model/api-model';
import {
  ILocationStatistics,
  LocationStatistics,
} from '../model/location-statistics';
import { ApiStatisticsItemAdapter } from './statistics-item.adapter';

export class ApiLocationStatisticsAdapter
  implements Adapter<ILocationStatistics>
{
  private readonly statisticsItemAdapter = new ApiStatisticsItemAdapter();

  public adapt(apiDto: IApiLocationStatistics): LocationStatistics {
    const items = apiDto.statistics.map((item) =>
      this.statisticsItemAdapter.adapt(item),
    );

    return new LocationStatistics({
      id: `${apiDto.id}`,
      name: apiDto.name,
      items,
    });
  }
}

<svg
  class="formunauts-logo-type"
  viewBox="0 0 27641 2762"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  xmlns:serif="http://www.serif.com/"
>
  <g style="fill-rule: nonzero">
    <path
      d="M0,2710.46l0,-2158.1c0,-303.789 145.99,-497.109 508.946,-497.109l1396.65,-0l0,532.617l-1329.57,-0l-0,694.375l1238.83,-0l0,528.685l-1238.83,0l-0,899.531l-576.029,0Z"
    />
    <path
      d="M4324.02,1384.82c-0,-469.493 -343.243,-832.461 -789.076,-832.461c-441.875,0 -789.062,362.968 -789.062,832.461c-0,469.492 347.187,832.474 789.062,832.474c441.875,-0 789.076,-362.982 789.076,-832.474Zm-2169.93,-0c-0,-781.172 595.742,-1376.91 1380.86,-1376.91c789.076,0 1384.81,595.742 1384.81,1376.91c0,785.117 -599.688,1376.93 -1384.81,1376.93c-785.117,0 -1380.86,-591.81 -1380.86,-1376.93Z"
    />
    <path
      d="M5984.86,587.865l-3.907,761.445l449.74,0c272.266,0 469.532,-142.031 469.532,-390.586c0,-260.39 -201.173,-370.859 -461.589,-370.859l-453.776,-0Zm-576.041,2122.59l-0,-2655.21l1021.87,-0c599.741,-0 1061.33,280.117 1061.33,844.297c-0,362.968 -213.021,643.099 -552.345,733.841c169.662,94.674 268.231,276.172 406.382,552.331l256.379,524.739l-623.307,0l-236.718,-473.45c-122.266,-244.61 -236.72,-374.805 -536.59,-374.805l-224.87,-0l-3.905,848.255l-568.229,0Z"
    />
    <path
      d="M7965.33,2710.46l359.115,-2284.35c47.266,-276.185 240.625,-422.148 508.854,-422.148c236.719,-0 438.021,102.578 501.171,359.023l445.703,1657.03c0,11.836 4.038,11.836 4.038,11.836c3.905,0 7.812,0 7.812,-11.836l438.021,-1645.19c67.057,-256.446 272.135,-370.859 512.891,-370.859c264.322,-0 453.645,149.922 493.098,433.984l327.475,2272.51l-583.856,0l-256.509,-2083.14c-0,-19.739 -3.907,-31.575 -19.662,-31.575c-11.849,-0 -19.792,7.903 -23.699,23.672l-493.227,1759.63c-55.21,201.211 -213.023,299.843 -402.344,299.843c-181.512,0 -351.174,-94.687 -398.568,-288.007l-493.099,-1771.47c-3.907,-19.714 -11.849,-23.672 -19.661,-23.672c-11.85,-0 -23.699,7.903 -23.699,27.629l-299.87,2087.08l-583.984,0Z"
    />
    <path
      d="M12029,1530.81l-0,-1475.56l576.041,-0l0,1463.72c0,461.588 189.322,686.484 579.947,686.484c390.625,0 579.948,-224.896 579.948,-686.484l0,-1463.72l576.042,-0l-0,1475.56c-0,765.377 -366.928,1223.03 -1155.99,1223.03c-792.969,0 -1155.99,-457.656 -1155.99,-1223.03Z"
    />
    <path
      d="M14956.3,2710.46l-0,-2292.25c-0,-264.323 193.359,-418.204 410.286,-418.204c173.567,-0 311.718,63.125 398.568,209.115l1049.35,1799.06l0,-1952.93l544.532,-0l0,2292.23c0,264.336 -193.359,414.271 -402.474,414.271c-169.662,0 -315.625,-59.193 -402.344,-205.156l-1049.48,-1810.92l0,1964.79l-548.437,0Z"
    />
    <path
      d="M18621.4,1653.1l777.213,0l-299.87,-1053.4c-15.755,-55.248 -39.454,-78.907 -86.718,-78.907c-43.491,0 -71.109,23.659 -86.851,78.907l-303.774,1053.4Zm-189.323,-1223.06c90.754,-287.996 327.472,-426.081 575.911,-426.081c252.603,-0 489.321,138.085 580.079,426.081l702.212,2280.42l-587.892,0l-153.774,-544.453l-1077.08,3.932l-153.907,540.521l-587.891,0l702.345,-2280.42Z"
    />
    <path
      d="M20566.4,1530.81l0,-1475.56l576.042,-0l-0,1463.72c-0,461.588 189.454,686.484 579.948,686.484c390.625,0 579.949,-224.896 579.949,-686.484l-0,-1463.72l576.041,-0l0,1475.56c0,765.377 -366.926,1223.03 -1155.99,1223.03c-792.967,0 -1155.99,-457.656 -1155.99,-1223.03Z"
    />
    <path
      d="M25359.9,587.865l-781.123,-0l-0,2122.59l-576.042,0l0,-2122.59l-781.119,-0l-0,-532.617l2138.28,-0l-0,532.617Z"
    />
    <path
      d="M25596.6,2181.77l481.379,-240.664c86.718,193.32 272.135,291.953 548.306,291.953c248.569,0 438.022,-102.578 438.022,-291.953c0,-220.924 -209.115,-256.445 -485.286,-303.789c-398.569,-67.07 -939.064,-209.102 -939.064,-808.789c-0,-465.547 418.23,-820.625 966.669,-820.625c437.89,0 816.667,201.211 986.329,579.961l-481.383,240.664c-86.718,-169.648 -260.417,-288.008 -528.644,-288.008c-216.929,0 -366.927,102.578 -366.927,264.336c0,213.047 220.964,268.282 508.983,319.57c394.532,67.071 915.365,209.102 915.365,812.748c0,477.383 -449.87,824.57 -1014.06,824.57c-477.343,0 -860.024,-201.211 -1029.68,-579.974Z"
    />
  </g>
</svg>

<svg
  *ngIf="tagline"
  class="formunauts-logo-tagline"
  [attr.preserveAspectRatio]="stretchTagline ? 'none' : null"
  viewBox="0 0 21473 744"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  xmlns:serif="http://www.serif.com/"
>
  <g style="fill-rule: nonzero">
    <path
      d="M413.191,104.349l-0,-92.084l-413.191,0l0,716.146l95.508,0l0,-306.914l276.758,0l-0,-92.083l-276.758,-0l0,-225.065l317.683,-0Z"
    />
    <path
      d="M1252.23,470.612l-0,-458.347l-96.471,0l-0,458.347c-0,35.807 -6.823,66.497 -21.445,94.114c-14.61,27.631 -34.102,48.086 -60.417,63.438c-26.315,15.338 -55.547,22.5 -89.649,22.5c-34.114,-0 -63.346,-7.162 -88.684,-22.5c-26.315,-15.352 -45.795,-35.807 -60.417,-63.438c-14.622,-27.617 -22.409,-58.307 -22.409,-94.114l-0,-458.347l-95.508,0l-0,458.347c-0,54.218 10.716,101.276 33.138,142.2c22.408,41.94 53.594,73.659 93.555,96.172c39.948,23.529 85.755,34.779 138.372,34.779c53.594,-0 101.341,-11.25 142.278,-34.779c39.949,-22.513 72.11,-54.232 94.519,-96.172c22.422,-40.924 33.138,-87.982 33.138,-142.2Z"
    />
    <path
      d="M2190.66,728.424l-0,-716.159l-96.485,0l-0,550.417l-385.898,-550.417l-98.425,0l0,716.159l95.508,0l0,-555.534l388.815,555.534l96.485,0Z"
    />
    <path
      d="M2769.49,634.297l-114.999,-0l-0,-527.904l114.999,0c33.126,0 64.311,6.146 92.578,19.44c28.256,13.307 53.595,32.734 76.003,56.276c21.445,24.544 38.008,53.19 50.677,84.909c11.693,31.719 17.54,66.497 17.54,103.333c-0,36.823 -5.847,71.615 -17.54,103.321c-12.669,31.719 -29.232,60.364 -50.677,83.893c-22.408,24.557 -47.747,42.968 -76.003,56.276c-28.267,13.294 -59.452,20.456 -92.578,20.456Zm173.451,48.086c49.701,-30.69 89.662,-72.631 117.917,-126.863c28.268,-54.218 42.877,-115.598 42.877,-185.169c0,-70.598 -14.609,-131.979 -42.877,-186.198c-28.255,-54.231 -68.216,-96.171 -117.917,-126.861c-50.664,-29.675 -108.164,-45.013 -173.451,-45.013l-210.495,-0l0,716.145l210.495,0c65.287,0 122.787,-15.351 173.451,-46.041Z"
    />
    <path
      d="M3916.47,728.424l-165.665,-295.664c7.8,-1.028 13.647,-4.101 20.469,-7.161c36.055,-18.425 65.287,-43.998 86.719,-77.761c20.469,-33.763 31.185,-71.614 31.185,-114.583c0,-43.984 -9.739,-81.849 -29.232,-115.599c-20.469,-33.763 -47.747,-59.349 -81.861,-78.776c-35.079,-19.44 -75.027,-28.646 -120.834,-27.63l-141.302,1.029l-88.672,-0l-0,716.145l95.495,0l-0,-274.179l125.716,-0l7.786,-1.029l152.032,275.208l108.164,0Zm-393.698,-624.075l131.562,-0c39.948,-0 73.087,12.279 98.425,35.807c25.326,23.529 37.994,55.247 37.994,93.099c0,24.557 -5.846,46.042 -16.562,65.482c-11.693,19.44 -26.315,34.778 -44.831,46.041c-18.515,11.25 -39.947,17.383 -63.333,17.383l-143.255,0l-0,-257.812Z"
    />
    <path
      d="M4264.36,728.424l72.109,-191.315l303.073,0l72.109,191.315l102.318,0l-274.806,-716.145l-102.316,-0l-274.818,716.145l102.331,0Zm106.21,-281.341l116.941,-312.044l117.917,312.044l-234.858,0Z"
    />
    <rect x="5104.38" y="12.279" width="95.495" height="716.146" />
    <path
      d="M5921.95,713.086c36.055,-17.396 63.348,-41.953 81.863,-73.659c18.516,-30.703 27.278,-65.482 27.278,-104.362c0,-45.013 -17.539,-86.953 -51.641,-124.805c-35.091,-37.864 -84.791,-64.453 -148.125,-80.833l-78.932,-20.456c-31.185,-8.19 -55.547,-20.469 -74.063,-36.836c-18.515,-15.338 -28.268,-38.867 -28.268,-68.541c-0,-35.808 11.694,-63.425 36.055,-83.894c23.399,-20.455 55.547,-30.69 96.485,-30.69c38.97,0 72.109,9.206 98.424,27.618c26.302,19.439 45.794,43.997 56.51,74.687l92.578,-32.734c-8.763,-28.646 -24.362,-55.248 -44.816,-78.776c-21.447,-23.529 -48.725,-42.969 -80.886,-58.321c-32.161,-14.323 -71.146,-21.484 -114.988,-21.484c-76.992,0 -135.455,18.424 -175.416,55.247c-40.925,36.836 -61.394,87.982 -61.394,153.464c-0,35.807 7.799,65.468 22.41,91.054c14.621,25.573 35.09,46.042 61.392,62.409c25.34,16.368 53.608,28.646 84.792,37.852l78.932,21.484c39.948,10.235 71.133,24.558 95.495,43.985c24.363,19.44 36.055,45.026 36.055,75.716c-0,33.763 -13.633,60.351 -40.924,79.792c-28.256,20.468 -66.263,30.69 -114.012,30.69c-39.96,-1.016 -73.097,-11.251 -100.377,-31.706c-27.291,-20.469 -45.807,-45.013 -55.547,-72.644l-92.578,31.719c5.846,21.485 15.599,40.925 30.21,60.365c14.621,19.427 32.161,35.807 54.569,51.146c21.446,15.351 46.784,27.63 76.993,36.836c29.232,9.205 62.369,13.294 98.424,13.294c52.617,-0 97.448,-9.206 133.502,-27.617Z"
    />
    <rect x="6359.49" y="12.279" width="95.509" height="716.146" />
    <path
      d="M7404.15,728.438l0,-716.159l-96.483,-0l0,550.416l-385.899,-550.416l-98.424,-0l-0,716.159l95.507,-0l-0,-555.534l388.816,555.534l96.483,-0Z"
    />
    <path
      d="M8242.2,701.836c45.807,-25.573 82.838,-62.409 109.153,-109.466c25.326,-46.042 37.996,-99.245 38.972,-160.625l-0,-73.659l-258.243,-0l0,83.893l164.702,0c-0,39.896 -8.777,75.703 -26.315,105.378c-18.516,31.705 -43.855,57.291 -76.016,74.674c-32.161,17.396 -69.193,26.601 -110.118,26.601c-50.677,0 -95.494,-12.278 -134.479,-35.807c-39.948,-23.528 -70.17,-56.263 -91.602,-98.216c-22.408,-41.94 -33.137,-89.01 -33.137,-143.229c-0,-55.247 10.729,-103.333 33.137,-145.273c22.41,-41.941 52.617,-74.688 91.602,-98.216c38.009,-23.529 82.839,-35.808 132.526,-35.808c25.339,0 50.678,4.089 76.015,13.308c24.362,9.205 47.748,22.5 68.217,38.867c20.469,16.367 38.008,34.791 50.676,56.276l76.979,-53.204c-17.538,-28.645 -39.947,-54.218 -68.214,-76.731c-28.256,-21.485 -60.417,-38.881 -94.519,-52.175c-35.092,-12.278 -71.146,-18.411 -109.154,-18.411c-69.18,-0 -129.597,15.338 -183.203,47.057c-53.593,31.719 -95.494,75.703 -125.704,130.951c-30.207,56.276 -45.806,120.729 -45.806,193.359c-0,71.614 15.599,135.052 45.806,190.3c30.21,56.262 72.111,99.231 125.704,130.95c53.606,31.706 114.023,48.086 183.203,48.086c60.429,0 114.024,-13.307 159.818,-38.88Z"
    />
    <path
      d="M9490.52,713.086c36.055,-17.383 63.346,-41.94 81.862,-73.659c18.515,-30.69 27.28,-65.469 27.28,-104.349c-0,-45.013 -17.54,-86.966 -51.642,-124.818c-35.09,-37.851 -84.791,-64.453 -148.125,-80.82l-78.932,-20.456c-31.185,-8.19 -55.547,-20.469 -74.062,-36.836c-18.516,-15.351 -28.269,-38.88 -28.269,-68.541c0,-35.808 11.692,-63.438 36.055,-83.893c23.399,-20.469 55.547,-30.691 96.484,-30.691c38.972,0 72.11,9.206 98.425,27.617c26.303,19.441 45.794,43.998 56.51,74.688l92.578,-32.747c-8.763,-28.646 -24.362,-55.235 -44.818,-78.776c-21.445,-23.529 -48.723,-42.969 -80.884,-58.308c-32.163,-14.323 -71.147,-21.484 -114.988,-21.484c-76.992,-0 -135.455,18.411 -175.417,55.247c-40.924,36.823 -61.393,87.982 -61.393,153.464c0,35.807 7.8,65.468 22.41,91.041c14.621,25.587 35.09,46.042 61.393,62.409c25.337,16.368 53.606,28.646 84.791,37.852l78.932,21.484c39.948,10.235 71.133,24.558 95.495,43.998c24.362,19.44 36.055,45.013 36.055,75.703c0,33.763 -13.633,60.364 -40.925,79.805c-28.254,20.455 -66.263,30.69 -114.01,30.69c-39.961,-1.016 -73.1,-11.251 -100.378,-31.719c-27.291,-20.456 -45.806,-45.013 -55.547,-72.63l-92.577,31.718c5.846,21.485 15.598,40.912 30.207,60.352c14.623,19.44 32.163,35.807 54.571,51.159c21.445,15.338 46.784,27.617 76.992,36.823c29.231,9.219 62.37,13.307 98.425,13.307c52.616,0 97.448,-9.206 133.502,-27.63Z"
    />
    <path
      d="M10023.6,728.438l-0,-255.769l152.994,0c41.902,0 78.933,-10.234 111.094,-29.661c32.148,-19.44 56.512,-47.071 75.027,-81.849c18.516,-34.792 27.291,-74.688 27.291,-118.672c0,-46.042 -9.738,-85.951 -29.232,-120.729c-20.469,-33.763 -47.76,-61.38 -81.861,-80.821c-35.078,-19.44 -75.04,-29.674 -120.834,-29.674l-141.301,1.029l-88.686,-0l0,716.146l95.508,-0Zm-0,-348.868l-0,-275.208l133.503,-0c26.315,-1.016 50.677,5.117 71.133,16.367c20.469,12.279 36.054,28.646 47.747,49.114c11.705,21.485 17.553,45.013 17.553,72.644c-0,39.896 -10.731,72.63 -33.139,98.203c-22.408,25.586 -50.677,38.88 -84.778,38.88l-152.019,0Z"
    />
    <path
      d="M10718.4,728.438l72.122,-191.316l303.06,0l72.122,191.316l102.318,-0l-274.804,-716.146l-102.331,-0l-274.805,716.146l102.318,-0Zm106.224,-281.342l116.939,-312.031l117.917,312.031l-234.856,0Z"
    />
    <path
      d="M11949.2,715.143c38.009,-16.367 72.111,-39.896 102.331,-69.57c30.21,-29.675 52.618,-64.454 69.18,-103.334l-85.755,-39.895c-12.669,28.646 -30.209,54.218 -50.664,75.703c-21.444,22.513 -45.808,39.909 -72.123,52.174c-26.302,13.307 -54.569,19.44 -84.778,19.44c-45.794,0 -86.732,-12.278 -122.786,-35.807c-36.055,-23.529 -64.311,-56.263 -84.778,-98.216c-20.469,-41.94 -31.185,-91.042 -31.185,-145.273c-0,-54.219 9.738,-102.305 30.207,-144.245c20.469,-41.954 47.748,-74.688 83.803,-98.216c36.068,-23.529 76.016,-35.808 121.822,-35.808c29.232,0 57.488,6.133 84.779,17.383c26.302,11.263 50.664,27.63 72.11,48.086c21.432,20.469 38.007,45.013 50.677,72.643l85.754,-40.924c-16.575,-37.852 -38.983,-70.586 -69.192,-99.232c-30.208,-27.63 -65.287,-50.13 -103.294,-66.511c-38.007,-15.338 -78.933,-23.528 -120.834,-23.528c-65.3,-0 -122.785,15.351 -172.486,47.07c-49.701,31.706 -89.661,75.703 -117.917,130.951c-28.269,56.263 -42.877,119.7 -42.877,192.331c-0,72.643 14.608,136.067 42.877,191.315c28.256,56.276 68.216,99.244 118.893,130.95c50.664,31.719 108.164,48.086 173.451,48.086c42.877,0 83.815,-8.177 122.785,-25.573Z"
    />
    <path
      d="M12844.7,104.375l0,-92.083l-410.261,-0l0,716.146l412.215,-0l-0,-92.071l-316.72,0l-0,-229.167l283.581,0l0,-90.026l-283.581,0l-0,-212.799l314.766,-0Z"
    />
    <path
      d="M14218.7,728.45l0,-716.158l-96.472,-0l-0,550.416l-385.898,-550.416l-98.426,-0l0,716.158l95.497,0l-0,-555.533l388.827,555.533l96.472,0Z"
    />
    <path
      d="M14611.4,728.45l72.108,-191.315l303.073,0l72.111,191.315l102.331,0l-274.819,-716.158l-102.318,-0l-274.805,716.158l102.319,0Zm106.223,-281.354l116.941,-312.031l117.917,312.031l-234.858,0Z"
    />
    <path
      d="M15707.7,728.45l279.687,-716.158l-102.331,-0l-230.949,603.619l-229.988,-603.619l-102.317,-0l279.675,716.158l106.223,0Z"
    />
    <rect x="16277.8" y="12.292" width="95.495" height="716.159" />
    <path
      d="M17211.4,701.849c45.794,-25.573 82.825,-62.409 109.14,-109.466c25.339,-46.042 38.009,-99.245 38.984,-160.625l0,-73.659l-258.242,-0l0,83.893l164.689,-0c-0,39.896 -8.777,75.703 -26.315,105.378c-18.518,31.705 -43.855,57.291 -76.016,74.674c-32.149,17.396 -69.18,26.602 -110.118,26.602c-50.664,-0 -95.494,-12.279 -134.479,-35.808c-39.948,-23.528 -70.157,-56.263 -91.602,-98.216c-22.408,-41.94 -33.124,-89.01 -33.124,-143.229c-0,-55.247 10.716,-103.333 33.124,-145.273c22.422,-41.94 52.63,-74.688 91.602,-98.216c38.007,-23.529 82.838,-35.808 132.538,-35.808c25.34,0 50.664,4.089 76.004,13.308c24.361,9.205 47.76,22.5 68.216,38.867c20.469,16.367 38.007,34.791 50.677,56.276l76.979,-53.204c-17.539,-28.645 -39.948,-54.218 -68.217,-76.731c-28.254,-21.485 -60.417,-38.88 -94.517,-52.175c-35.079,-12.278 -71.146,-18.411 -109.142,-18.411c-69.192,-0 -129.609,15.338 -183.202,47.057c-53.608,31.719 -95.509,75.703 -125.716,130.951c-30.208,56.276 -45.807,120.729 -45.807,193.359c-0,71.615 15.599,135.052 45.807,190.3c30.207,56.263 72.108,99.231 125.716,130.95c53.593,31.706 114.01,48.086 183.202,48.086c60.417,0 114.011,-13.307 159.819,-38.88Z"
    />
    <path
      d="M17686.9,728.45l72.107,-191.315l303.074,0l72.108,191.315l102.319,0l-274.804,-716.158l-102.32,-0l-274.803,716.158l102.319,0Zm106.223,-281.354l116.94,-312.031l117.902,312.031l-234.842,0Z"
    />
    <path
      d="M18952.8,104.375l-0,-92.083l-523.31,-0l0,92.083l213.426,-0l0,624.075l96.471,0l-0,-624.075l213.413,-0Z"
    />
    <path
      d="M19715.8,693.659c49.702,-31.706 89.648,-74.675 117.919,-130.951c28.254,-56.263 42.877,-119.7 42.877,-192.331c-0,-72.643 -14.623,-136.067 -42.877,-192.343c-28.271,-55.248 -68.217,-99.232 -118.895,-130.951c-50.678,-31.719 -109.142,-47.057 -175.403,-47.057c-66.277,-0 -124.741,15.338 -175.416,47.057c-50.665,31.719 -90.627,75.703 -118.882,130.951c-29.246,56.276 -43.853,119.7 -43.853,192.343c0,72.631 14.607,136.068 43.853,192.331c28.255,56.276 68.217,99.245 119.858,130.951c51.654,31.718 110.118,47.07 176.379,47.07c65.301,0 123.765,-15.352 174.44,-47.07Zm-299.165,-80.821c-37.031,-23.528 -66.264,-56.263 -86.733,-98.216c-20.469,-41.94 -31.185,-90.026 -31.185,-144.245c-0,-54.231 10.716,-102.317 31.185,-144.257c20.469,-41.94 48.723,-74.688 84.778,-98.216c36.056,-23.529 77.957,-35.808 124.741,-35.808c46.771,0 88.673,12.279 124.725,35.808c36.055,23.528 64.325,56.276 84.794,98.216c20.453,41.94 30.206,90.026 30.206,144.257c0,54.219 -9.753,102.305 -30.206,144.245c-20.469,41.953 -47.763,74.688 -83.815,98.216c-36.055,23.529 -76.981,35.808 -123.765,35.808c-46.771,-0 -88.673,-12.279 -124.725,-35.808Z"
    />
    <path
      d="M20689.3,728.45l-165.666,-295.677c7.801,-1.015 13.647,-4.088 20.469,-7.161c36.056,-18.412 65.289,-43.985 86.734,-77.748c20.453,-33.763 31.185,-71.614 31.185,-114.583c-0,-43.997 -9.753,-81.849 -29.246,-115.612c-20.456,-33.763 -47.747,-59.336 -81.848,-78.776c-35.079,-19.44 -75.041,-28.646 -120.833,-27.617l-141.303,1.016l-88.685,-0l-0,716.158l95.494,0l0,-274.192l125.717,-0l7.798,-1.016l152.019,275.208l108.165,0Zm-393.699,-624.075l131.563,-0c39.949,-0 73.086,12.278 98.425,35.807c25.339,23.529 38.007,55.247 38.007,93.099c0,24.557 -5.846,46.042 -16.574,65.482c-11.692,19.427 -26.303,34.779 -44.82,46.028c-18.514,11.264 -39.959,17.396 -63.346,17.396l-143.255,0l0,-257.812Z"
    />
    <path
      d="M21363.7,713.099c36.053,-17.396 63.347,-41.94 81.861,-73.659c18.504,-30.69 27.278,-65.482 27.278,-104.349c-0,-45.013 -17.538,-86.966 -51.654,-124.818c-35.076,-37.851 -84.779,-64.453 -148.112,-80.82l-78.945,-20.469c-31.17,-8.177 -55.533,-20.455 -74.05,-36.823c-18.514,-15.351 -28.267,-38.88 -28.267,-68.554c0,-35.808 11.692,-63.425 36.055,-83.881c23.384,-20.468 55.545,-30.703 96.484,-30.703c38.973,0 72.11,9.219 98.425,27.63c26.302,19.441 45.796,43.985 56.512,74.688l92.576,-32.747c-8.761,-28.646 -24.363,-55.248 -44.816,-78.777c-21.445,-23.528 -48.739,-42.968 -80.888,-58.307c-32.161,-14.323 -71.144,-21.484 -115,-21.484c-76.978,-0 -135.441,18.411 -175.403,55.234c-40.922,36.836 -61.391,87.995 -61.391,153.464c-0,35.807 7.798,65.482 22.408,91.054c14.623,25.574 35.092,46.042 61.391,62.409c25.339,16.368 53.609,28.646 84.794,37.852l78.933,21.484c39.946,10.235 71.131,24.557 95.494,43.998c24.36,19.44 36.052,45.013 36.052,75.703c0,33.763 -13.643,60.364 -40.922,79.804c-28.257,20.456 -66.264,30.69 -114.011,30.69c-39.959,-1.028 -73.1,-11.249 -100.378,-31.718c-27.291,-20.456 -45.808,-45.013 -55.545,-72.63l-92.58,31.705c5.846,21.485 15.587,40.925 30.21,60.365c14.623,19.44 32.161,35.807 54.569,51.159c21.445,15.338 46.784,27.617 76.993,36.823c29.23,9.206 62.355,13.307 98.423,13.307c52.617,0 97.449,-9.219 133.504,-27.63Z"
    />
  </g>
</svg>

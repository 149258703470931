import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Customer, CustomerId } from '@fmnts/api/shared';
import {
  ApiCustomerAdapter,
  ApiPageAdapter,
  IApiCustomer,
  PagedApiResponse,
  type Page,
} from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerApi {
  private static readonly rootUrl = '/v1/customers';

  private readonly _fromApiAdapter = new ApiCustomerAdapter();
  private readonly _fromApiPageAdapter = new ApiPageAdapter(
    this._fromApiAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(id: CustomerId): Observable<Customer> {
    const url = this.configService.buildCockpitApiUrl([
      CustomerApi.rootUrl,
      id,
    ]);

    return this.http
      .get<IApiCustomer>(url)
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<Page<Customer>> {
    const url = this.configService.buildCockpitApiUrl([CustomerApi.rootUrl]);

    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<PagedApiResponse<IApiCustomer>>(url, { params })
      .pipe(map((response) => this._fromApiPageAdapter.adapt(response)));
  }
}

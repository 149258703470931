import { InjectionToken } from '@angular/core';
import { AuthTokenEffects } from '@app/+state/effects/auth-token.effects';
import { PersistViewOptionsEffects } from '@app/+state/effects/persist-view-options.effects';
import { UserSessionEffects } from '@app/+state/effects/user-session.effects';
import { setSentryUserEffect } from '@app/core/error/sentry.effects';
import {
  filterStoreInternalActions,
  logNgrxActions,
  redactFromActionCreators,
} from '@fmnts/common/store';
import {
  AuthApiActions,
  AuthLoginPageActions,
} from '@fmnts/shared/auth/data-access';
import { routerReducer } from '@ngrx/router-store';
import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { State } from './state.model';

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('@fmnts.app.redux.root-reducers', {
  factory: () => ({
    router: routerReducer,
  }),
});

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer[] = [];

export const ROOT_EFFECTS = [
  AuthTokenEffects,
  PersistViewOptionsEffects,
  UserSessionEffects,
  {
    sentryUser: setSentryUserEffect,
    // Logs for each ngrx action
    log: logNgrxActions({
      predicate: filterStoreInternalActions,
      redact: redactFromActionCreators([
        AuthLoginPageActions.login,
        AuthApiActions.authenticate.request,
        AuthApiActions.authenticate.success,
        AuthApiActions.authenticate.failure,
      ]),
    }),
  },
];

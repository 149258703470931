<img
  class="fmnts-compliment__image"
  src="{{ image }}"
  draggable="false"
  [class.fmnts-compliment__image--disabled]="isDisabled"
  [class.fmnts-compliment__image--size-sm]="size === 'sm'"
  [class.fmnts-compliment__image--size-lg]="size === 'lg'"
/>

<div class="fmnts-compliment__label" *ngIf="labelVisible">
  {{ label }}
</div>

<div
  *ngIf="pill"
  class="fmnts-compliment__pill-drawer"
  [class.fmnts-compliment__pill-drawer--opened]="pillVisible"
>
  <div
    class="fmnts-compliment__pill"
    [class.fmnts-compliment__pill--opened]="pillVisible"
  >
    <img
      class="fmnts-compliment__image"
      src="{{ image }}"
      [class.fmnts-compliment__image--size-sm]="size === 'sm'"
      [class.fmnts-compliment__image--size-lg]="size === 'lg'"
    />
    <div class="fmnts-compliment__label fmnts-compliment__label--pill">
      {{ label }}
    </div>
  </div>
</div>

import { importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import {
  AppDiagnosticsDbSchema,
  AppFormDataTrashIdbRepository,
  AppLogsIdbRepository,
  appDiagnosticsDbStoreConfigs,
} from '@app/core/diagnostic/data-access';
import {
  AnalyticsEventsSchema,
  analyticsDbStoreConfigs,
} from '@app/shared/analytics/analytics-events.db';
import { IDBStoreConfigs, IndexedDbModule } from '@fmnts/common/indexed-db';
import type { DBSchema } from 'idb';

// TODO(kill OF): Migrate data from DB `analytics_events` from `AnalyticsModule`.
interface NextAnalyticsDbSchema extends DBSchema {
  next_analytics_events: AnalyticsEventsSchema['events'];
}
const nextAnalyticsDbStoreConfigs: IDBStoreConfigs<NextAnalyticsDbSchema> = {
  next_analytics_events: analyticsDbStoreConfigs.events,
};

/**
 * IndexedDB schema type for the formunauts web app.
 */
export type FmntsAppDbSchema = AppDiagnosticsDbSchema;

/**
 * Schema for stores that are considered "private".
 *
 * This is useful if you need to create object stores, but don't want them
 * to be exposed to the public (yet).
 */
type PrivateFmntsAppDbSchema = NextAnalyticsDbSchema;

/**
 * Complete IndexedDB schema, including both "public" and "private" object stores.
 */
type FullFmntsAppDbSchema = FmntsAppDbSchema & PrivateFmntsAppDbSchema;

/**
 * IndexedDB schema configuration for the formunauts web app.
 */
const fmntsAppDbStoreConfigs: IDBStoreConfigs<FullFmntsAppDbSchema> = {
  ...appDiagnosticsDbStoreConfigs,
  ...nextAnalyticsDbStoreConfigs,
};

/**
 * Provides the IndexedDB for the formunauts web app.
 */
export function provideFmntsAppIndexedDb() {
  return makeEnvironmentProviders([
    importProvidersFrom(
      IndexedDbModule.provideWithSchema<FmntsAppDbSchema>({
        name: '@fmnts/app',
        version: 1,
        stores: fmntsAppDbStoreConfigs,
      }),
    ),
    AppFormDataTrashIdbRepository,
    AppLogsIdbRepository,
  ]);
}

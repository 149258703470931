import { IDBStoreConfigs } from '@fmnts/common/indexed-db';
import { LogEvent } from '@fmnts/common/log';
import { DBSchema } from 'idb';

/**
 * Representation of a log entry in indexed DB.
 */
export interface DiagnosticsLogIdbModel extends LogEvent {
  id?: number;
}

/**
 * DB schema for all stores related to diagnostic logs.
 */
export interface AppDiagnosticsLogsDbSchema extends DBSchema {
  /**
   * Used for persisting log entries.
   */
  diagnostic_logs: {
    key: number;
    value: DiagnosticsLogIdbModel;
  };
}

export const appDiagnosticsLogsDbStoreConfigs: IDBStoreConfigs<AppDiagnosticsLogsDbSchema> =
  {
    diagnostic_logs: {
      key: 'id',
      autoIncrement: true,
      migrations: [],
    },
  };

import { IStatisticsItem, StatisticsItem } from './statistics-item';

export interface ICampaignStatistics {
  id: string;
  image: string;
  isOnline: boolean;
  logo: string;
  name: string;

  items: readonly Readonly<IStatisticsItem>[];
}

export class CampaignStatistics implements ICampaignStatistics {
  id: string;
  image: string;
  isOnline: boolean;
  logo: string;
  name: string;

  items: readonly Readonly<StatisticsItem>[];

  constructor(dto: Readonly<ICampaignStatistics>) {
    this.id = dto.id;
    this.image = dto.image;
    this.isOnline = dto.isOnline;
    this.logo = dto.logo;
    this.name = dto.name;

    this.items = dto.items.map((item) => new StatisticsItem(item));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IApiDate, IApiEntityId } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { map, type Observable } from 'rxjs';
import { ApiDonationFeedbackEvaluationAdapter } from '../adapter/donation-feedback-evaluation.adapter';
import type { DonationFeedbackEvaluation } from '../model/donation-feedback-evaluation';

export declare interface GetFeedbackEvaluationApiQuery {
  campaign?: IApiEntityId[];
  campaign_type?: string[];
  custom_answer_approved?: string;
  donation_date_from?: IApiDate;
  donation_date_to?: IApiDate;
  fundraiser?: IApiEntityId[];
  rating?: number[];
  team?: IApiEntityId[];
}

@Injectable({
  providedIn: 'root',
})
export class DonorFeedbackApi {
  private static readonly rootUrl = '/v1/feedbacks';

  private readonly _fromApiAdapter = new ApiDonationFeedbackEvaluationAdapter();

  private readonly apiHelper = inject(ApiRequestHelper);
  private readonly configService = inject(ApiConfigService);
  private readonly http = inject(HttpClient);

  /**
   * @param urlOrFilter options for filtering
   *
   * @returns
   * An observable that emits a paged list of locations
   */
  public getEvaluation(
    urlOrFilter: GetFeedbackEvaluationApiQuery,
  ): Observable<DonationFeedbackEvaluation> {
    const url = this.configService.buildCockpitApiUrl([
      DonorFeedbackApi.rootUrl,
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get(url, { params })
      .pipe(map((response) => this._fromApiAdapter.adapt(response)));
  }
}

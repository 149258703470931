import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeSize, ThemeSizeSmall } from '@fmnts/components';
import { ColorVariant } from './ci-colors';

/**
 * Component that displays the formunauts rocket
 */
@Component({
  selector: 'fmnts-rocket',
  templateUrl: './rocket.component.html',
  styleUrls: ['./rocket.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RocketComponent {
  private componentClass = 'rocket';

  /** If `true`, the thrusts are animated. */
  @Input() get animateThrusts(): boolean {
    return this._animateThrusts;
  }
  set animateThrusts(newValue: BooleanInput) {
    this._animateThrusts = coerceBooleanProperty(newValue);
  }
  private _animateThrusts = false;

  /** Color scheme to use. */
  @Input() color?: ColorVariant;
  /**
   * Used to control the size of the rocket.
   * If not provided, the size will be determined by inheriting
   * the font-size.
   */
  @Input() size?: ThemeSizeSmall | ThemeSize;

  @HostBinding('class') get hostClasses(): string {
    return classnames([
      this.componentClass,
      this.color && `${this.componentClass}--color-${this.color}`,
      this.size && `${this.componentClass}--size-${this.size}`,
    ]);
  }
}

import {
  FocusMonitor,
  FocusTrapFactory,
  InteractivityChecker,
} from '@angular/cdk/a11y';
import { CdkDialogContainer } from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ANIMATION_MODULE_TYPE,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import {
  AnimationDurations,
  ThemeDurationToken,
  ThemeDurations,
} from '@fmnts/components/core';
import { ModalConfig } from './modal-config';

/** Event that captures the state of modal container animations. */
interface LegacyDialogAnimationEvent {
  state: 'opened' | 'opening' | 'closing' | 'closed';
  totalTime: number;
}

/** Class added when the modal is open. */
const OPEN_CLASS = 'fmnts-modal--open';

/** Class added while the modal is opening. */
const OPENING_CLASS = 'fmnts-modal--opening';

/** Class added while the modal is closing. */
const CLOSING_CLASS = 'fmnts-modal--closing';

/** Duration token to use for the opening animation. */
export const OPEN_ANIMATION_DURATION: ThemeDurationToken = 'duration.short-3';

/** Duration token to use for the closing animation. */
export const CLOSE_ANIMATION_DURATION: ThemeDurationToken = 'duration.short-2';

/**
 * Base class for the `ModalContainer`. The base class does not implement
 * animations as these are left to implementers of the modal container.
 */
@Component({ template: '' })
export abstract class ModalContainerBaseComponent extends CdkDialogContainer<ModalConfig> {
  /**
   * Emits when an animation state changes.
   *
   * @internal
   */
  _animationStateChanged = new EventEmitter<LegacyDialogAnimationEvent>();

  constructor(
    private elementRef: ElementRef,
    // initialized with deprecated FocusTrapFactory because in CdkDialogContainer it will also be used
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) _document: Document,
    modalConfig: ModalConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor?: FocusMonitor,
  ) {
    super(
      elementRef,
      focusTrapFactory,
      _document,
      modalConfig,
      interactivityChecker,
      ngZone,
      overlayRef,
      focusMonitor,
    );
  }

  /**
   * Starts the modal exit animation.
   *
   * @internal
   */
  abstract _startExitAnimation(): void;

  protected override _captureInitialFocus(): void {
    if (!this._config.delayFocusTrap) {
      this._trapFocus();
    }
  }

  /**
   * Callback for when the open modal animation has finished. Intended to
   * be called by sub-classes that use different animation implementations.
   */
  protected _openAnimationDone(totalTime: number): void {
    if (this._config.delayFocusTrap) {
      this._trapFocus();
    }

    this._animationStateChanged.next({ state: 'opened', totalTime });
  }
}

const TRANSITION_DURATION_PROPERTY = '--fmnts-modal--transition-duration';

/**
 * Internal component that wraps user-provided modal content in a modal.
 */
@Component({
  selector: `fmnts-modal`,
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [CdkPortalOutlet],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'fmnts-modal',
    tabindex: '-1',
    '[attr.aria-modal]': '_config.ariaModal',
    '[id]': '_config.id',
    '[attr.role]': '_config.role',
    '[attr.aria-labelledby]':
      '_config.ariaLabel ? null : _ariaLabelledByQueue[0]',
    '[attr.aria-label]': '_config.ariaLabel',
    '[attr.aria-describedby]': '_config.ariaDescribedBy || null',
    '[class._fmnts-animation-noopable]': '!_animationsEnabled',
  },
})
export class ModalContainerComponent
  extends ModalContainerBaseComponent
  implements OnDestroy
{
  /** Whether animations are enabled. */
  _animationsEnabled: boolean = this._animationMode !== 'NoopAnimations';

  /** Host element of the modal container component. */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  private _hostElement: HTMLElement = this._elementRef.nativeElement;

  /** Duration of the modal open animation. */
  private _openAnimationDuration = this._animationsEnabled
    ? (this._config.enterAnimationDuration ?? OPEN_ANIMATION_DURATION)
    : null;

  /** Duration of the dialog close animation. */
  private _closeAnimationDuration = this._animationsEnabled
    ? (this._config.exitAnimationDuration ?? CLOSE_ANIMATION_DURATION)
    : null;

  /** Current timer for modal animations. */
  private _animationTimer: number | null = null;

  constructor(
    elementRef: ElementRef,
    // initialized with deprecated FocusTrapFactory because in ModalContainerBaseComponent it will also be used
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) document: Document,
    modalConfig: ModalConfig,
    checker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) private _animationMode?: string,
    focusMonitor?: FocusMonitor,
  ) {
    super(
      elementRef,
      focusTrapFactory,
      document,
      modalConfig,
      checker,
      ngZone,
      overlayRef,
      focusMonitor,
    );
  }

  protected override _contentAttached(): void {
    // Delegate to the original modal-container initialization (i.e. saving the
    // previous element, setting up the focus trap and moving focus to the container).
    super._contentAttached();
    this._startOpenAnimation();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this._animationTimer !== null) {
      clearTimeout(this._animationTimer);
    }
  }

  /** Starts the modal open animation if enabled. */
  private _startOpenAnimation() {
    const openDurationInMs = this._openAnimationDuration
      ? AnimationDurations[this._openAnimationDuration]
      : 0;
    this._animationStateChanged.emit({
      state: 'opening',
      totalTime: openDurationInMs,
    });

    if (this._animationsEnabled && this._openAnimationDuration) {
      // One would expect that the open class is added once the animation finished, but Modal
      // uses the open class in combination with the opening class to start the animation.
      this._hostElement.style.setProperty(
        TRANSITION_DURATION_PROPERTY,
        ThemeDurations[this._openAnimationDuration],
      );
      this._hostElement.classList.add(OPENING_CLASS);
      this._hostElement.classList.add(OPEN_CLASS);
      this._waitForAnimationToComplete(openDurationInMs, this._finishModalOpen);
    } else {
      this._hostElement.classList.add(OPEN_CLASS);
      // Note: We could immediately finish the modal opening here with noop animations,
      // but we defer until next tick so that consumers can subscribe to `afterOpened`.
      // Executing this immediately would mean that `afterOpened` emits synchronously
      // on `modal.open` before the consumer had a change to subscribe to `afterOpened`.
      void Promise.resolve().then(() => this._finishModalOpen());
    }
  }

  /**
   * Starts the exit animation of the modal if enabled. This method is
   * called by the modal ref.
   */
  _startExitAnimation(): void {
    const closeDurationInMs = this._closeAnimationDuration
      ? AnimationDurations[this._closeAnimationDuration]
      : 0;
    this._animationStateChanged.emit({
      state: 'closing',
      totalTime: closeDurationInMs,
    });
    this._hostElement.classList.remove(OPEN_CLASS);

    if (this._animationsEnabled && this._closeAnimationDuration) {
      this._hostElement.style.setProperty(
        TRANSITION_DURATION_PROPERTY,
        ThemeDurations[this._closeAnimationDuration],
      );
      this._hostElement.classList.add(CLOSING_CLASS);
      this._waitForAnimationToComplete(
        closeDurationInMs,
        this._finishModalClose,
      );
    } else {
      // This subscription to the `OverlayRef#backdropClick` observable in the `ModalRef` is
      // set up before any user can subscribe to the backdrop click. The subscription triggers
      // the modal close and this method synchronously. If we'd synchronously emit the `CLOSED`
      // animation state event if animations are disabled, the overlay would be disposed
      // immediately and all other subscriptions to `ModalRef#backdropClick` would be silently
      // skipped. We work around this by waiting with the modal close until the next tick when
      // all subscriptions have been fired as expected. This is not an ideal solution, but
      // there doesn't seem to be any other good way. Alternatives that have been considered:
      //   2. Ensuring that user subscriptions to `backdropClick`, `keydownEvents` in the modal
      //      ref are first. This would solve the issue, but has the risk of memory leaks and also
      //      doesn't solve the case where consumers call `ModalRef.close` in their subscriptions.
      void Promise.resolve().then(() => this._finishModalClose());
    }
  }

  /**
   * Completes the modal open by clearing potential animation classes, trapping
   * focus and emitting an opened event.
   */
  private _finishModalOpen = () => {
    const openDurationInMs = this._openAnimationDuration
      ? AnimationDurations[this._openAnimationDuration]
      : 0;
    this._clearAnimationClasses();
    this._openAnimationDone(openDurationInMs);
  };

  /**
   * Completes the modal close by clearing potential animation classes, restoring
   * focus and emitting a closed event.
   */
  private _finishModalClose = () => {
    const closeDurationInMs = this._closeAnimationDuration
      ? AnimationDurations[this._closeAnimationDuration]
      : 0;
    this._clearAnimationClasses();
    this._animationStateChanged.emit({
      state: 'closed',
      totalTime: closeDurationInMs,
    });
  };

  /** Clears all dialog animation classes. */
  private _clearAnimationClasses() {
    this._hostElement.classList.remove(OPENING_CLASS);
    this._hostElement.classList.remove(CLOSING_CLASS);
  }

  private _waitForAnimationToComplete(duration: number, callback: () => void) {
    if (this._animationTimer !== null) {
      clearTimeout(this._animationTimer);
    }

    // Note that we want this timer to run inside the NgZone, because we want
    // the related events like `afterClosed` to be inside the zone as well.
    this._animationTimer = window.setTimeout(callback, duration);
  }
}

import { ChartMetric } from '../chart-metric';

/**
 * Labeling Options for Charts
 */
export enum D3LabelOption {
  /**
   * Labels with absolute values
   *
   * Prints the value next to the name, e.g. `Label (15)`
   */
  AbsoluteValues = 'absolute_values',
  /**
   * Labels with Percentages
   *
   * Prints the percentage next to the name, e.g. `Label (15.00%)`
   */
  Percentage = 'percentage',
}

export const labelWithAbsoluteValues = (data: ChartMetric): string =>
  `${data.name} (${data.value})`;

export function labelWithRelativeValue(
  total: number,
): (data: ChartMetric) => string {
  return (data: ChartMetric) => {
    const percentage = (100 * data.value) / total;
    return `${data.name} (${percentage.toFixed(2)}%)`;
  };
}

/**
 * Updates the Label-Function
 *
 * @returns a Function that can be used for
 */
export function updateLabelFn(
  labeling: D3LabelOption,
  data: ChartMetric[],
): (data: ChartMetric) => string {
  switch (labeling) {
    case D3LabelOption.AbsoluteValues:
      return labelWithAbsoluteValues;
    case D3LabelOption.Percentage:
      return labelWithRelativeValue(getTotal(data));
    default:
      return labelWithAbsoluteValues;
  }
}

function getTotal(data: ChartMetric[]): number {
  return data.reduce((acc, cur) => acc + cur.value, 0);
}

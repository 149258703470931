import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ExternalUrlResolver {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const targetPath = (route.data.url || state.url).replace(
      /([^\/])(#|$)/,
      '$1/$2',
    );

    // Only set the location, if we have different paths
    if (window.location.pathname !== targetPath) {
      window.location.href = targetPath;
    }
  }
}

import { Injectable } from '@angular/core';
import { Auth } from '@formunauts/shared/domain';
import * as Arr from 'effect/Array';
import * as Fn from 'effect/Function';
import * as O from 'effect/Option';

/**
 * Util service that checks if a user (`AuthInfo`) has sufficient
 * permissions.
 */
@Injectable({ providedIn: 'root' })
export class PermissionService {
  /**
   * @deprecated Use `AuthStore` instead.
   *
   * Checks if the given `authInfo` fulfills any of the given permissions.
   *
   * ***NOTE***
   * Make sure to pass permissions, otherwise an error will be thrown.
   *
   * @param authInfo
   * @param permissions Permissions to check. There must be at least 1 item.
   * @returns
   * `true` if `authInfo` fulfills some permission, otherwise false.
   */
  public hasSome(
    authInfo: Auth.LegacyAuthInfo,
    permissions: ReadonlyArray<Auth.Permission>,
  ): boolean {
    if (!Arr.isNonEmptyReadonlyArray(permissions)) {
      throw new Error(
        'Passed permissions are empty. You must pass at least one permission.',
      );
    }

    return Fn.pipe(
      O.fromNullable(authInfo),
      O.map(Auth.legacyAuthInfoToAuthInfo),
      O.map(Auth.hasSomePermission(permissions)),
      O.getOrElse(Fn.constFalse),
    );
  }

  /**
   * @deprecated use `AuthStore` instead.
   *
   * Checks if the given `authInfo` fulfills every of the given permissions.
   *
   * ***NOTE***
   * Make sure to pass permissions, otherwise an error will be thrown.
   *
   * @param authInfo
   * @param permissions Permissions to check. There must be at least 1 item.
   * @returns
   * `true` if `authInfo` fulfills every permission, otherwise false.
   */
  public hasEvery(
    authInfo: Auth.LegacyAuthInfo,
    permissions: ReadonlyArray<Auth.Permission>,
  ): boolean {
    if (!Arr.isNonEmptyReadonlyArray(permissions)) {
      throw new Error(
        'Passed permissions are empty. You must pass at least one permission.',
      );
    }

    return Fn.pipe(
      O.fromNullable(authInfo),
      O.map(Auth.legacyAuthInfoToAuthInfo),
      O.map(Auth.hasEveryPermission(permissions)),
      O.getOrElse(Fn.constFalse),
    );
  }

  /**
   * @deprecated use `AuthStore` instead.
   */
  public hasPermission(
    authInfo: Auth.LegacyAuthInfo,
    permission: Auth.Permission,
  ): boolean {
    return this.hasSome(authInfo, [permission]);
  }
}

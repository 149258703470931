import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiFileResponse } from '@fmnts/api/shared';
import { ApiConfigService, ApiRequestHelper } from '@fmnts/api/util';
import { TimeSpan } from '@fmnts/core/chronos';
import { map, type Observable } from 'rxjs';
import { ApiLocationStatisticsAdapter } from '../adapter/location-statistics.adapter';
import { ApiStatisticsPageAdapter } from '../adapter/statistics-page.adapter';
import {
  IApiLocationStatistics,
  IApiStatisticsPage,
} from '../api-model/api-model';
import type { LocationStatistics } from '../model/location-statistics';
import type { StatisticsPage } from '../model/statistics-page';

@Injectable({
  providedIn: 'root',
})
export class DonationLocationStatisticsApi {
  public static readonly rootUrl = '/v1/location-stats';

  private readonly apiToLocationStatsAdapter =
    new ApiLocationStatisticsAdapter();
  private readonly apiToLocationStatsPageAdapter = new ApiStatisticsPageAdapter(
    this.apiToLocationStatsAdapter,
  );

  constructor(
    private apiHelper: ApiRequestHelper,
    private configService: ApiConfigService,
    private http: HttpClient,
  ) {}

  public get(id: string, dateRange: TimeSpan): Observable<LocationStatistics> {
    const url = this.configService.buildCockpitApiUrl([
      DonationLocationStatisticsApi.rootUrl,
      id,
    ]);
    const params = {
      date_from: dateRange[0].format('YYYY-MM-DD'),
      date_to: dateRange[1].format('YYYY-MM-DD'),
    };

    return this.http
      .get<IApiLocationStatistics>(url, { params })
      .pipe(map((response) => this.apiToLocationStatsAdapter.adapt(response)));
  }

  public list(
    urlOrFilter: string | Record<string, any> = {},
  ): Observable<StatisticsPage<LocationStatistics>> {
    const url = this.configService.buildCockpitApiUrl([
      DonationLocationStatisticsApi.rootUrl,
    ]);
    const params = this.apiHelper.makeParams(urlOrFilter);

    return this.http
      .get<IApiStatisticsPage<IApiLocationStatistics>>(url, { params })
      .pipe(
        map((response) => this.apiToLocationStatsPageAdapter.adapt(response)),
      );
  }

  /**
   *
   * @param format Chosen download format (e.g. "csv", "xslx")
   * @param filters Filters used for download
   */
  public downloadStatisticsFile(
    format: string,
    filters: string | Record<string, any>,
  ): Observable<ApiFileResponse> {
    const url = this.configService.buildCockpitApiUrl([
      DonationLocationStatisticsApi.rootUrl,
      format,
    ]);
    const params = this.apiHelper.makeParams(filters);

    return this.http.get<ApiFileResponse>(url, { params });
  }
}

/**
 * Version of the app bundle.
 */
export interface BundleVersion {
  readonly major: string;
  readonly minor: string;
  readonly patch: string;
}

/**
 * The release or version number of the app bundle.
 * @param version Ver
 * @returns
 */
export function bundleShortVersionString(version: BundleVersion): string {
  return [version.major, version.minor, version.patch].join('.');
}

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { classnames } from '@fmnts/common';
import { ThemeColor } from '@fmnts/components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ToastRef } from './toast-component-ref';
import { TOAST_DATA, TOAST_DURATION } from './toast-config';

export interface TextOnlyToastData {
  title: string;
  message?: string;
  actions?: ToastAction[];
  icon: IconProp;
  color?: ThemeColor;
}

/**
 * Base interface for a toast component that can be instantiated from data.
 */
export interface TextOnlyToast {
  data: TextOnlyToastData;
}

type ToastActionId = string;
export interface ToastAction {
  /** Identifier with which the toast will be dismissed when clicked. */
  actionId: ToastActionId;
  content: string;
}

@Component({
  selector: 'fmnts-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('timeoutTrigger', [
      state('*', style({ width: '0' })),
      transition(
        '* => run',
        [animate('{{ time }}ms', style({ width: '100%' }))],
        { params: { time: 0 } },
      ),
    ]),
  ],
})
export class ToastComponent implements TextOnlyToast, AfterViewInit {
  @HostBinding('class.fmnts-toast') protected readonly componentClass =
    'fmnts-toast';
  @HostBinding('class') get hostClasses(): string {
    return classnames([
      this.componentClass,
      this.data.color && `${this.componentClass}-${this.data.color}`,
    ]);
  }

  protected _timeoutState = 'new';
  protected readonly iconClose = faTimes;

  constructor(
    public toastRef: ToastRef<ToastComponent, ToastActionId>,
    @Inject(TOAST_DATA)
    public data: TextOnlyToastData,
    @Inject(TOAST_DURATION) public duration: number,
    private _cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    // For some reason Angular does not animate this using :enter
    // when a previous toast was shown (e.g. using toast service).
    // so instead we offset a change, so that change detection
    // first resets the timeout bar and start the animation later.
    // If you know a better way, pls fix it^^
    setTimeout(() => {
      this._timeoutState = 'run';
      this._cd.markForCheck();
    }, 0);
  }

  /** Performs the close on the toast. */
  close(): void {
    this.toastRef.dismiss();
  }

  closeWithAction(action: ToastAction): void {
    this.toastRef.dismissWithAction(action.actionId);
  }

  /** If the action button should be shown. */
  get hasAction(): boolean {
    return !!this.data.actions;
  }
}
